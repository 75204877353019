import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const co2Data10k = [
    {
      "title": "Ice Core + Mauna Loa Data",
      "strokeWidth": 2,
      "data": [
        {
          "x": 1,
          "y": 407.38
        },
        {
          "x": 2,
          "y": 405
        },
        {
          "x": 3,
          "y": 402.86
        },
        {
          "x": 4,
          "y": 399.41
        },
        {
          "x": 5,
          "y": 397.12
        },
        {
          "x": 6,
          "y": 395.19
        },
        {
          "x": 7,
          "y": 392.46
        },
        {
          "x": 8,
          "y": 390.45
        },
        {
          "x": 9,
          "y": 388.57
        },
        {
          "x": 10,
          "y": 386.29
        },
        {
          "x": 11,
          "y": 384.79
        },
        {
          "x": 12,
          "y": 382.68
        },
        {
          "x": 13,
          "y": 380.94
        },
        {
          "x": 14,
          "y": 378.81
        },
        {
          "x": 15,
          "y": 376.79
        },
        {
          "x": 16,
          "y": 374.96
        },
        {
          "x": 17,
          "y": 372.42
        },
        {
          "x": 18,
          "y": 370.41
        },
        {
          "x": 19,
          "y": 368.84
        },
        {
          "x": 20,
          "y": 367.64
        },
        {
          "x": 21,
          "y": 365.54
        },
        {
          "x": 22,
          "y": 362.9
        },
        {
          "x": 23,
          "y": 361.79
        },
        {
          "x": 24,
          "y": 360.04
        },
        {
          "x": 25,
          "y": 358.21
        },
        {
          "x": 26,
          "y": 356.71
        },
        {
          "x": 27,
          "y": 356
        },
        {
          "x": 28,
          "y": 355.29
        },
        {
          "x": 29,
          "y": 353.98
        },
        {
          "x": 30,
          "y": 352.79
        },
        {
          "x": 31,
          "y": 351.15
        },
        {
          "x": 32,
          "y": 348.62
        },
        {
          "x": 33,
          "y": 346.87
        },
        {
          "x": 34,
          "y": 345.47
        },
        {
          "x": 35,
          "y": 343.99
        },
        {
          "x": 36,
          "y": 342.44
        },
        {
          "x": 37,
          "y": 340.76
        },
        {
          "x": 38,
          "y": 340
        },
        {
          "x": 39,
          "y": 338.8
        },
        {
          "x": 40,
          "y": 336.84
        },
        {
          "x": 41,
          "y": 335.4
        },
        {
          "x": 42,
          "y": 333.83
        },
        {
          "x": 43,
          "y": 332.04
        },
        {
          "x": 44,
          "y": 331.11
        },
        {
          "x": 45,
          "y": 330.18
        },
        {
          "x": 46,
          "y": 329.68
        },
        {
          "x": 47,
          "y": 327.45
        },
        {
          "x": 48,
          "y": 326.32
        },
        {
          "x": 49,
          "y": 325.68
        },
        {
          "x": 50,
          "y": 324.62
        },
        {
          "x": 51,
          "y": 323.04
        },
        {
          "x": 52,
          "y": 322.16
        },
        {
          "x": 53,
          "y": 321.38
        },
        {
          "x": 54,
          "y": 320.04
        },
        {
          "x": 55,
          "y": 319.62
        },
        {
          "x": 56,
          "y": 318.99
        },
        {
          "x": 57,
          "y": 318.45
        },
        {
          "x": 58,
          "y": 317.64
        },
        {
          "x": 59,
          "y": 316.91
        },
        {
          "x": 60,
          "y": 315.97
        },
        {
          "x": 137,
          "y": 280.4
        },
        {
          "x": 268,
          "y": 274.9
        },
        {
          "x": 279,
          "y": 277.9
        },
        {
          "x": 395,
          "y": 279.1
        },
        {
          "x": 404,
          "y": 281.9
        },
        {
          "x": 485,
          "y": 277.7
        },
        {
          "x": 559,
          "y": 281.1
        },
        {
          "x": 672,
          "y": 282.2
        },
        {
          "x": 754,
          "y": 280.1
        },
        {
          "x": 877,
          "y": 278.4
        },
        {
          "x": 950,
          "y": 276.6
        },
        {
          "x": 1060,
          "y": 279.1
        },
        {
          "x": 1153,
          "y": 277.7
        },
        {
          "x": 1233,
          "y": 278.7
        },
        {
          "x": 1350,
          "y": 277.4
        },
        {
          "x": 1453,
          "y": 279.2
        },
        {
          "x": 1552,
          "y": 280
        },
        {
          "x": 1638,
          "y": 278.9
        },
        {
          "x": 1733,
          "y": 278.7
        },
        {
          "x": 1812,
          "y": 278
        },
        {
          "x": 1931,
          "y": 276.9
        },
        {
          "x": 2057,
          "y": 276.7
        },
        {
          "x": 2128,
          "y": 276.7
        },
        {
          "x": 2212,
          "y": 277.6
        },
        {
          "x": 2334,
          "y": 277.9
        },
        {
          "x": 2433,
          "y": 273.9
        },
        {
          "x": 2536,
          "y": 278.9
        },
        {
          "x": 2604,
          "y": 275.3
        },
        {
          "x": 2728,
          "y": 274.7
        },
        {
          "x": 2806,
          "y": 276.3
        },
        {
          "x": 2902,
          "y": 274.6
        },
        {
          "x": 3053,
          "y": 276.3
        },
        {
          "x": 3116,
          "y": 273.1
        },
        {
          "x": 3215,
          "y": 274
        },
        {
          "x": 3336,
          "y": 275
        },
        {
          "x": 3453,
          "y": 273.4
        },
        {
          "x": 3523,
          "y": 273
        },
        {
          "x": 3622,
          "y": 271.5
        },
        {
          "x": 3721,
          "y": 275.4
        },
        {
          "x": 3790,
          "y": 274.9
        },
        {
          "x": 3910,
          "y": 271.7
        },
        {
          "x": 4004,
          "y": 271.6
        },
        {
          "x": 4096,
          "y": 272.8
        },
        {
          "x": 4161,
          "y": 271.5
        },
        {
          "x": 4324,
          "y": 271.1
        },
        {
          "x": 4374,
          "y": 269.1
        },
        {
          "x": 4480,
          "y": 269.8
        },
        {
          "x": 4573,
          "y": 271.5
        },
        {
          "x": 4703,
          "y": 270.7
        },
        {
          "x": 4766,
          "y": 269.3
        },
        {
          "x": 4874,
          "y": 268.6
        },
        {
          "x": 5004,
          "y": 269.8
        },
        {
          "x": 5094,
          "y": 267.6
        },
        {
          "x": 5160,
          "y": 265.3
        },
        {
          "x": 5274,
          "y": 265.2
        },
        {
          "x": 5370,
          "y": 267.6
        },
        {
          "x": 5476,
          "y": 265.9
        },
        {
          "x": 5562,
          "y": 265.5
        },
        {
          "x": 5657,
          "y": 260.7
        },
        {
          "x": 5716,
          "y": 266.7
        },
        {
          "x": 5855,
          "y": 265.5
        },
        {
          "x": 5998,
          "y": 263.2
        },
        {
          "x": 6039,
          "y": 262.7
        },
        {
          "x": 6131,
          "y": 261.2
        },
        {
          "x": 6263,
          "y": 261.1
        },
        {
          "x": 6354,
          "y": 259.4
        },
        {
          "x": 6434,
          "y": 262.1
        },
        {
          "x": 6545,
          "y": 262.9
        },
        {
          "x": 6617,
          "y": 258.1
        },
        {
          "x": 6713,
          "y": 257.6
        },
        {
          "x": 6838,
          "y": 262.3
        },
        {
          "x": 6941,
          "y": 263
        },
        {
          "x": 7028,
          "y": 260.7
        },
        {
          "x": 7112,
          "y": 258.4
        },
        {
          "x": 7234,
          "y": 260.1
        },
        {
          "x": 7320,
          "y": 260.4
        },
        {
          "x": 7413,
          "y": 259.7
        },
        {
          "x": 7507,
          "y": 259.2
        },
        {
          "x": 7590,
          "y": 260.8
        },
        {
          "x": 7691,
          "y": 259.6
        },
        {
          "x": 7781,
          "y": 259.3
        },
        {
          "x": 7876,
          "y": 258.3
        },
        {
          "x": 7990,
          "y": 261.3
        },
        {
          "x": 8050,
          "y": 260.7
        },
        {
          "x": 8181,
          "y": 261.8
        },
        {
          "x": 8281,
          "y": 259
        },
        {
          "x": 8387,
          "y": 260.9
        },
        {
          "x": 8477,
          "y": 260.4
        },
        {
          "x": 8579,
          "y": 259.3
        },
        {
          "x": 8653,
          "y": 262
        },
        {
          "x": 8784,
          "y": 263.7
        },
        {
          "x": 8869,
          "y": 263.8
        },
        {
          "x": 8973,
          "y": 265.2
        },
        {
          "x": 9092,
          "y": 260.6
        },
        {
          "x": 9140,
          "y": 260.9
        },
        {
          "x": 9232,
          "y": 263
        },
        {
          "x": 9317,
          "y": 263.8
        },
        {
          "x": 9536,
          "y": 264.4
        },
        {
          "x": 9597,
          "y": 264.2
        },
        {
          "x": 9721,
          "y": 264
        },
        {
          "x": 9807,
          "y": 263.4
        },
        {
          "x": 9909,
          "y": 265.7
        },
        {
          "x": 9983,
          "y": 264.9
        },
        {
          "x": 10088,
          "y": 267.5
        }
      ]
    }
  ]

function Co210kyapresent({ data }) {
    return <div style={{ display: 'block', height: '300px', maxWidth: '640px' }}>
        <ResponsiveLine
            data={co2Data10k}
            margin={{ top: 50, right: 10, bottom: 30, left: 40 }}
            xScale={{ type: 'linear', reverse: true }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickValues:[1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000],
                format: '.1s',
                tickRotation: 0
            }}
            axisLeft={{
                orient: 'left',                
                tickValues:[0,100, 200, 300, 400],
                tickRotation: 0
            }}
            colors={['#63A2EC']}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="PPM"
            pointLabelYOffset={-12}
            useMesh={true}
            animate={false}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 1
                        }
                      },
                },
                grid: {
                    line: {
                        stroke: "#89969F"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
        />
    </div>
}

export default Co210kyapresent;