import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const phxHourlyTempData = [
    {
      "title": "Avg July in 1950s",
      "strokeWidth": 2,
      "color": '#63A2EC',
      "data": [
        {
          "x": "1949-07-01T00:00:00",
          "y": 83.8,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T01:00:00",
          "y": 83.1,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T02:00:00",
          "y": 81.3,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T03:00:00",
          "y": 80.4,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T04:00:00",
          "y": 79.7,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T05:00:00",
          "y": 78,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T06:00:00",
          "y": 77.9,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T07:00:00",
          "y": 82.8,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T08:00:00",
          "y": 87.7,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T09:00:00",
          "y": 91.8,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T10:00:00",
          "y": 94.8,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T11:00:00",
          "y": 97.4,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T12:00:00",
          "y": 99.7,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T13:00:00",
          "y": 101.4,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T14:00:00",
          "y": 102.9,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T15:00:00",
          "y": 104.5,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T16:00:00",
          "y": 104.3,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T17:00:00",
          "y": 104,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T18:00:00",
          "y": 103.4,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T19:00:00",
          "y": 101,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T20:00:00",
          "y": 96.5,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T21:00:00",
          "y": 92.7,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T22:00:00",
          "y": 91,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-01T23:00:00",
          "y": 87.4,
          "decade": "1950-1959",
          "day": 1,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T00:00:00",
          "y": 86,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T01:00:00",
          "y": 84.2,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T02:00:00",
          "y": 83,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T03:00:00",
          "y": 81.1,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T04:00:00",
          "y": 79.6,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T05:00:00",
          "y": 77.9,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T06:00:00",
          "y": 79,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T07:00:00",
          "y": 83.8,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T08:00:00",
          "y": 88.4,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T09:00:00",
          "y": 92.1,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T10:00:00",
          "y": 94.7,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T11:00:00",
          "y": 96.7,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T12:00:00",
          "y": 99.5,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T13:00:00",
          "y": 101.6,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T14:00:00",
          "y": 103.3,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T15:00:00",
          "y": 104.5,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T16:00:00",
          "y": 104.6,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T17:00:00",
          "y": 103.9,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T18:00:00",
          "y": 102,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T19:00:00",
          "y": 99.5,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T20:00:00",
          "y": 95.5,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T21:00:00",
          "y": 92.7,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T22:00:00",
          "y": 90.5,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-02T23:00:00",
          "y": 87.7,
          "decade": "1950-1959",
          "day": 2,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T00:00:00",
          "y": 86.1,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T01:00:00",
          "y": 84.4,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T02:00:00",
          "y": 82.9,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T03:00:00",
          "y": 81.3,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T04:00:00",
          "y": 79.6,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T05:00:00",
          "y": 79.2,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T06:00:00",
          "y": 78.7,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T07:00:00",
          "y": 82.7,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T08:00:00",
          "y": 87.9,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T09:00:00",
          "y": 91.8,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T10:00:00",
          "y": 94.2,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T11:00:00",
          "y": 95.8,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T12:00:00",
          "y": 98.2,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T13:00:00",
          "y": 99.4,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T14:00:00",
          "y": 101,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T15:00:00",
          "y": 102,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T16:00:00",
          "y": 103,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T17:00:00",
          "y": 102.8,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T18:00:00",
          "y": 102,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T19:00:00",
          "y": 99.5,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T20:00:00",
          "y": 95.1,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T21:00:00",
          "y": 91.5,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T22:00:00",
          "y": 89.7,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-03T23:00:00",
          "y": 88,
          "decade": "1950-1959",
          "day": 3,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T00:00:00",
          "y": 85.6,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T01:00:00",
          "y": 83.8,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T02:00:00",
          "y": 82.3,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T03:00:00",
          "y": 80.5,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T04:00:00",
          "y": 79,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T05:00:00",
          "y": 77.5,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T06:00:00",
          "y": 78.8,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T07:00:00",
          "y": 83.4,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T08:00:00",
          "y": 87.8,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T09:00:00",
          "y": 91.4,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T10:00:00",
          "y": 93.8,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T11:00:00",
          "y": 96.2,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T12:00:00",
          "y": 98.6,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T13:00:00",
          "y": 100.2,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T14:00:00",
          "y": 101.1,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T15:00:00",
          "y": 102.3,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T16:00:00",
          "y": 102.7,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T17:00:00",
          "y": 102.3,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T18:00:00",
          "y": 101.7,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T19:00:00",
          "y": 98.9,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T20:00:00",
          "y": 95.7,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T21:00:00",
          "y": 92,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T22:00:00",
          "y": 90.2,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-04T23:00:00",
          "y": 88,
          "decade": "1950-1959",
          "day": 4,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T00:00:00",
          "y": 85.9,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T01:00:00",
          "y": 84.6,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T02:00:00",
          "y": 82.7,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T03:00:00",
          "y": 81.5,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T04:00:00",
          "y": 80,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T05:00:00",
          "y": 79.1,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T06:00:00",
          "y": 78.8,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T07:00:00",
          "y": 81.8,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T08:00:00",
          "y": 85.6,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T09:00:00",
          "y": 89.6,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T10:00:00",
          "y": 91.6,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T11:00:00",
          "y": 93.2,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T12:00:00",
          "y": 95.7,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T13:00:00",
          "y": 97.1,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T14:00:00",
          "y": 99.1,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T15:00:00",
          "y": 100.6,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T16:00:00",
          "y": 101.8,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T17:00:00",
          "y": 101.9,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T18:00:00",
          "y": 100.9,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T19:00:00",
          "y": 99.1,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T20:00:00",
          "y": 95.2,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T21:00:00",
          "y": 92,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T22:00:00",
          "y": 89.9,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-05T23:00:00",
          "y": 88.2,
          "decade": "1950-1959",
          "day": 5,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T00:00:00",
          "y": 86.5,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T01:00:00",
          "y": 84.7,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T02:00:00",
          "y": 82.4,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T03:00:00",
          "y": 81.1,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T04:00:00",
          "y": 80,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T05:00:00",
          "y": 79.3,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T06:00:00",
          "y": 79.9,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T07:00:00",
          "y": 83.6,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T08:00:00",
          "y": 87.3,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T09:00:00",
          "y": 90.9,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T10:00:00",
          "y": 94.4,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T11:00:00",
          "y": 96.3,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T12:00:00",
          "y": 99.4,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T13:00:00",
          "y": 101.3,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T14:00:00",
          "y": 102.6,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T15:00:00",
          "y": 103.5,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T16:00:00",
          "y": 104.2,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T17:00:00",
          "y": 103.9,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T18:00:00",
          "y": 101.7,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T19:00:00",
          "y": 98.8,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T20:00:00",
          "y": 95.6,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T21:00:00",
          "y": 91.9,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T22:00:00",
          "y": 89.4,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-06T23:00:00",
          "y": 88.1,
          "decade": "1950-1959",
          "day": 6,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T00:00:00",
          "y": 86,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T01:00:00",
          "y": 85,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T02:00:00",
          "y": 81.8,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T03:00:00",
          "y": 81.4,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T04:00:00",
          "y": 80.1,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T05:00:00",
          "y": 79.3,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T06:00:00",
          "y": 79.4,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T07:00:00",
          "y": 82.4,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T08:00:00",
          "y": 85.7,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T09:00:00",
          "y": 89.5,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T10:00:00",
          "y": 92.4,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T11:00:00",
          "y": 94.8,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T12:00:00",
          "y": 97.4,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T13:00:00",
          "y": 99.5,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T14:00:00",
          "y": 101.8,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T15:00:00",
          "y": 102.1,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T16:00:00",
          "y": 102,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T17:00:00",
          "y": 101.9,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T18:00:00",
          "y": 100.7,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T19:00:00",
          "y": 98.3,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T20:00:00",
          "y": 94.1,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T21:00:00",
          "y": 91.5,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T22:00:00",
          "y": 89.2,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-07T23:00:00",
          "y": 88.6,
          "decade": "1950-1959",
          "day": 7,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T00:00:00",
          "y": 85.8,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T01:00:00",
          "y": 84.2,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T02:00:00",
          "y": 83.2,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T03:00:00",
          "y": 81.6,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T04:00:00",
          "y": 80.2,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T05:00:00",
          "y": 79.3,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T06:00:00",
          "y": 79.7,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T07:00:00",
          "y": 83.1,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T08:00:00",
          "y": 87.4,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T09:00:00",
          "y": 90.8,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T10:00:00",
          "y": 94.2,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T11:00:00",
          "y": 96.9,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T12:00:00",
          "y": 99.3,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T13:00:00",
          "y": 101,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T14:00:00",
          "y": 102.9,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T15:00:00",
          "y": 103.7,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T16:00:00",
          "y": 104.5,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T17:00:00",
          "y": 103.2,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T18:00:00",
          "y": 102.8,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T19:00:00",
          "y": 100.9,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T20:00:00",
          "y": 96.9,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T21:00:00",
          "y": 93.2,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T22:00:00",
          "y": 89.7,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-08T23:00:00",
          "y": 87.9,
          "decade": "1950-1959",
          "day": 8,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T00:00:00",
          "y": 86,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T01:00:00",
          "y": 84.3,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T02:00:00",
          "y": 82.6,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T03:00:00",
          "y": 81,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T04:00:00",
          "y": 80.2,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T05:00:00",
          "y": 79.5,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T06:00:00",
          "y": 80.3,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T07:00:00",
          "y": 84.3,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T08:00:00",
          "y": 87.8,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T09:00:00",
          "y": 91.5,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T10:00:00",
          "y": 94.2,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T11:00:00",
          "y": 96.4,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T12:00:00",
          "y": 99.3,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T13:00:00",
          "y": 100.5,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T14:00:00",
          "y": 102.3,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T15:00:00",
          "y": 103.6,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T16:00:00",
          "y": 103.6,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T17:00:00",
          "y": 103.8,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T18:00:00",
          "y": 102,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T19:00:00",
          "y": 99.7,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T20:00:00",
          "y": 96.5,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T21:00:00",
          "y": 92.7,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T22:00:00",
          "y": 89.7,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-09T23:00:00",
          "y": 88.2,
          "decade": "1950-1959",
          "day": 9,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T00:00:00",
          "y": 86.4,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T01:00:00",
          "y": 83.6,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T02:00:00",
          "y": 82.6,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T03:00:00",
          "y": 81,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T04:00:00",
          "y": 79.9,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T05:00:00",
          "y": 79.2,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T06:00:00",
          "y": 80.2,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T07:00:00",
          "y": 84.6,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T08:00:00",
          "y": 88.5,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T09:00:00",
          "y": 91.6,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T10:00:00",
          "y": 94.6,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T11:00:00",
          "y": 97.1,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T12:00:00",
          "y": 99.8,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T13:00:00",
          "y": 101.4,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T14:00:00",
          "y": 103.3,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T15:00:00",
          "y": 104.3,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T16:00:00",
          "y": 104.9,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T17:00:00",
          "y": 104.3,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T18:00:00",
          "y": 103.8,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T19:00:00",
          "y": 101.2,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T20:00:00",
          "y": 97.7,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T21:00:00",
          "y": 94.8,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T22:00:00",
          "y": 90.6,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-10T23:00:00",
          "y": 87.9,
          "decade": "1950-1959",
          "day": 10,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T00:00:00",
          "y": 86.4,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T01:00:00",
          "y": 84.9,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T02:00:00",
          "y": 83.9,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T03:00:00",
          "y": 82.3,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T04:00:00",
          "y": 81.2,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T05:00:00",
          "y": 79.7,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T06:00:00",
          "y": 79.9,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T07:00:00",
          "y": 84.1,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T08:00:00",
          "y": 88.3,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T09:00:00",
          "y": 92.3,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T10:00:00",
          "y": 94.8,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T11:00:00",
          "y": 97.6,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T12:00:00",
          "y": 99.6,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T13:00:00",
          "y": 101.5,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T14:00:00",
          "y": 103.7,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T15:00:00",
          "y": 104.6,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T16:00:00",
          "y": 105.3,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T17:00:00",
          "y": 105.5,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T18:00:00",
          "y": 104.3,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T19:00:00",
          "y": 101.2,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T20:00:00",
          "y": 97.3,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T21:00:00",
          "y": 94.6,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T22:00:00",
          "y": 91.1,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-11T23:00:00",
          "y": 88.5,
          "decade": "1950-1959",
          "day": 11,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T00:00:00",
          "y": 87.8,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T01:00:00",
          "y": 85.3,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T02:00:00",
          "y": 83.5,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T03:00:00",
          "y": 82.8,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T04:00:00",
          "y": 80.8,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T05:00:00",
          "y": 80.2,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T06:00:00",
          "y": 80.8,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T07:00:00",
          "y": 84.7,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T08:00:00",
          "y": 88.4,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T09:00:00",
          "y": 91.6,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T10:00:00",
          "y": 94.4,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T11:00:00",
          "y": 96.8,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T12:00:00",
          "y": 98.8,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T13:00:00",
          "y": 100.3,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T14:00:00",
          "y": 102.7,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T15:00:00",
          "y": 103.9,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T16:00:00",
          "y": 104.7,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T17:00:00",
          "y": 104,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T18:00:00",
          "y": 100.9,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T19:00:00",
          "y": 99.1,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T20:00:00",
          "y": 95,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T21:00:00",
          "y": 91.9,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T22:00:00",
          "y": 89.6,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-12T23:00:00",
          "y": 87.3,
          "decade": "1950-1959",
          "day": 12,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T00:00:00",
          "y": 84.8,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T01:00:00",
          "y": 83.6,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T02:00:00",
          "y": 81.9,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T03:00:00",
          "y": 80.8,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T04:00:00",
          "y": 80.5,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T05:00:00",
          "y": 79.7,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T06:00:00",
          "y": 80.1,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T07:00:00",
          "y": 83.3,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T08:00:00",
          "y": 87.2,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T09:00:00",
          "y": 91.2,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T10:00:00",
          "y": 93.3,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T11:00:00",
          "y": 94.4,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T12:00:00",
          "y": 97.5,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T13:00:00",
          "y": 99.3,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T14:00:00",
          "y": 101.5,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T15:00:00",
          "y": 102.3,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T16:00:00",
          "y": 102.9,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T17:00:00",
          "y": 102.6,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T18:00:00",
          "y": 101.4,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T19:00:00",
          "y": 99.7,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T20:00:00",
          "y": 95.7,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T21:00:00",
          "y": 92.7,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T22:00:00",
          "y": 91.7,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-13T23:00:00",
          "y": 89.1,
          "decade": "1950-1959",
          "day": 13,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T00:00:00",
          "y": 87.5,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T01:00:00",
          "y": 86.4,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T02:00:00",
          "y": 84.8,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T03:00:00",
          "y": 84.2,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T04:00:00",
          "y": 82.6,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T05:00:00",
          "y": 81.7,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T06:00:00",
          "y": 81.3,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T07:00:00",
          "y": 84.6,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T08:00:00",
          "y": 88.7,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T09:00:00",
          "y": 91.9,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T10:00:00",
          "y": 95.3,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T11:00:00",
          "y": 97,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T12:00:00",
          "y": 99.1,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T13:00:00",
          "y": 100.5,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T14:00:00",
          "y": 102.5,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T15:00:00",
          "y": 103.6,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T16:00:00",
          "y": 104.6,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T17:00:00",
          "y": 104,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T18:00:00",
          "y": 103.2,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T19:00:00",
          "y": 100.9,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T20:00:00",
          "y": 97.1,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T21:00:00",
          "y": 94.8,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T22:00:00",
          "y": 91.8,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-14T23:00:00",
          "y": 90.1,
          "decade": "1950-1959",
          "day": 14,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T00:00:00",
          "y": 88.2,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T01:00:00",
          "y": 87.1,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T02:00:00",
          "y": 85.7,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T03:00:00",
          "y": 84.1,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T04:00:00",
          "y": 82.9,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T05:00:00",
          "y": 82.2,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T06:00:00",
          "y": 82.7,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T07:00:00",
          "y": 85,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T08:00:00",
          "y": 88.6,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T09:00:00",
          "y": 92.9,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T10:00:00",
          "y": 95.8,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T11:00:00",
          "y": 98,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T12:00:00",
          "y": 100.2,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T13:00:00",
          "y": 101.8,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T14:00:00",
          "y": 103.4,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T15:00:00",
          "y": 104.6,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T16:00:00",
          "y": 104.8,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T17:00:00",
          "y": 104.2,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T18:00:00",
          "y": 103.1,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T19:00:00",
          "y": 101.1,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T20:00:00",
          "y": 97.4,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T21:00:00",
          "y": 94.6,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T22:00:00",
          "y": 93.5,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-15T23:00:00",
          "y": 91.6,
          "decade": "1950-1959",
          "day": 15,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T00:00:00",
          "y": 89.4,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T01:00:00",
          "y": 88.2,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T02:00:00",
          "y": 86.9,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T03:00:00",
          "y": 85.5,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T04:00:00",
          "y": 84.9,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T05:00:00",
          "y": 83.1,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T06:00:00",
          "y": 83.5,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T07:00:00",
          "y": 86.1,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T08:00:00",
          "y": 88.5,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T09:00:00",
          "y": 91.6,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T10:00:00",
          "y": 94.8,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T11:00:00",
          "y": 96.6,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T12:00:00",
          "y": 98.5,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T13:00:00",
          "y": 100,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T14:00:00",
          "y": 101.2,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T15:00:00",
          "y": 102.9,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T16:00:00",
          "y": 103.1,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T17:00:00",
          "y": 102.8,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T18:00:00",
          "y": 101.7,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T19:00:00",
          "y": 98,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T20:00:00",
          "y": 93.9,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T21:00:00",
          "y": 90.3,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T22:00:00",
          "y": 87.8,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-16T23:00:00",
          "y": 86.9,
          "decade": "1950-1959",
          "day": 16,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T00:00:00",
          "y": 85.3,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T01:00:00",
          "y": 84.7,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T02:00:00",
          "y": 83.6,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T03:00:00",
          "y": 82.5,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T04:00:00",
          "y": 81.4,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T05:00:00",
          "y": 81.2,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T06:00:00",
          "y": 80.9,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T07:00:00",
          "y": 83.5,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T08:00:00",
          "y": 86.2,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T09:00:00",
          "y": 88.8,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T10:00:00",
          "y": 90.5,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T11:00:00",
          "y": 90.8,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T12:00:00",
          "y": 93.2,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T13:00:00",
          "y": 95.1,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T14:00:00",
          "y": 96.4,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T15:00:00",
          "y": 97.9,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T16:00:00",
          "y": 98.3,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T17:00:00",
          "y": 97.9,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T18:00:00",
          "y": 97.1,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T19:00:00",
          "y": 94.77777778,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T20:00:00",
          "y": 92.3,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T21:00:00",
          "y": 89.5,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T22:00:00",
          "y": 87.7,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-17T23:00:00",
          "y": 85.7,
          "decade": "1950-1959",
          "day": 17,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T00:00:00",
          "y": 83.9,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T01:00:00",
          "y": 82.5,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T02:00:00",
          "y": 82.7,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T03:00:00",
          "y": 81.8,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T04:00:00",
          "y": 80.8,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T05:00:00",
          "y": 80.4,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T06:00:00",
          "y": 80,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T07:00:00",
          "y": 82.4,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T08:00:00",
          "y": 85.8,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T09:00:00",
          "y": 88.4,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T10:00:00",
          "y": 90.7,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T11:00:00",
          "y": 92.7,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T12:00:00",
          "y": 94.9,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T13:00:00",
          "y": 97.5,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T14:00:00",
          "y": 98.6,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T15:00:00",
          "y": 100,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T16:00:00",
          "y": 100.2,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T17:00:00",
          "y": 99.9,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T18:00:00",
          "y": 99.2,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T19:00:00",
          "y": 97,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T20:00:00",
          "y": 92.9,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T21:00:00",
          "y": 91,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T22:00:00",
          "y": 88.8,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-18T23:00:00",
          "y": 86,
          "decade": "1950-1959",
          "day": 18,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T00:00:00",
          "y": 85.3,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T01:00:00",
          "y": 83.4,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T02:00:00",
          "y": 82.2,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T03:00:00",
          "y": 81,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T04:00:00",
          "y": 80,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T05:00:00",
          "y": 79.5,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T06:00:00",
          "y": 79.7,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T07:00:00",
          "y": 82.9,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T08:00:00",
          "y": 86.5,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T09:00:00",
          "y": 90,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T10:00:00",
          "y": 92.4,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T11:00:00",
          "y": 94.7,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T12:00:00",
          "y": 96.8,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T13:00:00",
          "y": 98.4,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T14:00:00",
          "y": 100.2,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T15:00:00",
          "y": 101.1,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T16:00:00",
          "y": 102.1,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T17:00:00",
          "y": 101.6,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T18:00:00",
          "y": 99.7,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T19:00:00",
          "y": 97.1,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T20:00:00",
          "y": 94.3,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T21:00:00",
          "y": 92.5,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T22:00:00",
          "y": 90.3,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-19T23:00:00",
          "y": 88.5,
          "decade": "1950-1959",
          "day": 19,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T00:00:00",
          "y": 87.4,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T01:00:00",
          "y": 85,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T02:00:00",
          "y": 83.5,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T03:00:00",
          "y": 82.3,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T04:00:00",
          "y": 81.7,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T05:00:00",
          "y": 81.3,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T06:00:00",
          "y": 81.1,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T07:00:00",
          "y": 83.9,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T08:00:00",
          "y": 87.2,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T09:00:00",
          "y": 89.9,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T10:00:00",
          "y": 92.7,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T11:00:00",
          "y": 95.4,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T12:00:00",
          "y": 98,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T13:00:00",
          "y": 99.7,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T14:00:00",
          "y": 101.3,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T15:00:00",
          "y": 102.4,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T16:00:00",
          "y": 102.8,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T17:00:00",
          "y": 102.9,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T18:00:00",
          "y": 101.9,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T19:00:00",
          "y": 99.5,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T20:00:00",
          "y": 94.6,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T21:00:00",
          "y": 91.9,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T22:00:00",
          "y": 89.5,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-20T23:00:00",
          "y": 87.3,
          "decade": "1950-1959",
          "day": 20,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T00:00:00",
          "y": 85.9,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T01:00:00",
          "y": 84.7,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T02:00:00",
          "y": 83.3,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T03:00:00",
          "y": 81.7,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T04:00:00",
          "y": 81.3,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T05:00:00",
          "y": 80.8,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T06:00:00",
          "y": 80.5,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T07:00:00",
          "y": 83.5,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T08:00:00",
          "y": 86.7,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T09:00:00",
          "y": 90.1,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T10:00:00",
          "y": 93.1,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T11:00:00",
          "y": 95.1,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T12:00:00",
          "y": 97.4,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T13:00:00",
          "y": 99.2,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T14:00:00",
          "y": 101,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T15:00:00",
          "y": 101.3,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T16:00:00",
          "y": 102.1,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T17:00:00",
          "y": 101.8,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T18:00:00",
          "y": 100,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T19:00:00",
          "y": 96.7,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T20:00:00",
          "y": 94.6,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T21:00:00",
          "y": 91.7,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T22:00:00",
          "y": 89.9,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-21T23:00:00",
          "y": 88.8,
          "decade": "1950-1959",
          "day": 21,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T00:00:00",
          "y": 87.2,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T01:00:00",
          "y": 85.7,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T02:00:00",
          "y": 83.8,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T03:00:00",
          "y": 82.2,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T04:00:00",
          "y": 81.5,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T05:00:00",
          "y": 80.4,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T06:00:00",
          "y": 79.9,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T07:00:00",
          "y": 83.1,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T08:00:00",
          "y": 86,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T09:00:00",
          "y": 89.2,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T10:00:00",
          "y": 91.9,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T11:00:00",
          "y": 94.5,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T12:00:00",
          "y": 96.4,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T13:00:00",
          "y": 98.3,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T14:00:00",
          "y": 100.2,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T15:00:00",
          "y": 100.9,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T16:00:00",
          "y": 102,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T17:00:00",
          "y": 101.4,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T18:00:00",
          "y": 100.3,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T19:00:00",
          "y": 98.3,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T20:00:00",
          "y": 94.4,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T21:00:00",
          "y": 92.22222222,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T22:00:00",
          "y": 88.7,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-22T23:00:00",
          "y": 87.3,
          "decade": "1950-1959",
          "day": 22,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T00:00:00",
          "y": 85.7,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T01:00:00",
          "y": 84.4,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T02:00:00",
          "y": 83.2,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T03:00:00",
          "y": 82.5,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T04:00:00",
          "y": 81.4,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T05:00:00",
          "y": 81.2,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T06:00:00",
          "y": 81.1,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T07:00:00",
          "y": 83.4,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T08:00:00",
          "y": 86.6,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T09:00:00",
          "y": 89.1,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T10:00:00",
          "y": 91.5,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T11:00:00",
          "y": 93.1,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T12:00:00",
          "y": 95.6,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T13:00:00",
          "y": 97.6,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T14:00:00",
          "y": 98.8,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T15:00:00",
          "y": 99.2,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T16:00:00",
          "y": 99.6,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T17:00:00",
          "y": 98.4,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T18:00:00",
          "y": 97.6,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T19:00:00",
          "y": 94.6,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T20:00:00",
          "y": 91.9,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T21:00:00",
          "y": 89.4,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T22:00:00",
          "y": 86.8,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-23T23:00:00",
          "y": 85.5,
          "decade": "1950-1959",
          "day": 23,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T00:00:00",
          "y": 83.9,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T01:00:00",
          "y": 82.3,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T02:00:00",
          "y": 81.4,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T03:00:00",
          "y": 80.2,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T04:00:00",
          "y": 79.8,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T05:00:00",
          "y": 79.2,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T06:00:00",
          "y": 78.7,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T07:00:00",
          "y": 81.2,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T08:00:00",
          "y": 83.8,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T09:00:00",
          "y": 87.2,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T10:00:00",
          "y": 89.9,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T11:00:00",
          "y": 91.6,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T12:00:00",
          "y": 94.2,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T13:00:00",
          "y": 94.5,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T14:00:00",
          "y": 95.2,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T15:00:00",
          "y": 96.5,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T16:00:00",
          "y": 97.2,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T17:00:00",
          "y": 97.3,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T18:00:00",
          "y": 96.5,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T19:00:00",
          "y": 94.5,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T20:00:00",
          "y": 92.1,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T21:00:00",
          "y": 90.3,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T22:00:00",
          "y": 88.4,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-24T23:00:00",
          "y": 87,
          "decade": "1950-1959",
          "day": 24,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T00:00:00",
          "y": 85.6,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T01:00:00",
          "y": 84.8,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T02:00:00",
          "y": 83.8,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T03:00:00",
          "y": 82.2,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T04:00:00",
          "y": 81.8,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T05:00:00",
          "y": 80.6,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T06:00:00",
          "y": 80.2,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T07:00:00",
          "y": 82.9,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T08:00:00",
          "y": 85.9,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T09:00:00",
          "y": 88.7,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T10:00:00",
          "y": 91.1,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T11:00:00",
          "y": 93.2,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T12:00:00",
          "y": 95.1,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T13:00:00",
          "y": 96.8,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T14:00:00",
          "y": 97.9,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T15:00:00",
          "y": 98.4,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T16:00:00",
          "y": 98.66666667,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T17:00:00",
          "y": 96.5,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T18:00:00",
          "y": 96.2,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T19:00:00",
          "y": 93.4,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T20:00:00",
          "y": 91.2,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T21:00:00",
          "y": 87.8,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T22:00:00",
          "y": 87.3,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-25T23:00:00",
          "y": 86.5,
          "decade": "1950-1959",
          "day": 25,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T00:00:00",
          "y": 85,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T01:00:00",
          "y": 84.5,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T02:00:00",
          "y": 82.6,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T03:00:00",
          "y": 81.8,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T04:00:00",
          "y": 80.4,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T05:00:00",
          "y": 80,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T06:00:00",
          "y": 80,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T07:00:00",
          "y": 83.2,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T08:00:00",
          "y": 87,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T09:00:00",
          "y": 89.4,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T10:00:00",
          "y": 91.7,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T11:00:00",
          "y": 94,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T12:00:00",
          "y": 95.7,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T13:00:00",
          "y": 97.6,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T14:00:00",
          "y": 98.6,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T15:00:00",
          "y": 99,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T16:00:00",
          "y": 99.7,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T17:00:00",
          "y": 99.6,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T18:00:00",
          "y": 99.2,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T19:00:00",
          "y": 95.6,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T20:00:00",
          "y": 90.77777778,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T21:00:00",
          "y": 88,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T22:00:00",
          "y": 86.7,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-26T23:00:00",
          "y": 85.3,
          "decade": "1950-1959",
          "day": 26,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T00:00:00",
          "y": 83.7,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T01:00:00",
          "y": 82.5,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T02:00:00",
          "y": 81.8,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T03:00:00",
          "y": 80.6,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T04:00:00",
          "y": 79.7,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T05:00:00",
          "y": 78.8,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T06:00:00",
          "y": 79,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T07:00:00",
          "y": 82,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T08:00:00",
          "y": 85.5,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T09:00:00",
          "y": 89.5,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T10:00:00",
          "y": 92.5,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T11:00:00",
          "y": 94.9,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T12:00:00",
          "y": 97.2,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T13:00:00",
          "y": 99.5,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T14:00:00",
          "y": 100.3,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T15:00:00",
          "y": 102.1,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T16:00:00",
          "y": 102.3,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T17:00:00",
          "y": 102.2,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T18:00:00",
          "y": 98.8,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T19:00:00",
          "y": 97,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T20:00:00",
          "y": 93.6,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T21:00:00",
          "y": 91.8,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T22:00:00",
          "y": 87.8,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-27T23:00:00",
          "y": 86.7,
          "decade": "1950-1959",
          "day": 27,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T00:00:00",
          "y": 85.4,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T01:00:00",
          "y": 84.1,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T02:00:00",
          "y": 82.9,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T03:00:00",
          "y": 82,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T04:00:00",
          "y": 80.5,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T05:00:00",
          "y": 79.7,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T06:00:00",
          "y": 79.8,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T07:00:00",
          "y": 82.7,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T08:00:00",
          "y": 85.8,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T09:00:00",
          "y": 89.4,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T10:00:00",
          "y": 92,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T11:00:00",
          "y": 94.2,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T12:00:00",
          "y": 96.3,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T13:00:00",
          "y": 98.2,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T14:00:00",
          "y": 99.8,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T15:00:00",
          "y": 100.8,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T16:00:00",
          "y": 101.5,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T17:00:00",
          "y": 101.6,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T18:00:00",
          "y": 100.4,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T19:00:00",
          "y": 98.3,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T20:00:00",
          "y": 95.4,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T21:00:00",
          "y": 93.1,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T22:00:00",
          "y": 90.9,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-28T23:00:00",
          "y": 88.4,
          "decade": "1950-1959",
          "day": 28,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T00:00:00",
          "y": 87,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T01:00:00",
          "y": 84.7,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T02:00:00",
          "y": 83.7,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T03:00:00",
          "y": 82.4,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T04:00:00",
          "y": 81.6,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T05:00:00",
          "y": 81.5,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T06:00:00",
          "y": 81.3,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T07:00:00",
          "y": 83,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T08:00:00",
          "y": 85.6,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T09:00:00",
          "y": 87.6,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T10:00:00",
          "y": 90.1,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T11:00:00",
          "y": 92.8,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T12:00:00",
          "y": 94.5,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T13:00:00",
          "y": 96.4,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T14:00:00",
          "y": 97.6,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T15:00:00",
          "y": 99,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T16:00:00",
          "y": 98.8,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T17:00:00",
          "y": 99,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T18:00:00",
          "y": 97.5,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T19:00:00",
          "y": 94.1,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T20:00:00",
          "y": 91.4,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T21:00:00",
          "y": 89.6,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T22:00:00",
          "y": 85.9,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-29T23:00:00",
          "y": 84.3,
          "decade": "1950-1959",
          "day": 29,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T00:00:00",
          "y": 83.6,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T01:00:00",
          "y": 82.1,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T02:00:00",
          "y": 81.6,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T03:00:00",
          "y": 81.1,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T04:00:00",
          "y": 80.7,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T05:00:00",
          "y": 80.3,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T06:00:00",
          "y": 79.7,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T07:00:00",
          "y": 81.6,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T08:00:00",
          "y": 84.2,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T09:00:00",
          "y": 87.3,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T10:00:00",
          "y": 89.7,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T11:00:00",
          "y": 91.9,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T12:00:00",
          "y": 93,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T13:00:00",
          "y": 94,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T14:00:00",
          "y": 95.3,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T15:00:00",
          "y": 96.3,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T16:00:00",
          "y": 96.9,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T17:00:00",
          "y": 96.6,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T18:00:00",
          "y": 95.1,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T19:00:00",
          "y": 93.5,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T20:00:00",
          "y": 90.6,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T21:00:00",
          "y": 88.8,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T22:00:00",
          "y": 87.6,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-30T23:00:00",
          "y": 86.1,
          "decade": "1950-1959",
          "day": 30,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T00:00:00",
          "y": 85.2,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T01:00:00",
          "y": 84.1,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T02:00:00",
          "y": 82.6,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T03:00:00",
          "y": 81.8,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T04:00:00",
          "y": 81.3,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T05:00:00",
          "y": 80.8,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T06:00:00",
          "y": 80,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T07:00:00",
          "y": 82.4,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T08:00:00",
          "y": 85.9,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T09:00:00",
          "y": 88.4,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T10:00:00",
          "y": 91.2,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T11:00:00",
          "y": 92.9,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T12:00:00",
          "y": 95.1,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T13:00:00",
          "y": 95.9,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T14:00:00",
          "y": 97.8,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T15:00:00",
          "y": 98.5,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T16:00:00",
          "y": 99.9,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T17:00:00",
          "y": 99.1,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T18:00:00",
          "y": 97.9,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T19:00:00",
          "y": 94.1,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T20:00:00",
          "y": 91.3,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T21:00:00",
          "y": 88.8,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T22:00:00",
          "y": 85.9,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        },
        {
          "x": "1949-07-31T23:00:00",
          "y": 84.7,
          "decade": "1950-1959",
          "day": 31,
          "linecolor": "#63A2EC"
        }
       ]
    },
    {
      "title": "2019 Hourly Temps",
      "strokeWidth": 2,
      "color": '#E7A744',
      "data": [
        {
          "x": "1949-07-01T00:00:00",
          "y": 88.35,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T01:00:00",
          "y": 87.1,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T02:00:00",
          "y": 85.3,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T03:00:00",
          "y": 85.3,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T04:00:00",
          "y": 84.3,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T05:00:00",
          "y": 87.45,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T06:00:00",
          "y": 92.5,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T07:00:00",
          "y": 95.3,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T08:00:00",
          "y": 98,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T09:00:00",
          "y": 98,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T10:00:00",
          "y": 101.1,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T11:00:00",
          "y": 104.25,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T12:00:00",
          "y": 106.1,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T13:00:00",
          "y": 107.2,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T14:00:00",
          "y": 107,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T15:00:00",
          "y": 107,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T16:00:00",
          "y": 106,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T17:00:00",
          "y": 103.85,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T18:00:00",
          "y": 101,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T19:00:00",
          "y": 97.6,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T20:00:00",
          "y": 95.9,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T21:00:00",
          "y": 95.9,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T22:00:00",
          "y": 95,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-01T23:00:00",
          "y": 92.65,
          "decade": "2010-2019",
          "day": 1,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T00:00:00",
          "y": 89.8,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T01:00:00",
          "y": 88.3,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T02:00:00",
          "y": 86.4,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T03:00:00",
          "y": 86.1,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T04:00:00",
          "y": 85.2,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T05:00:00",
          "y": 88.8,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T06:00:00",
          "y": 93.5,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T07:00:00",
          "y": 96.5,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T08:00:00",
          "y": 99.4,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T09:00:00",
          "y": 99.4,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T10:00:00",
          "y": 101.9,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T11:00:00",
          "y": 105,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T12:00:00",
          "y": 106.4,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T13:00:00",
          "y": 107.2,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T14:00:00",
          "y": 107.3,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T15:00:00",
          "y": 107.2,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T16:00:00",
          "y": 106.4,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T17:00:00",
          "y": 104.55,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T18:00:00",
          "y": 100.9,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T19:00:00",
          "y": 98.5,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T20:00:00",
          "y": 96.3,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T21:00:00",
          "y": 96.3,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T22:00:00",
          "y": 95.22222222,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-02T23:00:00",
          "y": 93.25,
          "decade": "2010-2019",
          "day": 2,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T00:00:00",
          "y": 91.33333333,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T01:00:00",
          "y": 89,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T02:00:00",
          "y": 87.9,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T03:00:00",
          "y": 87.9,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T04:00:00",
          "y": 86.4,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T05:00:00",
          "y": 88.6,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T06:00:00",
          "y": 92.2,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T07:00:00",
          "y": 95.2,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T08:00:00",
          "y": 97.2,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T09:00:00",
          "y": 97.2,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T10:00:00",
          "y": 100.1,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T11:00:00",
          "y": 102.2,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T12:00:00",
          "y": 104.2,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T13:00:00",
          "y": 105.2,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T14:00:00",
          "y": 105.4,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T15:00:00",
          "y": 105.4,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T16:00:00",
          "y": 104.1,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T17:00:00",
          "y": 101.1,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T18:00:00",
          "y": 98.4,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T19:00:00",
          "y": 95.6,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T20:00:00",
          "y": 93.25333333,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T21:00:00",
          "y": 93.4,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T22:00:00",
          "y": 92,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-03T23:00:00",
          "y": 90.13333333,
          "decade": "2010-2019",
          "day": 3,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T00:00:00",
          "y": 87.2,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T01:00:00",
          "y": 86.2,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T02:00:00",
          "y": 85.6,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T03:00:00",
          "y": 85.6,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T04:00:00",
          "y": 84.6,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T05:00:00",
          "y": 87.4,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T06:00:00",
          "y": 90.6,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T07:00:00",
          "y": 93.1,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T08:00:00",
          "y": 94.3,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T09:00:00",
          "y": 94.3,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T10:00:00",
          "y": 96,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T11:00:00",
          "y": 98.4,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T12:00:00",
          "y": 101,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T13:00:00",
          "y": 101.8,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T14:00:00",
          "y": 102.1,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T15:00:00",
          "y": 102.1,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T16:00:00",
          "y": 101.8,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T17:00:00",
          "y": 100.15,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T18:00:00",
          "y": 97.7,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T19:00:00",
          "y": 96.3,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T20:00:00",
          "y": 94.4,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T21:00:00",
          "y": 94.3,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T22:00:00",
          "y": 93.25,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-04T23:00:00",
          "y": 90.2,
          "decade": "2010-2019",
          "day": 4,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T00:00:00",
          "y": 88.4,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T01:00:00",
          "y": 87.7,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T02:00:00",
          "y": 85.9,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T03:00:00",
          "y": 85.9,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T04:00:00",
          "y": 85.6,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T05:00:00",
          "y": 87.45,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T06:00:00",
          "y": 91.7,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T07:00:00",
          "y": 94.5,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T08:00:00",
          "y": 97.1,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T09:00:00",
          "y": 97.1,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T10:00:00",
          "y": 99,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T11:00:00",
          "y": 101.65,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T12:00:00",
          "y": 103.9,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T13:00:00",
          "y": 103.6,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T14:00:00",
          "y": 103.7,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T15:00:00",
          "y": 104,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T16:00:00",
          "y": 103.4,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T17:00:00",
          "y": 101,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T18:00:00",
          "y": 97.1,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T19:00:00",
          "y": 94.6,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T20:00:00",
          "y": 92.82857143,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T21:00:00",
          "y": 93,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T22:00:00",
          "y": 91.81666667,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-05T23:00:00",
          "y": 90.275,
          "decade": "2010-2019",
          "day": 5,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T00:00:00",
          "y": 88.4,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T01:00:00",
          "y": 87.1,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T02:00:00",
          "y": 85.9,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T03:00:00",
          "y": 85.9,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T04:00:00",
          "y": 85.35,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T05:00:00",
          "y": 87.6,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T06:00:00",
          "y": 91.7,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T07:00:00",
          "y": 94,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T08:00:00",
          "y": 97.7,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T09:00:00",
          "y": 97.7,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T10:00:00",
          "y": 99,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T11:00:00",
          "y": 102.75,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T12:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T13:00:00",
          "y": 105.2,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T14:00:00",
          "y": 105.0333333,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T15:00:00",
          "y": 105,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T16:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T17:00:00",
          "y": 102.35,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T18:00:00",
          "y": 100,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T19:00:00",
          "y": 97.9,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T20:00:00",
          "y": 96.1,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T21:00:00",
          "y": 96.1,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T22:00:00",
          "y": 95.1,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-06T23:00:00",
          "y": 92.85,
          "decade": "2010-2019",
          "day": 6,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T00:00:00",
          "y": 90.7,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T01:00:00",
          "y": 90,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T02:00:00",
          "y": 89.1,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T03:00:00",
          "y": 89.1,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T04:00:00",
          "y": 88.2,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T05:00:00",
          "y": 90.2,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T06:00:00",
          "y": 94.4,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T07:00:00",
          "y": 97,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T08:00:00",
          "y": 99.9,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T09:00:00",
          "y": 100.2,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T10:00:00",
          "y": 102.9,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T11:00:00",
          "y": 106.05,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T12:00:00",
          "y": 107.5,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T13:00:00",
          "y": 108.1,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T14:00:00",
          "y": 108.2,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T15:00:00",
          "y": 108.2,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T16:00:00",
          "y": 107.2,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T17:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T18:00:00",
          "y": 101.9,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T19:00:00",
          "y": 98.95,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T20:00:00",
          "y": 97.15,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T21:00:00",
          "y": 96.9,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T22:00:00",
          "y": 95.6,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-07T23:00:00",
          "y": 94.05,
          "decade": "2010-2019",
          "day": 7,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T00:00:00",
          "y": 92,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T01:00:00",
          "y": 90.3,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T02:00:00",
          "y": 89.5,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T03:00:00",
          "y": 89.5,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T04:00:00",
          "y": 88.1,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T05:00:00",
          "y": 91.2,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T06:00:00",
          "y": 95.2,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T07:00:00",
          "y": 98.4,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T08:00:00",
          "y": 100.8,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T09:00:00",
          "y": 101.1,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T10:00:00",
          "y": 103.4,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T11:00:00",
          "y": 105.8,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T12:00:00",
          "y": 107.9,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T13:00:00",
          "y": 108.3,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T14:00:00",
          "y": 107.1,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T15:00:00",
          "y": 107.3,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T16:00:00",
          "y": 106.4,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T17:00:00",
          "y": 104.4,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T18:00:00",
          "y": 100.8,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T19:00:00",
          "y": 98.4,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T20:00:00",
          "y": 96.4,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T21:00:00",
          "y": 96.2,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T22:00:00",
          "y": 94.62,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-08T23:00:00",
          "y": 92.2,
          "decade": "2010-2019",
          "day": 8,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T00:00:00",
          "y": 90.425,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T01:00:00",
          "y": 88.95,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T02:00:00",
          "y": 88.1,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T03:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T04:00:00",
          "y": 87.7,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T05:00:00",
          "y": 89.5,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T06:00:00",
          "y": 92.7,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T07:00:00",
          "y": 95.3,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T08:00:00",
          "y": 98,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T09:00:00",
          "y": 98,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T10:00:00",
          "y": 100.3,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T11:00:00",
          "y": 102.85,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T12:00:00",
          "y": 104.5666667,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T13:00:00",
          "y": 106.1,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T14:00:00",
          "y": 106,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T15:00:00",
          "y": 105.6,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T16:00:00",
          "y": 102.87,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T17:00:00",
          "y": 100.6,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T18:00:00",
          "y": 97.2,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T19:00:00",
          "y": 95.6,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T20:00:00",
          "y": 94.01666667,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T21:00:00",
          "y": 93.43333333,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T22:00:00",
          "y": 92,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-09T23:00:00",
          "y": 90.6,
          "decade": "2010-2019",
          "day": 9,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T00:00:00",
          "y": 89.8,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T01:00:00",
          "y": 88.06666667,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T02:00:00",
          "y": 86.6,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T03:00:00",
          "y": 86.5,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T04:00:00",
          "y": 85.9,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T05:00:00",
          "y": 88,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T06:00:00",
          "y": 90.6,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T07:00:00",
          "y": 93.6,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T08:00:00",
          "y": 96.1,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T09:00:00",
          "y": 96.1,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T10:00:00",
          "y": 98.7,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T11:00:00",
          "y": 102.15,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T12:00:00",
          "y": 104.4,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T13:00:00",
          "y": 105.7,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T14:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T15:00:00",
          "y": 103.9,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T16:00:00",
          "y": 102.8,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T17:00:00",
          "y": 100.8666667,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T18:00:00",
          "y": 98.7,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T19:00:00",
          "y": 97,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T20:00:00",
          "y": 95.4,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T21:00:00",
          "y": 95.2,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T22:00:00",
          "y": 93.05,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-10T23:00:00",
          "y": 90.94,
          "decade": "2010-2019",
          "day": 10,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T00:00:00",
          "y": 89.25,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T01:00:00",
          "y": 88.2,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T02:00:00",
          "y": 87.6,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T03:00:00",
          "y": 87.46666667,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T04:00:00",
          "y": 86,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T05:00:00",
          "y": 86.91666667,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T06:00:00",
          "y": 90.7,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T07:00:00",
          "y": 93.4,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T08:00:00",
          "y": 95.9,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T09:00:00",
          "y": 95.9,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T10:00:00",
          "y": 98.6,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T11:00:00",
          "y": 101.25,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T12:00:00",
          "y": 102.9,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T13:00:00",
          "y": 103.7,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T14:00:00",
          "y": 103.6,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T15:00:00",
          "y": 103.5,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T16:00:00",
          "y": 102.2666667,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T17:00:00",
          "y": 100.19,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T18:00:00",
          "y": 97.075,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T19:00:00",
          "y": 94.7,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T20:00:00",
          "y": 93.3,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T21:00:00",
          "y": 93.2,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T22:00:00",
          "y": 92.375,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-11T23:00:00",
          "y": 90.375,
          "decade": "2010-2019",
          "day": 11,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T00:00:00",
          "y": 87.925,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T01:00:00",
          "y": 86.86666667,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T02:00:00",
          "y": 85.9,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T03:00:00",
          "y": 86,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T04:00:00",
          "y": 85.6,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T05:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T06:00:00",
          "y": 92.2,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T07:00:00",
          "y": 94.8,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T08:00:00",
          "y": 97.8,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T09:00:00",
          "y": 97.7,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T10:00:00",
          "y": 100.1,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T11:00:00",
          "y": 102.55,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T12:00:00",
          "y": 103.5,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T13:00:00",
          "y": 103.6,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T14:00:00",
          "y": 103.45,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T15:00:00",
          "y": 103.7,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T16:00:00",
          "y": 102.4,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T17:00:00",
          "y": 100.5,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T18:00:00",
          "y": 97.9,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T19:00:00",
          "y": 96.7,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T20:00:00",
          "y": 95.4,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T21:00:00",
          "y": 95.2,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T22:00:00",
          "y": 93.3,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-12T23:00:00",
          "y": 91.85,
          "decade": "2010-2019",
          "day": 12,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T00:00:00",
          "y": 89.8,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T01:00:00",
          "y": 89,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T02:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T03:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T04:00:00",
          "y": 87.3,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T05:00:00",
          "y": 89.25,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T06:00:00",
          "y": 92.6,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T07:00:00",
          "y": 95,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T08:00:00",
          "y": 96.83333333,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T09:00:00",
          "y": 97.5,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T10:00:00",
          "y": 99.8,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T11:00:00",
          "y": 102.2,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T12:00:00",
          "y": 104,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T13:00:00",
          "y": 105,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T14:00:00",
          "y": 104.9,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T15:00:00",
          "y": 105.1,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T16:00:00",
          "y": 102.8,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T17:00:00",
          "y": 101.6,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T18:00:00",
          "y": 99,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T19:00:00",
          "y": 97.4,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T20:00:00",
          "y": 95.5,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T21:00:00",
          "y": 94.6,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T22:00:00",
          "y": 92.8,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-13T23:00:00",
          "y": 91,
          "decade": "2010-2019",
          "day": 13,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T00:00:00",
          "y": 89.5,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T01:00:00",
          "y": 88.8,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T02:00:00",
          "y": 88.2,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T03:00:00",
          "y": 88.2,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T04:00:00",
          "y": 87.3,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T05:00:00",
          "y": 89.3,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T06:00:00",
          "y": 92.3,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T07:00:00",
          "y": 94.3,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T08:00:00",
          "y": 97.1,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T09:00:00",
          "y": 97.1,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T10:00:00",
          "y": 98.8,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T11:00:00",
          "y": 102.6,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T12:00:00",
          "y": 104.2,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T13:00:00",
          "y": 103.4,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T14:00:00",
          "y": 102.8,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T15:00:00",
          "y": 102.58,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T16:00:00",
          "y": 101.35,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T17:00:00",
          "y": 99.55,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T18:00:00",
          "y": 96.6,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T19:00:00",
          "y": 94.55,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T20:00:00",
          "y": 93.6,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T21:00:00",
          "y": 93.6,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T22:00:00",
          "y": 92.5,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-14T23:00:00",
          "y": 90.75,
          "decade": "2010-2019",
          "day": 14,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T00:00:00",
          "y": 89,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T01:00:00",
          "y": 88.4,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T02:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T03:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T04:00:00",
          "y": 87.2,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T05:00:00",
          "y": 89.35,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T06:00:00",
          "y": 93,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T07:00:00",
          "y": 94.9,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T08:00:00",
          "y": 97.6,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T09:00:00",
          "y": 97.6,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T10:00:00",
          "y": 99.2,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T11:00:00",
          "y": 102.2,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T12:00:00",
          "y": 104.5,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T13:00:00",
          "y": 104.9,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T14:00:00",
          "y": 104.4,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T15:00:00",
          "y": 103.8333333,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T16:00:00",
          "y": 103.35,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T17:00:00",
          "y": 100.1952381,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T18:00:00",
          "y": 97.64666667,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T19:00:00",
          "y": 96.05,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T20:00:00",
          "y": 94.1,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T21:00:00",
          "y": 94,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T22:00:00",
          "y": 93,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-15T23:00:00",
          "y": 90.5,
          "decade": "2010-2019",
          "day": 15,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T00:00:00",
          "y": 89.1,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T01:00:00",
          "y": 87.6,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T02:00:00",
          "y": 86.9,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T03:00:00",
          "y": 86.9,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T04:00:00",
          "y": 85.8,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T05:00:00",
          "y": 88.2,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T06:00:00",
          "y": 92.1,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T07:00:00",
          "y": 94.9,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T08:00:00",
          "y": 97.4,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T09:00:00",
          "y": 97.4,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T10:00:00",
          "y": 100.5,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T11:00:00",
          "y": 103.2,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T12:00:00",
          "y": 105.2,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T13:00:00",
          "y": 105.2,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T14:00:00",
          "y": 104.9,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T15:00:00",
          "y": 104.9,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T16:00:00",
          "y": 104.2,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T17:00:00",
          "y": 101.9166667,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T18:00:00",
          "y": 98.65,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T19:00:00",
          "y": 96.2,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T20:00:00",
          "y": 94.6,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T21:00:00",
          "y": 93.75,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T22:00:00",
          "y": 91.15,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-16T23:00:00",
          "y": 90.1,
          "decade": "2010-2019",
          "day": 16,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T00:00:00",
          "y": 88.25,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T01:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T02:00:00",
          "y": 86.9,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T03:00:00",
          "y": 86.9,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T04:00:00",
          "y": 86.4,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T05:00:00",
          "y": 88.5,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T06:00:00",
          "y": 91.4,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T07:00:00",
          "y": 93.2,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T08:00:00",
          "y": 95.6,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T09:00:00",
          "y": 95.6,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T10:00:00",
          "y": 98.7,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T11:00:00",
          "y": 101.15,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T12:00:00",
          "y": 104.3,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T13:00:00",
          "y": 103.9,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T14:00:00",
          "y": 103.5,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T15:00:00",
          "y": 103.5,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T16:00:00",
          "y": 102.9,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T17:00:00",
          "y": 101,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T18:00:00",
          "y": 98.5,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T19:00:00",
          "y": 96.3,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T20:00:00",
          "y": 94.3,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T21:00:00",
          "y": 93.9,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T22:00:00",
          "y": 93,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-17T23:00:00",
          "y": 90.25,
          "decade": "2010-2019",
          "day": 17,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T00:00:00",
          "y": 88.3,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T01:00:00",
          "y": 87.5,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T02:00:00",
          "y": 85.9,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T03:00:00",
          "y": 85.75,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T04:00:00",
          "y": 85.4,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T05:00:00",
          "y": 87.85,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T06:00:00",
          "y": 91.2,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T07:00:00",
          "y": 94.1,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T08:00:00",
          "y": 96,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T09:00:00",
          "y": 96.2,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T10:00:00",
          "y": 98.75,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T11:00:00",
          "y": 101.4,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T12:00:00",
          "y": 102.9,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T13:00:00",
          "y": 104.4,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T14:00:00",
          "y": 103.2,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T15:00:00",
          "y": 103.1,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T16:00:00",
          "y": 102.65,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T17:00:00",
          "y": 98.24166667,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T18:00:00",
          "y": 94.95714286,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T19:00:00",
          "y": 93.6,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T20:00:00",
          "y": 92.8,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T21:00:00",
          "y": 92.8,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T22:00:00",
          "y": 91.65,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-18T23:00:00",
          "y": 89.9,
          "decade": "2010-2019",
          "day": 18,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T00:00:00",
          "y": 88.8,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T01:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T02:00:00",
          "y": 87.3,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T03:00:00",
          "y": 87.3,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T04:00:00",
          "y": 86.3,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T05:00:00",
          "y": 88.85,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T06:00:00",
          "y": 92,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T07:00:00",
          "y": 94.3,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T08:00:00",
          "y": 97.1,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T09:00:00",
          "y": 97.1,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T10:00:00",
          "y": 98.9,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T11:00:00",
          "y": 101.2,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T12:00:00",
          "y": 102.7,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T13:00:00",
          "y": 103.2,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T14:00:00",
          "y": 103.4,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T15:00:00",
          "y": 103.4,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T16:00:00",
          "y": 102.8,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T17:00:00",
          "y": 101.05,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T18:00:00",
          "y": 98.6,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T19:00:00",
          "y": 96.7,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T20:00:00",
          "y": 95.925,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T21:00:00",
          "y": 95.65,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T22:00:00",
          "y": 93.675,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-19T23:00:00",
          "y": 90.75,
          "decade": "2010-2019",
          "day": 19,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T00:00:00",
          "y": 88.9,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T01:00:00",
          "y": 87.9,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T02:00:00",
          "y": 86.6,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T03:00:00",
          "y": 86.5,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T04:00:00",
          "y": 86.4,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T05:00:00",
          "y": 88.1,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T06:00:00",
          "y": 91.8,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T07:00:00",
          "y": 94.1,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T08:00:00",
          "y": 96.8,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T09:00:00",
          "y": 96.8,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T10:00:00",
          "y": 99.4,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T11:00:00",
          "y": 102.5,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T12:00:00",
          "y": 104.7,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T13:00:00",
          "y": 104.9,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T14:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T15:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T16:00:00",
          "y": 104.3,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T17:00:00",
          "y": 101.75,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T18:00:00",
          "y": 99.5,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T19:00:00",
          "y": 96.65,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T20:00:00",
          "y": 93.43333333,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T21:00:00",
          "y": 93.4,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T22:00:00",
          "y": 91.975,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-20T23:00:00",
          "y": 90.65,
          "decade": "2010-2019",
          "day": 20,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T00:00:00",
          "y": 89,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T01:00:00",
          "y": 88.2,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T02:00:00",
          "y": 86.3,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T03:00:00",
          "y": 86.3,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T04:00:00",
          "y": 86.275,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T05:00:00",
          "y": 88.55,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T06:00:00",
          "y": 91.4,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T07:00:00",
          "y": 93.9,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T08:00:00",
          "y": 95,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T09:00:00",
          "y": 94.9,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T10:00:00",
          "y": 96.58333333,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T11:00:00",
          "y": 100.6,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T12:00:00",
          "y": 101.7,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T13:00:00",
          "y": 102.9,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T14:00:00",
          "y": 102.8,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T15:00:00",
          "y": 102.8,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T16:00:00",
          "y": 103,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T17:00:00",
          "y": 99.62,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T18:00:00",
          "y": 96.26666667,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T19:00:00",
          "y": 94.13333333,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T20:00:00",
          "y": 93.6,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T21:00:00",
          "y": 93.6,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T22:00:00",
          "y": 92.6,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-21T23:00:00",
          "y": 90.5,
          "decade": "2010-2019",
          "day": 21,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T00:00:00",
          "y": 88.6,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T01:00:00",
          "y": 88.2,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T02:00:00",
          "y": 87.6,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T03:00:00",
          "y": 87.5,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T04:00:00",
          "y": 86.5,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T05:00:00",
          "y": 88.15,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T06:00:00",
          "y": 91.3,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T07:00:00",
          "y": 94.3,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T08:00:00",
          "y": 96.1,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T09:00:00",
          "y": 96.1,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T10:00:00",
          "y": 99,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T11:00:00",
          "y": 101.6,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T12:00:00",
          "y": 102.8,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T13:00:00",
          "y": 103.5,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T14:00:00",
          "y": 103.65,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T15:00:00",
          "y": 103.4,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T16:00:00",
          "y": 102.4666667,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T17:00:00",
          "y": 99.75,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T18:00:00",
          "y": 97.55,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T19:00:00",
          "y": 95.15,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T20:00:00",
          "y": 93.5,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T21:00:00",
          "y": 92.9,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T22:00:00",
          "y": 91.88888889,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-22T23:00:00",
          "y": 90,
          "decade": "2010-2019",
          "day": 22,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T00:00:00",
          "y": 88.3,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T01:00:00",
          "y": 87.9,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T02:00:00",
          "y": 87.7,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T03:00:00",
          "y": 87.7,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T04:00:00",
          "y": 87.2,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T05:00:00",
          "y": 89.15,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T06:00:00",
          "y": 92.8,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T07:00:00",
          "y": 94.7,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T08:00:00",
          "y": 98,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T09:00:00",
          "y": 98,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T10:00:00",
          "y": 99.3,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T11:00:00",
          "y": 101.55,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T12:00:00",
          "y": 103,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T13:00:00",
          "y": 104.5,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T14:00:00",
          "y": 104.5,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T15:00:00",
          "y": 104.5,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T16:00:00",
          "y": 104.0571429,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T17:00:00",
          "y": 101.95,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T18:00:00",
          "y": 98.2,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T19:00:00",
          "y": 95.76666667,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T20:00:00",
          "y": 94.03,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T21:00:00",
          "y": 94,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T22:00:00",
          "y": 92.5,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-23T23:00:00",
          "y": 90.8,
          "decade": "2010-2019",
          "day": 23,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T00:00:00",
          "y": 89.3,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T01:00:00",
          "y": 88.8,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T02:00:00",
          "y": 88.05,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T03:00:00",
          "y": 88.03333333,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T04:00:00",
          "y": 87.225,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T05:00:00",
          "y": 88.3,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T06:00:00",
          "y": 91.3,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T07:00:00",
          "y": 92.3,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T08:00:00",
          "y": 94.65,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T09:00:00",
          "y": 95.5,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T10:00:00",
          "y": 97.55,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T11:00:00",
          "y": 100.4,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T12:00:00",
          "y": 103.4,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T13:00:00",
          "y": 103.8,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T14:00:00",
          "y": 103.9,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T15:00:00",
          "y": 103.9,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T16:00:00",
          "y": 103.7,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T17:00:00",
          "y": 100.5,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T18:00:00",
          "y": 97.9,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T19:00:00",
          "y": 96.6,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T20:00:00",
          "y": 94.9,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T21:00:00",
          "y": 93.7,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T22:00:00",
          "y": 92.2,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-24T23:00:00",
          "y": 90.55,
          "decade": "2010-2019",
          "day": 24,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T00:00:00",
          "y": 89.55,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T01:00:00",
          "y": 88.7,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T02:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T03:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T04:00:00",
          "y": 87.6,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T05:00:00",
          "y": 88.7,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T06:00:00",
          "y": 90.5,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T07:00:00",
          "y": 94.05,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T08:00:00",
          "y": 97.2,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T09:00:00",
          "y": 97.2,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T10:00:00",
          "y": 99.5,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T11:00:00",
          "y": 102.7,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T12:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T13:00:00",
          "y": 104.6,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T14:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T15:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T16:00:00",
          "y": 103.9,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T17:00:00",
          "y": 100.54,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T18:00:00",
          "y": 98.15,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T19:00:00",
          "y": 96.65,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T20:00:00",
          "y": 94.55,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T21:00:00",
          "y": 94.5,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T22:00:00",
          "y": 94,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-25T23:00:00",
          "y": 92.6,
          "decade": "2010-2019",
          "day": 25,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T00:00:00",
          "y": 91,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T01:00:00",
          "y": 90,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T02:00:00",
          "y": 88.8,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T03:00:00",
          "y": 88.8,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T04:00:00",
          "y": 87.9,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T05:00:00",
          "y": 89.7,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T06:00:00",
          "y": 93.1,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T07:00:00",
          "y": 95.5,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T08:00:00",
          "y": 98.3,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T09:00:00",
          "y": 98.3,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T10:00:00",
          "y": 101.5,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T11:00:00",
          "y": 103.8,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T12:00:00",
          "y": 105.8,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T13:00:00",
          "y": 106.1,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T14:00:00",
          "y": 106.1,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T15:00:00",
          "y": 106.1,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T16:00:00",
          "y": 105.3,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T17:00:00",
          "y": 103.75,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T18:00:00",
          "y": 100.9,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T19:00:00",
          "y": 98.95,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T20:00:00",
          "y": 97,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T21:00:00",
          "y": 97,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T22:00:00",
          "y": 95.75,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-26T23:00:00",
          "y": 93.61666667,
          "decade": "2010-2019",
          "day": 26,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T00:00:00",
          "y": 91.86666667,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T01:00:00",
          "y": 90.85,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T02:00:00",
          "y": 89.4,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T03:00:00",
          "y": 89.4,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T04:00:00",
          "y": 88.85,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T05:00:00",
          "y": 90.45,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T06:00:00",
          "y": 93.5,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T07:00:00",
          "y": 95.8,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T08:00:00",
          "y": 98.3,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T09:00:00",
          "y": 98.3,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T10:00:00",
          "y": 101,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T11:00:00",
          "y": 103.7,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T12:00:00",
          "y": 106,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T13:00:00",
          "y": 105.7,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T14:00:00",
          "y": 106,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T15:00:00",
          "y": 105.8,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T16:00:00",
          "y": 105.6,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T17:00:00",
          "y": 103.05,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T18:00:00",
          "y": 100.8,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T19:00:00",
          "y": 98.65,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T20:00:00",
          "y": 97,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T21:00:00",
          "y": 96.8,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T22:00:00",
          "y": 94.8,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-27T23:00:00",
          "y": 92.92,
          "decade": "2010-2019",
          "day": 27,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T00:00:00",
          "y": 91.1,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T01:00:00",
          "y": 90.1,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T02:00:00",
          "y": 89.1,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T03:00:00",
          "y": 89.1,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T04:00:00",
          "y": 88.2,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T05:00:00",
          "y": 89.7,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T06:00:00",
          "y": 92.6,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T07:00:00",
          "y": 94.7,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T08:00:00",
          "y": 97.1,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T09:00:00",
          "y": 97.1,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T10:00:00",
          "y": 99.9,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T11:00:00",
          "y": 102.45,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T12:00:00",
          "y": 104,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T13:00:00",
          "y": 104.8,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T14:00:00",
          "y": 104.4,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T15:00:00",
          "y": 104.4,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T16:00:00",
          "y": 104.3,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T17:00:00",
          "y": 101.35,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T18:00:00",
          "y": 98,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T19:00:00",
          "y": 95.05,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T20:00:00",
          "y": 93.33333333,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T21:00:00",
          "y": 93.2,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T22:00:00",
          "y": 92.7,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-28T23:00:00",
          "y": 91.35,
          "decade": "2010-2019",
          "day": 28,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T00:00:00",
          "y": 89.7,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T01:00:00",
          "y": 88.8,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T02:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T03:00:00",
          "y": 87.8,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T04:00:00",
          "y": 87.4,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T05:00:00",
          "y": 88.8,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T06:00:00",
          "y": 91.7,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T07:00:00",
          "y": 94,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T08:00:00",
          "y": 96.5,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T09:00:00",
          "y": 96.5,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T10:00:00",
          "y": 98.9,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T11:00:00",
          "y": 102.2,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T12:00:00",
          "y": 103.9,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T13:00:00",
          "y": 102.9,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T14:00:00",
          "y": 102,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T15:00:00",
          "y": 102,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T16:00:00",
          "y": 101.05,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T17:00:00",
          "y": 97.15,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T18:00:00",
          "y": 96.44444444,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T19:00:00",
          "y": 90.45,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T20:00:00",
          "y": 91.11111111,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T21:00:00",
          "y": 90.1,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T22:00:00",
          "y": 90.15,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-29T23:00:00",
          "y": 88.3,
          "decade": "2010-2019",
          "day": 29,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T00:00:00",
          "y": 87.1,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T01:00:00",
          "y": 86.7,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T02:00:00",
          "y": 86.1,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T03:00:00",
          "y": 86.1,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T04:00:00",
          "y": 85.7,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T05:00:00",
          "y": 87.3,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T06:00:00",
          "y": 90.5,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T07:00:00",
          "y": 92.9,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T08:00:00",
          "y": 95.7,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T09:00:00",
          "y": 95.7,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T10:00:00",
          "y": 97.8,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T11:00:00",
          "y": 101.2,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T12:00:00",
          "y": 102.8,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T13:00:00",
          "y": 103.3,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T14:00:00",
          "y": 103.1,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T15:00:00",
          "y": 103.2,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T16:00:00",
          "y": 102.3,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T17:00:00",
          "y": 99.3,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T18:00:00",
          "y": 97.58,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T19:00:00",
          "y": 94.55714286,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T20:00:00",
          "y": 93.6,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T21:00:00",
          "y": 92.86666667,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T22:00:00",
          "y": 90.875,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-30T23:00:00",
          "y": 88.8,
          "decade": "2010-2019",
          "day": 30,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T00:00:00",
          "y": 86.95,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T01:00:00",
          "y": 86.4,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T02:00:00",
          "y": 86.4,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T03:00:00",
          "y": 86.4,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T04:00:00",
          "y": 85.6,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T05:00:00",
          "y": 87,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T06:00:00",
          "y": 90,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T07:00:00",
          "y": 92.4,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T08:00:00",
          "y": 94.7,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T09:00:00",
          "y": 94.8,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T10:00:00",
          "y": 98.1,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T11:00:00",
          "y": 100.1,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T12:00:00",
          "y": 101.3,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T13:00:00",
          "y": 100.4,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T14:00:00",
          "y": 99.24285714,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T15:00:00",
          "y": 98.7,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T16:00:00",
          "y": 98.7,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T17:00:00",
          "y": 96.91666667,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T18:00:00",
          "y": 93.8,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T19:00:00",
          "y": 92,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T20:00:00",
          "y": 91.06666667,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T21:00:00",
          "y": 90.4,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T22:00:00",
          "y": 89.51666667,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        },
        {
          "x": "1949-07-31T23:00:00",
          "y": 87.36666667,
          "decade": "2010-2019",
          "day": 31,
          "linecolor": "#E7A744"
        }
       ]
    }
  ]

function PhxHourlyTemps({ data }) {
    return <div style={{ display: 'block', height: '300px', maxWidth: '640px' }}>
        <ResponsiveLine
            data={phxHourlyTempData}
            margin={{ top: 50, right: 10, bottom: 5, left: 40 }}
            xScale={{ type: 'time', format: '%Y-%m-%dT%H:%M:%S', useUTC: false, precision: 'hour', }}
            xFormat="time:%Y-%m-%dT%H:%M:%S"
            yScale={{ type: 'linear', min: 'auto', max: 110, stacked: false, reverse: false }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
                orient: 'left',                
                tickRotation: 0,
                tickValues:[70,80,90,100,110],
            }}
            colors={{datum: 'color' }}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="Degrees Farenheit"
            pointLabelYOffset={-12}
            useMesh={true}
            animate={false}
            layers={[
                "axes", "lines", "points", "legends", "markers"
              ]}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 1
                        }
                      },
                },
                grid: {
                    line: {
                        stroke: "#89969F"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
            markers={[
                {
                    axis: 'y',
                    value: 86.6,
                    lineStyle: { stroke: '#E7A744', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-right',
                    legend: '2010-2019 Avg Low: 86.6',
                    textStyle: {
                        fill: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }
                },
                // {
                //     axis: 'y',
                //     value: 104.7,
                //     lineStyle: { stroke: '#E7A744', strokeWidth: 2, strokeDasharray: '3, 3' },
                //     legendPosition: 'bottom-left',
                //     legend: '2010-2019 Avg High: 104.7',
                //     textStyle: {
                //         fill: 'white',
                //         fontSize: '12px',
                //     },
                //     textStyle: {
                //         fill: 'white',
                //         fontSize: '12px',
                //     },
                // },
                {
                    axis: 'y',
                    value: 79.8,
                    lineStyle: { stroke: '#63A2EC', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-right',
                    legend: '1950-1959 Avg Low: 79.8',
                    textStyle: {
                        fill: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        webkitTextStrokeColor: 'red',
                        webkitTextStrokeWidth: 2
                    }
                },
                // {
                //     axis: 'y',
                //     value: 102,
                //     lineStyle: { stroke: '#63A2EC', strokeWidth: 2, strokeDasharray: '3, 3' },
                //     legendPosition: 'bottom-right',
                //     legend: '1950-1959 Avg High: 102',
                //     textStyle: {
                //         fill: 'white',
                //         fontSize: '12px',
                //     },
                //     textStyle: {
                //         fill: 'white',
                //         fontSize: '12px',
                //     },
                // }
            ]}
            tooltip={({ point }) => (
                <div
                    style={{
                        color: 'white',
                        background: '#242424',
                        padding: '8px',
                        borderRadius: '4px'
                    }}>
                    <span
                        style={{
                            display: 'block',
                            marginBottom: '4px'
                        }}>
                        <span
                            style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                marginRight: '4px',
                                background: (point.data.linecolor)
                            }}>
                        </span>
                        {point.data.decade} Average
                    </span>
                    <span
                        style={{
                            display: 'block',
                            fontSize: '14px'
                        }}>
                        July {point.data.day}: {point.data.y} (°F)
                    </span>
                </div>)}
        />
    </div>
}

export default PhxHourlyTemps;