import SEO from "../../../../src/components/seo";
import PhxHourlyTemps from "../../../../src/components/chart-phoenix-hourly-temps";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import ClimateChangedOpenGraphImage from "../../../../src/images/social-climate-changed.jpg";
import * as React from 'react';
export default {
  SEO,
  PhxHourlyTemps,
  graphql,
  Img,
  ClimateChangedOpenGraphImage,
  React
};