import React from 'react';
import { Link } from 'gatsby'

function Stories() {
    return (
      <div className="content-padding mtm">

        <Link to="/mars-isnt-planet-b/" className="story-link mbm">
          <div className="story-link-container">
            <div className="story_image mars mbm"></div>
            <h2 className=""><Link to="/mars-isnt-planet-b/">Mars Isn't Planet B</Link></h2>
            <h4 className="mts story-meta">Mike McDearmon | Jan 05, 2022</h4>
            <p>Cold, barren, and lifeless, the Red Planet is no substitute for the Blue Marble.</p>
          </div>
        </Link>
        
        <Link to="/water/" className="story-link mbm">
          <div className="story-link-container">
            <div className="story_image water mbm"></div>
            <h2 className=""><Link to="/water/">Here Today, Gone Tomorrow: Water In A Destabilized Climate</Link></h2>
            <h4 className="mts story-meta">Mike McDearmon | Sep 01, 2021</h4>
            <p>Once dependable water sources are growing more volatile in a destabilized climate.</p>
          </div>
        </Link>
        
        <Link to="/gigaton/" className="story-link mbm">
          <div className="story-link-container">
            <div className="story_image gigaton mbm"></div>
            <h2 className=""><Link to="/gigaton/">Gigatons: the Measure of Climate Change</Link></h2>
            <h4 className="mts story-meta">Mike McDearmon | Apr 22, 2021</h4>
            <p>To understand the gigaton is to understand the impact humans are having on the planet.</p>
          </div>
        </Link>
        
        <Link to="/unrecognizable-earth/" className="story-link mbm">
          <div className="story-link-container">
            <div className="story_image iceland mbm"></div>
            <h2 className=""><Link to="/unrecognizable-earth/">Unrecognizable Earth</Link></h2>
            <h4 className="mts story-meta">Mike McDearmon | Dec 16, 2020</h4>
            <p>How a handful of human generations have transformed the surface of the planet.</p>
          </div>
        </Link>
        
        <Link to="/climate-changed/" className="mtm story-link mbm">
          <div className="story-link-container">
            <div className="story_image phoenix mbm"></div>
            <h2 className=""><Link to="/climate-changed/">The Climate We Grew Up With Isn't Coming Back</Link></h2>
            <h4 className="mts story-meta">Mike McDearmon | Sep 01, 2020</h4>
            <p>We can't return to the climate we knew, but we can prevent an unbearable future.</p>
          </div>
        </Link>
        
        <Link to="/extinction/" className="mtm story-link mbm">
          <div className="story-link-container">
            <div className="story_image footprints mbm"></div>
            <h2><Link to="/extinction/">The Ancient Roots of Modern Extinction</Link></h2>
            <h4 className="mts story-meta">Mike McDearmon | July 21, 2020</h4>
            <p>Extinctions marked by human influence are not new. They began in the stone age.</p>
          </div>
        </Link>
        
        <Link to="/mountains/" className="mtm story-link">
          <div className="story-link-container">
            <div className="story_image mountains mbm"></div>
            <h2><Link to="/mountains/">Mountain Time</Link></h2>
            <h4 className="mts story-meta">Mike McDearmon | April 22, 2020</h4>
            <p>How mountain belts travel through time and around the globe.</p>
          </div>
        </Link>

        <Link to="/co2-history/" className="mtm story-link">
          <div className="story-link-container">
            <div className="story_image eocene mbm"></div>
            <h2><Link to="/co2-history/">The Future of 35 Million Years Ago</Link></h2>
            <h4 className="mts story-meta">Mike McDearmon | Jan 15, 2020</h4>
            <p>The climate of the near future may look like the distant past.</p>
          </div>
        </Link>

      </div>
    );
  }
  
  export default Stories;