import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const mountainsData = [
    {
        "id": "Appalachian Orogeny",
        "color": '#66CDBB',
        "data": [
            {
            "x": 450,
            "y": 'Appalachians',
            "z": 'Taconic'
            },
            {
            "x": 435,
            "y": 'Appalachians',
            "z": 'Taconic'
            },  
            {
            "x": null,
            "y": 'Appalachians',
            "z": null
            }, 
            {
            "x": 390,
            "y": 'Appalachians',
            "z": 'Acadian'
            },
            {
            "x": 370,
            "y": 'Appalachians',
            "z": 'Acadian'
            },
            {
            "x": null,
            "y": 'Appalachians',
            "z": null
            }, 
            {
            "x": 300,
            "y": 'Appalachians',
            "z": 'Alleghanian'
            },
            {
            "x": 250,
            "y": 'Appalachians',
            "z": 'Alleghanian'
            },    
        ]
    },
    {
        "id": "Alleghanian Orogeny",
        "color": '#66CDBB',
        "reference": 'https://en.wikipedia.org/wiki/Alleghanian_orogeny',
        "data": [
            {
            "x": 300,
            "y": 'Atlas Range'
            },
            {
            "x": 250,
            "y": 'Atlas Range'
            },
            {
            "x": null,
            "y": 'Atlas Range'
            },
            {
            "x": 65,
            "y": 'Atlas Range'
            },
            {
            "x": 2.6,
            "y": 'Atlas Range'
            }     
        ]
    },
    {
        "id": "Uralian Orogeny",
        "color": '#66CDBB',
        "reference": 'https://earthscience.stackexchange.com/questions/13509/how-did-the-ural-mountains-form',
        "data": [
            {
            "x": 300,
            "y": 'Urals'
            },
            {
            "x": 240,
            "y": 'Urals'
            }     
        ]
    },
    {
        "id": "Rockies Orogeny",
        "color": '#66CDBB',
        "reference": 'https://www.britannica.com/science/Sevier-orogeny',
        "data": [
            {
            "x": 165,
            "y": 'Rockies',
            "z": 'Sevier'
            },
            {
            "x": 80,
            "y": 'Rockies',
            "z": 'Sevier'
            },
            {
            "x": null,
            "y": 'Rockies',
            "z": null
            }, 
            {
            "x": 70,
            "y": 'Rockies',
            "z": 'Laramide'
            },
            {
            "x": 40,
            "y": 'Rockies',
            "z": 'Laramide'
            },       
        ]
    },
    {
        "id": "Sierra Madre",
        "color": '#66CDBB',
        "referenace": 'https://www.britannica.com/science/Laramide-orogeny',
        "data": [
            {
            "x": 70,
            "y": 'Sierra Madres'
            },
            {
            "x": 40,
            "y": "Sierra Madres"
            },       
        ]
    },
    {
        "id": "Alpine Orogeny",
        "color": '#66CDBB',
        "data": [
            {
            "x": 65,
            "y": 'Alps'
            },
            {
            "x": 0,
            "y": "Alps"
            },       
        ]
    },
    {
        "id": "Andean Orogeny",
        "color": '#66CDBB',
        "data": [
            {
            "x": 50,
            "y": 'Andes',
            "z": 'Late Period'
            },
            {
            "x": 0,
            "y": 'Andes',
            "z": 'Late Period'
            },
        ]
    },
    {
        "id": "Himalayan Orogeny",
        "color": '#66CDBB',
        "data": [
            {
            "x": 50,
            "y": 'Himalayas'
            },
            {
            "x": 0,
            "y": "Himalayas"
            },       
        ]
    },
    {
        "id": "Caucasian Orogeny",
        "color": '#66CDBB',
        "data": [
            {
            "x": 28.49,
            "y": 'Caucasus Mtns'
            },
            {
            "x": 23.8,
            "y": "Caucasus Mtns"
            },       
        ]
    },
    {
        "id": "Alaskan Orogeny",
        "color": '#66CDBB',
        "data": [
            {
            "x": 25,
            "y": 'Alaska Range'
            },
            {
            "x": 0,
            "y": "Alaska Range"
            },       
        ]
    },
    {
        "id": "Zagros Orogeny",
        "color": '#66CDBB',
        "reference": 'https://en.wikipedia.org/wiki/Zagros_Mountains#Geology',
        "data": [
            {
            "x": 25,
            "y": 'Zagros Range'
            },
            {
            "x": 0,
            "y": "Zagros Range"
            },       
        ]
    },
    {
        "id": "Nevadan Orogeny",
        "color": '#66CDBB',
        "reference": 'https://en.wikipedia.org/wiki/Nevadan_orogeny',
        "data": [
            {
            "x": 155,
            "y": 'Sierra Nevadas',
            "z": 'Navadan Orogeny'
            },
            {
            "x": 145,
            "y": 'Sierra Nevadas',
            "z": 'Nevadan Orogeny'
            },
            {
            "x": null,
            "y": 'Sierra Nevadas',
            "z": null
            },       
            {
            "x": 10,
            "y": 'Sierra Nevadas',
            "z": 'Basin and Range'
            },
            {
            "x": 0,
            "y": 'Sierra Nevadas',
            "z": 'Basin and Range'
            },       
        ]
    },
  ]

function MountainsChart({ data }) {
    return <div style={{ height: '350px', width: '70%', position: 'relative', zIndex: 2  }}>
        <ResponsiveLine
            data={mountainsData}
            margin={{ top: 30, right: 0, bottom: 30, left: 110 }}
            xScale={{ type: 'linear', reverse: true }}
            yScale={{ type: 'point'}}
            enableGridX={true}
            gridXValues={[0,100,200,300,400]}
            enableGridY={true}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickValues:[0,100,200,300,400],
                tickSize: 0,
                tickPadding: 12,
                tickRotation: 0
            }}
            axisLeft={{
                orient: 'left',
                tickRotation: 0,
            }}
            colors={{ datum: 'color' }}
            lineWidth={8}
            pointSize={1}
            useMesh={true}
            animate={false}
            markers={[
                {
                    axis: 'x',
                    value: 299,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'Pangaea Assembles',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'x',
                    value: 180,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'Pangaea Breaks Apart',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                }
            ]}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 0
                        }
                      },
                },
                grid: {
                    line: {
                        stroke: "#202D35"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
        />
    </div>
}

export default MountainsChart;