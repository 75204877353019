import SEO from "../../../../src/components/seo";
import Co240m900kya from "../../../../src/components/chart-co2-40m-900kya";
import Co2800kyapresent from "../../../../src/components/chart-co2-800k-present";
import Co210kyapresent from "../../../../src/components/chart-co2-10k-present";
import Co21959present from "../../../../src/components/chart-co2-1959-present";
import Co2800kya2100 from "../../../../src/components/chart-co2-100k-2100";
import Co240mya2100 from "../../../../src/components/chart-co2-40m-2100";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Future35OpenGraphImage from "../../../../src/images/social-future-35million.jpg";
import * as React from 'react';
export default {
  SEO,
  Co240m900kya,
  Co2800kyapresent,
  Co210kyapresent,
  Co21959present,
  Co2800kya2100,
  Co240mya2100,
  graphql,
  Img,
  Future35OpenGraphImage,
  React
};