import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const co2DataFuture = [
    {
      "title": "Ice Core + Mauna Loa Data",
      "color": "#63A2EC",
      "data": [
        {
          "x": 1,
          "y": 407.38
        },
        {
          "x": 2,
          "y": 405
        },
        {
          "x": 3,
          "y": 402.86
        },
        {
          "x": 4,
          "y": 399.41
        },
        {
          "x": 5,
          "y": 397.12
        },
        {
          "x": 6,
          "y": 395.19
        },
        {
          "x": 7,
          "y": 392.46
        },
        {
          "x": 8,
          "y": 390.45
        },
        {
          "x": 9,
          "y": 388.57
        },
        {
          "x": 10,
          "y": 386.29
        },
        {
          "x": 11,
          "y": 384.79
        },
        {
          "x": 12,
          "y": 382.68
        },
        {
          "x": 13,
          "y": 380.94
        },
        {
          "x": 14,
          "y": 378.81
        },
        {
          "x": 15,
          "y": 376.79
        },
        {
          "x": 16,
          "y": 374.96
        },
        {
          "x": 17,
          "y": 372.42
        },
        {
          "x": 18,
          "y": 370.41
        },
        {
          "x": 19,
          "y": 368.84
        },
        {
          "x": 20,
          "y": 367.64
        },
        {
          "x": 21,
          "y": 365.54
        },
        {
          "x": 22,
          "y": 362.9
        },
        {
          "x": 23,
          "y": 361.79
        },
        {
          "x": 24,
          "y": 360.04
        },
        {
          "x": 25,
          "y": 358.21
        },
        {
          "x": 26,
          "y": 356.71
        },
        {
          "x": 27,
          "y": 356
        },
        {
          "x": 28,
          "y": 355.29
        },
        {
          "x": 29,
          "y": 353.98
        },
        {
          "x": 30,
          "y": 352.79
        },
        {
          "x": 31,
          "y": 351.15
        },
        {
          "x": 32,
          "y": 348.62
        },
        {
          "x": 33,
          "y": 346.87
        },
        {
          "x": 34,
          "y": 345.47
        },
        {
          "x": 35,
          "y": 343.99
        },
        {
          "x": 36,
          "y": 342.44
        },
        {
          "x": 37,
          "y": 340.76
        },
        {
          "x": 38,
          "y": 340
        },
        {
          "x": 39,
          "y": 338.8
        },
        {
          "x": 40,
          "y": 336.84
        },
        {
          "x": 41,
          "y": 335.4
        },
        {
          "x": 42,
          "y": 333.83
        },
        {
          "x": 43,
          "y": 332.04
        },
        {
          "x": 44,
          "y": 331.11
        },
        {
          "x": 45,
          "y": 330.18
        },
        {
          "x": 46,
          "y": 329.68
        },
        {
          "x": 47,
          "y": 327.45
        },
        {
          "x": 48,
          "y": 326.32
        },
        {
          "x": 49,
          "y": 325.68
        },
        {
          "x": 50,
          "y": 324.62
        },
        {
          "x": 51,
          "y": 323.04
        },
        {
          "x": 52,
          "y": 322.16
        },
        {
          "x": 53,
          "y": 321.38
        },
        {
          "x": 54,
          "y": 320.04
        },
        {
          "x": 55,
          "y": 319.62
        },
        {
          "x": 56,
          "y": 318.99
        },
        {
          "x": 57,
          "y": 318.45
        },
        {
          "x": 58,
          "y": 317.64
        },
        {
          "x": 59,
          "y": 316.91
        },
        {
          "x": 60,
          "y": 315.97
        },
        {
          "x": 137,
          "y": 280.4
        },
        {
          "x": 268,
          "y": 274.9
        },
        {
          "x": 279,
          "y": 277.9
        },
        {
          "x": 395,
          "y": 279.1
        },
        {
          "x": 404,
          "y": 281.9
        },
        {
          "x": 485,
          "y": 277.7
        },
        {
          "x": 559,
          "y": 281.1
        },
        {
          "x": 672,
          "y": 282.2
        },
        {
          "x": 754,
          "y": 280.1
        },
        {
          "x": 877,
          "y": 278.4
        },
        {
          "x": 950,
          "y": 276.6
        },
        {
          "x": 1060,
          "y": 279.1
        },
        {
          "x": 1153,
          "y": 277.7
        },
        {
          "x": 1233,
          "y": 278.7
        },
        {
          "x": 1350,
          "y": 277.4
        },
        {
          "x": 1453,
          "y": 279.2
        },
        {
          "x": 1552,
          "y": 280
        },
        {
          "x": 1638,
          "y": 278.9
        },
        {
          "x": 1733,
          "y": 278.7
        },
        {
          "x": 1812,
          "y": 278
        },
        {
          "x": 1931,
          "y": 276.9
        },
        {
          "x": 2057,
          "y": 276.7
        },
        {
          "x": 2128,
          "y": 276.7
        },
        {
          "x": 2212,
          "y": 277.6
        },
        {
          "x": 2334,
          "y": 277.9
        },
        {
          "x": 2433,
          "y": 273.9
        },
        {
          "x": 2536,
          "y": 278.9
        },
        {
          "x": 2604,
          "y": 275.3
        },
        {
          "x": 2728,
          "y": 274.7
        },
        {
          "x": 2806,
          "y": 276.3
        },
        {
          "x": 2902,
          "y": 274.6
        },
        {
          "x": 3053,
          "y": 276.3
        },
        {
          "x": 3116,
          "y": 273.1
        },
        {
          "x": 3215,
          "y": 274
        },
        {
          "x": 3336,
          "y": 275
        },
        {
          "x": 3453,
          "y": 273.4
        },
        {
          "x": 3523,
          "y": 273
        },
        {
          "x": 3622,
          "y": 271.5
        },
        {
          "x": 3721,
          "y": 275.4
        },
        {
          "x": 3790,
          "y": 274.9
        },
        {
          "x": 3910,
          "y": 271.7
        },
        {
          "x": 4004,
          "y": 271.6
        },
        {
          "x": 4096,
          "y": 272.8
        },
        {
          "x": 4161,
          "y": 271.5
        },
        {
          "x": 4324,
          "y": 271.1
        },
        {
          "x": 4374,
          "y": 269.1
        },
        {
          "x": 4480,
          "y": 269.8
        },
        {
          "x": 4573,
          "y": 271.5
        },
        {
          "x": 4703,
          "y": 270.7
        },
        {
          "x": 4766,
          "y": 269.3
        },
        {
          "x": 4874,
          "y": 268.6
        },
        {
          "x": 5004,
          "y": 269.8
        },
        {
          "x": 5094,
          "y": 267.6
        },
        {
          "x": 5160,
          "y": 265.3
        },
        {
          "x": 5274,
          "y": 265.2
        },
        {
          "x": 5370,
          "y": 267.6
        },
        {
          "x": 5476,
          "y": 265.9
        },
        {
          "x": 5562,
          "y": 265.5
        },
        {
          "x": 5657,
          "y": 260.7
        },
        {
          "x": 5716,
          "y": 266.7
        },
        {
          "x": 5855,
          "y": 265.5
        },
        {
          "x": 5998,
          "y": 263.2
        },
        {
          "x": 6039,
          "y": 262.7
        },
        {
          "x": 6131,
          "y": 261.2
        },
        {
          "x": 6263,
          "y": 261.1
        },
        {
          "x": 6354,
          "y": 259.4
        },
        {
          "x": 6434,
          "y": 262.1
        },
        {
          "x": 6545,
          "y": 262.9
        },
        {
          "x": 6617,
          "y": 258.1
        },
        {
          "x": 6713,
          "y": 257.6
        },
        {
          "x": 6838,
          "y": 262.3
        },
        {
          "x": 6941,
          "y": 263
        },
        {
          "x": 7028,
          "y": 260.7
        },
        {
          "x": 7112,
          "y": 258.4
        },
        {
          "x": 7234,
          "y": 260.1
        },
        {
          "x": 7320,
          "y": 260.4
        },
        {
          "x": 7413,
          "y": 259.7
        },
        {
          "x": 7507,
          "y": 259.2
        },
        {
          "x": 7590,
          "y": 260.8
        },
        {
          "x": 7691,
          "y": 259.6
        },
        {
          "x": 7781,
          "y": 259.3
        },
        {
          "x": 7876,
          "y": 258.3
        },
        {
          "x": 7990,
          "y": 261.3
        },
        {
          "x": 8050,
          "y": 260.7
        },
        {
          "x": 8181,
          "y": 261.8
        },
        {
          "x": 8281,
          "y": 259
        },
        {
          "x": 8387,
          "y": 260.9
        },
        {
          "x": 8477,
          "y": 260.4
        },
        {
          "x": 8579,
          "y": 259.3
        },
        {
          "x": 8653,
          "y": 262
        },
        {
          "x": 8784,
          "y": 263.7
        },
        {
          "x": 8869,
          "y": 263.8
        },
        {
          "x": 8973,
          "y": 265.2
        },
        {
          "x": 9092,
          "y": 260.6
        },
        {
          "x": 9140,
          "y": 260.9
        },
        {
          "x": 9232,
          "y": 263
        },
        {
          "x": 9317,
          "y": 263.8
        },
        {
          "x": 9536,
          "y": 264.4
        },
        {
          "x": 9597,
          "y": 264.2
        },
        {
          "x": 9721,
          "y": 264
        },
        {
          "x": 9807,
          "y": 263.4
        },
        {
          "x": 9909,
          "y": 265.7
        },
        {
          "x": 9983,
          "y": 264.9
        },
        {
          "x": 10088,
          "y": 267.5
        },
        {
          "x": 10209,
          "y": 266.9
        },
        {
          "x": 10294,
          "y": 266
        },
        {
          "x": 10417,
          "y": 265.1
        },
        {
          "x": 10527,
          "y": 267.6
        },
        {
          "x": 10621,
          "y": 264.8
        },
        {
          "x": 10744,
          "y": 264.8
        },
        {
          "x": 10805,
          "y": 265
        },
        {
          "x": 10827,
          "y": 265.3
        },
        {
          "x": 10895,
          "y": 264.4
        },
        {
          "x": 10933,
          "y": 264.1
        },
        {
          "x": 11014,
          "y": 264.2
        },
        {
          "x": 11087,
          "y": 264.5
        },
        {
          "x": 11136,
          "y": 264
        },
        {
          "x": 11201,
          "y": 263
        },
        {
          "x": 11236,
          "y": 265.2
        },
        {
          "x": 11278,
          "y": 258.8
        },
        {
          "x": 11338,
          "y": 260.8
        },
        {
          "x": 11392,
          "y": 255.4
        },
        {
          "x": 11436,
          "y": 253.9
        },
        {
          "x": 11469,
          "y": 253.8
        },
        {
          "x": 11580,
          "y": 250.7
        },
        {
          "x": 11635,
          "y": 249.7
        },
        {
          "x": 11676,
          "y": 251.1
        },
        {
          "x": 11727,
          "y": 250.7
        },
        {
          "x": 11819,
          "y": 245.3
        },
        {
          "x": 11896,
          "y": 245.3
        },
        {
          "x": 11958,
          "y": 246.6
        },
        {
          "x": 12050,
          "y": 243.2
        },
        {
          "x": 12122,
          "y": 240.3
        },
        {
          "x": 12371,
          "y": 237.5
        },
        {
          "x": 12496,
          "y": 237.6
        },
        {
          "x": 12642,
          "y": 234.2
        },
        {
          "x": 12760,
          "y": 238.3
        },
        {
          "x": 12942,
          "y": 237.3
        },
        {
          "x": 13090,
          "y": 237.9
        },
        {
          "x": 13241,
          "y": 237.6
        },
        {
          "x": 13440,
          "y": 236.4
        },
        {
          "x": 13542,
          "y": 239.2
        },
        {
          "x": 13653,
          "y": 238.6
        },
        {
          "x": 13804,
          "y": 238.6
        },
        {
          "x": 13948,
          "y": 239.1
        },
        {
          "x": 14303,
          "y": 228.5
        },
        {
          "x": 14550,
          "y": 228.4
        },
        {
          "x": 14725,
          "y": 226.1
        },
        {
          "x": 14890,
          "y": 225.2
        },
        {
          "x": 15012,
          "y": 224.5
        },
        {
          "x": 15233,
          "y": 222
        },
        {
          "x": 15438,
          "y": 221
        },
        {
          "x": 15570,
          "y": 220.9
        },
        {
          "x": 15742,
          "y": 219.4
        },
        {
          "x": 15886,
          "y": 214
        },
        {
          "x": 16073,
          "y": 207.5
        },
        {
          "x": 16260,
          "y": 207.7
        },
        {
          "x": 16452,
          "y": 202.9
        },
        {
          "x": 16659,
          "y": 200.8
        },
        {
          "x": 16870,
          "y": 195.2
        },
        {
          "x": 17111,
          "y": 193.9
        },
        {
          "x": 17375,
          "y": 191
        },
        {
          "x": 17565,
          "y": 188.5
        },
        {
          "x": 17809,
          "y": 188.5
        },
        {
          "x": 17943,
          "y": 189.2
        },
        {
          "x": 18285,
          "y": 187
        },
        {
          "x": 18541,
          "y": 188.6
        },
        {
          "x": 18828,
          "y": 189.4
        },
        {
          "x": 18868,
          "y": 192.3
        },
        {
          "x": 18921,
          "y": 188.3
        },
        {
          "x": 19347,
          "y": 188.7
        },
        {
          "x": 19509,
          "y": 188.8
        },
        {
          "x": 19748,
          "y": 190
        },
        {
          "x": 19988,
          "y": 188
        },
        {
          "x": 20168,
          "y": 188.2
        },
        {
          "x": 20197,
          "y": 195
        },
        {
          "x": 20502,
          "y": 187.8
        },
        {
          "x": 20748,
          "y": 186.9
        },
        {
          "x": 21011,
          "y": 186.5
        },
        {
          "x": 21257,
          "y": 184.7
        },
        {
          "x": 21507,
          "y": 186.1
        },
        {
          "x": 21854,
          "y": 185.7
        },
        {
          "x": 22015,
          "y": 184.4
        },
        {
          "x": 22827,
          "y": 189.2
        },
        {
          "x": 25994,
          "y": 191.6
        },
        {
          "x": 29063,
          "y": 188.5
        },
        {
          "x": 30020,
          "y": 191.7
        },
        {
          "x": 35009,
          "y": 205.3
        },
        {
          "x": 37471,
          "y": 209.1
        },
        {
          "x": 43500,
          "y": 209.1
        },
        {
          "x": 47336,
          "y": 189.3
        },
        {
          "x": 48854,
          "y": 188.4
        },
        {
          "x": 49690,
          "y": 210.1
        },
        {
          "x": 50663,
          "y": 215.7
        },
        {
          "x": 52382,
          "y": 190.4
        },
        {
          "x": 57088,
          "y": 221.7
        },
        {
          "x": 57657,
          "y": 210.4
        },
        {
          "x": 62859,
          "y": 195.3
        },
        {
          "x": 64939,
          "y": 191.4
        },
        {
          "x": 65939,
          "y": 194.9
        },
        {
          "x": 71049,
          "y": 227.3
        },
        {
          "x": 73227,
          "y": 229.1
        },
        {
          "x": 77150,
          "y": 217.1
        },
        {
          "x": 78183,
          "y": 221.7
        },
        {
          "x": 80614,
          "y": 230.9
        },
        {
          "x": 82417,
          "y": 241.1
        },
        {
          "x": 83333,
          "y": 236.4
        },
        {
          "x": 84016,
          "y": 228
        },
        {
          "x": 85020,
          "y": 214.2
        },
        {
          "x": 86181,
          "y": 217
        },
        {
          "x": 87917,
          "y": 208
        },
        {
          "x": 90357,
          "y": 224.2
        },
        {
          "x": 91249,
          "y": 228.3
        },
        {
          "x": 94353,
          "y": 232
        },
        {
          "x": 99849,
          "y": 225.8
        },
        {
          "x": 100837,
          "y": 230.8
        },
        {
          "x": 101749,
          "y": 236.9
        },
        {
          "x": 103465,
          "y": 228.1
        },
        {
          "x": 104704,
          "y": 236.9
        },
        {
          "x": 105636,
          "y": 230.6
        },
        {
          "x": 107579,
          "y": 238.2
        },
        {
          "x": 108153,
          "y": 245.6
        },
        {
          "x": 109804,
          "y": 251.2
        },
        {
          "x": 111862,
          "y": 256.7
        },
        {
          "x": 113262,
          "y": 266.3
        },
        {
          "x": 114096,
          "y": 261.4
        },
        {
          "x": 114601,
          "y": 274.5
        },
        {
          "x": 115118,
          "y": 273.2
        },
        {
          "x": 116501,
          "y": 262.5
        },
        {
          "x": 117750,
          "y": 267.6
        },
        {
          "x": 118649,
          "y": 273.7
        },
        {
          "x": 119672,
          "y": 271.9
        },
        {
          "x": 120382,
          "y": 265.2
        },
        {
          "x": 121017,
          "y": 277.6
        },
        {
          "x": 122344,
          "y": 272.1
        },
        {
          "x": 123070,
          "y": 276.4
        },
        {
          "x": 124213,
          "y": 268.7
        },
        {
          "x": 124257,
          "y": 266.6
        },
        {
          "x": 124789,
          "y": 266.3
        },
        {
          "x": 125081,
          "y": 279.7
        },
        {
          "x": 125262,
          "y": 273
        },
        {
          "x": 125434,
          "y": 277.1
        },
        {
          "x": 126347,
          "y": 273.7
        },
        {
          "x": 126598,
          "y": 267.1
        },
        {
          "x": 126886,
          "y": 262.5
        },
        {
          "x": 127132,
          "y": 262.6
        },
        {
          "x": 127622,
          "y": 275.3
        },
        {
          "x": 127907,
          "y": 275.6
        },
        {
          "x": 128344,
          "y": 274
        },
        {
          "x": 128372,
          "y": 287.1
        },
        {
          "x": 128609,
          "y": 286.8
        },
        {
          "x": 128866,
          "y": 282.6
        },
        {
          "x": 129146,
          "y": 264.1
        },
        {
          "x": 129340,
          "y": 263.4
        },
        {
          "x": 129652,
          "y": 257.9
        },
        {
          "x": 129736,
          "y": 259
        },
        {
          "x": 131329,
          "y": 245
        },
        {
          "x": 131455,
          "y": 240.4
        },
        {
          "x": 131728,
          "y": 228.9
        },
        {
          "x": 132492,
          "y": 223.5
        },
        {
          "x": 133069,
          "y": 223.9
        },
        {
          "x": 133105,
          "y": 220.3
        },
        {
          "x": 133427,
          "y": 210.6
        },
        {
          "x": 134123,
          "y": 208.9
        },
        {
          "x": 134287,
          "y": 203.7
        },
        {
          "x": 134960,
          "y": 204.5
        },
        {
          "x": 135114,
          "y": 200.4
        },
        {
          "x": 135207,
          "y": 198
        },
        {
          "x": 135603,
          "y": 198
        },
        {
          "x": 135883,
          "y": 201.7
        },
        {
          "x": 136011,
          "y": 200.7
        },
        {
          "x": 136251,
          "y": 202.4
        },
        {
          "x": 136567,
          "y": 195.8
        },
        {
          "x": 136655,
          "y": 201.1
        },
        {
          "x": 137293,
          "y": 194.3
        },
        {
          "x": 137633,
          "y": 193.4
        },
        {
          "x": 137982,
          "y": 194.2
        },
        {
          "x": 138185,
          "y": 190.2
        },
        {
          "x": 139275,
          "y": 192.3
        },
        {
          "x": 139617,
          "y": 196.5
        },
        {
          "x": 140899,
          "y": 195.6
        },
        {
          "x": 140960,
          "y": 196.4
        },
        {
          "x": 142058,
          "y": 190.4
        },
        {
          "x": 145363,
          "y": 196.9
        },
        {
          "x": 148831,
          "y": 203
        },
        {
          "x": 149803,
          "y": 191.9
        },
        {
          "x": 149921,
          "y": 188.9
        },
        {
          "x": 151423,
          "y": 200.6
        },
        {
          "x": 154480,
          "y": 189
        },
        {
          "x": 155395,
          "y": 185.5
        },
        {
          "x": 155813,
          "y": 187.5
        },
        {
          "x": 159562,
          "y": 204.3
        },
        {
          "x": 159943,
          "y": 196.5
        },
        {
          "x": 161679,
          "y": 191.6
        },
        {
          "x": 162228,
          "y": 190.1
        },
        {
          "x": 163024,
          "y": 186.7
        },
        {
          "x": 163698,
          "y": 183.8
        },
        {
          "x": 164439,
          "y": 196.6
        },
        {
          "x": 167183,
          "y": 197.8
        },
        {
          "x": 169492,
          "y": 197.7
        },
        {
          "x": 171351,
          "y": 196
        },
        {
          "x": 172434,
          "y": 190.3
        },
        {
          "x": 173135,
          "y": 189.4
        },
        {
          "x": 173394,
          "y": 190.1
        },
        {
          "x": 175306,
          "y": 207.7
        },
        {
          "x": 177139,
          "y": 213.2
        },
        {
          "x": 178179,
          "y": 217.7
        },
        {
          "x": 179117,
          "y": 198.1
        },
        {
          "x": 180068,
          "y": 199.7
        },
        {
          "x": 182046,
          "y": 203.4
        },
        {
          "x": 184685,
          "y": 210.7
        },
        {
          "x": 186697,
          "y": 231.3
        },
        {
          "x": 188436,
          "y": 231.4
        },
        {
          "x": 189076,
          "y": 220.3
        },
        {
          "x": 190352,
          "y": 218
        },
        {
          "x": 192910,
          "y": 226.5
        },
        {
          "x": 193481,
          "y": 220
        },
        {
          "x": 195017,
          "y": 226.4
        },
        {
          "x": 195673,
          "y": 241.2
        },
        {
          "x": 196179,
          "y": 242.6
        },
        {
          "x": 198972,
          "y": 250.9
        },
        {
          "x": 199918,
          "y": 239.1
        },
        {
          "x": 201163,
          "y": 247.6
        },
        {
          "x": 202413,
          "y": 244.4
        },
        {
          "x": 202874,
          "y": 231.9
        },
        {
          "x": 203317,
          "y": 232.2
        },
        {
          "x": 203837,
          "y": 228.6
        },
        {
          "x": 204480,
          "y": 226.3
        },
        {
          "x": 204908,
          "y": 229.4
        },
        {
          "x": 205362,
          "y": 231.4
        },
        {
          "x": 205952,
          "y": 238.1
        },
        {
          "x": 206144,
          "y": 237.2
        },
        {
          "x": 206810,
          "y": 230
        },
        {
          "x": 207120,
          "y": 240.5
        },
        {
          "x": 207544,
          "y": 242.2
        },
        {
          "x": 208064,
          "y": 244.6
        },
        {
          "x": 208254,
          "y": 243.9
        },
        {
          "x": 208803,
          "y": 247.2
        },
        {
          "x": 208995,
          "y": 252
        },
        {
          "x": 209432,
          "y": 246.9
        },
        {
          "x": 209817,
          "y": 239.5
        },
        {
          "x": 210154,
          "y": 257.4
        },
        {
          "x": 210813,
          "y": 243.4
        },
        {
          "x": 211858,
          "y": 251.2
        },
        {
          "x": 212716,
          "y": 241.4
        },
        {
          "x": 213270,
          "y": 240.3
        },
        {
          "x": 213536,
          "y": 242.6
        },
        {
          "x": 213984,
          "y": 247.5
        },
        {
          "x": 214459,
          "y": 251.7
        },
        {
          "x": 214794,
          "y": 251.1
        },
        {
          "x": 215382,
          "y": 245.3
        },
        {
          "x": 216352,
          "y": 240.5
        },
        {
          "x": 218361,
          "y": 214.1
        },
        {
          "x": 219116,
          "y": 216.1
        },
        {
          "x": 220058,
          "y": 207.1
        },
        {
          "x": 220739,
          "y": 208.8
        },
        {
          "x": 222030,
          "y": 205.6
        },
        {
          "x": 224269,
          "y": 203.3
        },
        {
          "x": 224923,
          "y": 215.7
        },
        {
          "x": 225909,
          "y": 235.5
        },
        {
          "x": 226711,
          "y": 234.5
        },
        {
          "x": 227027,
          "y": 233.1
        },
        {
          "x": 227776,
          "y": 224.5
        },
        {
          "x": 229423,
          "y": 232.4
        },
        {
          "x": 230422,
          "y": 233.9
        },
        {
          "x": 231066,
          "y": 241.6
        },
        {
          "x": 234817,
          "y": 245.2
        },
        {
          "x": 235480,
          "y": 252.1
        },
        {
          "x": 236114,
          "y": 241.4
        },
        {
          "x": 236734,
          "y": 247.4
        },
        {
          "x": 237294,
          "y": 243.1
        },
        {
          "x": 237868,
          "y": 239.1
        },
        {
          "x": 238558,
          "y": 245.6
        },
        {
          "x": 239013,
          "y": 245.8
        },
        {
          "x": 239477,
          "y": 247.4
        },
        {
          "x": 239973,
          "y": 252.8
        },
        {
          "x": 240945,
          "y": 259.7
        },
        {
          "x": 241366,
          "y": 263.2
        },
        {
          "x": 242007,
          "y": 279
        },
        {
          "x": 242346,
          "y": 280.2
        },
        {
          "x": 243071,
          "y": 263.7
        },
        {
          "x": 243429,
          "y": 252.3
        },
        {
          "x": 243856,
          "y": 249.9
        },
        {
          "x": 244347,
          "y": 236.7
        },
        {
          "x": 244864,
          "y": 230.4
        },
        {
          "x": 245441,
          "y": 219.4
        },
        {
          "x": 247681,
          "y": 214.7
        },
        {
          "x": 250133,
          "y": 200.2
        },
        {
          "x": 251005,
          "y": 213.9
        },
        {
          "x": 251864,
          "y": 195.4
        },
        {
          "x": 252739,
          "y": 196.7
        },
        {
          "x": 253636,
          "y": 195.4
        },
        {
          "x": 255498,
          "y": 199
        },
        {
          "x": 256309,
          "y": 201.9
        },
        {
          "x": 257100,
          "y": 204
        },
        {
          "x": 258661,
          "y": 203.9
        },
        {
          "x": 260353,
          "y": 209.6
        },
        {
          "x": 260916,
          "y": 205.7
        },
        {
          "x": 262092,
          "y": 208.9
        },
        {
          "x": 262930,
          "y": 214.6
        },
        {
          "x": 264509,
          "y": 228.1
        },
        {
          "x": 265653,
          "y": 199.9
        },
        {
          "x": 266326,
          "y": 211.7
        },
        {
          "x": 267442,
          "y": 188.7
        },
        {
          "x": 268181,
          "y": 187.2
        },
        {
          "x": 269154,
          "y": 194.2
        },
        {
          "x": 270222,
          "y": 198.8
        },
        {
          "x": 271256,
          "y": 184.7
        },
        {
          "x": 272311,
          "y": 190.4
        },
        {
          "x": 273310,
          "y": 193.9
        },
        {
          "x": 274321,
          "y": 194.1
        },
        {
          "x": 275350,
          "y": 198.4
        },
        {
          "x": 276326,
          "y": 193.2
        },
        {
          "x": 277361,
          "y": 202.2
        },
        {
          "x": 278350,
          "y": 204.5
        },
        {
          "x": 279320,
          "y": 211
        },
        {
          "x": 280269,
          "y": 215.3
        },
        {
          "x": 281758,
          "y": 223.7
        },
        {
          "x": 283785,
          "y": 231.3
        },
        {
          "x": 285380,
          "y": 228
        },
        {
          "x": 286128,
          "y": 226.4
        },
        {
          "x": 287036,
          "y": 231.4
        },
        {
          "x": 287538,
          "y": 230.4
        },
        {
          "x": 288233,
          "y": 231
        },
        {
          "x": 290153,
          "y": 234.9
        },
        {
          "x": 290730,
          "y": 220.4
        },
        {
          "x": 291367,
          "y": 217.1
        },
        {
          "x": 292405,
          "y": 207.6
        },
        {
          "x": 293057,
          "y": 206
        },
        {
          "x": 293779,
          "y": 206.7
        },
        {
          "x": 294568,
          "y": 212.7
        },
        {
          "x": 295930,
          "y": 213.1
        },
        {
          "x": 297921,
          "y": 217.1
        },
        {
          "x": 298741,
          "y": 224.4
        },
        {
          "x": 299621,
          "y": 231
        },
        {
          "x": 300472,
          "y": 236.1
        },
        {
          "x": 301402,
          "y": 239
        },
        {
          "x": 302280,
          "y": 236
        },
        {
          "x": 303226,
          "y": 240.2
        },
        {
          "x": 304232,
          "y": 240.7
        },
        {
          "x": 304901,
          "y": 250.2
        },
        {
          "x": 305655,
          "y": 248.6
        },
        {
          "x": 306547,
          "y": 244.8
        },
        {
          "x": 307495,
          "y": 225.8
        },
        {
          "x": 308744,
          "y": 227.8
        },
        {
          "x": 309389,
          "y": 226.2
        },
        {
          "x": 310168,
          "y": 233.2
        },
        {
          "x": 311013,
          "y": 237.8
        },
        {
          "x": 311868,
          "y": 239
        },
        {
          "x": 312676,
          "y": 241.9
        },
        {
          "x": 313414,
          "y": 251.6
        },
        {
          "x": 314144,
          "y": 256.7
        },
        {
          "x": 314867,
          "y": 257.1
        },
        {
          "x": 315572,
          "y": 246.8
        },
        {
          "x": 316200,
          "y": 272.6
        },
        {
          "x": 316897,
          "y": 251.6
        },
        {
          "x": 317734,
          "y": 245.2
        },
        {
          "x": 318559,
          "y": 233.4
        },
        {
          "x": 319480,
          "y": 255.8
        },
        {
          "x": 320358,
          "y": 249.2
        },
        {
          "x": 322015,
          "y": 257.2
        },
        {
          "x": 322767,
          "y": 260.4
        },
        {
          "x": 323526,
          "y": 260.3
        },
        {
          "x": 324280,
          "y": 260.5
        },
        {
          "x": 324971,
          "y": 266.2
        },
        {
          "x": 325720,
          "y": 264
        },
        {
          "x": 326321,
          "y": 266.1
        },
        {
          "x": 326972,
          "y": 270.1
        },
        {
          "x": 327590,
          "y": 271.9
        },
        {
          "x": 328221,
          "y": 275.1
        },
        {
          "x": 329475,
          "y": 265
        },
        {
          "x": 330208,
          "y": 271.7
        },
        {
          "x": 330740,
          "y": 272.6
        },
        {
          "x": 331438,
          "y": 273.1
        },
        {
          "x": 331944,
          "y": 282.4
        },
        {
          "x": 332285,
          "y": 289.1
        },
        {
          "x": 332462,
          "y": 288.4
        },
        {
          "x": 332919,
          "y": 298.6
        },
        {
          "x": 333380,
          "y": 278.1
        },
        {
          "x": 333890,
          "y": 285.8
        },
        {
          "x": 334261,
          "y": 278.6
        },
        {
          "x": 334748,
          "y": 270.5
        },
        {
          "x": 335287,
          "y": 255.7
        },
        {
          "x": 335918,
          "y": 241.9
        },
        {
          "x": 336725,
          "y": 239.6
        },
        {
          "x": 337391,
          "y": 234.2
        },
        {
          "x": 339298,
          "y": 250.1
        },
        {
          "x": 340456,
          "y": 200.7
        },
        {
          "x": 341802,
          "y": 205.2
        },
        {
          "x": 343282,
          "y": 204.8
        },
        {
          "x": 344446,
          "y": 211.9
        },
        {
          "x": 345980,
          "y": 220.3
        },
        {
          "x": 348298,
          "y": 221.1
        },
        {
          "x": 349688,
          "y": 216.2
        },
        {
          "x": 350925,
          "y": 209.4
        },
        {
          "x": 352275,
          "y": 209.2
        },
        {
          "x": 355302,
          "y": 193
        },
        {
          "x": 356898,
          "y": 186.1
        },
        {
          "x": 358712,
          "y": 185.8
        },
        {
          "x": 360957,
          "y": 201.2
        },
        {
          "x": 363385,
          "y": 206.3
        },
        {
          "x": 366235,
          "y": 201.9
        },
        {
          "x": 367856,
          "y": 199.9
        },
        {
          "x": 369446,
          "y": 214.7
        },
        {
          "x": 371090,
          "y": 224.6
        },
        {
          "x": 372646,
          "y": 229.6
        },
        {
          "x": 376568,
          "y": 227
        },
        {
          "x": 378096,
          "y": 240
        },
        {
          "x": 379696,
          "y": 239.1
        },
        {
          "x": 381132,
          "y": 246.8
        },
        {
          "x": 382670,
          "y": 245.8
        },
        {
          "x": 384534,
          "y": 258.1
        },
        {
          "x": 385398,
          "y": 264.6
        },
        {
          "x": 386144,
          "y": 259.2
        },
        {
          "x": 388062,
          "y": 255.2
        },
        {
          "x": 389946,
          "y": 250.1
        },
        {
          "x": 391896,
          "y": 266.3
        },
        {
          "x": 392544,
          "y": 259.5
        },
        {
          "x": 393579,
          "y": 273.6
        },
        {
          "x": 394560,
          "y": 260.7
        },
        {
          "x": 398086,
          "y": 276.3
        },
        {
          "x": 399722,
          "y": 277.1
        },
        {
          "x": 400504,
          "y": 283.2
        },
        {
          "x": 402000,
          "y": 283.1
        },
        {
          "x": 402731,
          "y": 275.7
        },
        {
          "x": 404181,
          "y": 276.5
        },
        {
          "x": 404927,
          "y": 280.5
        },
        {
          "x": 406368,
          "y": 279.6
        },
        {
          "x": 407093,
          "y": 285.6
        },
        {
          "x": 408600,
          "y": 284.5
        },
        {
          "x": 409383,
          "y": 275.2
        },
        {
          "x": 410206,
          "y": 282.6
        },
        {
          "x": 411071,
          "y": 283.5
        },
        {
          "x": 412962,
          "y": 274.9
        },
        {
          "x": 413948,
          "y": 264.9
        },
        {
          "x": 414963,
          "y": 271.6
        },
        {
          "x": 415717,
          "y": 276.4
        },
        {
          "x": 416193,
          "y": 271.7
        },
        {
          "x": 417191,
          "y": 273.4
        },
        {
          "x": 417698,
          "y": 271.8
        },
        {
          "x": 418245,
          "y": 274.6
        },
        {
          "x": 419260,
          "y": 273.7
        },
        {
          "x": 419808,
          "y": 271.2
        },
        {
          "x": 420350,
          "y": 273.8
        },
        {
          "x": 421484,
          "y": 268.6
        },
        {
          "x": 422074,
          "y": 266.4
        },
        {
          "x": 422649,
          "y": 270.6
        },
        {
          "x": 423764,
          "y": 267.7
        },
        {
          "x": 424332,
          "y": 268.3
        },
        {
          "x": 424840,
          "y": 270.8
        },
        {
          "x": 425242,
          "y": 270
        },
        {
          "x": 425569,
          "y": 265.4
        },
        {
          "x": 425975,
          "y": 255.3
        },
        {
          "x": 426598,
          "y": 252.1
        },
        {
          "x": 427285,
          "y": 248.2
        },
        {
          "x": 427566,
          "y": 242.5
        },
        {
          "x": 429006,
          "y": 219.7
        },
        {
          "x": 429876,
          "y": 227.2
        },
        {
          "x": 431063,
          "y": 211.5
        },
        {
          "x": 431445,
          "y": 207.6
        },
        {
          "x": 432599,
          "y": 209.8
        },
        {
          "x": 433674,
          "y": 207.5
        },
        {
          "x": 434804,
          "y": 200.3
        },
        {
          "x": 435989,
          "y": 201.7
        },
        {
          "x": 437152,
          "y": 201.3
        },
        {
          "x": 438356,
          "y": 202
        },
        {
          "x": 439565,
          "y": 199.1
        },
        {
          "x": 441220,
          "y": 201.1
        },
        {
          "x": 442411,
          "y": 203.5
        },
        {
          "x": 443562,
          "y": 208.1
        },
        {
          "x": 444650,
          "y": 201.7
        },
        {
          "x": 445829,
          "y": 201.2
        },
        {
          "x": 446984,
          "y": 204.9
        },
        {
          "x": 448103,
          "y": 201.9
        },
        {
          "x": 449244,
          "y": 198.4
        },
        {
          "x": 450455,
          "y": 193.3
        },
        {
          "x": 451593,
          "y": 192.5
        },
        {
          "x": 452283,
          "y": 199.1
        },
        {
          "x": 452795,
          "y": 204.3
        },
        {
          "x": 453514,
          "y": 203.3
        },
        {
          "x": 454023,
          "y": 208.3
        },
        {
          "x": 455279,
          "y": 202.4
        },
        {
          "x": 456591,
          "y": 195.5
        },
        {
          "x": 458049,
          "y": 190.7
        },
        {
          "x": 459430,
          "y": 194.4
        },
        {
          "x": 460792,
          "y": 199.9
        },
        {
          "x": 461687,
          "y": 205.2
        },
        {
          "x": 462133,
          "y": 210
        },
        {
          "x": 463456,
          "y": 208.1
        },
        {
          "x": 464866,
          "y": 204.4
        },
        {
          "x": 466265,
          "y": 203.4
        },
        {
          "x": 467602,
          "y": 205.5
        },
        {
          "x": 468323,
          "y": 206.5
        },
        {
          "x": 468810,
          "y": 215.5
        },
        {
          "x": 469470,
          "y": 218.7
        },
        {
          "x": 469941,
          "y": 229.2
        },
        {
          "x": 470597,
          "y": 232.7
        },
        {
          "x": 471046,
          "y": 243.7
        },
        {
          "x": 471763,
          "y": 243.9
        },
        {
          "x": 472095,
          "y": 245.6
        },
        {
          "x": 473102,
          "y": 241.2
        },
        {
          "x": 474162,
          "y": 233.1
        },
        {
          "x": 475218,
          "y": 232.8
        },
        {
          "x": 476240,
          "y": 236.6
        },
        {
          "x": 477162,
          "y": 239.1
        },
        {
          "x": 477719,
          "y": 236.5
        },
        {
          "x": 478124,
          "y": 231.3
        },
        {
          "x": 478721,
          "y": 220.8
        },
        {
          "x": 479144,
          "y": 218.8
        },
        {
          "x": 480108,
          "y": 223.8
        },
        {
          "x": 481035,
          "y": 223.4
        },
        {
          "x": 481932,
          "y": 227.3
        },
        {
          "x": 482803,
          "y": 231.4
        },
        {
          "x": 483649,
          "y": 231.4
        },
        {
          "x": 484465,
          "y": 231.2
        },
        {
          "x": 485276,
          "y": 233
        },
        {
          "x": 486043,
          "y": 232.9
        },
        {
          "x": 486816,
          "y": 231.3
        },
        {
          "x": 487541,
          "y": 237.1
        },
        {
          "x": 488268,
          "y": 242
        },
        {
          "x": 488689,
          "y": 240.9
        },
        {
          "x": 488996,
          "y": 252.8
        },
        {
          "x": 489475,
          "y": 249.3
        },
        {
          "x": 489722,
          "y": 242.6
        },
        {
          "x": 490445,
          "y": 243.3
        },
        {
          "x": 491191,
          "y": 246.7
        },
        {
          "x": 491959,
          "y": 243.7
        },
        {
          "x": 492738,
          "y": 239.7
        },
        {
          "x": 493507,
          "y": 239.1
        },
        {
          "x": 494311,
          "y": 238.3
        },
        {
          "x": 495085,
          "y": 238.5
        },
        {
          "x": 495853,
          "y": 237.6
        },
        {
          "x": 496608,
          "y": 236.4
        },
        {
          "x": 497366,
          "y": 236.5
        },
        {
          "x": 498104,
          "y": 232.8
        },
        {
          "x": 498865,
          "y": 230.6
        },
        {
          "x": 499610,
          "y": 230.8
        },
        {
          "x": 500363,
          "y": 228.2
        },
        {
          "x": 501130,
          "y": 230.8
        },
        {
          "x": 501866,
          "y": 231.2
        },
        {
          "x": 502625,
          "y": 232
        },
        {
          "x": 503375,
          "y": 232.2
        },
        {
          "x": 504083,
          "y": 234.1
        },
        {
          "x": 504777,
          "y": 233.9
        },
        {
          "x": 505516,
          "y": 235.5
        },
        {
          "x": 506287,
          "y": 236
        },
        {
          "x": 507011,
          "y": 238.2
        },
        {
          "x": 507714,
          "y": 237
        },
        {
          "x": 508476,
          "y": 240.3
        },
        {
          "x": 509247,
          "y": 233.4
        },
        {
          "x": 510008,
          "y": 236.4
        },
        {
          "x": 510765,
          "y": 235.2
        },
        {
          "x": 511515,
          "y": 242
        },
        {
          "x": 512269,
          "y": 238.2
        },
        {
          "x": 512997,
          "y": 239.2
        },
        {
          "x": 513724,
          "y": 241.5
        },
        {
          "x": 514429,
          "y": 243
        },
        {
          "x": 515113,
          "y": 247.2
        },
        {
          "x": 515771,
          "y": 246.2
        },
        {
          "x": 516417,
          "y": 245.5
        },
        {
          "x": 517069,
          "y": 245.3
        },
        {
          "x": 517706,
          "y": 247.7
        },
        {
          "x": 518334,
          "y": 245.5
        },
        {
          "x": 518965,
          "y": 243.5
        },
        {
          "x": 520892,
          "y": 241.9
        },
        {
          "x": 521552,
          "y": 236.8
        },
        {
          "x": 522216,
          "y": 235.8
        },
        {
          "x": 522923,
          "y": 233.7
        },
        {
          "x": 523666,
          "y": 230.3
        },
        {
          "x": 524409,
          "y": 227.8
        },
        {
          "x": 525164,
          "y": 224.7
        },
        {
          "x": 525908,
          "y": 222.7
        },
        {
          "x": 526661,
          "y": 221.2
        },
        {
          "x": 527454,
          "y": 220.3
        },
        {
          "x": 528262,
          "y": 220.7
        },
        {
          "x": 528747,
          "y": 214.5
        },
        {
          "x": 529101,
          "y": 211.4
        },
        {
          "x": 529640,
          "y": 204.4
        },
        {
          "x": 530016,
          "y": 200
        },
        {
          "x": 530685,
          "y": 195.1
        },
        {
          "x": 531035,
          "y": 193.8
        },
        {
          "x": 531662,
          "y": 190.5
        },
        {
          "x": 532119,
          "y": 199.8
        },
        {
          "x": 533246,
          "y": 199.4
        },
        {
          "x": 534364,
          "y": 205
        },
        {
          "x": 535483,
          "y": 206.4
        },
        {
          "x": 536622,
          "y": 212.9
        },
        {
          "x": 537770,
          "y": 211.2
        },
        {
          "x": 538959,
          "y": 205
        },
        {
          "x": 540154,
          "y": 203.6
        },
        {
          "x": 541345,
          "y": 208.1
        },
        {
          "x": 542502,
          "y": 210.3
        },
        {
          "x": 543676,
          "y": 209.6
        },
        {
          "x": 544804,
          "y": 209.7
        },
        {
          "x": 545956,
          "y": 204.6
        },
        {
          "x": 547013,
          "y": 202.5
        },
        {
          "x": 547573,
          "y": 208.8
        },
        {
          "x": 547972,
          "y": 213.6
        },
        {
          "x": 548933,
          "y": 215.2
        },
        {
          "x": 549830,
          "y": 219.9
        },
        {
          "x": 550637,
          "y": 221.7
        },
        {
          "x": 551124,
          "y": 222.3
        },
        {
          "x": 551452,
          "y": 226.3
        },
        {
          "x": 551979,
          "y": 222.1
        },
        {
          "x": 552250,
          "y": 220.3
        },
        {
          "x": 552961,
          "y": 223.6
        },
        {
          "x": 553626,
          "y": 224.3
        },
        {
          "x": 554254,
          "y": 226.7
        },
        {
          "x": 554831,
          "y": 233.9
        },
        {
          "x": 555345,
          "y": 241
        },
        {
          "x": 555637,
          "y": 243
        },
        {
          "x": 555839,
          "y": 249.1
        },
        {
          "x": 556143,
          "y": 244
        },
        {
          "x": 556364,
          "y": 250.5
        },
        {
          "x": 556724,
          "y": 245.6
        },
        {
          "x": 556889,
          "y": 240.4
        },
        {
          "x": 557385,
          "y": 238.1
        },
        {
          "x": 557887,
          "y": 234.5
        },
        {
          "x": 558183,
          "y": 229.7
        },
        {
          "x": 558393,
          "y": 230.2
        },
        {
          "x": 558929,
          "y": 230.3
        },
        {
          "x": 559461,
          "y": 228.3
        },
        {
          "x": 559972,
          "y": 231.6
        },
        {
          "x": 560459,
          "y": 231.9
        },
        {
          "x": 560930,
          "y": 234.6
        },
        {
          "x": 561309,
          "y": 234.1
        },
        {
          "x": 561846,
          "y": 240.1
        },
        {
          "x": 562272,
          "y": 242.3
        },
        {
          "x": 562690,
          "y": 245.7
        },
        {
          "x": 563115,
          "y": 245.8
        },
        {
          "x": 563517,
          "y": 247.6
        },
        {
          "x": 563918,
          "y": 251.4
        },
        {
          "x": 564311,
          "y": 252.4
        },
        {
          "x": 564699,
          "y": 252.6
        },
        {
          "x": 565077,
          "y": 251.4
        },
        {
          "x": 565466,
          "y": 253.7
        },
        {
          "x": 565851,
          "y": 254.3
        },
        {
          "x": 566233,
          "y": 253.9
        },
        {
          "x": 566619,
          "y": 254.5
        },
        {
          "x": 567007,
          "y": 253.2
        },
        {
          "x": 567381,
          "y": 253.9
        },
        {
          "x": 567777,
          "y": 252.8
        },
        {
          "x": 568167,
          "y": 253
        },
        {
          "x": 568556,
          "y": 250.2
        },
        {
          "x": 568946,
          "y": 251.3
        },
        {
          "x": 569331,
          "y": 250
        },
        {
          "x": 569728,
          "y": 251.3
        },
        {
          "x": 570139,
          "y": 251.8
        },
        {
          "x": 570548,
          "y": 251.8
        },
        {
          "x": 570955,
          "y": 249.6
        },
        {
          "x": 571367,
          "y": 251.6
        },
        {
          "x": 571775,
          "y": 250.3
        },
        {
          "x": 572190,
          "y": 246.3
        },
        {
          "x": 572619,
          "y": 247.7
        },
        {
          "x": 573037,
          "y": 249.2
        },
        {
          "x": 573462,
          "y": 248.7
        },
        {
          "x": 573902,
          "y": 251.8
        },
        {
          "x": 574353,
          "y": 251.9
        },
        {
          "x": 574803,
          "y": 252.1
        },
        {
          "x": 575265,
          "y": 248.9
        },
        {
          "x": 575730,
          "y": 252.5
        },
        {
          "x": 576222,
          "y": 252.9
        },
        {
          "x": 576723,
          "y": 251.3
        },
        {
          "x": 577242,
          "y": 251.3
        },
        {
          "x": 577769,
          "y": 251.1
        },
        {
          "x": 578305,
          "y": 249.1
        },
        {
          "x": 578857,
          "y": 252.9
        },
        {
          "x": 579435,
          "y": 251.5
        },
        {
          "x": 579821,
          "y": 244.1
        },
        {
          "x": 580019,
          "y": 243.8
        },
        {
          "x": 580400,
          "y": 236.2
        },
        {
          "x": 580695,
          "y": 230.3
        },
        {
          "x": 581160,
          "y": 225.2
        },
        {
          "x": 581507,
          "y": 219.4
        },
        {
          "x": 582000,
          "y": 215.4
        },
        {
          "x": 582391,
          "y": 209.9
        },
        {
          "x": 583034,
          "y": 206.7
        },
        {
          "x": 583413,
          "y": 210.6
        },
        {
          "x": 586406,
          "y": 224
        },
        {
          "x": 586893,
          "y": 226
        },
        {
          "x": 587647,
          "y": 234.4
        },
        {
          "x": 588034,
          "y": 238.8
        },
        {
          "x": 588716,
          "y": 246.3
        },
        {
          "x": 589224,
          "y": 250.2
        },
        {
          "x": 589868,
          "y": 248.1
        },
        {
          "x": 590335,
          "y": 243.6
        },
        {
          "x": 590893,
          "y": 237.4
        },
        {
          "x": 592279,
          "y": 225.7
        },
        {
          "x": 592697,
          "y": 229.4
        },
        {
          "x": 593415,
          "y": 233.2
        },
        {
          "x": 593870,
          "y": 238.1
        },
        {
          "x": 594639,
          "y": 238
        },
        {
          "x": 595189,
          "y": 232.9
        },
        {
          "x": 595950,
          "y": 221.2
        },
        {
          "x": 596515,
          "y": 216.4
        },
        {
          "x": 597443,
          "y": 216.3
        },
        {
          "x": 597966,
          "y": 219
        },
        {
          "x": 598833,
          "y": 226
        },
        {
          "x": 599434,
          "y": 229.4
        },
        {
          "x": 600882,
          "y": 232.5
        },
        {
          "x": 602278,
          "y": 237.9
        },
        {
          "x": 603673,
          "y": 239.1
        },
        {
          "x": 605076,
          "y": 244.5
        },
        {
          "x": 606374,
          "y": 248.5
        },
        {
          "x": 607708,
          "y": 254.6
        },
        {
          "x": 608929,
          "y": 259.2
        },
        {
          "x": 610136,
          "y": 257.7
        },
        {
          "x": 611319,
          "y": 259.6
        },
        {
          "x": 612514,
          "y": 258
        },
        {
          "x": 613709,
          "y": 256
        },
        {
          "x": 614923,
          "y": 252.5
        },
        {
          "x": 616227,
          "y": 252.4
        },
        {
          "x": 617544,
          "y": 252.3
        },
        {
          "x": 618955,
          "y": 247.9
        },
        {
          "x": 620338,
          "y": 245.4
        },
        {
          "x": 621760,
          "y": 243
        },
        {
          "x": 623214,
          "y": 238.6
        },
        {
          "x": 624690,
          "y": 237.3
        },
        {
          "x": 625570,
          "y": 234.3
        },
        {
          "x": 626215,
          "y": 227.8
        },
        {
          "x": 627211,
          "y": 214.9
        },
        {
          "x": 627999,
          "y": 205
        },
        {
          "x": 629446,
          "y": 198.7
        },
        {
          "x": 630295,
          "y": 199.9
        },
        {
          "x": 632001,
          "y": 199.5
        },
        {
          "x": 632856,
          "y": 195.6
        },
        {
          "x": 634592,
          "y": 192.1
        },
        {
          "x": 635489,
          "y": 193.9
        },
        {
          "x": 636957,
          "y": 193
        },
        {
          "x": 638127,
          "y": 194.3
        },
        {
          "x": 639848,
          "y": 187.7
        },
        {
          "x": 640760,
          "y": 189.1
        },
        {
          "x": 642405,
          "y": 190.2
        },
        {
          "x": 643204,
          "y": 190.4
        },
        {
          "x": 644662,
          "y": 194.1
        },
        {
          "x": 645470,
          "y": 194.6
        },
        {
          "x": 646723,
          "y": 188.6
        },
        {
          "x": 647588,
          "y": 190.5
        },
        {
          "x": 648946,
          "y": 192.1
        },
        {
          "x": 649598,
          "y": 194.8
        },
        {
          "x": 650891,
          "y": 187.8
        },
        {
          "x": 651556,
          "y": 192
        },
        {
          "x": 652802,
          "y": 185.8
        },
        {
          "x": 653448,
          "y": 185.3
        },
        {
          "x": 654473,
          "y": 191.3
        },
        {
          "x": 655202,
          "y": 193.2
        },
        {
          "x": 656286,
          "y": 198.4
        },
        {
          "x": 656882,
          "y": 198.7
        },
        {
          "x": 657923,
          "y": 191.3
        },
        {
          "x": 658474,
          "y": 189.2
        },
        {
          "x": 659524,
          "y": 182.4
        },
        {
          "x": 660084,
          "y": 183.9
        },
        {
          "x": 660981,
          "y": 186.6
        },
        {
          "x": 661650,
          "y": 189.8
        },
        {
          "x": 662669,
          "y": 187.8
        },
        {
          "x": 663206,
          "y": 189
        },
        {
          "x": 664192,
          "y": 189.1
        },
        {
          "x": 664690,
          "y": 185.8
        },
        {
          "x": 665645,
          "y": 178.2
        },
        {
          "x": 666174,
          "y": 178.5
        },
        {
          "x": 666995,
          "y": 172.7
        },
        {
          "x": 667569,
          "y": 171.6
        },
        {
          "x": 668447,
          "y": 175.6
        },
        {
          "x": 668934,
          "y": 178.5
        },
        {
          "x": 669751,
          "y": 185.5
        },
        {
          "x": 670176,
          "y": 189.3
        },
        {
          "x": 670916,
          "y": 192.6
        },
        {
          "x": 671286,
          "y": 194.6
        },
        {
          "x": 671926,
          "y": 192.5
        },
        {
          "x": 672386,
          "y": 194.8
        },
        {
          "x": 673095,
          "y": 198.7
        },
        {
          "x": 673475,
          "y": 202.3
        },
        {
          "x": 674173,
          "y": 209.2
        },
        {
          "x": 674521,
          "y": 213.7
        },
        {
          "x": 675589,
          "y": 220
        },
        {
          "x": 676170,
          "y": 217.4
        },
        {
          "x": 676524,
          "y": 214
        },
        {
          "x": 677068,
          "y": 215.6
        },
        {
          "x": 677380,
          "y": 217.4
        },
        {
          "x": 677984,
          "y": 226.5
        },
        {
          "x": 678291,
          "y": 230
        },
        {
          "x": 678914,
          "y": 230.8
        },
        {
          "x": 679252,
          "y": 230.3
        },
        {
          "x": 679732,
          "y": 223
        },
        {
          "x": 680081,
          "y": 225
        },
        {
          "x": 680608,
          "y": 218.4
        },
        {
          "x": 680920,
          "y": 216.2
        },
        {
          "x": 681531,
          "y": 217.3
        },
        {
          "x": 681849,
          "y": 219.7
        },
        {
          "x": 682446,
          "y": 222.6
        },
        {
          "x": 682762,
          "y": 221.3
        },
        {
          "x": 683262,
          "y": 218.7
        },
        {
          "x": 683612,
          "y": 217.5
        },
        {
          "x": 684142,
          "y": 217.5
        },
        {
          "x": 684419,
          "y": 219.4
        },
        {
          "x": 684949,
          "y": 219.5
        },
        {
          "x": 685217,
          "y": 219.7
        },
        {
          "x": 685716,
          "y": 223.5
        },
        {
          "x": 685980,
          "y": 224.4
        },
        {
          "x": 686425,
          "y": 224.3
        },
        {
          "x": 686726,
          "y": 227.3
        },
        {
          "x": 687227,
          "y": 228.7
        },
        {
          "x": 687470,
          "y": 228.8
        },
        {
          "x": 687949,
          "y": 232.3
        },
        {
          "x": 688195,
          "y": 233
        },
        {
          "x": 688652,
          "y": 235.8
        },
        {
          "x": 688881,
          "y": 234.9
        },
        {
          "x": 689304,
          "y": 235.8
        },
        {
          "x": 689603,
          "y": 239.9
        },
        {
          "x": 690057,
          "y": 235.6
        },
        {
          "x": 690294,
          "y": 234.8
        },
        {
          "x": 690771,
          "y": 240
        },
        {
          "x": 690999,
          "y": 234
        },
        {
          "x": 691432,
          "y": 235.6
        },
        {
          "x": 691672,
          "y": 236.6
        },
        {
          "x": 692057,
          "y": 235.5
        },
        {
          "x": 692338,
          "y": 235.9
        },
        {
          "x": 692765,
          "y": 232.9
        },
        {
          "x": 692990,
          "y": 234.8
        },
        {
          "x": 693433,
          "y": 234.1
        },
        {
          "x": 693639,
          "y": 234.1
        },
        {
          "x": 694068,
          "y": 235.4
        },
        {
          "x": 694300,
          "y": 234.4
        },
        {
          "x": 694672,
          "y": 235.8
        },
        {
          "x": 694941,
          "y": 236.9
        },
        {
          "x": 695383,
          "y": 236.7
        },
        {
          "x": 695618,
          "y": 237.6
        },
        {
          "x": 696062,
          "y": 234.6
        },
        {
          "x": 696287,
          "y": 234.8
        },
        {
          "x": 696717,
          "y": 235.4
        },
        {
          "x": 696963,
          "y": 232.9
        },
        {
          "x": 697331,
          "y": 232.4
        },
        {
          "x": 697660,
          "y": 232.2
        },
        {
          "x": 698120,
          "y": 231
        },
        {
          "x": 698386,
          "y": 228
        },
        {
          "x": 698880,
          "y": 226.4
        },
        {
          "x": 699102,
          "y": 227.7
        },
        {
          "x": 699633,
          "y": 227.6
        },
        {
          "x": 699909,
          "y": 226
        },
        {
          "x": 700389,
          "y": 228.7
        },
        {
          "x": 700744,
          "y": 227.6
        },
        {
          "x": 701242,
          "y": 228.2
        },
        {
          "x": 701606,
          "y": 228.8
        },
        {
          "x": 702158,
          "y": 229.3
        },
        {
          "x": 702381,
          "y": 230.3
        },
        {
          "x": 702988,
          "y": 232.4
        },
        {
          "x": 703384,
          "y": 232.8
        },
        {
          "x": 703934,
          "y": 231.2
        },
        {
          "x": 704211,
          "y": 231.2
        },
        {
          "x": 704973,
          "y": 233.9
        },
        {
          "x": 705410,
          "y": 228.5
        },
        {
          "x": 706141,
          "y": 228.6
        },
        {
          "x": 706409,
          "y": 228.1
        },
        {
          "x": 709680,
          "y": 219.7
        },
        {
          "x": 710237,
          "y": 220.2
        },
        {
          "x": 711104,
          "y": 218.9
        },
        {
          "x": 711421,
          "y": 218.7
        },
        {
          "x": 712833,
          "y": 218.5
        },
        {
          "x": 713843,
          "y": 222.3
        },
        {
          "x": 714288,
          "y": 221.7
        },
        {
          "x": 715180,
          "y": 217.7
        },
        {
          "x": 715750,
          "y": 222.1
        },
        {
          "x": 716704,
          "y": 208.1
        },
        {
          "x": 717065,
          "y": 205
        },
        {
          "x": 718064,
          "y": 192.9
        },
        {
          "x": 718779,
          "y": 184.3
        },
        {
          "x": 719794,
          "y": 187.8
        },
        {
          "x": 720337,
          "y": 195.2
        },
        {
          "x": 721318,
          "y": 206.3
        },
        {
          "x": 722039,
          "y": 211.3
        },
        {
          "x": 723133,
          "y": 213.4
        },
        {
          "x": 723464,
          "y": 213.2
        },
        {
          "x": 724445,
          "y": 210.3
        },
        {
          "x": 725059,
          "y": 206.3
        },
        {
          "x": 725937,
          "y": 206.6
        },
        {
          "x": 726322,
          "y": 208.1
        },
        {
          "x": 727192,
          "y": 204.5
        },
        {
          "x": 727732,
          "y": 205.5
        },
        {
          "x": 728568,
          "y": 205.1
        },
        {
          "x": 728921,
          "y": 205.4
        },
        {
          "x": 730299,
          "y": 204
        },
        {
          "x": 730949,
          "y": 204.1
        },
        {
          "x": 731329,
          "y": 204.9
        },
        {
          "x": 732141,
          "y": 203.8
        },
        {
          "x": 732548,
          "y": 205.9
        },
        {
          "x": 733261,
          "y": 203.9
        },
        {
          "x": 733543,
          "y": 204.4
        },
        {
          "x": 734223,
          "y": 205.3
        },
        {
          "x": 734604,
          "y": 207.4
        },
        {
          "x": 735167,
          "y": 201.7
        },
        {
          "x": 735459,
          "y": 201.3
        },
        {
          "x": 736079,
          "y": 204.1
        },
        {
          "x": 736460,
          "y": 209.7
        },
        {
          "x": 737034,
          "y": 208.1
        },
        {
          "x": 737266,
          "y": 211.3
        },
        {
          "x": 737889,
          "y": 210.6
        },
        {
          "x": 738272,
          "y": 206.5
        },
        {
          "x": 738844,
          "y": 200.3
        },
        {
          "x": 739137,
          "y": 197.8
        },
        {
          "x": 739842,
          "y": 185
        },
        {
          "x": 740277,
          "y": 178.9
        },
        {
          "x": 741021,
          "y": 178.6
        },
        {
          "x": 741307,
          "y": 176.6
        },
        {
          "x": 742075,
          "y": 177.9
        },
        {
          "x": 742558,
          "y": 182.3
        },
        {
          "x": 743233,
          "y": 181.7
        },
        {
          "x": 743589,
          "y": 180.4
        },
        {
          "x": 744354,
          "y": 181.2
        },
        {
          "x": 744843,
          "y": 182
        },
        {
          "x": 745836,
          "y": 180.2
        },
        {
          "x": 746643,
          "y": 183.1
        },
        {
          "x": 747110,
          "y": 180.8
        },
        {
          "x": 747759,
          "y": 180.8
        },
        {
          "x": 748101,
          "y": 180.4
        },
        {
          "x": 748913,
          "y": 181.4
        },
        {
          "x": 749401,
          "y": 182.9
        },
        {
          "x": 750194,
          "y": 184.8
        },
        {
          "x": 750476,
          "y": 184
        },
        {
          "x": 751301,
          "y": 186.2
        },
        {
          "x": 751830,
          "y": 190.1
        },
        {
          "x": 752605,
          "y": 188
        },
        {
          "x": 752978,
          "y": 196.5
        },
        {
          "x": 753984,
          "y": 191.5
        },
        {
          "x": 754394,
          "y": 195.8
        },
        {
          "x": 755241,
          "y": 200.5
        },
        {
          "x": 755495,
          "y": 201.9
        },
        {
          "x": 756357,
          "y": 206
        },
        {
          "x": 756871,
          "y": 210.7
        },
        {
          "x": 757561,
          "y": 215
        },
        {
          "x": 757962,
          "y": 219.2
        },
        {
          "x": 758259,
          "y": 215.3
        },
        {
          "x": 758475,
          "y": 217.6
        },
        {
          "x": 758883,
          "y": 217.9
        },
        {
          "x": 759306,
          "y": 214.9
        },
        {
          "x": 760202,
          "y": 208.8
        },
        {
          "x": 760500,
          "y": 206.9
        },
        {
          "x": 761453,
          "y": 206.1
        },
        {
          "x": 762064,
          "y": 212
        },
        {
          "x": 762871,
          "y": 216
        },
        {
          "x": 763319,
          "y": 215
        },
        {
          "x": 763686,
          "y": 225.7
        },
        {
          "x": 763934,
          "y": 221.4
        },
        {
          "x": 764249,
          "y": 224.1
        },
        {
          "x": 764833,
          "y": 222.6
        },
        {
          "x": 765735,
          "y": 216.8
        },
        {
          "x": 766110,
          "y": 215.7
        },
        {
          "x": 767096,
          "y": 213.5
        },
        {
          "x": 767737,
          "y": 217.7
        },
        {
          "x": 768655,
          "y": 222.4
        },
        {
          "x": 769050,
          "y": 221.4
        },
        {
          "x": 769514,
          "y": 228.1
        },
        {
          "x": 769787,
          "y": 226
        },
        {
          "x": 770133,
          "y": 231.5
        },
        {
          "x": 770447,
          "y": 235.6
        },
        {
          "x": 770724,
          "y": 238.2
        },
        {
          "x": 770994,
          "y": 235.6
        },
        {
          "x": 771260,
          "y": 237.7
        },
        {
          "x": 771708,
          "y": 229.5
        },
        {
          "x": 772076,
          "y": 230.9
        },
        {
          "x": 773043,
          "y": 230
        },
        {
          "x": 773680,
          "y": 233.3
        },
        {
          "x": 774548,
          "y": 236.9
        },
        {
          "x": 775051,
          "y": 240.5
        },
        {
          "x": 776037,
          "y": 238.8
        },
        {
          "x": 776636,
          "y": 243.9
        },
        {
          "x": 777556,
          "y": 246.3
        },
        {
          "x": 777900,
          "y": 246.1
        },
        {
          "x": 778798,
          "y": 245.8
        },
        {
          "x": 779364,
          "y": 243.9
        },
        {
          "x": 780268,
          "y": 248.5
        },
        {
          "x": 780612,
          "y": 242.8
        },
        {
          "x": 781535,
          "y": 248.7
        },
        {
          "x": 782059,
          "y": 246.2
        },
        {
          "x": 783263,
          "y": 252.1
        },
        {
          "x": 784147,
          "y": 246.9
        },
        {
          "x": 784692,
          "y": 250.7
        },
        {
          "x": 785413,
          "y": 253.3
        },
        {
          "x": 785789,
          "y": 255.4
        },
        {
          "x": 786587,
          "y": 260.3
        },
        {
          "x": 787076,
          "y": 256.9
        },
        {
          "x": 787805,
          "y": 248.1
        },
        {
          "x": 788033,
          "y": 247.9
        },
        {
          "x": 788887,
          "y": 229.5
        },
        {
          "x": 789381,
          "y": 226.3
        },
        {
          "x": 790153,
          "y": 218.2
        },
        {
          "x": 790538,
          "y": 221.3
        },
        {
          "x": 791491,
          "y": 215.4
        },
        {
          "x": 792081,
          "y": 205.1
        },
        {
          "x": 792943,
          "y": 204
        },
        {
          "x": 793260,
          "y": 209
        },
        {
          "x": 794608,
          "y": 199.4
        },
        {
          "x": 795202,
          "y": 195.2
        },
        {
          "x": 796467,
          "y": 189.3
        },
        {
          "x": 797099,
          "y": 188.4
        },
        {
          "x": 798512,
          "y": 191
        }
      ]
    },
    {
      "title": "RCP8.5",
      "color": "#F09662",
      "data": [
        {
          "x": 0,
          "y": 408
        },
        {
          "x": 1,
          "y": 936
        }
      ]
    }
  ]

function Co2800kya2100({ data }) {
    return <div style={{ display: 'block', height: '300px', maxWidth: '640px' }}>
        <ResponsiveLine
            data={co2DataFuture}
            margin={{ top: 50, right: 10, bottom: 30, left: 40 }}
            colors={{ datum: 'color' }}
            xScale={{ type: 'linear', reverse: true }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickValues:[100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000],
                format: '.2s',
                tickRotation: 0
            }}
            axisLeft={{
                orient: 'left',                
                tickValues:[0,100, 200, 300, 400, 500, 600, 700, 800, 900],
                tickRotation: 0
            }}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="PPM"
            pointLabelYOffset={-12}
            useMesh={true}
            animate={false}
            markers={[
                {
                    axis: 'y',
                    value: 450,
                    lineStyle: { stroke: 'purple', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'y',
                    value: 550,
                    lineStyle: { stroke: 'teal', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px'
                    },
                },
                {
                    axis: 'y',
                    value: 936,
                    lineStyle: { stroke: 'red', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px'
                    },
                }
            ]}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 1
                        }
                      },
                },
                grid: {
                    line: {
                        stroke: "#89969F"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
        />
    </div>
}

export default Co2800kya2100;