import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const co2Recent = [
    {
      "title": "Mauna Loa Observations",
      "strokeWidth": 2,
      "data": [
        {
          "x": 2018,
          "y": 407.38
        },
        {
          "x": 2017,
          "y": 405
        },
        {
          "x": 2016,
          "y": 402.86
        },
        {
          "x": 2015,
          "y": 399.41
        },
        {
          "x": 2014,
          "y": 397.12
        },
        {
          "x": 2013,
          "y": 395.19
        },
        {
          "x": 2012,
          "y": 392.46
        },
        {
          "x": 2011,
          "y": 390.45
        },
        {
          "x": 2010,
          "y": 388.57
        },
        {
          "x": 2009,
          "y": 386.29
        },
        {
          "x": 2008,
          "y": 384.79
        },
        {
          "x": 2007,
          "y": 382.68
        },
        {
          "x": 2006,
          "y": 380.94
        },
        {
          "x": 2005,
          "y": 378.81
        },
        {
          "x": 2004,
          "y": 376.79
        },
        {
          "x": 2003,
          "y": 374.96
        },
        {
          "x": 2002,
          "y": 372.42
        },
        {
          "x": 2001,
          "y": 370.41
        },
        {
          "x": 2000,
          "y": 368.84
        },
        {
          "x": 1999,
          "y": 367.64
        },
        {
          "x": 1998,
          "y": 365.54
        },
        {
          "x": 1997,
          "y": 362.9
        },
        {
          "x": 1996,
          "y": 361.79
        },
        {
          "x": 1995,
          "y": 360.04
        },
        {
          "x": 1994,
          "y": 358.21
        },
        {
          "x": 1993,
          "y": 356.71
        },
        {
          "x": 1992,
          "y": 356
        },
        {
          "x": 1991,
          "y": 355.29
        },
        {
          "x": 1990,
          "y": 353.98
        },
        {
          "x": 1989,
          "y": 352.79
        },
        {
          "x": 1988,
          "y": 351.15
        },
        {
          "x": 1987,
          "y": 348.62
        },
        {
          "x": 1986,
          "y": 346.87
        },
        {
          "x": 1985,
          "y": 345.47
        },
        {
          "x": 1984,
          "y": 343.99
        },
        {
          "x": 1983,
          "y": 342.44
        },
        {
          "x": 1982,
          "y": 340.76
        },
        {
          "x": 1981,
          "y": 340
        },
        {
          "x": 1980,
          "y": 338.8
        },
        {
          "x": 1979,
          "y": 336.84
        },
        {
          "x": 1978,
          "y": 335.4
        },
        {
          "x": 1977,
          "y": 333.83
        },
        {
          "x": 1976,
          "y": 332.04
        },
        {
          "x": 1975,
          "y": 331.11
        },
        {
          "x": 1974,
          "y": 330.18
        },
        {
          "x": 1973,
          "y": 329.68
        },
        {
          "x": 1972,
          "y": 327.45
        },
        {
          "x": 1971,
          "y": 326.32
        },
        {
          "x": 1970,
          "y": 325.68
        },
        {
          "x": 1969,
          "y": 324.62
        },
        {
          "x": 1968,
          "y": 323.04
        },
        {
          "x": 1967,
          "y": 322.16
        },
        {
          "x": 1966,
          "y": 321.38
        },
        {
          "x": 1965,
          "y": 320.04
        },
        {
          "x": 1964,
          "y": 319.62
        },
        {
          "x": 1963,
          "y": 318.99
        },
        {
          "x": 1962,
          "y": 318.45
        },
        {
          "x": 1961,
          "y": 317.64
        },
        {
          "x": 1960,
          "y": 316.91
        },
        {
          "x": 1959,
          "y": 315.97
        }
      ]
    }
  ]

function Co21959present({ data }) {
    return <div style={{ display: 'block', height: '300px', maxWidth: '640px' }}>
        <ResponsiveLine
            data={co2Recent}
            margin={{ top: 50, right: 10, bottom: 30, left: 40 }}
            xScale={{ type: 'linear', reverse: false, min: 1959 }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickValues:[1960, 1970, 1980, 1990, 2000, 2010],
                tickRotation: 0
            }}
            axisLeft={{
                orient: 'left',                
                tickValues:[0,100, 200, 300, 400],
                tickRotation: 0
            }}
            colors={['#63A2EC']}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="PPM"
            pointLabelYOffset={-12}
            useMesh={true}
            animate={false}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 1
                        }
                      },
                },
                grid: {
                    line: {
                        stroke: "#89969F"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
        />
    </div>
}

export default Co21959present;