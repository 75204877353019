import Stories from "../../../../src/components/stories";
import SEO from "../../../../src/components/seo";
import Footer from "../../../../src/components/footer";
import "../../../../src/styles/App.scss";
import * as React from 'react';
export default {
  Stories,
  SEO,
  Footer,
  React
};