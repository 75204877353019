import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const mountainsHeightData = [
    {
        "id": "Appalachian Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Appalachians',
            "z": 'Taconic'
            },
            {
            "x": 2037,
            "y": 'Appalachians',
            "z": 'Taconic'
            }  
        ]
    },
    {
        "id": "Alleghanian Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Atlas Range'
            },
            {
            "x": 4167,
            "y": 'Atlas Range'
            }     
        ]
    },
    {
        "id": "Uralian Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Urals'
            },
            {
            "x": 1895,
            "y": 'Urals'
            }     
        ]
    },
    {
        "id": "Rockies Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Rockies',
            "z": 'Sevier'
            },
            {
            "x": 4401,
            "y": 'Rockies',
            "z": 'Sevier'
            }      
        ]
    },
    {
        "id": "Sierra Madre",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Sierra Madre'
            },
            {
            "x": 3311,
            "y": "Sierra Madre"
            },       
        ]
    },
    {
        "id": "Alpine Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Alps'
            },
            {
            "x": 4808,
            "y": "Alps"
            },       
        ]
    },
    {
        "id": "Andean Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Andes',
            "z": 'Late Period'
            },
            {
            "x": 6962,
            "y": 'Andes',
            "z": 'Late Period'
            },
        ]
    },
    {
        "id": "Himalayan Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Himalayas'
            },
            {
            "x": 8848,
            "y": "Himalayas"
            },       
        ]
    },
    {
        "id": "Caucasian Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Caucasus Mtns'
            },
            {
            "x": 5642,
            "y": "Caucasus Mtns"
            },       
        ]
    },
    {
        "id": "Alaskan Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Alaska Range'
            },
            {
            "x": 6201,
            "y": "Alaska Range"
            },       
        ]
    },
    {
        "id": "Zagros Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Zagros Range'
            },
            {
            "x": 4409,
            "y": "Zagros Range"
            },       
        ]
    },
    {
        "id": "Nevadan Orogeny",
        "color": '#E7A744',
        "data": [
            {
            "x": 0,
            "y": 'Sierra Nevadas',
            "z": 'Navadan Orogeny'
            },
            {
            "x": 4421,
            "y": 'Sierra Nevadas',
            "z": 'Nevadan Orogeny'
            }     
        ]
    }
  ]

  function MountainsHeightChart({ data }) {
    return <div style={{ height: '350px', width: '30%', position: 'relative', zIndex: 1 }}>
        <ResponsiveLine
            data={mountainsHeightData}
            margin={{ top: 30, right: 10, bottom:30, left: 0 }}
            xScale={{ type: 'linear', reverse: false }}
            yScale={{ type: 'point'}}
            enableGridX={true}
            gridXValues={[0, 2000, 4000, 6000, 8000]}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisTop={{
                orient: 'top',
                tickValues:[0, 4000, 8000],
                format: '.1s',
                legend: 'Current Height (Meters)',
                legendOffset: -48,
                legendPosition: 'middle',
                tickSize: 0,
                tickPadding: 12,
                tickRotation: 0
            }}
            axisBottom={{
                orient: 'bottom',
                tickValues:[],
            }}
            axisLeft={{
                orient: 'left',
                tickValues:[],
                tickRotation: 0,
            }}
            colors={{ datum: 'color' }}
            lineWidth={8}
            pointSize={1}
            useMesh={true}
            animate={false}
            theme={{
                background: "#151B1E",
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        },
                    },
                    legend: {
                        text: {
                          fontSize: 12,
                          fill: 'white'
                        }
                      },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 0
                        }
                      },
                },
                legends: {
                    text: {
                      fill: 'white'
                    }
                  },
                  labels: {
                    text: {
                        fill: 'white'
                      }
                  },
                grid: {
                    line: {
                        stroke: "#202D35"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
        />
    </div>
}

export default MountainsHeightChart;