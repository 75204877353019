import SEO from "../../../../src/components/seo";
import MountainsChart from "../../../../src/components/chart-mountains";
import MountainsHeightChart from "../../../../src/components/chart-mountains-height";
import MountainsHeightOverTimeChart from "../../../../src/components/chart-mountains-height-over-time";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import MountainsOpenGraphImage from "../../../../src/images/social-mountain-time.jpg";
import * as React from 'react';
export default {
  SEO,
  MountainsChart,
  MountainsHeightChart,
  MountainsHeightOverTimeChart,
  graphql,
  Img,
  MountainsOpenGraphImage,
  React
};