import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <p className="mbn mtl">Made with <span role="img" aria-label="love"> ❤️</span>on planet <span role="img" aria-label="Earth"> 🌎</span></p>
      <p className="mtn mbl">© Copyright Stories From Planet Earth 2022</p>
    </div>
  );
}

export default Footer;