import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const mountainsData = [
    {
        "id": "Appalachians",
        "color": '#66CDBB',
        "data": [
            {
            "x": 490,
            "y": 0,
            "name": "Appalachians"
            },
            {
            "x": 390,
            "y": 4000,
            "name": "Appalachians"
            },
            {
            "x": 250,
            "y": 6000,
            "name": "Appalachians"
            },
            {
            "x": 0,
            "y": 2469,
            "name": "Appalachians"
            }     
        ]
    },
    {
        "id": "Rockies",
        "color": '#F37462',
        "reference": 'https://en.wikipedia.org/wiki/Geology_of_the_Rocky_Mountains',
        "data": [
            {
            "x": 70,
            "y": 900,
            "name": "Rockies"
            },
            {
            "x": 40,
            "y": 6000,
            "name": "Rockies"
            },
            {
            "x": 0,
            "y": 4401,
            "name": "Rockies"
            }     
        ]
    },
    {
        "id": "Himalayas",
        "color": '#E7A744',
        "data": [
            {
            "x": 50,
            "y": 0,
            "name": "Himalayas"
            },
            {
            "x": 0,
            "y": 8848,
            "name": "Himalayas"
            }     
        ]
    },
  ]

function MountainsHeightOverTimeChart({ data }) {
    return <div style={{ height: '420px', width: '100%', position: 'relative', zIndex: 2  }}>
        <ResponsiveLine
            data={mountainsData}
            margin={{ top: 30, right: 0, bottom: 30, left: 50 }}
            xScale={{ type: 'linear', reverse: true }}
            yScale={{ type: 'linear'}}
            curve={'natural'}
            enableGridX={true}
            gridXValues={[0,100,200,300,400]}
            enableGridY={true}
            axisTop={null}
            enableArea={true}
            areaOpacity={0.1}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickValues:[0,100,200,300,400],
                tickSize: 0,
                tickPadding: 12,
                tickRotation: 0
            }}
            axisLeft={{
                orient: 'left',
                tickRotation: 0,
                format: '.1s',
                legend: 'Height (Meters)',
                legendPosition: 'middle',
                legendOffset: '-40'
            }}
            layers={[
                "grid","axes", "lines", "points", "legends", "areas", "markers"
              ]}
            colors={{ datum: 'color' }}
            lineWidth={3}
            pointSize={1}
            useMesh={true}
            animate={false}
            tooltip={({ point }) => (
                <div
                    style={{
                        color: 'white',
                        background: '#242424',
                        padding: '8px',
                        borderRadius: '4px'
                    }}>
                    <span
                        style={{
                            display: 'block',
                            marginBottom: '4px'
                        }}>
                        {point.data.name}
                    </span>
                    <span
                        style={{
                            display: 'block',
                            fontSize: '14px',
                            marginBottom: '4px'
                        }}>
                        {point.data.y} height (meters) &nbsp;
                    </span>
                    <span
                        style={{
                            display: 'block',
                            fontSize: '14px'
                        }}>
                        {point.data.x} Million Years Ago
                    </span>
                </div>)}
            markers={[
                {
                    axis: 'x',
                    value: 470,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'First plants on land',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'x',
                    value: 400,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'First insects',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'x',
                    value: 360,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'First tetrapods on land',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'x',
                    value: 310,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'First reptiles and proto-mammals',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'x',
                    value: 230,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'First Dinosaurs',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'x',
                    value: 150,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'First Birds',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'x',
                    value: 100,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'Dinosaurs reach peak size',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'x',
                    value: 50,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'First Primates',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'x',
                    value: 5,
                    lineStyle: { stroke: '#6C6C6C', strokeWidth: 2, 
                    strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical',
                    legend: 'First Hominids',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                }
            ]}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    legend: {
                        text: {
                            fill: "rgba(255, 255, 255, 0.87)",
                            fontFamily: "Roboto Slab"
                        }
                    },
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 0
                        }
                      },
                },
                grid: {
                    line: {
                        stroke: "#202D35"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
        />
    </div>
}

export default MountainsHeightOverTimeChart;