// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-climate-changed-mdx": () => import("./../../../src/pages/climate-changed.mdx" /* webpackChunkName: "component---src-pages-climate-changed-mdx" */),
  "component---src-pages-co-2-history-mdx": () => import("./../../../src/pages/co2-history.mdx" /* webpackChunkName: "component---src-pages-co-2-history-mdx" */),
  "component---src-pages-extinction-mdx": () => import("./../../../src/pages/extinction.mdx" /* webpackChunkName: "component---src-pages-extinction-mdx" */),
  "component---src-pages-gigaton-mdx": () => import("./../../../src/pages/gigaton.mdx" /* webpackChunkName: "component---src-pages-gigaton-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-mars-isnt-planet-b-mdx": () => import("./../../../src/pages/mars-isnt-planet-b.mdx" /* webpackChunkName: "component---src-pages-mars-isnt-planet-b-mdx" */),
  "component---src-pages-mountains-mdx": () => import("./../../../src/pages/mountains.mdx" /* webpackChunkName: "component---src-pages-mountains-mdx" */),
  "component---src-pages-unrecognizable-earth-mdx": () => import("./../../../src/pages/unrecognizable-earth.mdx" /* webpackChunkName: "component---src-pages-unrecognizable-earth-mdx" */),
  "component---src-pages-water-mdx": () => import("./../../../src/pages/water.mdx" /* webpackChunkName: "component---src-pages-water-mdx" */)
}

