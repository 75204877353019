import React, { useState } from 'react';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';

const ExtinctionData = [
    {
        "id": "Atelopus longirostris",
        "name": "longnose stubfoot toad",
        "yearsago": 3,
        "yearextinct": 2017,
        "group": "Amphibia",
        "order": "Anura",
        "family": "Bufonidae",
        "location": "Ecuador",
        "wikipedia": "https://en.wikipedia.org/wiki/Atelopus_longirostris",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Atelopus_longirostris_female.jpg/440px-Atelopus_longirostris_female.jpg"
    },
    {
        "id": "Emoia nativitatis",
        "name": "Christmas Island Whiptail-skink",
        "yearsago": 3,
        "yearextinct": 2017,
        "group": "Reptilia",
        "order": "Squamata",
        "family": "Scincidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Christmas_Island_forest_skink",
        "img": "https://s2r.iucnredlist.org/sis2_images/D14jJ2fJ-D25Nlf-crw.jpg"
    },
    {
        "id": "Melomys rubicol",
        "name": "Bramble Cay Melomys",
        "yearsago": 4,
        "yearextinct": 2016,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Muridae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Bramble_Cay_melomys",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Bramble-cay-melomys.jpg/440px-Bramble-cay-melomys.jpg"
    },
    {
        "id": "Aegolius gradyi",
        "name": "Bermuda Saw-whet Owl",
        "yearsago": 6,
        "yearextinct": 2014,
        "group": "Aves",
        "order": "Strigiformes",
        "family": "Strigidae",
        "location": "Bermuda",
        "wikipedia": "https://en.wikipedia.org/wiki/Bermuda_saw-whet_owl",
        "img": "https://alchetron.com/cdn/bermuda-saw-whet-owl-67b4ede4-fd27-4be3-8e8c-aaeff2bd86c-resize-750.jpeg"
    },
    {
        "id": "Chioninia coctei",
        "name": "Cape Verde Giant Skink",
        "yearsago": 7,
        "yearextinct": 2013,
        "group": "Reptilia",
        "order": "Squamata",
        "family": "Scincidae",
        "location": "Cape Verde Islands",
        "wikipedia": "https://en.wikipedia.org/wiki/Cape_Verde_giant_skink",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Macroscincus_coctei003.jpg/800px-Macroscincus_coctei003.jpg"
    },
    {
        "id": "Neofelis nebulosa brachyura",
        "name": "Formosan Clouded Leopard",
        "yearsago": 7,
        "yearextinct": 2013,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Felidae",
        "location": "Taiwan",
        "wikipedia": "https://en.wikipedia.org/wiki/Formosan_clouded_leopard",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/LeopardusBrachyurusWolf.jpg/220px-LeopardusBrachyurusWolf.jpg"
    },
    {
        "id": "Chelonoidis abingdonii",
        "name": "Pinta Island tortoise",
        "yearsago": 8,
        "yearextinct": 2012,
        "group": "Reptilia",
        "order": "Testudines",
        "family": "Testudinidae",
        "location": "Ecuador",
        "wikipedia": "https://en.wikipedia.org/wiki/Pinta_Island_tortoise",
        "img": "https://nc.iucnredlist.org/redlist/species-of-the-day/chelonoidis-nigra-abingdonii/images/full/chelonoidis-(nigra)-abingdonii.jpg"
    },
    {
        "id": "Lutra lutra whiteleyi",
        "name": "Japanese river otter",
        "yearsago": 8,
        "yearextinct": 2012,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Mustelidae",
        "location": "Japan",
        "wikipedia": "https://en.wikipedia.org/wiki/Japanese_river_otter",
        "img": "https://upload.wikimedia.org/wikipedia/commons/9/99/Japanese_otter.jpg"
    },
    {
        "id": "Diceros bicornis longipes",
        "name": "Western black rhinoceros",
        "yearsago": 9,
        "yearextinct": 2011,
        "group": "Mammalia",
        "order": "Perissodactyla",
        "family": "Rhinocerotidae",
        "location": "Africa",
        "wikipedia": "https://en.wikipedia.org/wiki/Western_black_rhinoceros",
        "img": "https://upload.wikimedia.org/wikipedia/commons/3/33/Diceros_bicornis_longipes.jpg"
    },
    {
        "id": "Puma concolor couguar",
        "name": "Eastern cougar",
        "yearsago": 9,
        "yearextinct": 2011,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Felidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Eastern_cougar",
        "img": "https://upload.wikimedia.org/wikipedia/commons/e/ef/EasternCougar.jpg"
    },
    {
        "id": "Nannophrys guentheri",
        "name": "Gunther's streamlined frog",
        "yearsago": 16,
        "yearextinct": 2004,
        "group": "Amphibia",
        "order": "Anura",
        "family": "Dicroglossidae",
        "location": "Skri Lanka",
        "wikipedia": "https://en.wikipedia.org/wiki/Nannophrys_guentheri",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Nannophrys_guentheri.jpg/220px-Nannophrys_guentheri.jpg"
    },
    {
        "id": "Pseudophilautus adspersus",
        "name": "Pseudophilautus adspersus",
        "yearsago": 16,
        "yearextinct": 2004,
        "group": "Amphibia",
        "order": "Anura",
        "family": "Rhacophoridae",
        "location": "Skri Lanka",
        "wikipedia": "https://en.wikipedia.org/wiki/Pseudophilautus_adspersus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Pseudophilautus_adspersus.jpg/220px-Pseudophilautus_adspersus.jpg"
    },
    {
        "id": "Capra pyrenaica pyrenaica",
        "name": "Pyrenean Ibex",
        "yearsago": 20,
        "yearextinct": 2000,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "Pyrenees",
        "wikipedia": "https://en.wikipedia.org/wiki/Pyrenean_ibex",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Pyrenean_Ibex.png/800px-Pyrenean_Ibex.png"
    },
    {
        "id": "Ammodramus maritimus nigrescens",
        "name": "Dusky Seaside Sparrow",
        "yearsago": 30,
        "yearextinct": 1990,
        "group": "Aves",
        "order": "Passeriformes",
        "family": "Passerellidae",
        "location": "Florida",
        "wikipedia": "https://en.wikipedia.org/wiki/Dusky_seaside_sparrow",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Dusky_Seaside_Sparrow.jpg/220px-Dusky_Seaside_Sparrow.jpg"
    },
    {
        "id": "Incilius periglenes",
        "name": "Golden Toad",
        "yearsago": 31,
        "yearextinct": 1989,
        "group": "Amphibia",
        "order": "Anura",
        "family": "Bufonidae",
        "location": "Costa Rica",
        "wikipedia": "https://en.wikipedia.org/wiki/Golden_toad",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Bufo_periglenes2.jpg/220px-Bufo_periglenes2.jpg"
    },
    {
        "id": "Podilymbus gigas",
        "name": "Atitlán grebe",
        "yearsago": 31,
        "yearextinct": 1989,
        "group": "Aves",
        "order": "Podicipediformes",
        "family": "Podicipedidae",
        "location": "Guatemala",
        "wikipedia": "https://en.wikipedia.org/wiki/Atitl%C3%A1n_grebe",
        "img": "https://upload.wikimedia.org/wikipedia/en/3/33/Atitl%C3%A1n_Grebe.jpg"
    },
    {
        "id": "Moho braccatus",
        "name": "Kauaʻi ʻōʻō",
        "yearsago": 33,
        "yearextinct": 1987,
        "group": "Aves",
        "order": "Passeriformes",
        "family": "Mohoidae",
        "location": "Hawaii",
        "wikipedia": "https://en.wikipedia.org/wiki/Kaua%CA%BBi_%CA%BB%C5%8D%CA%BB%C5%8D",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Moho_braccatus.jpg/220px-Moho_braccatus.jpg"
    },
    {
        "id": "Rheobatrachus vitellinus",
        "name": "Northern Gastric-brooding Frog",
        "yearsago": 35,
        "yearextinct": 1985,
        "group": "Amphibia",
        "order": "Anura",
        "family": "Myobatrachidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Gastric-brooding_frog",
        "img": "https://www.extinctanimals.org/wp-content/uploads/2019/01/Northern-Gastric-Brooding-Frog.jpg"
    },
    {
        "id": "Rheobatrachus silus",
        "name": "Southern Gastric-brooding Frog",
        "yearsago": 39,
        "yearextinct": 1981,
        "group": "Amphibia",
        "order": "Anura",
        "family": "Myobatrachidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Gastric-brooding_frog",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Rheobatrachus_silus.jpg/440px-Rheobatrachus_silus.jpg"
    },
    {
        "id": "Cynops wolterstorffi",
        "name": "Yunnan lake newt",
        "yearsago": 41,
        "yearextinct": 1979,
        "group": "Amphibia",
        "order": "Urodela",
        "family": "Salamandridae",
        "location": "China (Yunnan)",
        "wikipedia": "https://en.wikipedia.org/wiki/Yunnan_lake_newt",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Cynops_wolterstorffi.JPG/220px-Cynops_wolterstorffi.JPG"
    },
    {
        "id": "Panthera tigris sondaica",
        "name": "Javan Tiger",
        "yearsago": 44,
        "yearextinct": 1976,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Felidae",
        "location": "Java",
        "wikipedia": "https://en.wikipedia.org/wiki/Javan_tiger",
        "img": "https://upload.wikimedia.org/wikipedia/commons/6/6d/Panthera_tigris_sondaica_01.jpg"
    },
    {
        "id": "Bolyeria multocarinata",
        "name": "Round Island burrowing boa",
        "yearsago": 45,
        "yearextinct": 1975,
        "group": "Reptilia",
        "order": "Squamata",
        "family": "Bolyeriidae",
        "location": "Round Island",
        "wikipedia": "https://en.wikipedia.org/wiki/Round_Island_burrowing_boa",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Bolyeria_multocarinata.jpg/220px-Bolyeria_multocarinata.jpg"
    },
    {
        "id": "Zalophus japonicus",
        "name": "Japanese Sea Lion",
        "yearsago": 46,
        "yearextinct": 1974,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Otariidae",
        "location": "Sea of Japan",
        "wikipedia": "https://en.wikipedia.org/wiki/Japanese_sea_lion",
        "img": "https://upload.wikimedia.org/wikipedia/commons/8/84/Zalophus_japonicus.JPG"
    },
    {
        "id": "Panthera tigris tigris",
        "name": "Caspian Tiger",
        "yearsago": 50,
        "yearextinct": 1970,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Felidae",
        "location": "Central Asia",
        "wikipedia": "https://en.wikipedia.org/wiki/Caspian_tiger",
        "img": "https://upload.wikimedia.org/wikipedia/commons/8/84/Panthera_tigris_virgata.jpg"
    },
    {
        "id": "Struthio camelus syriacus",
        "name": "Arabian Ostrich",
        "yearsago": 54,
        "yearextinct": 1966,
        "group": "Aves",
        "order": "Struthioniformes",
        "family": "Struthionidae",
        "location": "Arabian Peninsula",
        "wikipedia": "https://en.wikipedia.org/wiki/Arabian_ostrich",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Syrischer_Maler_um_1335_001.jpg/640px-Syrischer_Maler_um_1335_001.jpg"
    },
    {
        "id": "Ursus arctos",
        "name": "Mexican Grizzly Bear",
        "yearsago": 56,
        "yearextinct": 1964,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Ursidae",
        "location": "Mexico",
        "wikipedia": "https://en.wikipedia.org/wiki/Mexican_grizzly_bear",
        "img": "https://upload.wikimedia.org/wikipedia/en/thumb/5/5a/Mexican_grizzly_bear.jpeg/320px-Mexican_grizzly_bear.jpeg"
    },
    {
        "id": "Cryptonanus ignitus",
        "name": "Red-bellied Gracile Opossum",
        "yearsago": 58,
        "yearextinct": 1962,
        "group": "Mammalia",
        "order": "Didelphimorphia",
        "family": "Didelphidae",
        "location": "Argentina",
        "wikipedia": "https://en.wikipedia.org/wiki/Red-bellied_gracile_opossum",
        "img": "https://static.inaturalist.org/photos/22492088/medium.jpg?1533263340"
    },
    {
        "id": "Noturus trautmani",
        "name": "Scioto Madtom",
        "yearsago": 63,
        "yearextinct": 1957,
        "group": "Actinopterygii",
        "order": "Siluriformes",
        "family": "Ictaluridae",
        "location": "Ohio",
        "wikipedia": "https://en.wikipedia.org/wiki/Scioto_madtom",
        "img": "https://www.floridamuseum.ufl.edu/wp-content/uploads/sites/59/2017/07/31-N_trautmani.jpg"
    },
    {
        "id": "Onychogalea lunata",
        "name": "Crescent Nailtail Wallaby",
        "yearsago": 64,
        "yearextinct": 1956,
        "group": "Mammalia",
        "order": "Diprotodontia",
        "family": "Macropodidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Crescent_nail-tail_wallaby",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Macropus_lunata_Gould_Mamm_Aust_vol_2_plate_55.jpg/320px-Macropus_lunata_Gould_Mamm_Aust_vol_2_plate_55.jpg"
    },
    {
        "id": "Geocapromys thoracatus",
        "name": "Little Swan Island hutia",
        "yearsago": 65,
        "yearextinct": 1955,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Capromyidae",
        "location": "Swan Islands",
        "wikipedia": "https://en.wikipedia.org/wiki/Little_Swan_Island_hutia",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Geocapromys_thoracatus_%28Harvard_University%29.JPG/180px-Geocapromys_thoracatus_%28Harvard_University%29.JPG"
    },
    {
        "id": "Canis lupus bernardi",
        "name": "Banks Island wolf",
        "yearsago": 68,
        "yearextinct": 1952,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "Canada",
        "wikipedia": "https://en.wikipedia.org/wiki/Bernard%27s_wolf",
        "img": "http://www.cosmosmith.com/images/canis_lupus_bernardi.jpg"
    },
    {
        "id": "Neomonachus tropicalis",
        "name": "Caribbean monk seal",
        "yearsago": 68,
        "yearextinct": 1952,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Phocidae",
        "location": "Caribbean",
        "wikipedia": "https://en.wikipedia.org/wiki/Caribbean_monk_seal",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Cms-newyorkzoologicalsociety1910.jpg/320px-Cms-newyorkzoologicalsociety1910.jpg"
    },
    {
        "id": "Chaeropus ecaudatus",
        "name": "Pig-footed bandicoot",
        "yearsago": 70,
        "yearextinct": 1950,
        "group": "Mammalia",
        "order": "Peramelemorphia",
        "family": "Chaeropodidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Pig-footed_bandicoot",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/PigFootedBandicoot.jpg/360px-PigFootedBandicoot.jpg"
    },
    {
        "id": "Canis lupus monstrabilis",
        "name": "Texas wolf",
        "yearsago": 78,
        "yearextinct": 1942,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Texas_wolf",
        "img": "https://upload.wikimedia.org/wikipedia/commons/8/8f/Dogs%2C_jackals%2C_wolves%2C_and_foxes_%28Plate_III%29_C._l._monstrabilis_mod.jpg"
    },
    {
        "id": "Panthera leo leo",
        "name": "Barbary lion",
        "yearsago": 78,
        "yearextinct": 1942,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Felidae",
        "location": "North Africa",
        "wikipedia": "https://en.wikipedia.org/wiki/Barbary_lion",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Barbary_lion.jpg/320px-Barbary_lion.jpg"
    },
    {
        "id": "Canis lupus fuscus",
        "name": "Cascade mountain wolf",
        "yearsago": 80,
        "yearextinct": 1940,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Cascade_mountain_wolf",
        "img": "https://upload.wikimedia.org/wikipedia/commons/a/a5/Dogs%2C_jackals%2C_wolves%2C_and_foxes_%28Plate_IV%29_C._l._fuscus_mod.jpg"
    },
    {
        "id": "Macropus greyi",
        "name": "Toolache wallaby",
        "yearsago": 81,
        "yearextinct": 1939,
        "group": "Mammalia",
        "order": "Diprotodontia",
        "family": "Macropodidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Toolache_wallaby",
        "img": "https://upload.wikimedia.org/wikipedia/commons/e/e7/Macropus_greyi_-_Gould.jpg"
    },
    {
        "id": "Thylacinus cynocephalus",
        "name": "Thylacine",
        "yearsago": 84,
        "yearextinct": 1936,
        "group": "Mammalia",
        "order": "Dasyuromorphia",
        "family": "Thylacinidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Thylacine",
        "img": "https://upload.wikimedia.org/wikipedia/commons/d/d5/Thylacinus.jpg"
    },
    {
        "id": "Caloprymnus campestris",
        "name": "Desert rat-kangaroo",
        "yearsago": 85,
        "yearextinct": 1935,
        "group": "Mammalia",
        "order": "Diprotodontia",
        "family": "Potoroidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Desert_rat-kangaroo",
        "img": "https://upload.wikimedia.org/wikipedia/commons/9/98/Caloprymnus.jpg"
    },
    {
        "id": "Canis lupus mogollonensis",
        "name": "Mogollon mountain wolf",
        "yearsago": 85,
        "yearextinct": 1935,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "Southwest United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Mogollon_mountain_wolf",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/The_Wolves_of_North_America_%281944%29_C._l._mogollonensis_%E2%99%82.jpg/267px-The_Wolves_of_North_America_%281944%29_C._l._mogollonensis_%E2%99%82.jpg"
    },
    {
        "id": "Nesoryzomys indefessus",
        "name": "indefatigable Galapagos mouse",
        "yearsago": 86,
        "yearextinct": 1934,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Cricetidae",
        "location": "Ecuador",
        "wikipedia": "https://en.wikipedia.org/wiki/Nesoryzomys_indefessus",
        "img": "https://alchetron.com/cdn/nesoryzomys-indefessus-9b2906cc-d84d-45e7-9eae-b13be93b449-resize-750.jpeg"
    },
    {
        "id": "Leporillus apicalis",
        "name": "white-tipped stick-nest rat",
        "yearsago": 87,
        "yearextinct": 1933,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Muridae",
        "location": "Australia, west-central",
        "wikipedia": "https://en.wikipedia.org/wiki/Lesser_stick-nest_rat",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Leporillus_apicalis_-_Gould.jpg/180px-Leporillus_apicalis_-_Gould.jpg"
    },
    {
        "id": "Rucervus schomburgki",
        "name": "Schomburgk's deer",
        "yearsago": 88,
        "yearextinct": 1932,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Cervidae",
        "location": "Thailand",
        "wikipedia": "https://en.wikipedia.org/wiki/Schomburgk%27s_deer",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/SchomburgksDeer-Berlin1911.jpg/180px-SchomburgksDeer-Berlin1911.jpg"
    },
    {
        "id": "Tympanuchus cupido cupido",
        "name": "Heath hen",
        "yearsago": 88,
        "yearextinct": 1932,
        "group": "Aves",
        "order": "Galliformes",
        "family": "Phasianidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Heath_hen",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Tympanuchus_cupido_cupidoAEP11LA.png/234px-Tympanuchus_cupido_cupidoAEP11LA.png"
    },
    {
        "id": "Macrotis leucura",
        "name": "Lesser bilby",
        "yearsago": 89,
        "yearextinct": 1931,
        "group": "Mammalia",
        "order": "Peramelemorphia",
        "family": "Thylacomyidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Lesser_bilby",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Lesserbilby.jpg/360px-Lesserbilby.jpg"
    },
    {
        "id": "Nesoryzomys darwini",
        "name": "Darwin's Galapagos mouse",
        "yearsago": 90,
        "yearextinct": 1930,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Cricetidae",
        "location": "Ecuador",
        "wikipedia": "https://en.wikipedia.org/wiki/Nesoryzomys_darwini",
        "img": "https://animalcorner.co.uk/wp-content/uploads/2015/02/galapagos-rice-rat-340x200.jpg"
    },
    {
        "id": "Pseudomys gouldii",
        "name": "Gould's mouse",
        "yearsago": 90,
        "yearextinct": 1930,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Muridae",
        "location": "Australia, southern half",
        "wikipedia": "https://en.wikipedia.org/wiki/Gould%27s_mouse",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Pseudomys_gouldii_-_Gould.jpg/360px-Pseudomys_gouldii_-_Gould.jpg"
    },
    {
        "id": "Canis lupus alces",
        "name": "Kenai Peninsula wolf",
        "yearsago": 95,
        "yearextinct": 1925,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "Alaska",
        "wikipedia": "https://en.wikipedia.org/wiki/Kenai_Peninsula_wolf",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/The_Wolves_of_North_America_%281944%29_C._l._alces_%E2%99%82.jpg/275px-The_Wolves_of_North_America_%281944%29_C._l._alces_%E2%99%82.jpg"
    },
    {
        "id": "Ursus arctos californicus",
        "name": "California Grizzly Bear",
        "yearsago": 96,
        "yearextinct": 1924,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Ursidae",
        "location": "California",
        "wikipedia": "https://en.wikipedia.org/wiki/California_grizzly_bear",
        "img": "https://upload.wikimedia.org/wikipedia/commons/d/de/Monarch_the_bear.jpg"
    },
    {
        "id": "Conuropsis carolinensis",
        "name": "Carolina parakeet",
        "yearsago": 102,
        "yearextinct": 1918,
        "group": "Aves",
        "order": "Psittaciformes",
        "family": "Psittacidae",
        "location": "United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Carolina_parakeet",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Conuropsis_carolinensis_%28Carolina_parakeet%29.jpg/260px-Conuropsis_carolinensis_%28Carolina_parakeet%29.jpg"
    },
    {
        "id": "Ectopistes migratorius",
        "name": "Passenger pigeon",
        "yearsago": 106,
        "yearextinct": 1914,
        "group": "Aves",
        "order": "Columbiformes",
        "family": "Columbidae",
        "location": "United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Passenger_pigeon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Bird_lore_%281913%29_%2814562557107%29.jpg/360px-Bird_lore_%281913%29_%2814562557107%29.jpg"
    },
    {
        "id": "Canis lupus beothucus",
        "name": "Newfoundland wolf",
        "yearsago": 109,
        "yearextinct": 1911,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "Newfoundland",
        "wikipedia": "https://en.wikipedia.org/wiki/Newfoundland_wolf",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Stuffed_Newfoundland_wolf.jpg/292px-Stuffed_Newfoundland_wolf.jpg"
    },
    {
        "id": "Quiscalus palustris",
        "name": "Slender-billed grackle",
        "yearsago": 110,
        "yearextinct": 1910,
        "group": "Aves",
        "order": "Passeriformes",
        "family": "Icteridae",
        "location": "Mexico, Central",
        "wikipedia": "https://en.wikipedia.org/wiki/Slender-billed_grackle",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/QuiscalusTenuirostris.jpg/220px-QuiscalusTenuirostris.jpg"
    },
    {
        "id": "Equus ferus ferus",
        "name": "Tarpan",
        "yearsago": 111,
        "yearextinct": 1909,
        "group": "Mammalia",
        "order": "Perissodactyla",
        "family": "Equidae",
        "location": "Eurasia",
        "wikipedia": "https://en.wikipedia.org/wiki/Tarpan",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Kherson_tarpan.jpg/320px-Kherson_tarpan.jpg"
    },
    {
        "id": "Heteralocha acutirostris",
        "name": "Huia",
        "yearsago": 113,
        "yearextinct": 1907,
        "group": "Aves",
        "order": "Passeriformes",
        "family": "Callaeidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Huia",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Huia_Buller.jpg/192px-Huia_Buller.jpg"
    },
    {
        "id": "Canis lupus hodophilax",
        "name": "Japanese wolf",
        "yearsago": 115,
        "yearextinct": 1905,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "Japan",
        "wikipedia": "https://en.wikipedia.org/wiki/Japanese_wolf",
        "img": "https://upload.wikimedia.org/wikipedia/commons/8/81/Fauna_Japonica_%281833%29_Canis_hodophylax.jpg"
    },
    {
        "id": "Rattus macleari",
        "name": "Maclear's rat",
        "yearsago": 117,
        "yearextinct": 1903,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Muridae",
        "location": "Christmas Island",
        "wikipedia": "https://en.wikipedia.org/wiki/Maclear%27s_rat",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/MusMacleariSmit.jpg/360px-MusMacleariSmit.jpg"
    },
    {
        "id": "Rattus nativitatis",
        "name": "Bulldog rat",
        "yearsago": 117,
        "yearextinct": 1903,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Muridae",
        "location": "Christmas Island",
        "wikipedia": "https://en.wikipedia.org/wiki/Bulldog_rat",
        "img": "https://upload.wikimedia.org/wikipedia/en/thumb/6/65/Rattus_nativitatis.jpg/360px-Rattus_nativitatis.jpg"
    },
    {
        "id": "Megalomys desmarestii",
        "name": "Antillean giant rice rat",
        "yearsago": 118,
        "yearextinct": 1902,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Cricetidae",
        "location": "Martinique",
        "wikipedia": "https://en.wikipedia.org/wiki/Megalomys_desmarestii",
        "img": "https://upload.wikimedia.org/wikipedia/commons/9/96/Megalomys_desmarestii_Gervais.png"
    },
    {
        "id": "Caracara lutosa",
        "name": "Guadalupe caracara",
        "yearsago": 120,
        "yearextinct": 1900,
        "group": "Aves",
        "order": "Falconiformes",
        "family": "Falconidae",
        "location": "Mexico, Guadalupe",
        "wikipedia": "https://en.wikipedia.org/wiki/Guadalupe_caracara",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Caracara_cheriway_by_Audubon.jpg/75px-Caracara_cheriway_by_Audubon.jpg"
    },
    {
        "id": "Oryzomys nelsoni",
        "name": "Nelson's rice rat",
        "yearsago": 123,
        "yearextinct": 1897,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Cricetidae",
        "location": "Islas Marías",
        "wikipedia": "https://en.wikipedia.org/wiki/Nelson%27s_rice_rat",
        "img": "https://upload.wikimedia.org/wikipedia/commons/7/7d/Oryzomys_nelsoni_dorsal.png"
    },
    {
        "id": "Lagorchestes leporides",
        "name": "Eastern hare-wallaby",
        "yearsago": 130,
        "yearextinct": 1890,
        "group": "Mammalia",
        "order": "Diprotodontia",
        "family": "Macropodidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Eastern_hare-wallaby",
        "img": "https://upload.wikimedia.org/wikipedia/commons/1/13/Lagorchestes_leporides_Gould.jpg"
    },
    {
        "id": "Canis lupus hattai",
        "name": "Hokkaido wolf",
        "yearsago": 131,
        "yearextinct": 1889,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "Japan",
        "wikipedia": "https://en.wikipedia.org/wiki/Hokkaido_wolf",
        "img": "https://upload.wikimedia.org/wikipedia/commons/2/2c/Yezo_wolf_at_Hokkaido_University.jpg"
    },
    {
        "id": "Equus quagga quagga",
        "name": "Quagga",
        "yearsago": 137,
        "yearextinct": 1883,
        "group": "Mammalia",
        "order": "Perissodactyla",
        "family": "Equidae",
        "location": "South Africa",
        "wikipedia": "https://en.wikipedia.org/wiki/Quagga",
        "img": "https://upload.wikimedia.org/wikipedia/commons/d/d2/Quagga_photo.jpg"
    },
    {
        "id": "Megalomys luciae",
        "name": "St Lucy giant rice rat",
        "yearsago": 139,
        "yearextinct": 1881,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Cricetidae",
        "location": "Saint Lucia",
        "wikipedia": "https://en.wikipedia.org/wiki/Megalomys_luciae",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Mus_Nat_Hist_Nat_25022013_Megalomys_luciae.jpg/300px-Mus_Nat_Hist_Nat_25022013_Megalomys_luciae.jpg"
    },
    {
        "id": "Cervus canadensis canadensis",
        "name": "Eastern Elk",
        "yearsago": 140,
        "yearextinct": 1880,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Cervidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Eastern_elk",
        "img": "https://upload.wikimedia.org/wikipedia/commons/9/96/Brooklyn_Museum_-_American_Elk_-_John_J._Audubon.jpg"
    },
    {
        "id": "Ursus arctos crowtheri",
        "name": "Atlas bear",
        "yearsago": 141,
        "yearextinct": 1879,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Ursidae",
        "location": "North Africa",
        "wikipedia": "https://en.wikipedia.org/wiki/Atlas_bear",
        "img": "https://upload.wikimedia.org/wikipedia/commons/6/6d/Atlasbear.jpg"
    },
    {
        "id": "Camptorhynchus labradorius",
        "name": "Labrador duck",
        "yearsago": 142,
        "yearextinct": 1878,
        "group": "Aves",
        "order": "Anseriformes",
        "family": "Anatidae",
        "location": "Canada",
        "wikipedia": "https://en.wikipedia.org/wiki/Labrador_duck",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Labrador_Ducks_AMNH.jpg/320px-Labrador_Ducks_AMNH.jpg"
    },
    {
        "id": "Oryzomys antillarum",
        "name": "Jamaican rice rat",
        "yearsago": 143,
        "yearextinct": 1877,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Cricetidae",
        "location": "Jamaica",
        "wikipedia": "https://en.wikipedia.org/wiki/Jamaican_rice_rat",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Oryzomys_antillarum_Ray.png/130px-Oryzomys_antillarum_Ray.png"
    },
    {
        "id": "Dusicyon australis",
        "name": "Falkland Islands wolf",
        "yearsago": 144,
        "yearextinct": 1876,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "Falkland Islands",
        "wikipedia": "https://en.wikipedia.org/wiki/Falkland_Islands_wolf",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Falkland_Island_wolf%2C_Dusicyon_australis_OMNZVT2369_%211pub_%28cropped%29.jpg/265px-Falkland_Island_wolf%2C_Dusicyon_australis_OMNZVT2369_%211pub_%28cropped%29.jpg"
    },
    {
        "id": "Potorous platyops",
        "name": "Broad-faced potoroo",
        "yearsago": 145,
        "yearextinct": 1875,
        "group": "Mammalia",
        "order": "Diprotodontia",
        "family": "Potoroidae",
        "location": "Australia",
        "wikipedia": "https://en.wikipedia.org/wiki/Broad-faced_potoroo",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Potorous_platyops_Natural_History_Museum_Pisa.jpg/320px-Potorous_platyops_Natural_History_Museum_Pisa.jpg"
    },
    {
        "id": "Pteropus brunneus",
        "name": "Percy Island flying fox",
        "yearsago": 150,
        "yearextinct": 1870,
        "group": "Mammalia",
        "order": "Chiroptera",
        "family": "Pteropodidae",
        "location": "Percy Island",
        "wikipedia": "https://en.wikipedia.org/wiki/Dusky_flying_fox",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Pteropus.jpg/220px-Pteropus.jpg"
    },
    {
        "id": "Pteropus subniger",
        "name": "Small Mauritian flying fox",
        "yearsago": 156,
        "yearextinct": 1864,
        "group": "Mammalia",
        "order": "Chiroptera",
        "family": "Pteropodidae",
        "location": "Réunion and Mauritius",
        "wikipedia": "https://en.wikipedia.org/wiki/Lesser_Mascarene_flying_fox",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Pteropus_subniger.jpg/180px-Pteropus_subniger.jpg"
    },
    {
        "id": "Ara tricolor",
        "name": "Cuban macaw",
        "yearsago": 160,
        "yearextinct": 1860,
        "group": "Aves",
        "order": "Psittaciformes",
        "family": "Psittacidae",
        "location": "Cuba",
        "wikipedia": "https://en.wikipedia.org/wiki/Cuban_macaw",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/A._tricolor.jpg/220px-A._tricolor.jpg"
    },
    {
        "id": "Conilurus albipes",
        "name": "White-footed rabbit-rat",
        "yearsago": 160,
        "yearextinct": 1860,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Muridae",
        "location": "Australia, East Coast",
        "wikipedia": "https://en.wikipedia.org/wiki/White-footed_rabbit-rat",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Conilurus_albipes_-_Gould.jpg/360px-Conilurus_albipes_-_Gould.jpg"
    },
    {
        "id": "Neovison macrodon",
        "name": "Sea mink",
        "yearsago": 160,
        "yearextinct": 1860,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Mustelidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Sea_mink",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/The_Canadian_field-naturalist_%281988%29_%2820332897078%29.jpg/320px-The_Canadian_field-naturalist_%281988%29_%2820332897078%29.jpg"
    },
    {
        "id": "Pinguinus impennis",
        "name": "Great auk",
        "yearsago": 168,
        "yearextinct": 1852,
        "group": "Aves",
        "order": "Charadriiformes",
        "family": "Alcidae",
        "location": "North Atlantic",
        "wikipedia": "https://en.wikipedia.org/wiki/Great_auk",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Great_Auk_%28Pinguinis_impennis%29_specimen%2C_Kelvingrove%2C_Glasgow_-_geograph.org.uk_-_1108249.jpg/160px-Great_Auk_%28Pinguinis_impennis%29_specimen%2C_Kelvingrove%2C_Glasgow_-_geograph.org.uk_-_1108249.jpg"
    },
    {
        "id": "Eudorcas rufina",
        "name": "Red gazelle",
        "yearsago": 170,
        "yearextinct": 1850,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "Algeria",
        "wikipedia": "https://en.wikipedia.org/wiki/Red_gazelle",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Eudorcas_rufina.jpg/260px-Eudorcas_rufina.jpg"
    },
    {
        "id": "Phalacrocorax perspicillatus",
        "name": "Spectacled cormorant",
        "yearsago": 170,
        "yearextinct": 1850,
        "group": "Aves",
        "order": "Suliformes",
        "family": "Phalacrocoracidae",
        "location": "Bering Island",
        "wikipedia": "https://en.wikipedia.org/wiki/Spectacled_cormorant",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/ExtbPallusCormorantovw.jpg/220px-ExtbPallusCormorantovw.jpg"
    },
    {
        "id": "Phelsuma gigas",
        "name": "Rodrigues giant day gecko",
        "yearsago": 178,
        "yearextinct": 1842,
        "group": "Reptilia",
        "order": "Squamata",
        "family": "Gekkonidae",
        "location": "Rodrigues",
        "wikipedia": "https://en.wikipedia.org/wiki/Rodrigues_giant_day_gecko",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Phelsuma_gigas_-_Giant_Rodrigues_Gecko_-_extinct_2.jpg/220px-Phelsuma_gigas_-_Giant_Rodrigues_Gecko_-_extinct_2.jpg"
    },
    {
        "id": "Cylindraspis indica",
        "name": "Réunion giant tortoise",
        "yearsago": 180,
        "yearextinct": 1840,
        "group": "Reptilia",
        "order": "Testudines",
        "family": "Testudinidae",
        "location": "Reunion Island",
        "wikipedia": "https://en.wikipedia.org/wiki/R%C3%A9union_giant_tortoise",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Cylindraspis_indica_1792.png/220px-Cylindraspis_indica_1792.png"
    },
    {
        "id": "Tachygyia microlepis",
        "name": "Tonga ground skink",
        "yearsago": 193,
        "yearextinct": 1827,
        "group": "Reptilia",
        "order": "Squamata",
        "family": "Scincidae",
        "location": "Tonga",
        "wikipedia": "https://en.wikipedia.org/wiki/Tonga_ground_skink",
        "img": "https://alchetron.com/cdn/tonga-ground-skink-e4b7c297-f11a-4b69-80cf-d3aeaa2ed63-resize-750.jpeg"
    },
    {
        "id": "Alectroenas nitidissimus",
        "name": "Mauritius blue pigeon",
        "yearsago": 194,
        "yearextinct": 1826,
        "group": "Aves",
        "order": "Columbiformes",
        "family": "Columbidae",
        "location": "Mauritius",
        "wikipedia": "https://en.wikipedia.org/wiki/Mauritius_blue_pigeon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/07/Mauritius_Blue_Pigeon_national_museum_of_scotland.JPG/183px-Mauritius_Blue_Pigeon_national_museum_of_scotland.JPG"
    },
    {
        "id": "Aplonis mavornata",
        "name": "Mauke starling",
        "yearsago": 195,
        "yearextinct": 1825,
        "group": "Aves",
        "order": "Passeriformes",
        "family": "Sturnidae",
        "location": "Mauke, Cooke Islands",
        "wikipedia": "https://en.wikipedia.org/wiki/Mauke_starling",
        "img": "https://avibirds.com/wp-content/uploads/2019/06/mysterious-starling.jpg"
    },
    {
        "id": "Cylindraspis peltastes",
        "name": "Domed Rodrigues giant tortoise",
        "yearsago": 220,
        "yearextinct": 1800,
        "group": "Reptilia",
        "order": "Testudines",
        "family": "Testudinidae",
        "location": "Rodrigues",
        "wikipedia": "https://en.wikipedia.org/wiki/Domed_Rodrigues_giant_tortoise",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Cylindraspis_peltastes_1770.png/220px-Cylindraspis_peltastes_1770.png"
    },
    {
        "id": "Cylindraspis vosmaeri",
        "name": "Saddle-backed Rodrigues giant tortoise",
        "yearsago": 220,
        "yearextinct": 1800,
        "group": "Reptilia",
        "order": "Testudines",
        "family": "Testudinidae",
        "location": "Rodrigues",
        "wikipedia": "https://en.wikipedia.org/wiki/Saddle-backed_Rodrigues_giant_tortoise",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Centenaire_de_la_fondation_du_Mus%C3%A9um_d%27histoire_naturelle_10_juin_1793_-_10_juin_1893_-_volume_comm%C3%A9moratif_%281893%29_%2819965220184%29.jpg/220px-Centenaire_de_la_fondation_du_Mus%C3%A9um_d%27histoire_naturelle_10_juin_1793_-_10_juin_1893_-_volume_comm%C3%A9moratif_%281893%29_%2819965220184%29.jpg"
    },
    {
        "id": "Hippotragus leucophaeus",
        "name": "Bluebuck",
        "yearsago": 220,
        "yearextinct": 1800,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "South Africa",
        "wikipedia": "https://en.wikipedia.org/wiki/Bluebuck",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/The_book_of_antelopes_%281894%29_Hippotragus_leucophaeus.png/360px-The_book_of_antelopes_%281894%29_Hippotragus_leucophaeus.png"
    },
    {
        "id": "Hoplodactylus delcourti",
        "name": "Kawekaweau",
        "yearsago": 220,
        "yearextinct": 1800,
        "group": "Reptilia",
        "order": "Squamata",
        "family": "Diplodactylidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Hoplodactylus_delcourti",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Gecko_de_Delcourt_Hoplodactylus_delcourti_GLAM_MHNL_2016_3742.jpg/220px-Gecko_de_Delcourt_Hoplodactylus_delcourti_GLAM_MHNL_2016_3742.jpg"
    },
    {
        "id": "Porphyrio albus",
        "name": "Lord Howe swamphen",
        "yearsago": 230,
        "yearextinct": 1790,
        "group": "Aves",
        "order": "Gruiformes",
        "family": "Rallidae",
        "location": "Lorde Howe Island",
        "wikipedia": "https://en.wikipedia.org/wiki/Lord_Howe_swamphen",
        "img": "https://upload.wikimedia.org/wikipedia/commons/0/0d/PorphyrioAlbus.jpg"
    },
    {
        "id": "Psittacara maugei",
        "name": "Puerto Rican parakeet",
        "yearsago": 241,
        "yearextinct": 1779,
        "group": "Aves",
        "order": "Psittaciformes",
        "family": "Psittacidae",
        "location": "Puerto Rico",
        "wikipedia": "https://en.wikipedia.org/wiki/Puerto_Rican_parakeet",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Aratinga_chloroptera_maugei.jpg/220px-Aratinga_chloroptera_maugei.jpg"
    },
    {
        "id": "Cyanoramphus ulietanus",
        "name": "Society parakeet",
        "yearsago": 243,
        "yearextinct": 1777,
        "group": "Aves",
        "order": "Psittaciformes",
        "family": "Psittaculidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Society_parakeet",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Perru_disparue.jpg/181px-Perru_disparue.jpg"
    },
    {
        "id": "Prolagus sardus",
        "name": "Sardinian pika",
        "yearsago": 246,
        "yearextinct": 1774,
        "group": "Mammalia",
        "order": "Lagomorpha",
        "family": "Prolagidae",
        "location": "Sardinia",
        "wikipedia": "https://en.wikipedia.org/wiki/Sardinian_pika",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Prolagus3.jpg/320px-Prolagus3.jpg"
    },
    {
        "id": "Prosobonia leucoptera",
        "name": "Tahiti sandpiper",
        "yearsago": 247,
        "yearextinct": 1773,
        "group": "Aves",
        "order": "Charadriiformes",
        "family": "Scolopacidae",
        "location": "Tahiti",
        "wikipedia": "https://en.wikipedia.org/wiki/Tahiti_sandpiper",
        "img": "https://upload.wikimedia.org/wikipedia/commons/9/9e/Prosobonia_ellisi.jpg"
    },
    {
        "id": "Hydrodamalis gigas",
        "name": "Steller's sea cow",
        "yearsago": 252,
        "yearextinct": 1768,
        "group": "Mammalia",
        "order": "Sirenia",
        "family": "Dugongidae",
        "location": "Commander Islands, Bering Sea",
        "wikipedia": "https://en.wikipedia.org/wiki/Steller%27s_sea_cow",
        "img": "https://upload.wikimedia.org/wikipedia/commons/5/5a/Em_-_Hydrodamalis_gigas_model.jpg"
    },
    {
        "id": "Cylindraspis triserrata",
        "name": "Domed Mauritius giant tortoise",
        "yearsago": 285,
        "yearextinct": 1735,
        "group": "Reptilia",
        "order": "Testudines",
        "family": "Testudinidae",
        "location": "Mauritius",
        "wikipedia": "https://en.wikipedia.org/wiki/Domed_Mauritius_giant_tortoise",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Cylindraspis_inepta_skull.jpg/220px-Cylindraspis_inepta_skull.jpg"
    },
    {
        "id": "Aepyornis maximus",
        "name": "Elephant bird",
        "yearsago": 330,
        "yearextinct": 1690,
        "group": "Aves",
        "order": "Aepyornithiformes",
        "family": "Aepyornithidae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Aepyornis",
        "img": "https://upload.wikimedia.org/wikipedia/commons/1/1e/Aepyornis_maximus.jpg"
    },
    {
        "id": "Raphus cucullatus",
        "name": "Dodo",
        "yearsago": 358,
        "yearextinct": 1662,
        "group": "Aves",
        "order": "Columbiformes",
        "family": "Columbidae",
        "location": "Mauritius",
        "wikipedia": "https://en.wikipedia.org/wiki/Dodo",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Oxford_Dodo_display.jpg/320px-Oxford_Dodo_display.jpg"
    },
    {
        "id": "Didosaurus maurtianus",
        "name": "Mauritian giant skink",
        "yearsago": 370,
        "yearextinct": 1650,
        "group": "Reptilia",
        "order": "Squamata",
        "family": "Scincidae",
        "location": "Mauritius",
        "wikipedia": "https://en.wikipedia.org/wiki/Mauritian_giant_skink",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Leiolopisma_mauritiana_-_memorial_model_-_Ile_aux_Aigrettes.jpg/220px-Leiolopisma_mauritiana_-_memorial_model_-_Ile_aux_Aigrettes.jpg"
    },
    {
        "id": "Chenonetta finschi",
        "name": "Finsch's duck",
        "yearsago": 375,
        "yearextinct": 1645,
        "group": "Aves",
        "order": "Anseriformes",
        "family": "Anatidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Finsch%27s_duck",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/47083MA_I043773.jpg"
    },
    {
        "id": "Bos primigenius",
        "name": "Aurochs",
        "yearsago": 393,
        "yearextinct": 1627,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "Europe, Asia, North Africa",
        "wikipedia": "https://en.wikipedia.org/wiki/Aurochs",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Copenhagen_Aurochse.jpg/240px-Copenhagen_Aurochse.jpg"
    },
    {
        "id": "Megadyptes waitaha",
        "name": "Waitaha penguin",
        "yearsago": 470,
        "yearextinct": 1550,
        "group": "Aves",
        "order": "Sphenisciformes",
        "family": "Spheniscidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Waitaha_penguin",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/470120S.036277~v%201~Megadyptes%20waitaha%20humerus.jpg"
    },
    {
        "id": "Anomalopteryx didiformis",
        "name": "Little bush moa",
        "yearsago": 520,
        "yearextinct": 1500,
        "group": "Aves",
        "order": "Dinornithiformes",
        "family": "Emeidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Moa",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/47038MA_I043763.jpg"
    },
    {
        "id": "Antillothrix bernensis",
        "name": "Hispaniola monkey",
        "yearsago": 520,
        "yearextinct": 1500,
        "group": "Mammalia",
        "order": "Primates",
        "family": "Pitheciidae",
        "location": "Hispaniola",
        "wikipedia": "https://en.wikipedia.org/wiki/Hispaniola_monkey",
        "img": "https://www.researchgate.net/profile/Siobhan_Cooke/publication/45365541/figure/fig1/AS:601729519325214@1520474905982/The-Antillothrix-bernensis-skull-MHD-01-shown-in-several-views-a-right-b-left.png"
    },
    {
        "id": "Boromys offella",
        "name": "Oriente cave rat",
        "yearsago": 520,
        "yearextinct": 1500,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Echimyidae",
        "location": "Cuba",
        "wikipedia": "https://en.wikipedia.org/wiki/Oriente_cave_rat",
        "img": "https://upload.wikimedia.org/wikipedia/commons/7/77/Boromys.offella.bmcz.jpg"
    },
    {
        "id": "Boromys torrei",
        "name": "Torre's cave rat",
        "yearsago": 520,
        "yearextinct": 1500,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Echimyidae",
        "location": "Cuba",
        "wikipedia": "https://en.wikipedia.org/wiki/Torre%27s_cave_rat",
        "img": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Boromys.torrei.bmcz.jpg"
    },
    {
        "id": "Cryptoprocta spelea",
        "name": "Giant fossa",
        "yearsago": 520,
        "yearextinct": 1500,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Eupleridae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Giant_fossa",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Fossa_de_les_cavernes.png/180px-Fossa_de_les_cavernes.png"
    },
    {
        "id": "Hippopotamus lemerlei",
        "name": "Madagascan dwarf hippopotamus",
        "yearsago": 520,
        "yearextinct": 1500,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Hippopotamidae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Madagascan_dwarf_hippopotamus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Hippopotamus_lemerlei_skull.jpg/150px-Hippopotamus_lemerlei_skull.jpg"
    },
    {
        "id": "Megaoryzomys curioi",
        "name": "Galápagos giant rat",
        "yearsago": 520,
        "yearextinct": 1500,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Cricetidae",
        "location": "Santa Cruz Island, Galápagos",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaoryzomys",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Megaoryzomys_curioi_skull.JPG/440px-Megaoryzomys_curioi_skull.JPG"
    },
    {
        "id": "Tyto pollens",
        "name": "Andros Island barn owl",
        "yearsago": 520,
        "yearextinct": 1500,
        "group": "Aves",
        "order": "Strigiformes",
        "family": "Tytonidae",
        "location": "Bahamas",
        "wikipedia": "https://en.wikipedia.org/wiki/Tyto_pollens",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Tyto_pollens_hccm.jpeg/440px-Tyto_pollens_hccm.jpeg"
    },
    {
        "id": "Palaeopropithecus ingens",
        "name": "Large Sloth Lemur",
        "yearsago": 580,
        "yearextinct": 1440,
        "group": "Mammalia",
        "order": "Primates",
        "family": "Palaeopropithecidae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Palaeopropithecus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Palaeopropithecus_ingens.jpg/181px-Palaeopropithecus_ingens.jpg"
    },
    {
        "id": "Dinornis robustus",
        "name": "South Island giant moa",
        "yearsago": 600,
        "yearextinct": 1420,
        "group": "Aves",
        "order": "Dinornithiformes",
        "family": "Notopalaeognathae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Moa",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/47046MA_I043758.jpg"
    },
    {
        "id": "Dinornis novaezealandiae",
        "name": "North Island giant moa",
        "yearsago": 620,
        "yearextinct": 1400,
        "group": "Aves",
        "order": "Dinornithiformes",
        "family": "Dinornithidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Moa",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/47045MA_I043757.jpg"
    },
    {
        "id": "Dinornis struthoides",
        "name": "Slender bush moa",
        "yearsago": 620,
        "yearextinct": 1400,
        "group": "Aves",
        "order": "Dinornithiformes",
        "family": "Dinornithidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Dinornis",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Dinornis_struthoides.jpg/170px-Dinornis_struthoides.jpg"
    },
    {
        "id": "Emeus crassus",
        "name": "Eastern moa",
        "yearsago": 620,
        "yearextinct": 1400,
        "group": "Aves",
        "order": "Dinornithiformes",
        "family": "Emeidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Moa",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/47043MA_I043764.jpg"
    },
    {
        "id": "Hieraaetus moorei",
        "name": "Haast's eagle",
        "yearsago": 620,
        "yearextinct": 1400,
        "group": "Aves",
        "order": "Accipitriformes",
        "family": "Accipitridae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Haast%27s_eagle",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Giant_Haasts_eagle_attacking_New_Zealand_moa.jpg/600px-Giant_Haasts_eagle_attacking_New_Zealand_moa.jpg"
    },
    {
        "id": "Pachyornis australis",
        "name": "Crested moa",
        "yearsago": 620,
        "yearextinct": 1400,
        "group": "Aves",
        "order": "Dinornithiformes",
        "family": "Emeidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Moa",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/47042MA_I043760.jpg"
    },
    {
        "id": "Pachyornis elephantopus",
        "name": "Heavy-footed moa",
        "yearsago": 620,
        "yearextinct": 1400,
        "group": "Aves",
        "order": "Dinornithiformes",
        "family": "Emeidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Moa",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/47041MA_I043761.jpg"
    },
    {
        "id": "Nesophontes",
        "name": "West Indies shrew",
        "yearsago": 660,
        "yearextinct": 1360,
        "group": "Mammalia",
        "order": "Eulipotyphla",
        "family": "Nesophontidae",
        "location": "Caribbean",
        "wikipedia": "https://en.wikipedia.org/wiki/Nesophontes",
        "img": "https://upload.wikimedia.org/wikipedia/commons/4/43/Puerto_Rican_shrew.jpg"
    },
    {
        "id": "Pachyornis geranoides",
        "name": "Mantell's moa",
        "yearsago": 694,
        "yearextinct": 1326,
        "group": "Aves",
        "order": "Dinornithiformes",
        "family": "Emeidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Moa",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/47040MA_I043762.jpg"
    },
    {
        "id": "Megalapteryx didinus",
        "name": "Upland Moa",
        "yearsago": 698,
        "yearextinct": 1322,
        "group": "Aves",
        "order": "Notopalaeognathae",
        "family": "Dinornithiformes",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Upland_moa",
        "img": "https://upload.wikimedia.org/wikipedia/en/thumb/d/d7/Megalapteryx.png/170px-Megalapteryx.png"
    },
    {
        "id": "Thambetochen chauliodous",
        "name": "Maui Nui large-billed moa-nalo",
        "yearsago": 840,
        "yearextinct": 1180,
        "group": "Aves",
        "order": "Anseriformes",
        "family": "Anatidae",
        "location": "Hawaii",
        "wikipedia": "https://en.wikipedia.org/wiki/Maui_Nui_large-billed_moa-nalo",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Moa_Nalo_Maui_Nui.JPG/204px-Moa_Nalo_Maui_Nui.JPG"
    },
    {
        "id": "Aegotheles novazelandiae",
        "name": "New Zealand owlet-nightjar",
        "yearsago": 1024,
        "yearextinct": 996,
        "group": "Aves",
        "order": "Caprimulgiformes",
        "family": "Aegothelidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/New_Zealand_owlet-nightjar",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/470503MA_I043885.jpg"
    },
    {
        "id": "Vini vidivici",
        "name": "Conquered lorikeet",
        "yearsago": 1070,
        "yearextinct": 950,
        "group": "Aves",
        "order": "Psittaciformes",
        "family": "Psittaculidae",
        "location": "Marquesas Islands",
        "wikipedia": "https://en.wikipedia.org/wiki/Conquered_lorikeet",
        "img": "https://upload.wikimedia.org/wikipedia/commons/2/22/Vini_vidivici.jpg"
    },
    {
        "id": "Plesiorycteropus",
        "name": "Malagasy aardvark",
        "yearsago": 1105,
        "yearextinct": 915,
        "group": "Mammalia",
        "order": "Afrosoricida (sub-order)",
        "family": "Tenrecomorpha",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Plesiorycteropus",
        "img": "https://www.itsnature.org/wp-content/uploads/2010/10/Plesiorycteropus_madagascarensis-e1287671061637.jpg"
    },
    {
        "id": "Daubentonia robusta",
        "name": "Giant aye-aye",
        "yearsago": 1135,
        "yearextinct": 885,
        "group": "Mammalia",
        "order": "Primates",
        "family": "Daubentoniidae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Giant_aye-aye",
        "img": "https://i.pinimg.com/originals/00/97/26/009726f2066f29afd6217af30f88b4f9.jpg"
    },
    {
        "id": "Euryapteryx curtus",
        "name": "Stout-legged moa",
        "yearsago": 1184,
        "yearextinct": 836,
        "group": "Aves",
        "order": "Dinornithiformes",
        "family": "Emeidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/Broad-billed_moa",
        "img": "http://nzbirdsonline.org.nz/sites/all/files/47042MA_I043760.jpg"
    },
    {
        "id": "Pachylemur insignis",
        "name": "Pachylemur",
        "yearsago": 1290,
        "yearextinct": 730,
        "group": "Mammalia",
        "order": "Primates",
        "family": "Lemuridae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Pachylemur",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Pachylemur_insignis.jpg/440px-Pachylemur_insignis.jpg"
    },
    {
        "id": "Puffinus olsoni",
        "name": "Lava shearwater",
        "yearsago": 1335,
        "yearextinct": 685,
        "group": "Mammalia",
        "order": "Procellariiformes",
        "family": "Procellariidae",
        "location": "Canary Islands",
        "wikipedia": "https://en.wikipedia.org/wiki/Lava_shearwater",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Puffinus_olsoni_restoration.jpg/440px-Puffinus_olsoni_restoration.jpg"
    },
    {
        "id": "Alopochen sirabensis",
        "name": "Malagasy shelduck",
        "yearsago": 1450,
        "yearextinct": 570,
        "group": "Aves",
        "order": "Anseriformes",
        "family": "Anatidae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Malagasy_shelduck",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Alopochen_sirabensis.jpg/440px-Alopochen_sirabensis.jpg"
    },
    {
        "id": "Hadropithecus",
        "name": "Hadropithecus",
        "yearsago": 1483,
        "yearextinct": 537,
        "group": "Mammalia",
        "order": "Primates",
        "family": "Archaeolemuridae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Hadropithecus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Hadropithecus_stenognathus.jpg/440px-Hadropithecus_stenognathus.jpg"
    },
    {
        "id": "Meiolania",
        "name": "Meiolania",
        "yearsago": 1570,
        "yearextinct": 450,
        "group": "Reptilia",
        "order": "N/A",
        "family": "Meiolaniidae",
        "location": "New Caledonia",
        "wikipedia": "https://en.wikipedia.org/wiki/Meiolania",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Meiolania_platyceps.jpg/340px-Meiolania_platyceps.jpg"
    },
    {
        "id": "Loxodonta africana pharaohensis",
        "name": "North African elephant",
        "yearsago": 1720,
        "yearextinct": 300,
        "group": "Mammalia",
        "order": "Proboscidea",
        "family": "Elephantidae",
        "location": "North Africa",
        "wikipedia": "https://en.wikipedia.org/wiki/North_African_elephant",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Roman_bronze_elephant_Staatliche_Antikensammlungen_SL_50_2.jpg/440px-Roman_bronze_elephant_Staatliche_Antikensammlungen_SL_50_2.jpg"
    },
    {
        "id": "Mesopropithecus globiceps",
        "name": "Mesopropithecus globiceps",
        "yearsago": 1764,
        "yearextinct": 256,
        "group": "Mammalia",
        "order": "Primates",
        "family": "Palaeopropithecidae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Mesopropithecus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Mesopropithecus_globiceps_skull_001.jpg/440px-Mesopropithecus_globiceps_skull_001.jpg"
    },
    {
        "id": "Apteribis",
        "name": "Apteribis",
        "yearsago": 1920,
        "yearextinct": 100,
        "group": "Aves",
        "order": "Pelecaniformes",
        "family": "Threskiornithidae",
        "location": "Hawaii",
        "wikipedia": "https://en.wikipedia.org/wiki/Apteribis",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Apteribis_sp._%285212794163%29.jpg/440px-Apteribis_sp._%285212794163%29.jpg"
    },
    {
        "id": "Elephas maximus asurus",
        "name": "Syrian elephant",
        "yearsago": 2120,
        "yearextinct": -100,
        "group": "Mammalia",
        "order": "Proboscidea",
        "family": "Elephantidae",
        "location": "Middle East",
        "wikipedia": "https://en.wikipedia.org/wiki/Syrian_elephant",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Elephant_Attacking_a_Feline.jpg/440px-Elephant_Attacking_a_Feline.jpg"
    },
    {
        "id": "Archaeolemur edwardsi",
        "name": "Archaeolemur",
        "yearsago": 2130,
        "yearextinct": -110,
        "group": "Mammalia",
        "order": "Primates",
        "family": "Archaeolemuridae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Archaeolemur",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Archaeolemur_edwardsi.jpg/440px-Archaeolemur_edwardsi.jpg"
    },
    {
        "id": "Xenothrix mcgregori",
        "name": "Jamaican monkey",
        "yearsago": 2215,
        "yearextinct": -195,
        "group": "Mammalia",
        "order": "Primates",
        "family": "Pitheciidae",
        "location": "Jamaica",
        "wikipedia": "https://en.wikipedia.org/wiki/Jamaican_monkey",
        "img": "https://vignette.wikia.nocookie.net/prehistrico/images/b/bb/HadrSte1.jpg/revision/latest?cb=20151008173311&path-prefix=es"
    },
    {
        "id": "Archaeoindris fontoynonti",
        "name": "Archaeoindris",
        "yearsago": 2361,
        "yearextinct": -341,
        "group": "Mammalia",
        "order": "Primates",
        "family": "Palaeopropithecidae",
        "location": "Madagascar",
        "wikipedia": "https://en.wikipedia.org/wiki/Archaeoindris",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Archaeoindris_fontoynonti.jpg/440px-Archaeoindris_fontoynonti.jpg"
    },
    {
        "id": "Crocidura balsamifera",
        "name": "Crocidura",
        "yearsago": 2470,
        "yearextinct": -450,
        "group": "Mammalia",
        "order": "Eulipotyphla",
        "family": "Soricidae",
        "location": "Egypt",
        "wikipedia": "https://en.wikipedia.org/wiki/Crocidura",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Brehms_-_Crocidura_russulus.jpg/440px-Brehms_-_Crocidura_russulus.jpg"
    },
    {
        "id": "Elasmodontomys obliquus",
        "name": "Plate-toothed giant hutia",
        "yearsago": 2477,
        "yearextinct": -457,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Heptaxodontidae",
        "location": "Puerto Rico",
        "wikipedia": "https://en.wikipedia.org/wiki/Plate-toothed_giant_hutia",
        "img": "https://vignette.wikia.nocookie.net/prehistrico/images/b/b6/Ndex-3.jpeg/revision/latest/scale-to-width-down/220?cb=20150714093139&path-prefix=es"
    },
    {
        "id": "Acratocnus odontrigonus",
        "name": "Acratocnus",
        "yearsago": 3400,
        "yearextinct": -1380,
        "group": "Mammalia",
        "order": "Pilosa",
        "family": "Megalonychidae",
        "location": "Puerto Rico",
        "wikipedia": "https://en.wikipedia.org/wiki/Acratocnus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/8/81/Habanocnus.JPG"
    },
    {
        "id": "Sylviornis",
        "name": "giant flightless megapode",
        "yearsago": 3540,
        "yearextinct": -1520,
        "group": "Aves",
        "order": "none",
        "family": "Sylviornithidae",
        "location": "New Caledonia",
        "wikipedia": "https://en.wikipedia.org/wiki/Sylviornis",
        "img": "http://s3-us-west-1.amazonaws.com/scifindr/articles/image2s/000/002/657/large/sylviornis2.jpg?1460254124"
    },
    {
        "id": "Mammuthus primigenius",
        "name": "Woolly mammoth",
        "yearsago": 3720,
        "yearextinct": -1700,
        "group": "Mammalia",
        "order": "Proboscidea",
        "family": "Elephantidae",
        "location": "North America, Russia",
        "wikipedia": "https://en.wikipedia.org/wiki/Woolly_mammoth",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Woolly_mammoth_model_Royal_BC_Museum_in_Victoria.jpg/440px-Woolly_mammoth_model_Royal_BC_Museum_in_Victoria.jpg"
    },
    {
        "id": "Chendytes lawi",
        "name": "Law's diving-goose",
        "yearsago": 3820,
        "yearextinct": -1800,
        "group": "Aves",
        "order": "Anseriformes",
        "family": "Anatidae",
        "location": "California",
        "wikipedia": "https://en.wikipedia.org/wiki/Chendytes",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Chendytes_lawi.jpg/220px-Chendytes_lawi.jpg"
    },
    {
        "id": "Megalocnus rodens",
        "name": "Megalocnus",
        "yearsago": 4260,
        "yearextinct": -2240,
        "group": "Mammalia",
        "order": "Pilosa",
        "family": "Megalonychidae",
        "location": "Cuba",
        "wikipedia": "https://en.wikipedia.org/wiki/Megalocnus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Megalocnus.jpg/220px-Megalocnus.jpg"
    },
    {
        "id": "Neocnus comes",
        "name": "Neocnus",
        "yearsago": 4261,
        "yearextinct": -2241,
        "group": "Mammalia",
        "order": "Pilosa",
        "family": "Megalonychidae",
        "location": "Haiti",
        "wikipedia": "https://en.wikipedia.org/wiki/Neocnus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Synocnus_comes.jpg/220px-Synocnus_comes.jpg"
    },
    {
        "id": "Ardea bennuides",
        "name": "Bennu heron",
        "yearsago": 4570,
        "yearextinct": -2550,
        "group": "Aves",
        "order": "Pelecaniformes",
        "family": "Ardeidae",
        "location": "Arabian Peninsula",
        "wikipedia": "https://en.wikipedia.org/wiki/Bennu_heron",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Representation_of_the_Bennu_%281878%29_-_TIMEA.jpg/220px-Representation_of_the_Bennu_%281878%29_-_TIMEA.jpg"
    },
    {
        "id": "Pelorovis antiquus",
        "name": "North African buffalo",
        "yearsago": 4785,
        "yearextinct": -2765,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "North Africa",
        "wikipedia": "https://en.wikipedia.org/wiki/Pelorovis",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Pelorovis.JPG/220px-Pelorovis.JPG"
    },
    {
        "id": "Myotragus balearicus",
        "name": "Balearic cave goat",
        "yearsago": 4855,
        "yearextinct": -2835,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "Balearic Islands",
        "wikipedia": "https://en.wikipedia.org/wiki/Myotragus#Extinction",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Myotragus_balearicus.JPG/220px-Myotragus_balearicus.JPG"
    },
    {
        "id": "Dusicyon avus",
        "name": "Falkland Islands wolf",
        "yearsago": 4935,
        "yearextinct": -2915,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "Uruguay, Argentina, Chile",
        "wikipedia": "https://en.wikipedia.org/wiki/Dusicyon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/FalklandIslandFox2.jpg/220px-FalklandIslandFox2.jpg"
    },
    {
        "id": "Doedicurus clavicaudatus",
        "name": "Doedicurus",
        "yearsago": 6625,
        "yearextinct": -4605,
        "group": "Mammalia",
        "order": "Cingulata",
        "family": "Chlamyphoridae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Doedicurus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Doedicurus.png/220px-Doedicurus.png"
    },
    {
        "id": "Megaloceros giganteus",
        "name": "Irish elk",
        "yearsago": 6886,
        "yearextinct": -4866,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Cervidae",
        "location": "Urals and Western Siberia",
        "wikipedia": "https://en.wikipedia.org/wiki/Irish_elk",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Giant_deer.JPG/220px-Giant_deer.JPG"
    },
    {
        "id": "Petauroides ayamaruensis",
        "name": "Petauroides ayamaruensis",
        "yearsago": 6970,
        "yearextinct": -4950,
        "group": "Mammalia",
        "order": "Diprotodontia",
        "family": "Pseudocheiridae",
        "location": "New Guinea",
        "wikipedia": "https://en.wikipedia.org/wiki/Pseudocheiridae",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/07/Pseudocheirus_peregrinus-Cayley.jpg/220px-Pseudocheirus_peregrinus-Cayley.jpg"
    },
    {
        "id": "Praemegaceros cazioti",
        "name": "Sardinian giant deer",
        "yearsago": 7040,
        "yearextinct": -5020,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Cervidae",
        "location": "Sardinia",
        "wikipedia": "https://en.wikipedia.org/wiki/Praemegaceros",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Megaloceros_Species.jpg/220px-Megaloceros_Species.jpg"
    },
    {
        "id": "Megatherium americanum",
        "name": "Giant sloth",
        "yearsago": 7390,
        "yearextinct": -5370,
        "group": "Mammalia",
        "order": "Pilosa",
        "family": "Megatheriidae",
        "location": "Argentina",
        "wikipedia": "https://en.wikipedia.org/wiki/Megatherium",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Megatherum_DB.jpg/170px-Megatherum_DB.jpg"
    },
    {
        "id": "Megalotragus priscus",
        "name": "Megalotragus",
        "yearsago": 8070,
        "yearextinct": -6050,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "South Africa",
        "wikipedia": "https://en.wikipedia.org/wiki/Megalotragus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Megalotragus_skull.jpg/220px-Megalotragus_skull.jpg"
    },
    {
        "id": "Camelops",
        "name": "Camelops",
        "yearsago": 8310,
        "yearextinct": -6290,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Camelidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Camelops#Extinction",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Camelops_NT_small.jpg/220px-Camelops_NT_small.jpg"
    },
    {
        "id": "Hemiauchenia",
        "name": "Hemiauchenia",
        "yearsago": 8597,
        "yearextinct": -6577,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Camelidae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Hemiauchenia",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Aepycamelus_Alticamelus_hharder.jpg/320px-Aepycamelus_Alticamelus_hharder.jpg"
    },
    {
        "id": "Mylodon",
        "name": "Mylodon",
        "yearsago": 8709,
        "yearextinct": -6689,
        "group": "Mammalia",
        "order": "Pilosa",
        "family": "Mylodontidae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Mylodon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Mylodon_model.jpg/440px-Mylodon_model.jpg"
    },
    {
        "id": "Mammuthus columbi",
        "name": "Columbian mammoth",
        "yearsago": 8750,
        "yearextinct": -6730,
        "group": "Mammalia",
        "order": "Proboscidea",
        "family": "Elephantidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Columbian_mammoth",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d8/Archidiskodon_imperator121.jpg/220px-Archidiskodon_imperator121.jpg"
    },
    {
        "id": "Bison priscus",
        "name": "Steppe bison",
        "yearsago": 8930,
        "yearextinct": -6910,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "Europe, Central and Northern to Northeast Asia, North America, Beringia",
        "wikipedia": "https://en.wikipedia.org/wiki/Steppe_bison",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Prazubr_rysunek_600.jpg/220px-Prazubr_rysunek_600.jpg"
    },
    {
        "id": "Scelidodon chiliensis",
        "name": "Scelidodon",
        "yearsago": 8980,
        "yearextinct": -6960,
        "group": "Mammalia",
        "order": "Pilosa",
        "family": "Mylodontidae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Scelidodon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Scelidodon.JPG/220px-Scelidodon.JPG"
    },
    {
        "id": "Smilodon populator",
        "name": "Saber Toothed Tiger",
        "yearsago": 9200,
        "yearextinct": -7180,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Felidae",
        "location": "Brazil",
        "wikipedia": "https://en.wikipedia.org/wiki/Smilodon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Smilodon_fatalis.jpg/220px-Smilodon_fatalis.jpg"
    },
    {
        "id": "Hippopotamus minor",
        "name": "Cyprus dwarf hippopotamus",
        "yearsago": 9310,
        "yearextinct": -7290,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Hippopotamidae",
        "location": "Cyprus",
        "wikipedia": "https://en.wikipedia.org/wiki/Cyprus_dwarf_hippopotamus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Hippo-Cyprus.JPG/220px-Hippo-Cyprus.JPG"
    },
    {
        "id": "Canis dirus",
        "name": "Dire wolf",
        "yearsago": 9470,
        "yearextinct": -7450,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "North America, South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Dire_wolf",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Canis_dirus_Sternberg_Museum.jpg/240px-Canis_dirus_Sternberg_Museum.jpg"
    },
    {
        "id": "Mylohyus",
        "name": "Mylohyus",
        "yearsago": 9480,
        "yearextinct": -7460,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Tayassuidae",
        "location": "North America, Central America",
        "wikipedia": "https://en.wikipedia.org/wiki/Mylohyus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Mylohyus_nasutus.jpg/220px-Mylohyus_nasutus.jpg"
    },
    {
        "id": "Megalonyx jeffersonii",
        "name": "Megalonyx jeffersonii",
        "yearsago": 9510,
        "yearextinct": -7490,
        "group": "Mammalia",
        "order": "Pilosa",
        "family": "Megalonychidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Megalonyx",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Megalonyx_wheatleyi12.jpg/440px-Megalonyx_wheatleyi12.jpg"
    },
    {
        "id": "Nothrotheriops",
        "name": "Nothrotheriops",
        "yearsago": 9910,
        "yearextinct": -7890,
        "group": "Mammalia",
        "order": "Xenarthra",
        "family": "Nothrotheriidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Nothrotheriops",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Nothrotheriops.jpg/200px-Nothrotheriops.jpg"
    },
    {
        "id": "Glossotherium",
        "name": "Glossotherium",
        "yearsago": 9950,
        "yearextinct": -7930,
        "group": "Mammalia",
        "order": "Xenarthra",
        "family": "Mylodontidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Glossotherium",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Extm_Glossotherium_robustum_rbh-hlmwh01913-12.jpg/220px-Extm_Glossotherium_robustum_rbh-hlmwh01913-12.jpg"
    },
    {
        "id": "Homesina floridanus",
        "name": "Homesina floridanus",
        "yearsago": 9950,
        "yearextinct": -7930,
        "group": "Mammalia",
        "order": "Cingulata",
        "family": "Pampatheriidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Holmesina",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Holmesina_floridanus_life_reconstruction.jpg/220px-Holmesina_floridanus_life_reconstruction.jpg"
    },
    {
        "id": "Palaeolama mirifica",
        "name": "Palaeolama",
        "yearsago": 9950,
        "yearextinct": -7930,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Camelidae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Palaeolama",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Pleistocene_mammals_of_Chile.jpg/220px-Pleistocene_mammals_of_Chile.jpg"
    },
    {
        "id": "Tapirus veroensis",
        "name": "vero tapir",
        "yearsago": 9950,
        "yearextinct": -7930,
        "group": "Mammalia",
        "order": "Perissodactyla",
        "family": "Tapiridae",
        "location": "",
        "wikipedia": "https://en.wikipedia.org/wiki/Tapir",
        "img": "https://markgelbart.files.wordpress.com/2011/12/mountain-tapir-photos.jpg?w=300"
    },
    {
        "id": "Ciconia maltha",
        "name": "La Brea stork",
        "yearsago": 10020,
        "yearextinct": -8000,
        "group": "Aves",
        "order": "Ciconiiformes",
        "family": "Ciconiidae",
        "location": "United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Ciconia_maltha",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Ciconia_maltha.jpg/220px-Ciconia_maltha.jpg"
    },
    {
        "id": "Dasypus bellus",
        "name": "beautiful armadillo",
        "yearsago": 10020,
        "yearextinct": -8000,
        "group": "Mammalia",
        "order": "Cingulata",
        "family": "Dasypodidae",
        "location": "North America and South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Dasypus_bellus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/D._bellus_jaw.png/220px-D._bellus_jaw.png"
    },
    {
        "id": "Holmesina septentrionalis",
        "name": "Holmesina septentrionalis",
        "yearsago": 10020,
        "yearextinct": -8000,
        "group": "Mammalia",
        "order": "Cingulata",
        "family": "Pampatheriidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Holmesina",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Gyptodon_Cosmo_Caixa.JPG/250px-Gyptodon_Cosmo_Caixa.JPG"
    },
    {
        "id": "Meleagris californica",
        "name": "Californian turkey",
        "yearsago": 10020,
        "yearextinct": -8000,
        "group": "Aves",
        "order": "Galliformes",
        "family": "Phasianidae",
        "location": "California",
        "wikipedia": "https://en.wikipedia.org/wiki/Californian_turkey",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Meleagris_NT.jpg/220px-Meleagris_NT.jpg"
    },
    {
        "id": "Teratornis merriami",
        "name": "Teratornis merriami",
        "yearsago": 10020,
        "yearextinct": -8000,
        "group": "Aves",
        "order": "Accipitriformes",
        "family": "Teratornithidae",
        "location": "North America and South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Teratornithidae",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Teratornis_BW.jpg/220px-Teratornis_BW.jpg"
    },
    {
        "id": "Amerhippus",
        "name": "Amerhippus",
        "yearsago": 10260,
        "yearextinct": -8240,
        "group": "Mammalia",
        "order": "Perissodactyla",
        "family": "Equidae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Amerhippus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/South_American_Pleistocene_equids.jpg/434px-South_American_Pleistocene_equids.jpg"
    },
    {
        "id": "Glyptodon",
        "name": "Glyptodon",
        "yearsago": 10260,
        "yearextinct": -8240,
        "group": "Mammalia",
        "order": "Cingulata",
        "family": "Chlamyphoridae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Glyptodon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Glyptodon_%28Riha2000%29.jpg/220px-Glyptodon_%28Riha2000%29.jpg"
    },
    {
        "id": "Toxodon",
        "name": "Toxodon",
        "yearsago": 10260,
        "yearextinct": -8240,
        "group": "Mammalia",
        "order": "Notoungulata",
        "family": "Toxodontidae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Toxodon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Toxodon_NT_small.jpg/220px-Toxodon_NT_small.jpg"
    },
    {
        "id": "Castoroides",
        "name": "Giant beaver",
        "yearsago": 10300,
        "yearextinct": -8280,
        "group": "Mammalia",
        "order": "Rodentia",
        "family": "Castoridae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Castoroides",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Castoroides_Knight.jpg/220px-Castoroides_Knight.jpg"
    },
    {
        "id": "Cervalces scotti",
        "name": "Elk moose",
        "yearsago": 10300,
        "yearextinct": -8280,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Cervidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Cervalces_scotti",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Life_restoration_cervalces_scotti.jpg/220px-Life_restoration_cervalces_scotti.jpg"
    },
    {
        "id": "Panthera leo atrox",
        "name": "American lion",
        "yearsago": 10440,
        "yearextinct": -8420,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Felidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/American_lion",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/PantheraLeoAtrox1.jpg/220px-PantheraLeoAtrox1.jpg"
    },
    {
        "id": "Mammut Americanum",
        "name": "American Mastodon",
        "yearsago": 10520,
        "yearextinct": -8500,
        "group": "Mammalia",
        "order": "Proboscidea",
        "family": "Mammut",
        "location": "North America, North Central Asia",
        "wikipedia": "https://en.wikipedia.org/wiki/Mastodon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Knight_Mastodon.jpg/220px-Knight_Mastodon.jpg"
    },
    {
        "id": "Hippidion",
        "name": "Hippidion",
        "yearsago": 10755,
        "yearextinct": -8735,
        "group": "Mammalia",
        "order": "Perissodactyla",
        "family": "Equidae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Hippidion",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/South_American_Pleistocene_equids.jpg/220px-South_American_Pleistocene_equids.jpg"
    },
    {
        "id": "Oreamnos harringtoni",
        "name": "Harrington's mountain goat",
        "yearsago": 10940,
        "yearextinct": -8920,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Harrington%27s_mountain_goat",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Oreamnos_harringtoni.jpg/220px-Oreamnos_harringtoni.jpg"
    },
    {
        "id": "Amplibuteo woodwardi",
        "name": "Woodward's eagle",
        "yearsago": 11020,
        "yearextinct": -9000,
        "group": "Aves",
        "order": "Accipitriformes",
        "family": "Accipitridae",
        "location": "North America and Caribbean",
        "wikipedia": "https://en.wikipedia.org/wiki/Woodward%27s_eagle",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Amplibuteo_woodwardi.jpg/220px-Amplibuteo_woodwardi.jpg"
    },
    {
        "id": "Bootherium bombifrons",
        "name": "Harlan's muskox",
        "yearsago": 11050,
        "yearextinct": -9030,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Bootherium_bombifrons",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Bootherium_bombifrons.JPG/220px-Bootherium_bombifrons.JPG"
    },
    {
        "id": "Mammuthus exilis",
        "name": "Pygmy mammoth",
        "yearsago": 11100,
        "yearextinct": -9080,
        "group": "Mammalia",
        "order": "Proboscidea",
        "family": "Elephantidae",
        "location": "Northern Channel Islands",
        "wikipedia": "https://en.wikipedia.org/wiki/Pygmy_mammoth",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Mammuthus_exilis.jpg/220px-Mammuthus_exilis.jpg"
    },
    {
        "id": "Scelidotherium",
        "name": "Scelidotherium",
        "yearsago": 11110,
        "yearextinct": -9090,
        "group": "Mammalia",
        "order": "Xenarthra",
        "family": "Mylodontidae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Scelidotherium",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Scelidotherium_leptocephalum_side.jpg/220px-Scelidotherium_leptocephalum_side.jpg"
    },
    {
        "id": "Stegomastodon",
        "name": "Stegomastodon",
        "yearsago": 11110,
        "yearextinct": -9090,
        "group": "Mammalia",
        "order": "Proboscidea",
        "family": "Gomphotheriidae",
        "location": "South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Stegomastodon",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Stegomastodon_mirificus_-_Smithsonian.JPG/220px-Stegomastodon_mirificus_-_Smithsonian.JPG"
    },
    {
        "id": "Platygonus",
        "name": "Platygonus",
        "yearsago": 11130,
        "yearextinct": -9110,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Tayassuidae",
        "location": "North America, South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Platygonus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Platygonus_leptorhinus.jpg/220px-Platygonus_leptorhinus.jpg"
    },
    {
        "id": "Bison antiquus",
        "name": "Ancient bison",
        "yearsago": 11200,
        "yearextinct": -9180,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Bison_antiquus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Bison_antiquus.jpg/220px-Bison_antiquus.jpg"
    },
    {
        "id": "Capromeryx",
        "name": "Capromeryx",
        "yearsago": 11240,
        "yearextinct": -9220,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Antilocapridae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Capromeryx",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Capromeryx_minor_p1350725.jpg/220px-Capromeryx_minor_p1350725.jpg"
    },
    {
        "id": "Equus conversidens",
        "name": "Mexican horse",
        "yearsago": 11400,
        "yearextinct": -9380,
        "group": "Mammalia",
        "order": "Perissodactyla",
        "family": "Equidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Equus_conversidens",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Equus_conversidens.jpg/220px-Equus_conversidens.jpg"
    },
    {
        "id": "Stockoceros",
        "name": "Stockoceros",
        "yearsago": 11400,
        "yearextinct": -9380,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Antilocapridae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Stockoceros",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Fossil_ruminant.jpg/220px-Fossil_ruminant.jpg"
    },
    {
        "id": "Eremotherium",
        "name": "Eremotherium",
        "yearsago": 11410,
        "yearextinct": -9390,
        "group": "Mammalia",
        "order": "Pilosa",
        "family": "Megatheriidae",
        "location": "North America, South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Eremotherium",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/WLA_hmns_Giant_ground_sloth_2.jpg/250px-WLA_hmns_Giant_ground_sloth_2.jpg"
    },
    {
        "id": "Cynotherium sardous",
        "name": "Sardinian dhole",
        "yearsago": 11420,
        "yearextinct": -9400,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Canidae",
        "location": "Sardinia",
        "wikipedia": "https://en.wikipedia.org/wiki/Sardinian_dhole",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Cynotherium_sardous.png/220px-Cynotherium_sardous.png"
    },
    {
        "id": "Arctodus simus",
        "name": "Short-faced bear",
        "yearsago": 11550,
        "yearextinct": -9530,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Ursidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Short-faced_bear",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/ArctodusSimusReconstruct.jpg/220px-ArctodusSimusReconstruct.jpg"
    },
    {
        "id": "Arctotherium",
        "name": "Arctotherium",
        "yearsago": 11670,
        "yearextinct": -9650,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Ursidae",
        "location": "Central America, South America",
        "wikipedia": "https://en.wikipedia.org/wiki/Arctotherium",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Arctotherium.jpg/220px-Arctotherium.jpg"
    },
    {
        "id": "Euceratherium",
        "name": "Shrub-ox",
        "yearsago": 11700,
        "yearextinct": -9680,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Bovidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Euceratherium",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Euceratherium_BW.jpg/220px-Euceratherium_BW.jpg"
    },
    {
        "id": "Cuvieronius",
        "name": "Cuvieronius",
        "yearsago": 11970,
        "yearextinct": -9950,
        "group": "Mammalia",
        "order": "Proboscidea",
        "family": "Gomphotheriidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Cuvieronius",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Cuvieronius_hyodon2.jpg/220px-Cuvieronius_hyodon2.jpg"
    },
    {
        "id": "Aztlanolagus agilis",
        "name": "Aztlanolagus",
        "yearsago": 12020,
        "yearextinct": -10000,
        "group": "Mammalia",
        "order": "Lagomorpha",
        "family": "Leporidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Aztlanolagus",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/Aztlanolagus.gif/220px-Aztlanolagus.gif"
    },
    {
        "id": "Equus occidentalis",
        "name": "Western horse",
        "yearsago": 12020,
        "yearextinct": -10000,
        "group": "Mammalia",
        "order": "Perissodactyla",
        "family": "Equidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Equus_occidentalis",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Equus_occidentalis_skeleton.jpg/220px-Equus_occidentalis_skeleton.jpg"
    },
    {
        "id": "Homotherium",
        "name": "scimitar-toothed cat",
        "yearsago": 12020,
        "yearextinct": -10000,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Felidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Homotherium",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Homotheriumtex1.JPG/220px-Homotheriumtex1.JPG"
    },
    {
        "id": "Odocoileus lucasi",
        "name": "American mountain deer",
        "yearsago": 12020,
        "yearextinct": -10000,
        "group": "Mammalia",
        "order": "Artiodactyla",
        "family": "Cervidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/American_mountain_deer",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Navahoceros_skeleton.jpg/170px-Navahoceros_skeleton.jpg"
    },
    {
        "id": "Panthera onca augusta",
        "name": "Pleistocene jaguar",
        "yearsago": 12020,
        "yearextinct": -10000,
        "group": "Mammalia",
        "order": "Carnivora",
        "family": "Felidae",
        "location": "North America",
        "wikipedia": "https://en.wikipedia.org/wiki/Panthera_onca_augusta",
        "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Panthera_onca_augusta.JPG/220px-Panthera_onca_augusta.JPG"
    },
    {
        "id": "lacerum",
        "name": "harelip sucker",
        "yearsago": 127,
        "yearextinct": 1893,
        "group": "Actinopterygii",
        "order": "Cypriniformes",
        "family": "Catostomidae",
        "location": "United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Harelip_sucker",
        "img": "http://www.outdooralabama.com/sites/default/files/fishing/Freshwater%20Fishing/non_game_fish/sucker/SuckerHarelip.gif"
    },
    {
        "id": "crassicauda",
        "name": "thicktail chub",
        "yearsago": 63,
        "yearextinct": 1957,
        "group": "Actinopterygii",
        "order": "Cypriniformes",
        "family": "Cyprinidae",
        "location": "United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Thicktail_chub",
        "img": "https://upload.wikimedia.org/wikipedia/commons/6/67/Thicktail_Chub.jpg"
    },
    {
        "id": "ciscoides",
        "name": "Clear Lake Splittail",
        "yearsago": 50,
        "yearextinct": 1970,
        "group": "Actinopterygii",
        "order": "Cypriniformes",
        "family": "Cyprinidae",
        "location": "United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Clear_Lake_splittail",
        "img": "https://bioaccumulation.files.wordpress.com/2012/02/splittail.jpeg"
    },
    {
        "id": "oxyrhynchus",
        "name": "New Zealand grayling",
        "yearsago": 97,
        "yearextinct": 1923,
        "group": "Actinopterygii",
        "order": "Osmeriformes",
        "family": "Retropinnidae",
        "location": "New Zealand",
        "wikipedia": "https://en.wikipedia.org/wiki/New_Zealand_grayling",
        "img": "https://upload.wikimedia.org/wikipedia/commons/7/72/Prototroctes_oxyrhynchus.jpg"
    },
    {
        "id": "johannae",
        "name": "deepwater ciscoe",
        "yearsago": 68,
        "yearextinct": 1952,
        "group": "Actinopterygii",
        "order": "Salmoniformes",
        "family": "Salmonidae",
        "location": "Canada, United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Deepwater_cisco",
        "img": "http://1.bp.blogspot.com/-ioSbK_RlmZk/Tm76_O_QLYI/AAAAAAAAAGk/ryC_1BWjm8Q/s320/Deepwater%2Bcisco.jpg"
    },
    {
        "id": "agassizi",
        "name": "silver trout",
        "yearsago": 90,
        "yearextinct": 1930,
        "group": "Actinopterygii",
        "order": "Salmoniformes",
        "family": "Salmonidae",
        "location": "United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Silver_trout",
        "img": "https://upload.wikimedia.org/wikipedia/commons/c/c0/Salvelinus_agassizi.jpg"
    },
    {
        "id": "cuvieri",
        "name": "Lake Titicaca orestias",
        "yearsago": 83,
        "yearextinct": 1937,
        "group": "Actinopterygii",
        "order": "Cyprinodontiformes",
        "family": "Cyprinodontidae",
        "location": "Bolivia, Peru",
        "wikipedia": "https://en.wikipedia.org/wiki/Titicaca_orestias",
        "img": "https://en.wikipedia.org/wiki/Titicaca_orestias#/media/File:LakeTiticacaOrestia-1835.gif"
    },
    {
        "id": "albolineatus",
        "name": "whiteline topminnow",
        "yearsago": 131,
        "yearextinct": 1889,
        "group": "Actinopterygii",
        "order": "Cyprinodontiformes",
        "family": "Fundulidae",
        "location": "United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Whiteline_topminnow",
        "img": "http://www.outdoorsalabama.com/sites/default/files/fishing/Freshwater%20Fishing/non_game_fish/topminnow/TopminnowWhiteline.jpg"
    },
    {
        "id": "garmani",
        "name": "Parras characodon",
        "yearsago": 140,
        "yearextinct": 1880,
        "group": "Actinopterygii",
        "order": "Cyprinodontiformes",
        "family": "Goodeidae",
        "location": "Mexico",
        "wikipedia": "https://en.wikipedia.org/wiki/Parras_characodon",
        "img": "https://s2r.iucnredlist.org/sis2_images/bRkS1dJF-D61QpL-pgc.png"
    },
    {
        "id": "amistadensis",
        "name": "Amistad gambusia",
        "yearsago": 52,
        "yearextinct": 1968,
        "group": "Actinopterygii",
        "order": "Cyprinodontiformes",
        "family": "Poeciliidae",
        "location": "United States",
        "wikipedia": "https://en.wikipedia.org/wiki/Amistad_gambusia",
        "img": "https://www.fishbase.se/images/thumbnails/jpg/tn_Gaami_m0.jpg"
    }
]

function ExtinctionSwarmViz({ data }) {
    const [currentNode, setCurrentNode] = useState(null);

    return <div style={{ display: 'block', height: '450px', maxWidth: '640px' }}>
        <ResponsiveSwarmPlot
            data={ExtinctionData}
            groups={['Actinopterygii', 'Amphibia', 'Aves', 'Mammalia', 'Reptilia']}
            value="yearsago"
            valueScale={{ type: 'linear', min: 0, max: 12000, reverse: true }}
            size={10}
            layout="horizontal"
            axisTop={null}
            useMesh={false}
            axisRight={null}
            forceStrength={3}
            simulationIterations={120}
            motionStiffness={50}
            motionDamping={10}
            colors={{ scheme: "nivo" }}
            margin={{ top: 24, right: 60, bottom: 40, left: 80 }}
            onClick={(circle, event) => {
                if (currentNode) currentNode.setAttribute("class", "");
                event.target.setAttribute("class", "active")
                setCurrentNode(event.target);
            }}
            onMouseEnter={(circle, event) => {
                if (currentNode) currentNode.setAttribute("class", "");
                event.target.setAttribute("class", "active")
                setCurrentNode(event.target);
            }}
            annotations={[
                {
                    type: 'circle',
                    match: { index: 154},
                    noteX: 0,
                    noteY: -120,
                    offset: 5,
                    note: 'Giant Sloth',
                },
                {
                    type: 'circle',
                    match: { index: 183},
                    noteX: 0,
                    noteY: -190,
                    offset: 5,
                    note: 'American Mastodon',
                },
                {
                    type: 'circle',
                    match: { index: 162},
                    noteX: 0,
                    noteY: 80,
                    offset: 5,
                    note: 'Saber Toothed Tiger',
                },
            ]}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    }
                },
                grid: {
                    line: {
                        stroke: "#202D35"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                annotations: {
                    text: {
                      fontSize: 13,
                      outlineWidth: 2,
                      fill: 'rgba(255,255,255,0.87)',
                      outlineColor: 'rgba(0,0,0,0)'
                    },
                    link: {
                      stroke: '#DC86F6',
                      strokeWidth: 2,
                      outlineWidth: 2,
                      outlineColor: ''
                    },
                    outline: {
                      fill: 'none',
                      stroke: '#DC86F6',
                      strokeWidth: 2,
                      outlineWidth: 2,
                      outlineColor: ''
                    },
                    symbol: {
                      fill: '#DC86F6',
                      outlineWidth: 2,
                      outlineColor: ''
                    }
                  }
            }}
            axisBottom={{
                orient: 'bottom',
                tickSize: 10,
                tickPadding: 5,
                tickValues: [0, 2000, 4000, 6000, 8000, 10000, 12000],
                format: '.2s',
                tickRotation: 0,
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -76
            }}
            tooltip={({ node }) => (
                <div
                    style={{
                        color: 'white',
                        background: '#242424',
                        padding: '12px',
                        maxWidth: '160px'
                    }}>
                    <div
                        className={node.data.group}
                        style={{
                            height: '75px',
                            maxWidth: '150px',
                            maxHeight: '100px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '40px 40px',
                            backgroundPosition: 'center',
                            margin: 'auto',
                            marginBottom: '12px',
                            marginTop: '4px'
                        }}
                    >
                        <img
                            src={node.data.img}
                            alt={node.data.name}
                            style={{
                                height: '75px',
                                maxWidth: '150px',
                                objectFit: 'cover'
                            }}
                            onError={
                                i => i.target.style.display = 'none'
                            }
                        />
                    </div>
                    <strong
                        style={{
                            textTransform: 'capitalize',
                            display: 'block',
                            marginBottom: '4px'
                        }}
                    >
                        {node.data.name}
                    </strong>
                    <div
                        style={{
                            paddingTop: '4px',
                            fontSize: '12px'
                        }}
                    >
                        <span
                            style={{
                                background: (node.color),
                                display: 'inline-block',
                                borderRadius: '50%',
                                marginRight: '4px',
                                width: '8px',
                                height: '8px'
                            }}>
                        </span>
                        <span>
                            {node.group}
                        </span>
                        <br />
                        <span>
                            Extinct {node.data.yearsago} years ago
                        </span>
                    </div>
                </div>
            )}
        />
    </div>
}

export default ExtinctionSwarmViz;