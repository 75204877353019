import SEO from "../../../../src/components/seo";
import Compact from "../../../../src/images/compact.png";
import MarsAtmosphere from "../../../../src/images/mars-atmosphere.jpeg";
import MarsDome from "../../../../src/images/hi-seas.jpeg";
import MarsSurface from "../../../../src/images/mars-surface.png";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import MarsOpenGraphImage from "../../../../src/images/mars-banner.jpg";
import * as React from 'react';
export default {
  SEO,
  Compact,
  MarsAtmosphere,
  MarsDome,
  MarsSurface,
  graphql,
  Img,
  MarsOpenGraphImage,
  React
};