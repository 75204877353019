import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const co2Data40Million = [
    {
      "id": "A 40-million-year history of atmospheric CO2",
      "data": [
        {
          "x": 0.93,
          "y": 291
        },
        {
          "x": 1.05,
          "y": 301
        },
        {
          "x": 1.19,
          "y": 322
        },
        {
          "x": 1.21,
          "y": 311
        },
        {
          "x": 1.22,
          "y": 317
        },
        {
          "x": 1.23,
          "y": 322
        },
        {
          "x": 1.25,
          "y": 291
        },
        {
          "x": 1.27,
          "y": 309
        },
        {
          "x": 1.28,
          "y": 298
        },
        {
          "x": 1.35,
          "y": 326
        },
        {
          "x": 1.64,
          "y": 298
        },
        {
          "x": 1.73,
          "y": 312
        },
        {
          "x": 1.97,
          "y": 309
        },
        {
          "x": 2.09,
          "y": 329
        },
        {
          "x": 2.19,
          "y": 331
        },
        {
          "x": 2.3,
          "y": 337
        },
        {
          "x": 2.4,
          "y": 344
        },
        {
          "x": 2.59,
          "y": 333
        },
        {
          "x": 2.6,
          "y": 305
        },
        {
          "x": 2.72,
          "y": 317
        },
        {
          "x": 2.94,
          "y": 307
        },
        {
          "x": 3.08,
          "y": 338
        },
        {
          "x": 3.13,
          "y": 318
        },
        {
          "x": 3.32,
          "y": 336
        },
        {
          "x": 3.36,
          "y": 321
        },
        {
          "x": 3.46,
          "y": 339
        },
        {
          "x": 3.51,
          "y": 393
        },
        {
          "x": 3.52,
          "y": 346
        },
        {
          "x": 3.67,
          "y": 346
        },
        {
          "x": 3.85,
          "y": 327
        },
        {
          "x": 3.99,
          "y": 334
        },
        {
          "x": 4.11,
          "y": 348
        },
        {
          "x": 4.45,
          "y": 379
        },
        {
          "x": 4.78,
          "y": 361
        },
        {
          "x": 7.15,
          "y": 379
        },
        {
          "x": 14.1,
          "y": 341
        },
        {
          "x": 14.74,
          "y": 430
        },
        {
          "x": 15.84,
          "y": 402
        },
        {
          "x": 16.82,
          "y": 401
        },
        {
          "x": 18.26,
          "y": 412
        },
        {
          "x": 20.3,
          "y": 358
        },
        {
          "x": 22.94,
          "y": 462
        },
        {
          "x": 23.48,
          "y": 410
        },
        {
          "x": 25.3,
          "y": 416
        },
        {
          "x": 26.57,
          "y": 656
        },
        {
          "x": 27.85,
          "y": 712
        },
        {
          "x": 28.78,
          "y": 730
        },
        {
          "x": 29.21,
          "y": 854
        },
        {
          "x": 29.57,
          "y": 972
        },
        {
          "x": 29.64,
          "y": 895
        },
        {
          "x": 30.62,
          "y": 765
        },
        {
          "x": 31.27,
          "y": 725
        },
        {
          "x": 32.04,
          "y": 743
        },
        {
          "x": 32.58,
          "y": 756
        },
        {
          "x": 32.83,
          "y": 740
        },
        {
          "x": 32.91,
          "y": 723
        },
        {
          "x": 32.97,
          "y": 727
        },
        {
          "x": 33.45,
          "y": 767
        },
        {
          "x": 33.57,
          "y": 671
        },
        {
          "x": 33.69,
          "y": 930
        },
        {
          "x": 34.69,
          "y": 1038
        },
        {
          "x": 35.29,
          "y": 879
        },
        {
          "x": 35.56,
          "y": 919
        },
        {
          "x": 35.86,
          "y": 947
        },
        {
          "x": 36.09,
          "y": 1132
        },
        {
          "x": 36.81,
          "y": 1247
        },
        {
          "x": 37.1,
          "y": 939
        },
        {
          "x": 37.43,
          "y": 1042
        },
        {
            "x": 38.25,
            "y": 898
          },
        {
          "x": 38.55,
          "y": 798
        },
        {
          "x": 38.62,
          "y": 863
        }
      ]
    }
  ]

function Co240m900kya({ data }) {
    return <div style={{ display: 'block', height: '300px', maxWidth: '640px' }}>
        <ResponsiveLine
            data={co2Data40Million}
            margin={{ top: 50, right: 10, bottom: 30, left: 40 }}
            xScale={{ type: 'linear', reverse: true }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickValues:[5,10,15,20,25,30,35],
                tickRotation: 0
            }}
            axisLeft={{
                orient: 'left',
                tickValues:[0,200,400,600,800,1000,1200],
                tickRotation: 0
            }}
            markers={[
                {
                    axis: 'y',
                    value: 407.38,
                    lineStyle: { stroke: '#107F10', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px'
                    },
                },
            ]}
            colors={['#63A2EC']}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="PPM"
            pointLabelYOffset={-12}
            useMesh={true}
            animate={false}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 1
                        }
                      },
                },
                grid: {
                    line: {
                        stroke: "#89969F"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
        />
    </div>
}

export default Co240m900kya;