import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import DefaultImage from '../images/sfpe-default-social.png'

const SEO = ({title, description, author, image, pageCanonical}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          image
        }
      }
    }
  `);

  return (
    <Helmet 
      htmlAttributes={{lang: `en`}}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
    >
      <title>{title}</title>
      <link rel="canonical" href={pageCanonical} />
      <meta 
        name='description' 
        content={description || data.site.siteMetadata.description}
      />
      <meta 
        name='author'
        content={author || data.site.siteMetadata.author}
      />
      <meta 
        property='og:url' 
        content={pageCanonical}
      />
      <meta 
        property='og:title' 
        content={title} 
      />
      <meta
        property='og:description'
        content={description || data.site.siteMetadata.description}
      />      
      <meta 
        property='og:type' 
        content='website' 
      />
      <meta 
        property='og:site_name' 
        content={data.site.siteMetadata.title} 
      />
      <meta
        property='og:image'
        content={image || 'https://storybook.earth' + DefaultImage}
      />
      <meta 
        name='twitter:card' 
        content='summary_large_image' 
      />
      <meta 
        name='twitter:text:title' 
        content={title}
      />
      <meta 
        name='twitter:description' 
        content={description || data.site.siteMetadata.description}
      />
      <meta
        name='twitter:image'
        content={image || 'https://storybook.earth' + DefaultImage}
      />
      <meta
        name='twitter:creator'
        content={author || data.site.siteMetadata.author}
      />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.string,
};

export default SEO