import SEO from "../../../../src/components/seo";
import ReservoirLevels from "../../../../src/components/chart-powell-mead";
import Scablands from "../../../../src/images/scablands.jpeg";
import Michigan from "../../../../src/images/michigan-levels.png";
import Compact from "../../../../src/images/compact.png";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import WaterOpenGraphImage from "../../../../src/images/water-social.png";
import * as React from 'react';
export default {
  SEO,
  ReservoirLevels,
  Scablands,
  Michigan,
  Compact,
  graphql,
  Img,
  WaterOpenGraphImage,
  React
};