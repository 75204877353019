import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const co240MillionToPresent = [
    {
      "title": "Ice Core + Mauna Loa Data + 40 Mil",
      "color": "#63A2EC",
      "data": [
        {
          "x": 0.000001,
          "y": 407.38
        },
        {
          "x": 0.000002,
          "y": 405
        },
        {
          "x": 0.000003,
          "y": 402.86
        },
        {
          "x": 0.000004,
          "y": 399.41
        },
        {
          "x": 0.000005,
          "y": 397.12
        },
        {
          "x": 0.000006,
          "y": 395.19
        },
        {
          "x": 0.000007,
          "y": 392.46
        },
        {
          "x": 0.000008,
          "y": 390.45
        },
        {
          "x": 0.000009,
          "y": 388.57
        },
        {
          "x": 0.00001,
          "y": 386.29
        },
        {
          "x": 0.000011,
          "y": 384.79
        },
        {
          "x": 0.000012,
          "y": 382.68
        },
        {
          "x": 0.000013,
          "y": 380.94
        },
        {
          "x": 0.000014,
          "y": 378.81
        },
        {
          "x": 0.000015,
          "y": 376.79
        },
        {
          "x": 0.000016,
          "y": 374.96
        },
        {
          "x": 0.000017,
          "y": 372.42
        },
        {
          "x": 0.000018,
          "y": 370.41
        },
        {
          "x": 0.000019,
          "y": 368.84
        },
        {
          "x": 0.00002,
          "y": 367.64
        },
        {
          "x": 0.000021,
          "y": 365.54
        },
        {
          "x": 0.000022,
          "y": 362.9
        },
        {
          "x": 0.000023,
          "y": 361.79
        },
        {
          "x": 0.000024,
          "y": 360.04
        },
        {
          "x": 0.000025,
          "y": 358.21
        },
        {
          "x": 0.000026,
          "y": 356.71
        },
        {
          "x": 0.000027,
          "y": 356
        },
        {
          "x": 0.000028,
          "y": 355.29
        },
        {
          "x": 0.000029,
          "y": 353.98
        },
        {
          "x": 0.00003,
          "y": 352.79
        },
        {
          "x": 0.000031,
          "y": 351.15
        },
        {
          "x": 0.000032,
          "y": 348.62
        },
        {
          "x": 0.000033,
          "y": 346.87
        },
        {
          "x": 0.000034,
          "y": 345.47
        },
        {
          "x": 0.000035,
          "y": 343.99
        },
        {
          "x": 0.000036,
          "y": 342.44
        },
        {
          "x": 0.000037,
          "y": 340.76
        },
        {
          "x": 0.000038,
          "y": 340
        },
        {
          "x": 0.000039,
          "y": 338.8
        },
        {
          "x": 0.00004,
          "y": 336.84
        },
        {
          "x": 0.000041,
          "y": 335.4
        },
        {
          "x": 0.000042,
          "y": 333.83
        },
        {
          "x": 0.000043,
          "y": 332.04
        },
        {
          "x": 0.000044,
          "y": 331.11
        },
        {
          "x": 0.000045,
          "y": 330.18
        },
        {
          "x": 0.000046,
          "y": 329.68
        },
        {
          "x": 0.000047,
          "y": 327.45
        },
        {
          "x": 0.000048,
          "y": 326.32
        },
        {
          "x": 0.000049,
          "y": 325.68
        },
        {
          "x": 0.00005,
          "y": 324.62
        },
        {
          "x": 0.000051,
          "y": 323.04
        },
        {
          "x": 0.000052,
          "y": 322.16
        },
        {
          "x": 0.000053,
          "y": 321.38
        },
        {
          "x": 0.000054,
          "y": 320.04
        },
        {
          "x": 0.000055,
          "y": 319.62
        },
        {
          "x": 0.000056,
          "y": 318.99
        },
        {
          "x": 0.000057,
          "y": 318.45
        },
        {
          "x": 0.000058,
          "y": 317.64
        },
        {
          "x": 0.000059,
          "y": 316.91
        },
        {
          "x": 0.00006,
          "y": 315.97
        },
        {
          "x": 0.000137,
          "y": 280.4
        },
        {
          "x": 0.000268,
          "y": 274.9
        },
        {
          "x": 0.000279,
          "y": 277.9
        },
        {
          "x": 0.000395,
          "y": 279.1
        },
        {
          "x": 0.000404,
          "y": 281.9
        },
        {
          "x": 0.000485,
          "y": 277.7
        },
        {
          "x": 0.000559,
          "y": 281.1
        },
        {
          "x": 0.000672,
          "y": 282.2
        },
        {
          "x": 0.000754,
          "y": 280.1
        },
        {
          "x": 0.000877,
          "y": 278.4
        },
        {
          "x": 0.00095,
          "y": 276.6
        },
        {
          "x": 0.00106,
          "y": 279.1
        },
        {
          "x": 0.001153,
          "y": 277.7
        },
        {
          "x": 0.001233,
          "y": 278.7
        },
        {
          "x": 0.00135,
          "y": 277.4
        },
        {
          "x": 0.001453,
          "y": 279.2
        },
        {
          "x": 0.001552,
          "y": 280
        },
        {
          "x": 0.001638,
          "y": 278.9
        },
        {
          "x": 0.001733,
          "y": 278.7
        },
        {
          "x": 0.001812,
          "y": 278
        },
        {
          "x": 0.001931,
          "y": 276.9
        },
        {
          "x": 0.002057,
          "y": 276.7
        },
        {
          "x": 0.002128,
          "y": 276.7
        },
        {
          "x": 0.002212,
          "y": 277.6
        },
        {
          "x": 0.002334,
          "y": 277.9
        },
        {
          "x": 0.002433,
          "y": 273.9
        },
        {
          "x": 0.002536,
          "y": 278.9
        },
        {
          "x": 0.002604,
          "y": 275.3
        },
        {
          "x": 0.002728,
          "y": 274.7
        },
        {
          "x": 0.002806,
          "y": 276.3
        },
        {
          "x": 0.002902,
          "y": 274.6
        },
        {
          "x": 0.003053,
          "y": 276.3
        },
        {
          "x": 0.003116,
          "y": 273.1
        },
        {
          "x": 0.003215,
          "y": 274
        },
        {
          "x": 0.003336,
          "y": 275
        },
        {
          "x": 0.003453,
          "y": 273.4
        },
        {
          "x": 0.003523,
          "y": 273
        },
        {
          "x": 0.003622,
          "y": 271.5
        },
        {
          "x": 0.003721,
          "y": 275.4
        },
        {
          "x": 0.00379,
          "y": 274.9
        },
        {
          "x": 0.00391,
          "y": 271.7
        },
        {
          "x": 0.004004,
          "y": 271.6
        },
        {
          "x": 0.004096,
          "y": 272.8
        },
        {
          "x": 0.004161,
          "y": 271.5
        },
        {
          "x": 0.004324,
          "y": 271.1
        },
        {
          "x": 0.004374,
          "y": 269.1
        },
        {
          "x": 0.00448,
          "y": 269.8
        },
        {
          "x": 0.004573,
          "y": 271.5
        },
        {
          "x": 0.004703,
          "y": 270.7
        },
        {
          "x": 0.004766,
          "y": 269.3
        },
        {
          "x": 0.004874,
          "y": 268.6
        },
        {
          "x": 0.005004,
          "y": 269.8
        },
        {
          "x": 0.005094,
          "y": 267.6
        },
        {
          "x": 0.00516,
          "y": 265.3
        },
        {
          "x": 0.005274,
          "y": 265.2
        },
        {
          "x": 0.00537,
          "y": 267.6
        },
        {
          "x": 0.005476,
          "y": 265.9
        },
        {
          "x": 0.005562,
          "y": 265.5
        },
        {
          "x": 0.005657,
          "y": 260.7
        },
        {
          "x": 0.005716,
          "y": 266.7
        },
        {
          "x": 0.005855,
          "y": 265.5
        },
        {
          "x": 0.005998,
          "y": 263.2
        },
        {
          "x": 0.006039,
          "y": 262.7
        },
        {
          "x": 0.006131,
          "y": 261.2
        },
        {
          "x": 0.006263,
          "y": 261.1
        },
        {
          "x": 0.006354,
          "y": 259.4
        },
        {
          "x": 0.006434,
          "y": 262.1
        },
        {
          "x": 0.006545,
          "y": 262.9
        },
        {
          "x": 0.006617,
          "y": 258.1
        },
        {
          "x": 0.006713,
          "y": 257.6
        },
        {
          "x": 0.006838,
          "y": 262.3
        },
        {
          "x": 0.006941,
          "y": 263
        },
        {
          "x": 0.007028,
          "y": 260.7
        },
        {
          "x": 0.007112,
          "y": 258.4
        },
        {
          "x": 0.007234,
          "y": 260.1
        },
        {
          "x": 0.00732,
          "y": 260.4
        },
        {
          "x": 0.007413,
          "y": 259.7
        },
        {
          "x": 0.007507,
          "y": 259.2
        },
        {
          "x": 0.00759,
          "y": 260.8
        },
        {
          "x": 0.007691,
          "y": 259.6
        },
        {
          "x": 0.007781,
          "y": 259.3
        },
        {
          "x": 0.007876,
          "y": 258.3
        },
        {
          "x": 0.00799,
          "y": 261.3
        },
        {
          "x": 0.00805,
          "y": 260.7
        },
        {
          "x": 0.008181,
          "y": 261.8
        },
        {
          "x": 0.008281,
          "y": 259
        },
        {
          "x": 0.008387,
          "y": 260.9
        },
        {
          "x": 0.008477,
          "y": 260.4
        },
        {
          "x": 0.008579,
          "y": 259.3
        },
        {
          "x": 0.008653,
          "y": 262
        },
        {
          "x": 0.008784,
          "y": 263.7
        },
        {
          "x": 0.008869,
          "y": 263.8
        },
        {
          "x": 0.008973,
          "y": 265.2
        },
        {
          "x": 0.009092,
          "y": 260.6
        },
        {
          "x": 0.00914,
          "y": 260.9
        },
        {
          "x": 0.009232,
          "y": 263
        },
        {
          "x": 0.009317,
          "y": 263.8
        },
        {
          "x": 0.009536,
          "y": 264.4
        },
        {
          "x": 0.009597,
          "y": 264.2
        },
        {
          "x": 0.009721,
          "y": 264
        },
        {
          "x": 0.009807,
          "y": 263.4
        },
        {
          "x": 0.009909,
          "y": 265.7
        },
        {
          "x": 0.009983,
          "y": 264.9
        },
        {
          "x": 0.010088,
          "y": 267.5
        },
        {
          "x": 0.010209,
          "y": 266.9
        },
        {
          "x": 0.010294,
          "y": 266
        },
        {
          "x": 0.010417,
          "y": 265.1
        },
        {
          "x": 0.010527,
          "y": 267.6
        },
        {
          "x": 0.010621,
          "y": 264.8
        },
        {
          "x": 0.010744,
          "y": 264.8
        },
        {
          "x": 0.010805,
          "y": 265
        },
        {
          "x": 0.010827,
          "y": 265.3
        },
        {
          "x": 0.010895,
          "y": 264.4
        },
        {
          "x": 0.010933,
          "y": 264.1
        },
        {
          "x": 0.011014,
          "y": 264.2
        },
        {
          "x": 0.011087,
          "y": 264.5
        },
        {
          "x": 0.011136,
          "y": 264
        },
        {
          "x": 0.011201,
          "y": 263
        },
        {
          "x": 0.011236,
          "y": 265.2
        },
        {
          "x": 0.011278,
          "y": 258.8
        },
        {
          "x": 0.011338,
          "y": 260.8
        },
        {
          "x": 0.011392,
          "y": 255.4
        },
        {
          "x": 0.011436,
          "y": 253.9
        },
        {
          "x": 0.011469,
          "y": 253.8
        },
        {
          "x": 0.01158,
          "y": 250.7
        },
        {
          "x": 0.011635,
          "y": 249.7
        },
        {
          "x": 0.011676,
          "y": 251.1
        },
        {
          "x": 0.011727,
          "y": 250.7
        },
        {
          "x": 0.011819,
          "y": 245.3
        },
        {
          "x": 0.011896,
          "y": 245.3
        },
        {
          "x": 0.011958,
          "y": 246.6
        },
        {
          "x": 0.01205,
          "y": 243.2
        },
        {
          "x": 0.012122,
          "y": 240.3
        },
        {
          "x": 0.012371,
          "y": 237.5
        },
        {
          "x": 0.012496,
          "y": 237.6
        },
        {
          "x": 0.012642,
          "y": 234.2
        },
        {
          "x": 0.01276,
          "y": 238.3
        },
        {
          "x": 0.012942,
          "y": 237.3
        },
        {
          "x": 0.01309,
          "y": 237.9
        },
        {
          "x": 0.013241,
          "y": 237.6
        },
        {
          "x": 0.01344,
          "y": 236.4
        },
        {
          "x": 0.013542,
          "y": 239.2
        },
        {
          "x": 0.013653,
          "y": 238.6
        },
        {
          "x": 0.013804,
          "y": 238.6
        },
        {
          "x": 0.013948,
          "y": 239.1
        },
        {
          "x": 0.014303,
          "y": 228.5
        },
        {
          "x": 0.01455,
          "y": 228.4
        },
        {
          "x": 0.014725,
          "y": 226.1
        },
        {
          "x": 0.01489,
          "y": 225.2
        },
        {
          "x": 0.015012,
          "y": 224.5
        },
        {
          "x": 0.015233,
          "y": 222
        },
        {
          "x": 0.015438,
          "y": 221
        },
        {
          "x": 0.01557,
          "y": 220.9
        },
        {
          "x": 0.015742,
          "y": 219.4
        },
        {
          "x": 0.015886,
          "y": 214
        },
        {
          "x": 0.016073,
          "y": 207.5
        },
        {
          "x": 0.01626,
          "y": 207.7
        },
        {
          "x": 0.016452,
          "y": 202.9
        },
        {
          "x": 0.016659,
          "y": 200.8
        },
        {
          "x": 0.01687,
          "y": 195.2
        },
        {
          "x": 0.017111,
          "y": 193.9
        },
        {
          "x": 0.017375,
          "y": 191
        },
        {
          "x": 0.017565,
          "y": 188.5
        },
        {
          "x": 0.017809,
          "y": 188.5
        },
        {
          "x": 0.017943,
          "y": 189.2
        },
        {
          "x": 0.018285,
          "y": 187
        },
        {
          "x": 0.018541,
          "y": 188.6
        },
        {
          "x": 0.018828,
          "y": 189.4
        },
        {
          "x": 0.018868,
          "y": 192.3
        },
        {
          "x": 0.018921,
          "y": 188.3
        },
        {
          "x": 0.019347,
          "y": 188.7
        },
        {
          "x": 0.019509,
          "y": 188.8
        },
        {
          "x": 0.019748,
          "y": 190
        },
        {
          "x": 0.019988,
          "y": 188
        },
        {
          "x": 0.020168,
          "y": 188.2
        },
        {
          "x": 0.020197,
          "y": 195
        },
        {
          "x": 0.020502,
          "y": 187.8
        },
        {
          "x": 0.020748,
          "y": 186.9
        },
        {
          "x": 0.021011,
          "y": 186.5
        },
        {
          "x": 0.021257,
          "y": 184.7
        },
        {
          "x": 0.021507,
          "y": 186.1
        },
        {
          "x": 0.021854,
          "y": 185.7
        },
        {
          "x": 0.022015,
          "y": 184.4
        },
        {
          "x": 0.022827,
          "y": 189.2
        },
        {
          "x": 0.025994,
          "y": 191.6
        },
        {
          "x": 0.029063,
          "y": 188.5
        },
        {
          "x": 0.03002,
          "y": 191.7
        },
        {
          "x": 0.035009,
          "y": 205.3
        },
        {
          "x": 0.037471,
          "y": 209.1
        },
        {
          "x": 0.0435,
          "y": 209.1
        },
        {
          "x": 0.047336,
          "y": 189.3
        },
        {
          "x": 0.048854,
          "y": 188.4
        },
        {
          "x": 0.04969,
          "y": 210.1
        },
        {
          "x": 0.050663,
          "y": 215.7
        },
        {
          "x": 0.052382,
          "y": 190.4
        },
        {
          "x": 0.057088,
          "y": 221.7
        },
        {
          "x": 0.057657,
          "y": 210.4
        },
        {
          "x": 0.062859,
          "y": 195.3
        },
        {
          "x": 0.064939,
          "y": 191.4
        },
        {
          "x": 0.065939,
          "y": 194.9
        },
        {
          "x": 0.071049,
          "y": 227.3
        },
        {
          "x": 0.073227,
          "y": 229.1
        },
        {
          "x": 0.07715,
          "y": 217.1
        },
        {
          "x": 0.078183,
          "y": 221.7
        },
        {
          "x": 0.080614,
          "y": 230.9
        },
        {
          "x": 0.082417,
          "y": 241.1
        },
        {
          "x": 0.083333,
          "y": 236.4
        },
        {
          "x": 0.084016,
          "y": 228
        },
        {
          "x": 0.08502,
          "y": 214.2
        },
        {
          "x": 0.086181,
          "y": 217
        },
        {
          "x": 0.087917,
          "y": 208
        },
        {
          "x": 0.090357,
          "y": 224.2
        },
        {
          "x": 0.091249,
          "y": 228.3
        },
        {
          "x": 0.094353,
          "y": 232
        },
        {
          "x": 0.099849,
          "y": 225.8
        },
        {
          "x": 0.100837,
          "y": 230.8
        },
        {
          "x": 0.101749,
          "y": 236.9
        },
        {
          "x": 0.103465,
          "y": 228.1
        },
        {
          "x": 0.104704,
          "y": 236.9
        },
        {
          "x": 0.105636,
          "y": 230.6
        },
        {
          "x": 0.107579,
          "y": 238.2
        },
        {
          "x": 0.108153,
          "y": 245.6
        },
        {
          "x": 0.109804,
          "y": 251.2
        },
        {
          "x": 0.111862,
          "y": 256.7
        },
        {
          "x": 0.113262,
          "y": 266.3
        },
        {
          "x": 0.114096,
          "y": 261.4
        },
        {
          "x": 0.114601,
          "y": 274.5
        },
        {
          "x": 0.115118,
          "y": 273.2
        },
        {
          "x": 0.116501,
          "y": 262.5
        },
        {
          "x": 0.11775,
          "y": 267.6
        },
        {
          "x": 0.118649,
          "y": 273.7
        },
        {
          "x": 0.119672,
          "y": 271.9
        },
        {
          "x": 0.120382,
          "y": 265.2
        },
        {
          "x": 0.121017,
          "y": 277.6
        },
        {
          "x": 0.122344,
          "y": 272.1
        },
        {
          "x": 0.12307,
          "y": 276.4
        },
        {
          "x": 0.124213,
          "y": 268.7
        },
        {
          "x": 0.124257,
          "y": 266.6
        },
        {
          "x": 0.124789,
          "y": 266.3
        },
        {
          "x": 0.125081,
          "y": 279.7
        },
        {
          "x": 0.125262,
          "y": 273
        },
        {
          "x": 0.125434,
          "y": 277.1
        },
        {
          "x": 0.126347,
          "y": 273.7
        },
        {
          "x": 0.126598,
          "y": 267.1
        },
        {
          "x": 0.126886,
          "y": 262.5
        },
        {
          "x": 0.127132,
          "y": 262.6
        },
        {
          "x": 0.127622,
          "y": 275.3
        },
        {
          "x": 0.127907,
          "y": 275.6
        },
        {
          "x": 0.128344,
          "y": 274
        },
        {
          "x": 0.128372,
          "y": 287.1
        },
        {
          "x": 0.128609,
          "y": 286.8
        },
        {
          "x": 0.128866,
          "y": 282.6
        },
        {
          "x": 0.129146,
          "y": 264.1
        },
        {
          "x": 0.12934,
          "y": 263.4
        },
        {
          "x": 0.129652,
          "y": 257.9
        },
        {
          "x": 0.129736,
          "y": 259
        },
        {
          "x": 0.131329,
          "y": 245
        },
        {
          "x": 0.131455,
          "y": 240.4
        },
        {
          "x": 0.131728,
          "y": 228.9
        },
        {
          "x": 0.132492,
          "y": 223.5
        },
        {
          "x": 0.133069,
          "y": 223.9
        },
        {
          "x": 0.133105,
          "y": 220.3
        },
        {
          "x": 0.133427,
          "y": 210.6
        },
        {
          "x": 0.134123,
          "y": 208.9
        },
        {
          "x": 0.134287,
          "y": 203.7
        },
        {
          "x": 0.13496,
          "y": 204.5
        },
        {
          "x": 0.135114,
          "y": 200.4
        },
        {
          "x": 0.135207,
          "y": 198
        },
        {
          "x": 0.135603,
          "y": 198
        },
        {
          "x": 0.135883,
          "y": 201.7
        },
        {
          "x": 0.136011,
          "y": 200.7
        },
        {
          "x": 0.136251,
          "y": 202.4
        },
        {
          "x": 0.136567,
          "y": 195.8
        },
        {
          "x": 0.136655,
          "y": 201.1
        },
        {
          "x": 0.137293,
          "y": 194.3
        },
        {
          "x": 0.137633,
          "y": 193.4
        },
        {
          "x": 0.137982,
          "y": 194.2
        },
        {
          "x": 0.138185,
          "y": 190.2
        },
        {
          "x": 0.139275,
          "y": 192.3
        },
        {
          "x": 0.139617,
          "y": 196.5
        },
        {
          "x": 0.140899,
          "y": 195.6
        },
        {
          "x": 0.14096,
          "y": 196.4
        },
        {
          "x": 0.142058,
          "y": 190.4
        },
        {
          "x": 0.145363,
          "y": 196.9
        },
        {
          "x": 0.148831,
          "y": 203
        },
        {
          "x": 0.149803,
          "y": 191.9
        },
        {
          "x": 0.149921,
          "y": 188.9
        },
        {
          "x": 0.151423,
          "y": 200.6
        },
        {
          "x": 0.15448,
          "y": 189
        },
        {
          "x": 0.155395,
          "y": 185.5
        },
        {
          "x": 0.155813,
          "y": 187.5
        },
        {
          "x": 0.159562,
          "y": 204.3
        },
        {
          "x": 0.159943,
          "y": 196.5
        },
        {
          "x": 0.161679,
          "y": 191.6
        },
        {
          "x": 0.162228,
          "y": 190.1
        },
        {
          "x": 0.163024,
          "y": 186.7
        },
        {
          "x": 0.163698,
          "y": 183.8
        },
        {
          "x": 0.164439,
          "y": 196.6
        },
        {
          "x": 0.167183,
          "y": 197.8
        },
        {
          "x": 0.169492,
          "y": 197.7
        },
        {
          "x": 0.171351,
          "y": 196
        },
        {
          "x": 0.172434,
          "y": 190.3
        },
        {
          "x": 0.173135,
          "y": 189.4
        },
        {
          "x": 0.173394,
          "y": 190.1
        },
        {
          "x": 0.175306,
          "y": 207.7
        },
        {
          "x": 0.177139,
          "y": 213.2
        },
        {
          "x": 0.178179,
          "y": 217.7
        },
        {
          "x": 0.179117,
          "y": 198.1
        },
        {
          "x": 0.180068,
          "y": 199.7
        },
        {
          "x": 0.182046,
          "y": 203.4
        },
        {
          "x": 0.184685,
          "y": 210.7
        },
        {
          "x": 0.186697,
          "y": 231.3
        },
        {
          "x": 0.188436,
          "y": 231.4
        },
        {
          "x": 0.189076,
          "y": 220.3
        },
        {
          "x": 0.190352,
          "y": 218
        },
        {
          "x": 0.19291,
          "y": 226.5
        },
        {
          "x": 0.193481,
          "y": 220
        },
        {
          "x": 0.195017,
          "y": 226.4
        },
        {
          "x": 0.195673,
          "y": 241.2
        },
        {
          "x": 0.196179,
          "y": 242.6
        },
        {
          "x": 0.198972,
          "y": 250.9
        },
        {
          "x": 0.199918,
          "y": 239.1
        },
        {
          "x": 0.201163,
          "y": 247.6
        },
        {
          "x": 0.202413,
          "y": 244.4
        },
        {
          "x": 0.202874,
          "y": 231.9
        },
        {
          "x": 0.203317,
          "y": 232.2
        },
        {
          "x": 0.203837,
          "y": 228.6
        },
        {
          "x": 0.20448,
          "y": 226.3
        },
        {
          "x": 0.204908,
          "y": 229.4
        },
        {
          "x": 0.205362,
          "y": 231.4
        },
        {
          "x": 0.205952,
          "y": 238.1
        },
        {
          "x": 0.206144,
          "y": 237.2
        },
        {
          "x": 0.20681,
          "y": 230
        },
        {
          "x": 0.20712,
          "y": 240.5
        },
        {
          "x": 0.207544,
          "y": 242.2
        },
        {
          "x": 0.208064,
          "y": 244.6
        },
        {
          "x": 0.208254,
          "y": 243.9
        },
        {
          "x": 0.208803,
          "y": 247.2
        },
        {
          "x": 0.208995,
          "y": 252
        },
        {
          "x": 0.209432,
          "y": 246.9
        },
        {
          "x": 0.209817,
          "y": 239.5
        },
        {
          "x": 0.210154,
          "y": 257.4
        },
        {
          "x": 0.210813,
          "y": 243.4
        },
        {
          "x": 0.211858,
          "y": 251.2
        },
        {
          "x": 0.212716,
          "y": 241.4
        },
        {
          "x": 0.21327,
          "y": 240.3
        },
        {
          "x": 0.213536,
          "y": 242.6
        },
        {
          "x": 0.213984,
          "y": 247.5
        },
        {
          "x": 0.214459,
          "y": 251.7
        },
        {
          "x": 0.214794,
          "y": 251.1
        },
        {
          "x": 0.215382,
          "y": 245.3
        },
        {
          "x": 0.216352,
          "y": 240.5
        },
        {
          "x": 0.218361,
          "y": 214.1
        },
        {
          "x": 0.219116,
          "y": 216.1
        },
        {
          "x": 0.220058,
          "y": 207.1
        },
        {
          "x": 0.220739,
          "y": 208.8
        },
        {
          "x": 0.22203,
          "y": 205.6
        },
        {
          "x": 0.224269,
          "y": 203.3
        },
        {
          "x": 0.224923,
          "y": 215.7
        },
        {
          "x": 0.225909,
          "y": 235.5
        },
        {
          "x": 0.226711,
          "y": 234.5
        },
        {
          "x": 0.227027,
          "y": 233.1
        },
        {
          "x": 0.227776,
          "y": 224.5
        },
        {
          "x": 0.229423,
          "y": 232.4
        },
        {
          "x": 0.230422,
          "y": 233.9
        },
        {
          "x": 0.231066,
          "y": 241.6
        },
        {
          "x": 0.234817,
          "y": 245.2
        },
        {
          "x": 0.23548,
          "y": 252.1
        },
        {
          "x": 0.236114,
          "y": 241.4
        },
        {
          "x": 0.236734,
          "y": 247.4
        },
        {
          "x": 0.237294,
          "y": 243.1
        },
        {
          "x": 0.237868,
          "y": 239.1
        },
        {
          "x": 0.238558,
          "y": 245.6
        },
        {
          "x": 0.239013,
          "y": 245.8
        },
        {
          "x": 0.239477,
          "y": 247.4
        },
        {
          "x": 0.239973,
          "y": 252.8
        },
        {
          "x": 0.240945,
          "y": 259.7
        },
        {
          "x": 0.241366,
          "y": 263.2
        },
        {
          "x": 0.242007,
          "y": 279
        },
        {
          "x": 0.242346,
          "y": 280.2
        },
        {
          "x": 0.243071,
          "y": 263.7
        },
        {
          "x": 0.243429,
          "y": 252.3
        },
        {
          "x": 0.243856,
          "y": 249.9
        },
        {
          "x": 0.244347,
          "y": 236.7
        },
        {
          "x": 0.244864,
          "y": 230.4
        },
        {
          "x": 0.245441,
          "y": 219.4
        },
        {
          "x": 0.247681,
          "y": 214.7
        },
        {
          "x": 0.250133,
          "y": 200.2
        },
        {
          "x": 0.251005,
          "y": 213.9
        },
        {
          "x": 0.251864,
          "y": 195.4
        },
        {
          "x": 0.252739,
          "y": 196.7
        },
        {
          "x": 0.253636,
          "y": 195.4
        },
        {
          "x": 0.255498,
          "y": 199
        },
        {
          "x": 0.256309,
          "y": 201.9
        },
        {
          "x": 0.2571,
          "y": 204
        },
        {
          "x": 0.258661,
          "y": 203.9
        },
        {
          "x": 0.260353,
          "y": 209.6
        },
        {
          "x": 0.260916,
          "y": 205.7
        },
        {
          "x": 0.262092,
          "y": 208.9
        },
        {
          "x": 0.26293,
          "y": 214.6
        },
        {
          "x": 0.264509,
          "y": 228.1
        },
        {
          "x": 0.265653,
          "y": 199.9
        },
        {
          "x": 0.266326,
          "y": 211.7
        },
        {
          "x": 0.267442,
          "y": 188.7
        },
        {
          "x": 0.268181,
          "y": 187.2
        },
        {
          "x": 0.269154,
          "y": 194.2
        },
        {
          "x": 0.270222,
          "y": 198.8
        },
        {
          "x": 0.271256,
          "y": 184.7
        },
        {
          "x": 0.272311,
          "y": 190.4
        },
        {
          "x": 0.27331,
          "y": 193.9
        },
        {
          "x": 0.274321,
          "y": 194.1
        },
        {
          "x": 0.27535,
          "y": 198.4
        },
        {
          "x": 0.276326,
          "y": 193.2
        },
        {
          "x": 0.277361,
          "y": 202.2
        },
        {
          "x": 0.27835,
          "y": 204.5
        },
        {
          "x": 0.27932,
          "y": 211
        },
        {
          "x": 0.280269,
          "y": 215.3
        },
        {
          "x": 0.281758,
          "y": 223.7
        },
        {
          "x": 0.283785,
          "y": 231.3
        },
        {
          "x": 0.28538,
          "y": 228
        },
        {
          "x": 0.286128,
          "y": 226.4
        },
        {
          "x": 0.287036,
          "y": 231.4
        },
        {
          "x": 0.287538,
          "y": 230.4
        },
        {
          "x": 0.288233,
          "y": 231
        },
        {
          "x": 0.290153,
          "y": 234.9
        },
        {
          "x": 0.29073,
          "y": 220.4
        },
        {
          "x": 0.291367,
          "y": 217.1
        },
        {
          "x": 0.292405,
          "y": 207.6
        },
        {
          "x": 0.293057,
          "y": 206
        },
        {
          "x": 0.293779,
          "y": 206.7
        },
        {
          "x": 0.294568,
          "y": 212.7
        },
        {
          "x": 0.29593,
          "y": 213.1
        },
        {
          "x": 0.297921,
          "y": 217.1
        },
        {
          "x": 0.298741,
          "y": 224.4
        },
        {
          "x": 0.299621,
          "y": 231
        },
        {
          "x": 0.300472,
          "y": 236.1
        },
        {
          "x": 0.301402,
          "y": 239
        },
        {
          "x": 0.30228,
          "y": 236
        },
        {
          "x": 0.303226,
          "y": 240.2
        },
        {
          "x": 0.304232,
          "y": 240.7
        },
        {
          "x": 0.304901,
          "y": 250.2
        },
        {
          "x": 0.305655,
          "y": 248.6
        },
        {
          "x": 0.306547,
          "y": 244.8
        },
        {
          "x": 0.307495,
          "y": 225.8
        },
        {
          "x": 0.308744,
          "y": 227.8
        },
        {
          "x": 0.309389,
          "y": 226.2
        },
        {
          "x": 0.310168,
          "y": 233.2
        },
        {
          "x": 0.311013,
          "y": 237.8
        },
        {
          "x": 0.311868,
          "y": 239
        },
        {
          "x": 0.312676,
          "y": 241.9
        },
        {
          "x": 0.313414,
          "y": 251.6
        },
        {
          "x": 0.314144,
          "y": 256.7
        },
        {
          "x": 0.314867,
          "y": 257.1
        },
        {
          "x": 0.315572,
          "y": 246.8
        },
        {
          "x": 0.3162,
          "y": 272.6
        },
        {
          "x": 0.316897,
          "y": 251.6
        },
        {
          "x": 0.317734,
          "y": 245.2
        },
        {
          "x": 0.318559,
          "y": 233.4
        },
        {
          "x": 0.31948,
          "y": 255.8
        },
        {
          "x": 0.320358,
          "y": 249.2
        },
        {
          "x": 0.322015,
          "y": 257.2
        },
        {
          "x": 0.322767,
          "y": 260.4
        },
        {
          "x": 0.323526,
          "y": 260.3
        },
        {
          "x": 0.32428,
          "y": 260.5
        },
        {
          "x": 0.324971,
          "y": 266.2
        },
        {
          "x": 0.32572,
          "y": 264
        },
        {
          "x": 0.326321,
          "y": 266.1
        },
        {
          "x": 0.326972,
          "y": 270.1
        },
        {
          "x": 0.32759,
          "y": 271.9
        },
        {
          "x": 0.328221,
          "y": 275.1
        },
        {
          "x": 0.329475,
          "y": 265
        },
        {
          "x": 0.330208,
          "y": 271.7
        },
        {
          "x": 0.33074,
          "y": 272.6
        },
        {
          "x": 0.331438,
          "y": 273.1
        },
        {
          "x": 0.331944,
          "y": 282.4
        },
        {
          "x": 0.332285,
          "y": 289.1
        },
        {
          "x": 0.332462,
          "y": 288.4
        },
        {
          "x": 0.332919,
          "y": 298.6
        },
        {
          "x": 0.33338,
          "y": 278.1
        },
        {
          "x": 0.33389,
          "y": 285.8
        },
        {
          "x": 0.334261,
          "y": 278.6
        },
        {
          "x": 0.334748,
          "y": 270.5
        },
        {
          "x": 0.335287,
          "y": 255.7
        },
        {
          "x": 0.335918,
          "y": 241.9
        },
        {
          "x": 0.336725,
          "y": 239.6
        },
        {
          "x": 0.337391,
          "y": 234.2
        },
        {
          "x": 0.339298,
          "y": 250.1
        },
        {
          "x": 0.340456,
          "y": 200.7
        },
        {
          "x": 0.341802,
          "y": 205.2
        },
        {
          "x": 0.343282,
          "y": 204.8
        },
        {
          "x": 0.344446,
          "y": 211.9
        },
        {
          "x": 0.34598,
          "y": 220.3
        },
        {
          "x": 0.348298,
          "y": 221.1
        },
        {
          "x": 0.349688,
          "y": 216.2
        },
        {
          "x": 0.350925,
          "y": 209.4
        },
        {
          "x": 0.352275,
          "y": 209.2
        },
        {
          "x": 0.355302,
          "y": 193
        },
        {
          "x": 0.356898,
          "y": 186.1
        },
        {
          "x": 0.358712,
          "y": 185.8
        },
        {
          "x": 0.360957,
          "y": 201.2
        },
        {
          "x": 0.363385,
          "y": 206.3
        },
        {
          "x": 0.366235,
          "y": 201.9
        },
        {
          "x": 0.367856,
          "y": 199.9
        },
        {
          "x": 0.369446,
          "y": 214.7
        },
        {
          "x": 0.37109,
          "y": 224.6
        },
        {
          "x": 0.372646,
          "y": 229.6
        },
        {
          "x": 0.376568,
          "y": 227
        },
        {
          "x": 0.378096,
          "y": 240
        },
        {
          "x": 0.379696,
          "y": 239.1
        },
        {
          "x": 0.381132,
          "y": 246.8
        },
        {
          "x": 0.38267,
          "y": 245.8
        },
        {
          "x": 0.384534,
          "y": 258.1
        },
        {
          "x": 0.385398,
          "y": 264.6
        },
        {
          "x": 0.386144,
          "y": 259.2
        },
        {
          "x": 0.388062,
          "y": 255.2
        },
        {
          "x": 0.389946,
          "y": 250.1
        },
        {
          "x": 0.391896,
          "y": 266.3
        },
        {
          "x": 0.392544,
          "y": 259.5
        },
        {
          "x": 0.393579,
          "y": 273.6
        },
        {
          "x": 0.39456,
          "y": 260.7
        },
        {
          "x": 0.398086,
          "y": 276.3
        },
        {
          "x": 0.399722,
          "y": 277.1
        },
        {
          "x": 0.400504,
          "y": 283.2
        },
        {
          "x": 0.402,
          "y": 283.1
        },
        {
          "x": 0.402731,
          "y": 275.7
        },
        {
          "x": 0.404181,
          "y": 276.5
        },
        {
          "x": 0.404927,
          "y": 280.5
        },
        {
          "x": 0.406368,
          "y": 279.6
        },
        {
          "x": 0.407093,
          "y": 285.6
        },
        {
          "x": 0.4086,
          "y": 284.5
        },
        {
          "x": 0.409383,
          "y": 275.2
        },
        {
          "x": 0.410206,
          "y": 282.6
        },
        {
          "x": 0.411071,
          "y": 283.5
        },
        {
          "x": 0.412962,
          "y": 274.9
        },
        {
          "x": 0.413948,
          "y": 264.9
        },
        {
          "x": 0.414963,
          "y": 271.6
        },
        {
          "x": 0.415717,
          "y": 276.4
        },
        {
          "x": 0.416193,
          "y": 271.7
        },
        {
          "x": 0.417191,
          "y": 273.4
        },
        {
          "x": 0.417698,
          "y": 271.8
        },
        {
          "x": 0.418245,
          "y": 274.6
        },
        {
          "x": 0.41926,
          "y": 273.7
        },
        {
          "x": 0.419808,
          "y": 271.2
        },
        {
          "x": 0.42035,
          "y": 273.8
        },
        {
          "x": 0.421484,
          "y": 268.6
        },
        {
          "x": 0.422074,
          "y": 266.4
        },
        {
          "x": 0.422649,
          "y": 270.6
        },
        {
          "x": 0.423764,
          "y": 267.7
        },
        {
          "x": 0.424332,
          "y": 268.3
        },
        {
          "x": 0.42484,
          "y": 270.8
        },
        {
          "x": 0.425242,
          "y": 270
        },
        {
          "x": 0.425569,
          "y": 265.4
        },
        {
          "x": 0.425975,
          "y": 255.3
        },
        {
          "x": 0.426598,
          "y": 252.1
        },
        {
          "x": 0.427285,
          "y": 248.2
        },
        {
          "x": 0.427566,
          "y": 242.5
        },
        {
          "x": 0.429006,
          "y": 219.7
        },
        {
          "x": 0.429876,
          "y": 227.2
        },
        {
          "x": 0.431063,
          "y": 211.5
        },
        {
          "x": 0.431445,
          "y": 207.6
        },
        {
          "x": 0.432599,
          "y": 209.8
        },
        {
          "x": 0.433674,
          "y": 207.5
        },
        {
          "x": 0.434804,
          "y": 200.3
        },
        {
          "x": 0.435989,
          "y": 201.7
        },
        {
          "x": 0.437152,
          "y": 201.3
        },
        {
          "x": 0.438356,
          "y": 202
        },
        {
          "x": 0.439565,
          "y": 199.1
        },
        {
          "x": 0.44122,
          "y": 201.1
        },
        {
          "x": 0.442411,
          "y": 203.5
        },
        {
          "x": 0.443562,
          "y": 208.1
        },
        {
          "x": 0.44465,
          "y": 201.7
        },
        {
          "x": 0.445829,
          "y": 201.2
        },
        {
          "x": 0.446984,
          "y": 204.9
        },
        {
          "x": 0.448103,
          "y": 201.9
        },
        {
          "x": 0.449244,
          "y": 198.4
        },
        {
          "x": 0.450455,
          "y": 193.3
        },
        {
          "x": 0.451593,
          "y": 192.5
        },
        {
          "x": 0.452283,
          "y": 199.1
        },
        {
          "x": 0.452795,
          "y": 204.3
        },
        {
          "x": 0.453514,
          "y": 203.3
        },
        {
          "x": 0.454023,
          "y": 208.3
        },
        {
          "x": 0.455279,
          "y": 202.4
        },
        {
          "x": 0.456591,
          "y": 195.5
        },
        {
          "x": 0.458049,
          "y": 190.7
        },
        {
          "x": 0.45943,
          "y": 194.4
        },
        {
          "x": 0.460792,
          "y": 199.9
        },
        {
          "x": 0.461687,
          "y": 205.2
        },
        {
          "x": 0.462133,
          "y": 210
        },
        {
          "x": 0.463456,
          "y": 208.1
        },
        {
          "x": 0.464866,
          "y": 204.4
        },
        {
          "x": 0.466265,
          "y": 203.4
        },
        {
          "x": 0.467602,
          "y": 205.5
        },
        {
          "x": 0.468323,
          "y": 206.5
        },
        {
          "x": 0.46881,
          "y": 215.5
        },
        {
          "x": 0.46947,
          "y": 218.7
        },
        {
          "x": 0.469941,
          "y": 229.2
        },
        {
          "x": 0.470597,
          "y": 232.7
        },
        {
          "x": 0.471046,
          "y": 243.7
        },
        {
          "x": 0.471763,
          "y": 243.9
        },
        {
          "x": 0.472095,
          "y": 245.6
        },
        {
          "x": 0.473102,
          "y": 241.2
        },
        {
          "x": 0.474162,
          "y": 233.1
        },
        {
          "x": 0.475218,
          "y": 232.8
        },
        {
          "x": 0.47624,
          "y": 236.6
        },
        {
          "x": 0.477162,
          "y": 239.1
        },
        {
          "x": 0.477719,
          "y": 236.5
        },
        {
          "x": 0.478124,
          "y": 231.3
        },
        {
          "x": 0.478721,
          "y": 220.8
        },
        {
          "x": 0.479144,
          "y": 218.8
        },
        {
          "x": 0.480108,
          "y": 223.8
        },
        {
          "x": 0.481035,
          "y": 223.4
        },
        {
          "x": 0.481932,
          "y": 227.3
        },
        {
          "x": 0.482803,
          "y": 231.4
        },
        {
          "x": 0.483649,
          "y": 231.4
        },
        {
          "x": 0.484465,
          "y": 231.2
        },
        {
          "x": 0.485276,
          "y": 233
        },
        {
          "x": 0.486043,
          "y": 232.9
        },
        {
          "x": 0.486816,
          "y": 231.3
        },
        {
          "x": 0.487541,
          "y": 237.1
        },
        {
          "x": 0.488268,
          "y": 242
        },
        {
          "x": 0.488689,
          "y": 240.9
        },
        {
          "x": 0.488996,
          "y": 252.8
        },
        {
          "x": 0.489475,
          "y": 249.3
        },
        {
          "x": 0.489722,
          "y": 242.6
        },
        {
          "x": 0.490445,
          "y": 243.3
        },
        {
          "x": 0.491191,
          "y": 246.7
        },
        {
          "x": 0.491959,
          "y": 243.7
        },
        {
          "x": 0.492738,
          "y": 239.7
        },
        {
          "x": 0.493507,
          "y": 239.1
        },
        {
          "x": 0.494311,
          "y": 238.3
        },
        {
          "x": 0.495085,
          "y": 238.5
        },
        {
          "x": 0.495853,
          "y": 237.6
        },
        {
          "x": 0.496608,
          "y": 236.4
        },
        {
          "x": 0.497366,
          "y": 236.5
        },
        {
          "x": 0.498104,
          "y": 232.8
        },
        {
          "x": 0.498865,
          "y": 230.6
        },
        {
          "x": 0.49961,
          "y": 230.8
        },
        {
          "x": 0.500363,
          "y": 228.2
        },
        {
          "x": 0.50113,
          "y": 230.8
        },
        {
          "x": 0.501866,
          "y": 231.2
        },
        {
          "x": 0.502625,
          "y": 232
        },
        {
          "x": 0.503375,
          "y": 232.2
        },
        {
          "x": 0.504083,
          "y": 234.1
        },
        {
          "x": 0.504777,
          "y": 233.9
        },
        {
          "x": 0.505516,
          "y": 235.5
        },
        {
          "x": 0.506287,
          "y": 236
        },
        {
          "x": 0.507011,
          "y": 238.2
        },
        {
          "x": 0.507714,
          "y": 237
        },
        {
          "x": 0.508476,
          "y": 240.3
        },
        {
          "x": 0.509247,
          "y": 233.4
        },
        {
          "x": 0.510008,
          "y": 236.4
        },
        {
          "x": 0.510765,
          "y": 235.2
        },
        {
          "x": 0.511515,
          "y": 242
        },
        {
          "x": 0.512269,
          "y": 238.2
        },
        {
          "x": 0.512997,
          "y": 239.2
        },
        {
          "x": 0.513724,
          "y": 241.5
        },
        {
          "x": 0.514429,
          "y": 243
        },
        {
          "x": 0.515113,
          "y": 247.2
        },
        {
          "x": 0.515771,
          "y": 246.2
        },
        {
          "x": 0.516417,
          "y": 245.5
        },
        {
          "x": 0.517069,
          "y": 245.3
        },
        {
          "x": 0.517706,
          "y": 247.7
        },
        {
          "x": 0.518334,
          "y": 245.5
        },
        {
          "x": 0.518965,
          "y": 243.5
        },
        {
          "x": 0.520892,
          "y": 241.9
        },
        {
          "x": 0.521552,
          "y": 236.8
        },
        {
          "x": 0.522216,
          "y": 235.8
        },
        {
          "x": 0.522923,
          "y": 233.7
        },
        {
          "x": 0.523666,
          "y": 230.3
        },
        {
          "x": 0.524409,
          "y": 227.8
        },
        {
          "x": 0.525164,
          "y": 224.7
        },
        {
          "x": 0.525908,
          "y": 222.7
        },
        {
          "x": 0.526661,
          "y": 221.2
        },
        {
          "x": 0.527454,
          "y": 220.3
        },
        {
          "x": 0.528262,
          "y": 220.7
        },
        {
          "x": 0.528747,
          "y": 214.5
        },
        {
          "x": 0.529101,
          "y": 211.4
        },
        {
          "x": 0.52964,
          "y": 204.4
        },
        {
          "x": 0.530016,
          "y": 200
        },
        {
          "x": 0.530685,
          "y": 195.1
        },
        {
          "x": 0.531035,
          "y": 193.8
        },
        {
          "x": 0.531662,
          "y": 190.5
        },
        {
          "x": 0.532119,
          "y": 199.8
        },
        {
          "x": 0.533246,
          "y": 199.4
        },
        {
          "x": 0.534364,
          "y": 205
        },
        {
          "x": 0.535483,
          "y": 206.4
        },
        {
          "x": 0.536622,
          "y": 212.9
        },
        {
          "x": 0.53777,
          "y": 211.2
        },
        {
          "x": 0.538959,
          "y": 205
        },
        {
          "x": 0.540154,
          "y": 203.6
        },
        {
          "x": 0.541345,
          "y": 208.1
        },
        {
          "x": 0.542502,
          "y": 210.3
        },
        {
          "x": 0.543676,
          "y": 209.6
        },
        {
          "x": 0.544804,
          "y": 209.7
        },
        {
          "x": 0.545956,
          "y": 204.6
        },
        {
          "x": 0.547013,
          "y": 202.5
        },
        {
          "x": 0.547573,
          "y": 208.8
        },
        {
          "x": 0.547972,
          "y": 213.6
        },
        {
          "x": 0.548933,
          "y": 215.2
        },
        {
          "x": 0.54983,
          "y": 219.9
        },
        {
          "x": 0.550637,
          "y": 221.7
        },
        {
          "x": 0.551124,
          "y": 222.3
        },
        {
          "x": 0.551452,
          "y": 226.3
        },
        {
          "x": 0.551979,
          "y": 222.1
        },
        {
          "x": 0.55225,
          "y": 220.3
        },
        {
          "x": 0.552961,
          "y": 223.6
        },
        {
          "x": 0.553626,
          "y": 224.3
        },
        {
          "x": 0.554254,
          "y": 226.7
        },
        {
          "x": 0.554831,
          "y": 233.9
        },
        {
          "x": 0.555345,
          "y": 241
        },
        {
          "x": 0.555637,
          "y": 243
        },
        {
          "x": 0.555839,
          "y": 249.1
        },
        {
          "x": 0.556143,
          "y": 244
        },
        {
          "x": 0.556364,
          "y": 250.5
        },
        {
          "x": 0.556724,
          "y": 245.6
        },
        {
          "x": 0.556889,
          "y": 240.4
        },
        {
          "x": 0.557385,
          "y": 238.1
        },
        {
          "x": 0.557887,
          "y": 234.5
        },
        {
          "x": 0.558183,
          "y": 229.7
        },
        {
          "x": 0.558393,
          "y": 230.2
        },
        {
          "x": 0.558929,
          "y": 230.3
        },
        {
          "x": 0.559461,
          "y": 228.3
        },
        {
          "x": 0.559972,
          "y": 231.6
        },
        {
          "x": 0.560459,
          "y": 231.9
        },
        {
          "x": 0.56093,
          "y": 234.6
        },
        {
          "x": 0.561309,
          "y": 234.1
        },
        {
          "x": 0.561846,
          "y": 240.1
        },
        {
          "x": 0.562272,
          "y": 242.3
        },
        {
          "x": 0.56269,
          "y": 245.7
        },
        {
          "x": 0.563115,
          "y": 245.8
        },
        {
          "x": 0.563517,
          "y": 247.6
        },
        {
          "x": 0.563918,
          "y": 251.4
        },
        {
          "x": 0.564311,
          "y": 252.4
        },
        {
          "x": 0.564699,
          "y": 252.6
        },
        {
          "x": 0.565077,
          "y": 251.4
        },
        {
          "x": 0.565466,
          "y": 253.7
        },
        {
          "x": 0.565851,
          "y": 254.3
        },
        {
          "x": 0.566233,
          "y": 253.9
        },
        {
          "x": 0.566619,
          "y": 254.5
        },
        {
          "x": 0.567007,
          "y": 253.2
        },
        {
          "x": 0.567381,
          "y": 253.9
        },
        {
          "x": 0.567777,
          "y": 252.8
        },
        {
          "x": 0.568167,
          "y": 253
        },
        {
          "x": 0.568556,
          "y": 250.2
        },
        {
          "x": 0.568946,
          "y": 251.3
        },
        {
          "x": 0.569331,
          "y": 250
        },
        {
          "x": 0.569728,
          "y": 251.3
        },
        {
          "x": 0.570139,
          "y": 251.8
        },
        {
          "x": 0.570548,
          "y": 251.8
        },
        {
          "x": 0.570955,
          "y": 249.6
        },
        {
          "x": 0.571367,
          "y": 251.6
        },
        {
          "x": 0.571775,
          "y": 250.3
        },
        {
          "x": 0.57219,
          "y": 246.3
        },
        {
          "x": 0.572619,
          "y": 247.7
        },
        {
          "x": 0.573037,
          "y": 249.2
        },
        {
          "x": 0.573462,
          "y": 248.7
        },
        {
          "x": 0.573902,
          "y": 251.8
        },
        {
          "x": 0.574353,
          "y": 251.9
        },
        {
          "x": 0.574803,
          "y": 252.1
        },
        {
          "x": 0.575265,
          "y": 248.9
        },
        {
          "x": 0.57573,
          "y": 252.5
        },
        {
          "x": 0.576222,
          "y": 252.9
        },
        {
          "x": 0.576723,
          "y": 251.3
        },
        {
          "x": 0.577242,
          "y": 251.3
        },
        {
          "x": 0.577769,
          "y": 251.1
        },
        {
          "x": 0.578305,
          "y": 249.1
        },
        {
          "x": 0.578857,
          "y": 252.9
        },
        {
          "x": 0.579435,
          "y": 251.5
        },
        {
          "x": 0.579821,
          "y": 244.1
        },
        {
          "x": 0.580019,
          "y": 243.8
        },
        {
          "x": 0.5804,
          "y": 236.2
        },
        {
          "x": 0.580695,
          "y": 230.3
        },
        {
          "x": 0.58116,
          "y": 225.2
        },
        {
          "x": 0.581507,
          "y": 219.4
        },
        {
          "x": 0.582,
          "y": 215.4
        },
        {
          "x": 0.582391,
          "y": 209.9
        },
        {
          "x": 0.583034,
          "y": 206.7
        },
        {
          "x": 0.583413,
          "y": 210.6
        },
        {
          "x": 0.586406,
          "y": 224
        },
        {
          "x": 0.586893,
          "y": 226
        },
        {
          "x": 0.587647,
          "y": 234.4
        },
        {
          "x": 0.588034,
          "y": 238.8
        },
        {
          "x": 0.588716,
          "y": 246.3
        },
        {
          "x": 0.589224,
          "y": 250.2
        },
        {
          "x": 0.589868,
          "y": 248.1
        },
        {
          "x": 0.590335,
          "y": 243.6
        },
        {
          "x": 0.590893,
          "y": 237.4
        },
        {
          "x": 0.592279,
          "y": 225.7
        },
        {
          "x": 0.592697,
          "y": 229.4
        },
        {
          "x": 0.593415,
          "y": 233.2
        },
        {
          "x": 0.59387,
          "y": 238.1
        },
        {
          "x": 0.594639,
          "y": 238
        },
        {
          "x": 0.595189,
          "y": 232.9
        },
        {
          "x": 0.59595,
          "y": 221.2
        },
        {
          "x": 0.596515,
          "y": 216.4
        },
        {
          "x": 0.597443,
          "y": 216.3
        },
        {
          "x": 0.597966,
          "y": 219
        },
        {
          "x": 0.598833,
          "y": 226
        },
        {
          "x": 0.599434,
          "y": 229.4
        },
        {
          "x": 0.600882,
          "y": 232.5
        },
        {
          "x": 0.602278,
          "y": 237.9
        },
        {
          "x": 0.603673,
          "y": 239.1
        },
        {
          "x": 0.605076,
          "y": 244.5
        },
        {
          "x": 0.606374,
          "y": 248.5
        },
        {
          "x": 0.607708,
          "y": 254.6
        },
        {
          "x": 0.608929,
          "y": 259.2
        },
        {
          "x": 0.610136,
          "y": 257.7
        },
        {
          "x": 0.611319,
          "y": 259.6
        },
        {
          "x": 0.612514,
          "y": 258
        },
        {
          "x": 0.613709,
          "y": 256
        },
        {
          "x": 0.614923,
          "y": 252.5
        },
        {
          "x": 0.616227,
          "y": 252.4
        },
        {
          "x": 0.617544,
          "y": 252.3
        },
        {
          "x": 0.618955,
          "y": 247.9
        },
        {
          "x": 0.620338,
          "y": 245.4
        },
        {
          "x": 0.62176,
          "y": 243
        },
        {
          "x": 0.623214,
          "y": 238.6
        },
        {
          "x": 0.62469,
          "y": 237.3
        },
        {
          "x": 0.62557,
          "y": 234.3
        },
        {
          "x": 0.626215,
          "y": 227.8
        },
        {
          "x": 0.627211,
          "y": 214.9
        },
        {
          "x": 0.627999,
          "y": 205
        },
        {
          "x": 0.629446,
          "y": 198.7
        },
        {
          "x": 0.630295,
          "y": 199.9
        },
        {
          "x": 0.632001,
          "y": 199.5
        },
        {
          "x": 0.632856,
          "y": 195.6
        },
        {
          "x": 0.634592,
          "y": 192.1
        },
        {
          "x": 0.635489,
          "y": 193.9
        },
        {
          "x": 0.636957,
          "y": 193
        },
        {
          "x": 0.638127,
          "y": 194.3
        },
        {
          "x": 0.639848,
          "y": 187.7
        },
        {
          "x": 0.64076,
          "y": 189.1
        },
        {
          "x": 0.642405,
          "y": 190.2
        },
        {
          "x": 0.643204,
          "y": 190.4
        },
        {
          "x": 0.644662,
          "y": 194.1
        },
        {
          "x": 0.64547,
          "y": 194.6
        },
        {
          "x": 0.646723,
          "y": 188.6
        },
        {
          "x": 0.647588,
          "y": 190.5
        },
        {
          "x": 0.648946,
          "y": 192.1
        },
        {
          "x": 0.649598,
          "y": 194.8
        },
        {
          "x": 0.650891,
          "y": 187.8
        },
        {
          "x": 0.651556,
          "y": 192
        },
        {
          "x": 0.652802,
          "y": 185.8
        },
        {
          "x": 0.653448,
          "y": 185.3
        },
        {
          "x": 0.654473,
          "y": 191.3
        },
        {
          "x": 0.655202,
          "y": 193.2
        },
        {
          "x": 0.656286,
          "y": 198.4
        },
        {
          "x": 0.656882,
          "y": 198.7
        },
        {
          "x": 0.657923,
          "y": 191.3
        },
        {
          "x": 0.658474,
          "y": 189.2
        },
        {
          "x": 0.659524,
          "y": 182.4
        },
        {
          "x": 0.660084,
          "y": 183.9
        },
        {
          "x": 0.660981,
          "y": 186.6
        },
        {
          "x": 0.66165,
          "y": 189.8
        },
        {
          "x": 0.662669,
          "y": 187.8
        },
        {
          "x": 0.663206,
          "y": 189
        },
        {
          "x": 0.664192,
          "y": 189.1
        },
        {
          "x": 0.66469,
          "y": 185.8
        },
        {
          "x": 0.665645,
          "y": 178.2
        },
        {
          "x": 0.666174,
          "y": 178.5
        },
        {
          "x": 0.666995,
          "y": 172.7
        },
        {
          "x": 0.667569,
          "y": 171.6
        },
        {
          "x": 0.668447,
          "y": 175.6
        },
        {
          "x": 0.668934,
          "y": 178.5
        },
        {
          "x": 0.669751,
          "y": 185.5
        },
        {
          "x": 0.670176,
          "y": 189.3
        },
        {
          "x": 0.670916,
          "y": 192.6
        },
        {
          "x": 0.671286,
          "y": 194.6
        },
        {
          "x": 0.671926,
          "y": 192.5
        },
        {
          "x": 0.672386,
          "y": 194.8
        },
        {
          "x": 0.673095,
          "y": 198.7
        },
        {
          "x": 0.673475,
          "y": 202.3
        },
        {
          "x": 0.674173,
          "y": 209.2
        },
        {
          "x": 0.674521,
          "y": 213.7
        },
        {
          "x": 0.675589,
          "y": 220
        },
        {
          "x": 0.67617,
          "y": 217.4
        },
        {
          "x": 0.676524,
          "y": 214
        },
        {
          "x": 0.677068,
          "y": 215.6
        },
        {
          "x": 0.67738,
          "y": 217.4
        },
        {
          "x": 0.677984,
          "y": 226.5
        },
        {
          "x": 0.678291,
          "y": 230
        },
        {
          "x": 0.678914,
          "y": 230.8
        },
        {
          "x": 0.679252,
          "y": 230.3
        },
        {
          "x": 0.679732,
          "y": 223
        },
        {
          "x": 0.680081,
          "y": 225
        },
        {
          "x": 0.680608,
          "y": 218.4
        },
        {
          "x": 0.68092,
          "y": 216.2
        },
        {
          "x": 0.681531,
          "y": 217.3
        },
        {
          "x": 0.681849,
          "y": 219.7
        },
        {
          "x": 0.682446,
          "y": 222.6
        },
        {
          "x": 0.682762,
          "y": 221.3
        },
        {
          "x": 0.683262,
          "y": 218.7
        },
        {
          "x": 0.683612,
          "y": 217.5
        },
        {
          "x": 0.684142,
          "y": 217.5
        },
        {
          "x": 0.684419,
          "y": 219.4
        },
        {
          "x": 0.684949,
          "y": 219.5
        },
        {
          "x": 0.685217,
          "y": 219.7
        },
        {
          "x": 0.685716,
          "y": 223.5
        },
        {
          "x": 0.68598,
          "y": 224.4
        },
        {
          "x": 0.686425,
          "y": 224.3
        },
        {
          "x": 0.686726,
          "y": 227.3
        },
        {
          "x": 0.687227,
          "y": 228.7
        },
        {
          "x": 0.68747,
          "y": 228.8
        },
        {
          "x": 0.687949,
          "y": 232.3
        },
        {
          "x": 0.688195,
          "y": 233
        },
        {
          "x": 0.688652,
          "y": 235.8
        },
        {
          "x": 0.688881,
          "y": 234.9
        },
        {
          "x": 0.689304,
          "y": 235.8
        },
        {
          "x": 0.689603,
          "y": 239.9
        },
        {
          "x": 0.690057,
          "y": 235.6
        },
        {
          "x": 0.690294,
          "y": 234.8
        },
        {
          "x": 0.690771,
          "y": 240
        },
        {
          "x": 0.690999,
          "y": 234
        },
        {
          "x": 0.691432,
          "y": 235.6
        },
        {
          "x": 0.691672,
          "y": 236.6
        },
        {
          "x": 0.692057,
          "y": 235.5
        },
        {
          "x": 0.692338,
          "y": 235.9
        },
        {
          "x": 0.692765,
          "y": 232.9
        },
        {
          "x": 0.69299,
          "y": 234.8
        },
        {
          "x": 0.693433,
          "y": 234.1
        },
        {
          "x": 0.693639,
          "y": 234.1
        },
        {
          "x": 0.694068,
          "y": 235.4
        },
        {
          "x": 0.6943,
          "y": 234.4
        },
        {
          "x": 0.694672,
          "y": 235.8
        },
        {
          "x": 0.694941,
          "y": 236.9
        },
        {
          "x": 0.695383,
          "y": 236.7
        },
        {
          "x": 0.695618,
          "y": 237.6
        },
        {
          "x": 0.696062,
          "y": 234.6
        },
        {
          "x": 0.696287,
          "y": 234.8
        },
        {
          "x": 0.696717,
          "y": 235.4
        },
        {
          "x": 0.696963,
          "y": 232.9
        },
        {
          "x": 0.697331,
          "y": 232.4
        },
        {
          "x": 0.69766,
          "y": 232.2
        },
        {
          "x": 0.69812,
          "y": 231
        },
        {
          "x": 0.698386,
          "y": 228
        },
        {
          "x": 0.69888,
          "y": 226.4
        },
        {
          "x": 0.699102,
          "y": 227.7
        },
        {
          "x": 0.699633,
          "y": 227.6
        },
        {
          "x": 0.699909,
          "y": 226
        },
        {
          "x": 0.700389,
          "y": 228.7
        },
        {
          "x": 0.700744,
          "y": 227.6
        },
        {
          "x": 0.701242,
          "y": 228.2
        },
        {
          "x": 0.701606,
          "y": 228.8
        },
        {
          "x": 0.702158,
          "y": 229.3
        },
        {
          "x": 0.702381,
          "y": 230.3
        },
        {
          "x": 0.702988,
          "y": 232.4
        },
        {
          "x": 0.703384,
          "y": 232.8
        },
        {
          "x": 0.703934,
          "y": 231.2
        },
        {
          "x": 0.704211,
          "y": 231.2
        },
        {
          "x": 0.704973,
          "y": 233.9
        },
        {
          "x": 0.70541,
          "y": 228.5
        },
        {
          "x": 0.706141,
          "y": 228.6
        },
        {
          "x": 0.706409,
          "y": 228.1
        },
        {
          "x": 0.70968,
          "y": 219.7
        },
        {
          "x": 0.710237,
          "y": 220.2
        },
        {
          "x": 0.711104,
          "y": 218.9
        },
        {
          "x": 0.711421,
          "y": 218.7
        },
        {
          "x": 0.712833,
          "y": 218.5
        },
        {
          "x": 0.713843,
          "y": 222.3
        },
        {
          "x": 0.714288,
          "y": 221.7
        },
        {
          "x": 0.71518,
          "y": 217.7
        },
        {
          "x": 0.71575,
          "y": 222.1
        },
        {
          "x": 0.716704,
          "y": 208.1
        },
        {
          "x": 0.717065,
          "y": 205
        },
        {
          "x": 0.718064,
          "y": 192.9
        },
        {
          "x": 0.718779,
          "y": 184.3
        },
        {
          "x": 0.719794,
          "y": 187.8
        },
        {
          "x": 0.720337,
          "y": 195.2
        },
        {
          "x": 0.721318,
          "y": 206.3
        },
        {
          "x": 0.722039,
          "y": 211.3
        },
        {
          "x": 0.723133,
          "y": 213.4
        },
        {
          "x": 0.723464,
          "y": 213.2
        },
        {
          "x": 0.724445,
          "y": 210.3
        },
        {
          "x": 0.725059,
          "y": 206.3
        },
        {
          "x": 0.725937,
          "y": 206.6
        },
        {
          "x": 0.726322,
          "y": 208.1
        },
        {
          "x": 0.727192,
          "y": 204.5
        },
        {
          "x": 0.727732,
          "y": 205.5
        },
        {
          "x": 0.728568,
          "y": 205.1
        },
        {
          "x": 0.728921,
          "y": 205.4
        },
        {
          "x": 0.730299,
          "y": 204
        },
        {
          "x": 0.730949,
          "y": 204.1
        },
        {
          "x": 0.731329,
          "y": 204.9
        },
        {
          "x": 0.732141,
          "y": 203.8
        },
        {
          "x": 0.732548,
          "y": 205.9
        },
        {
          "x": 0.733261,
          "y": 203.9
        },
        {
          "x": 0.733543,
          "y": 204.4
        },
        {
          "x": 0.734223,
          "y": 205.3
        },
        {
          "x": 0.734604,
          "y": 207.4
        },
        {
          "x": 0.735167,
          "y": 201.7
        },
        {
          "x": 0.735459,
          "y": 201.3
        },
        {
          "x": 0.736079,
          "y": 204.1
        },
        {
          "x": 0.73646,
          "y": 209.7
        },
        {
          "x": 0.737034,
          "y": 208.1
        },
        {
          "x": 0.737266,
          "y": 211.3
        },
        {
          "x": 0.737889,
          "y": 210.6
        },
        {
          "x": 0.738272,
          "y": 206.5
        },
        {
          "x": 0.738844,
          "y": 200.3
        },
        {
          "x": 0.739137,
          "y": 197.8
        },
        {
          "x": 0.739842,
          "y": 185
        },
        {
          "x": 0.740277,
          "y": 178.9
        },
        {
          "x": 0.741021,
          "y": 178.6
        },
        {
          "x": 0.741307,
          "y": 176.6
        },
        {
          "x": 0.742075,
          "y": 177.9
        },
        {
          "x": 0.742558,
          "y": 182.3
        },
        {
          "x": 0.743233,
          "y": 181.7
        },
        {
          "x": 0.743589,
          "y": 180.4
        },
        {
          "x": 0.744354,
          "y": 181.2
        },
        {
          "x": 0.744843,
          "y": 182
        },
        {
          "x": 0.745836,
          "y": 180.2
        },
        {
          "x": 0.746643,
          "y": 183.1
        },
        {
          "x": 0.74711,
          "y": 180.8
        },
        {
          "x": 0.747759,
          "y": 180.8
        },
        {
          "x": 0.748101,
          "y": 180.4
        },
        {
          "x": 0.748913,
          "y": 181.4
        },
        {
          "x": 0.749401,
          "y": 182.9
        },
        {
          "x": 0.750194,
          "y": 184.8
        },
        {
          "x": 0.750476,
          "y": 184
        },
        {
          "x": 0.751301,
          "y": 186.2
        },
        {
          "x": 0.75183,
          "y": 190.1
        },
        {
          "x": 0.752605,
          "y": 188
        },
        {
          "x": 0.752978,
          "y": 196.5
        },
        {
          "x": 0.753984,
          "y": 191.5
        },
        {
          "x": 0.754394,
          "y": 195.8
        },
        {
          "x": 0.755241,
          "y": 200.5
        },
        {
          "x": 0.755495,
          "y": 201.9
        },
        {
          "x": 0.756357,
          "y": 206
        },
        {
          "x": 0.756871,
          "y": 210.7
        },
        {
          "x": 0.757561,
          "y": 215
        },
        {
          "x": 0.757962,
          "y": 219.2
        },
        {
          "x": 0.758259,
          "y": 215.3
        },
        {
          "x": 0.758475,
          "y": 217.6
        },
        {
          "x": 0.758883,
          "y": 217.9
        },
        {
          "x": 0.759306,
          "y": 214.9
        },
        {
          "x": 0.760202,
          "y": 208.8
        },
        {
          "x": 0.7605,
          "y": 206.9
        },
        {
          "x": 0.761453,
          "y": 206.1
        },
        {
          "x": 0.762064,
          "y": 212
        },
        {
          "x": 0.762871,
          "y": 216
        },
        {
          "x": 0.763319,
          "y": 215
        },
        {
          "x": 0.763686,
          "y": 225.7
        },
        {
          "x": 0.763934,
          "y": 221.4
        },
        {
          "x": 0.764249,
          "y": 224.1
        },
        {
          "x": 0.764833,
          "y": 222.6
        },
        {
          "x": 0.765735,
          "y": 216.8
        },
        {
          "x": 0.76611,
          "y": 215.7
        },
        {
          "x": 0.767096,
          "y": 213.5
        },
        {
          "x": 0.767737,
          "y": 217.7
        },
        {
          "x": 0.768655,
          "y": 222.4
        },
        {
          "x": 0.76905,
          "y": 221.4
        },
        {
          "x": 0.769514,
          "y": 228.1
        },
        {
          "x": 0.769787,
          "y": 226
        },
        {
          "x": 0.770133,
          "y": 231.5
        },
        {
          "x": 0.770447,
          "y": 235.6
        },
        {
          "x": 0.770724,
          "y": 238.2
        },
        {
          "x": 0.770994,
          "y": 235.6
        },
        {
          "x": 0.77126,
          "y": 237.7
        },
        {
          "x": 0.771708,
          "y": 229.5
        },
        {
          "x": 0.772076,
          "y": 230.9
        },
        {
          "x": 0.773043,
          "y": 230
        },
        {
          "x": 0.77368,
          "y": 233.3
        },
        {
          "x": 0.774548,
          "y": 236.9
        },
        {
          "x": 0.775051,
          "y": 240.5
        },
        {
          "x": 0.776037,
          "y": 238.8
        },
        {
          "x": 0.776636,
          "y": 243.9
        },
        {
          "x": 0.777556,
          "y": 246.3
        },
        {
          "x": 0.7779,
          "y": 246.1
        },
        {
          "x": 0.778798,
          "y": 245.8
        },
        {
          "x": 0.779364,
          "y": 243.9
        },
        {
          "x": 0.780268,
          "y": 248.5
        },
        {
          "x": 0.780612,
          "y": 242.8
        },
        {
          "x": 0.781535,
          "y": 248.7
        },
        {
          "x": 0.782059,
          "y": 246.2
        },
        {
          "x": 0.783263,
          "y": 252.1
        },
        {
          "x": 0.784147,
          "y": 246.9
        },
        {
          "x": 0.784692,
          "y": 250.7
        },
        {
          "x": 0.785413,
          "y": 253.3
        },
        {
          "x": 0.785789,
          "y": 255.4
        },
        {
          "x": 0.786587,
          "y": 260.3
        },
        {
          "x": 0.787076,
          "y": 256.9
        },
        {
          "x": 0.787805,
          "y": 248.1
        },
        {
          "x": 0.788033,
          "y": 247.9
        },
        {
          "x": 0.788887,
          "y": 229.5
        },
        {
          "x": 0.789381,
          "y": 226.3
        },
        {
          "x": 0.790153,
          "y": 218.2
        },
        {
          "x": 0.790538,
          "y": 221.3
        },
        {
          "x": 0.791491,
          "y": 215.4
        },
        {
          "x": 0.792081,
          "y": 205.1
        },
        {
          "x": 0.792943,
          "y": 204
        },
        {
          "x": 0.79326,
          "y": 209
        },
        {
          "x": 0.794608,
          "y": 199.4
        },
        {
          "x": 0.795202,
          "y": 195.2
        },
        {
          "x": 0.796467,
          "y": 189.3
        },
        {
          "x": 0.797099,
          "y": 188.4
        },
        {
          "x": 0.798512,
          "y": 191
        },
        {
            "x": 0.93,
            "y": 291
          },
          {
            "x": 1.05,
            "y": 301
          },
          {
            "x": 1.19,
            "y": 322
          },
          {
            "x": 1.21,
            "y": 311
          },
          {
            "x": 1.22,
            "y": 317
          },
          {
            "x": 1.23,
            "y": 322
          },
          {
            "x": 1.25,
            "y": 291
          },
          {
            "x": 1.27,
            "y": 309
          },
          {
            "x": 1.28,
            "y": 298
          },
          {
            "x": 1.35,
            "y": 326
          },
          {
            "x": 1.64,
            "y": 298
          },
          {
            "x": 1.73,
            "y": 312
          },
          {
            "x": 1.97,
            "y": 309
          },
          {
            "x": 2.09,
            "y": 329
          },
          {
            "x": 2.19,
            "y": 331
          },
          {
            "x": 2.3,
            "y": 337
          },
          {
            "x": 2.4,
            "y": 344
          },
          {
            "x": 2.59,
            "y": 333
          },
          {
            "x": 2.6,
            "y": 305
          },
          {
            "x": 2.72,
            "y": 317
          },
          {
            "x": 2.94,
            "y": 307
          },
          {
            "x": 3.08,
            "y": 338
          },
          {
            "x": 3.13,
            "y": 318
          },
          {
            "x": 3.32,
            "y": 336
          },
          {
            "x": 3.36,
            "y": 321
          },
          {
            "x": 3.46,
            "y": 339
          },
          {
            "x": 3.51,
            "y": 393
          },
          {
            "x": 3.52,
            "y": 346
          },
          {
            "x": 3.67,
            "y": 346
          },
          {
            "x": 3.85,
            "y": 327
          },
          {
            "x": 3.99,
            "y": 334
          },
          {
            "x": 4.11,
            "y": 348
          },
          {
            "x": 4.45,
            "y": 379
          },
          {
            "x": 4.78,
            "y": 361
          },
          {
            "x": 7.15,
            "y": 379
          },
          {
            "x": 14.1,
            "y": 341
          },
          {
            "x": 14.74,
            "y": 430
          },
          {
            "x": 15.84,
            "y": 402
          },
          {
            "x": 16.82,
            "y": 401
          },
          {
            "x": 18.26,
            "y": 412
          },
          {
            "x": 20.3,
            "y": 358
          },
          {
            "x": 22.94,
            "y": 462
          },
          {
            "x": 23.48,
            "y": 410
          },
          {
            "x": 25.3,
            "y": 416
          },
          {
            "x": 26.57,
            "y": 656
          },
          {
            "x": 27.85,
            "y": 712
          },
          {
            "x": 28.78,
            "y": 730
          },
          {
            "x": 29.21,
            "y": 854
          },
          {
            "x": 29.57,
            "y": 972
          },
          {
            "x": 29.64,
            "y": 895
          },
          {
            "x": 30.62,
            "y": 765
          },
          {
            "x": 31.27,
            "y": 725
          },
          {
            "x": 32.04,
            "y": 743
          },
          {
            "x": 32.58,
            "y": 756
          },
          {
            "x": 32.83,
            "y": 740
          },
          {
            "x": 32.91,
            "y": 723
          },
          {
            "x": 32.97,
            "y": 727
          },
          {
            "x": 33.45,
            "y": 767
          },
          {
            "x": 33.57,
            "y": 671
          },
          {
            "x": 33.69,
            "y": 930
          },
          {
            "x": 34.69,
            "y": 1038
          },
          {
            "x": 35.29,
            "y": 879
          },
          {
            "x": 35.56,
            "y": 919
          },
          {
            "x": 35.86,
            "y": 947
          },
          {
            "x": 36.09,
            "y": 1132
          },
          {
            "x": 36.81,
            "y": 1247
          },
          {
            "x": 37.1,
            "y": 939
          },
          {
            "x": 37.43,
            "y": 1042
          },
          {
            "x": 38.25,
            "y": 898
          },
          {
            "x": 38.55,
            "y": 798
          },
          {
            "x": 38.62,
            "y": 863
          },
      ]
    },
    {
      "title": "RCP8.5",
      "color": "#F09662",
      "data": [
        {
          "x": 0,
          "y": 408
        },
        {
          "x": 0.000081,
          "y": 936
        }
      ]
    }
  ]

function Co240mya2100({ data }) {
    return <div style={{ display: 'block', height: '300px', maxWidth: '640px' }}>
        <ResponsiveLine
            data={co240MillionToPresent}
            margin={{ top: 50, right: 10, bottom: 30, left: 40 }}
            colors={{ datum: 'color' }}
            xScale={{ type: 'linear', reverse: true }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickValues:[5,10,15,20,25,30,35],
                tickRotation: 0
            }}
            axisLeft={{
                orient: 'left',                
                tickValues:[0,200,400,600,800,1000,1200],
                tickRotation: 0
            }}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="PPM"
            pointLabelYOffset={-12}
            useMesh={true}
            animate={false}
            markers={[
                {
                    axis: 'y',
                    value: 450,
                    lineStyle: { stroke: 'purple', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px',
                    },
                },
                {
                    axis: 'y',
                    value: 550,
                    lineStyle: { stroke: 'teal', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px'
                    },
                },
                {
                    axis: 'y',
                    value: 936,
                    lineStyle: { stroke: 'red', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-left',
                    textStyle: {
                        fill: 'white',
                        fontSize: '12px'
                    },
                }
            ]}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 1
                        }
                      },
                },
                grid: {
                    line: {
                        stroke: "#89969F"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
        />
    </div>
}

export default Co240mya2100;