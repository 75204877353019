import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const powellMeadLevels = [
    {
      "title": "Powell Levels",
      "strokeWidth": 2,
      "color": '#63A2EC',
      "data": [
        // {
        //   "x": "1963-12-01",
        //   "y": -290.4,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-01-01",
        //   "y": -285.4,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-02-01",
        //   "y": -285.2,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-03-01",
        //   "y": -289.2,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-04-01",
        //   "y": -301.8,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-05-01",
        //   "y": -265.3,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-06-01",
        //   "y": -228.02,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-07-01",
        //   "y": -215.85,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-08-01",
        //   "y": -209.72,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-09-01",
        //   "y": -208.31,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-10-01",
        //   "y": -208.09,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-11-01",
        //   "y": -208.53,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1964-12-01",
        //   "y": -208.06,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-01-01",
        //   "y": -208.59,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-02-01",
        //   "y": -208.09,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-03-01",
        //   "y": -208.12,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-04-01",
        //   "y": -209.09,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-05-01",
        //   "y": -208.18,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-06-01",
        //   "y": -189.15,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-07-01",
        //   "y": -169.09,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-08-01",
        //   "y": -168.66,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-09-01",
        //   "y": -169.88,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-10-01",
        //   "y": -168.43,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-11-01",
        //   "y": -167.44,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1965-12-01",
        //   "y": -165.37,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-01-01",
        //   "y": -164.79,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-02-01",
        //   "y": -165.66,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-03-01",
        //   "y": -163.27,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-04-01",
        //   "y": -161.02,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-05-01",
        //   "y": -155.43,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-06-01",
        //   "y": -155.09,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-07-01",
        //   "y": -159.99,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-08-01",
        //   "y": -166,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-09-01",
        //   "y": -170.54,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-10-01",
        //   "y": -172.88,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-11-01",
        //   "y": -176.43,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1966-12-01",
        //   "y": -178.55,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-01-01",
        //   "y": -182.61,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-02-01",
        //   "y": -184.85,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-03-01",
        //   "y": -187.5,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-04-01",
        //   "y": -192.68,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-05-01",
        //   "y": -190.9,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-06-01",
        //   "y": -170.85,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-07-01",
        //   "y": -167.47,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-08-01",
        //   "y": -169.52,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-09-01",
        //   "y": -171.51,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-10-01",
        //   "y": -171.77,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-11-01",
        //   "y": -171.68,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1967-12-01",
        //   "y": -172.94,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-01-01",
        //   "y": -174.94,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-02-01",
        //   "y": -173.96,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-03-01",
        //   "y": -179.52,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-04-01",
        //   "y": -185.96,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-05-01",
        //   "y": -177.76,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-06-01",
        //   "y": -153,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-07-01",
        //   "y": -153.9,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-08-01",
        //   "y": -151.27,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-09-01",
        //   "y": -154.65,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-10-01",
        //   "y": -156.61,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-11-01",
        //   "y": -158.33,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1968-12-01",
        //   "y": -160.71,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-01-01",
        //   "y": -159.78,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-02-01",
        //   "y": -157.13,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-03-01",
        //   "y": -156.35,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-04-01",
        //   "y": -147.39,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-05-01",
        //   "y": -128.51,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-06-01",
        //   "y": -120.05,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-07-01",
        //   "y": -119.46,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-08-01",
        //   "y": -124.06,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-09-01",
        //   "y": -126.68,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-10-01",
        //   "y": -125.1,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-11-01",
        //   "y": -125.79,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1969-12-01",
        //   "y": -127.89,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-01-01",
        //   "y": -130.63,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-02-01",
        //   "y": -129.87,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-03-01",
        //   "y": -128.73,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-04-01",
        //   "y": -133.35,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-05-01",
        //   "y": -117.87,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-06-01",
        //   "y": -100.87,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-07-01",
        //   "y": -99.12,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-08-01",
        //   "y": -102.1,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-09-01",
        //   "y": -101.13,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-10-01",
        //   "y": -100.16,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-11-01",
        //   "y": -99.11,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1970-12-01",
        //   "y": -100.22,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-01-01",
        //   "y": -99.21,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-02-01",
        //   "y": -97.34,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-03-01",
        //   "y": -97.14,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-04-01",
        //   "y": -96.38,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-05-01",
        //   "y": -91.27,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-06-01",
        //   "y": -79.21,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-07-01",
        //   "y": -79.2,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-08-01",
        //   "y": -82.69,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-09-01",
        //   "y": -85.75,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-10-01",
        //   "y": -85.85,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-11-01",
        //   "y": -87.58,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1971-12-01",
        //   "y": -90.44,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-01-01",
        //   "y": -92.13,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-02-01",
        //   "y": -90.49,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-03-01",
        //   "y": -87.26,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-04-01",
        //   "y": -89.42,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-05-01",
        //   "y": -88.08,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-06-01",
        //   "y": -80.43,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-07-01",
        //   "y": -84.57,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-08-01",
        //   "y": -90.88,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-09-01",
        //   "y": -96.6,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-10-01",
        //   "y": -90.91,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-11-01",
        //   "y": -90.49,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1972-12-01",
        //   "y": -93.8,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-01-01",
        //   "y": -98.96,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-02-01",
        //   "y": -99.32,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-03-01",
        //   "y": -101.88,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-04-01",
        //   "y": -109.37,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-05-01",
        //   "y": -83.48,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-06-01",
        //   "y": -63.64,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-07-01",
        //   "y": -55.95,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-08-01",
        //   "y": -54.75,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-09-01",
        //   "y": -53.98,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-10-01",
        //   "y": -54.2,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-11-01",
        //   "y": -53.15,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1973-12-01",
        //   "y": -51.16,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-01-01",
        //   "y": -52.91,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-02-01",
        //   "y": -51.51,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-03-01",
        //   "y": -48.86,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-04-01",
        //   "y": -47.66,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-05-01",
        //   "y": -37.7,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-06-01",
        //   "y": -32.65,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-07-01",
        //   "y": -37.38,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-08-01",
        //   "y": -44.18,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-09-01",
        //   "y": -48.27,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-10-01",
        //   "y": -49.29,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-11-01",
        //   "y": -50.54,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1974-12-01",
        //   "y": -51.73,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-01-01",
        //   "y": -54.22,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-02-01",
        //   "y": -54.66,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-03-01",
        //   "y": -53.9,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-04-01",
        //   "y": -52.19,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-05-01",
        //   "y": -44.4,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-06-01",
        //   "y": -31.1,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-07-01",
        //   "y": -25.19,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-08-01",
        //   "y": -28.11,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-09-01",
        //   "y": -31.94,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-10-01",
        //   "y": -33.43,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-11-01",
        //   "y": -32.85,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1975-12-01",
        //   "y": -32.12,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-01-01",
        //   "y": -33.23,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-02-01",
        //   "y": -34.57,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-03-01",
        //   "y": -35.3,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-04-01",
        //   "y": -35.83,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-05-01",
        //   "y": -32.24,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-06-01",
        //   "y": -27.84,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-07-01",
        //   "y": -29.61,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-08-01",
        //   "y": -32.56,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-09-01",
        //   "y": -36,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-10-01",
        //   "y": -38.76,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-11-01",
        //   "y": -42.11,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1976-12-01",
        //   "y": -44.69,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-01-01",
        //   "y": -48.22,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-02-01",
        //   "y": -48.47,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-03-01",
        //   "y": -48.8,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-04-01",
        //   "y": -47.37,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-05-01",
        //   "y": -45.73,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-06-01",
        //   "y": -46.35,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-07-01",
        //   "y": -50.64,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-08-01",
        //   "y": -57.52,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-09-01",
        //   "y": -63.3,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-10-01",
        //   "y": -64.35,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-11-01",
        //   "y": -65.38,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1977-12-01",
        //   "y": -69.95,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-01-01",
        //   "y": -74.98,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-02-01",
        //   "y": -77.29,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-03-01",
        //   "y": -77.31,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-04-01",
        //   "y": -73.15,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-05-01",
        //   "y": -64.38,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-06-01",
        //   "y": -50.35,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-07-01",
        //   "y": -47.71,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-08-01",
        //   "y": -54.2,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-09-01",
        //   "y": -59.83,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-10-01",
        //   "y": -62.72,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-11-01",
        //   "y": -63.92,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1978-12-01",
        //   "y": -67.17,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-01-01",
        //   "y": -71.16,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-02-01",
        //   "y": -72.01,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-03-01",
        //   "y": -64.97,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-04-01",
        //   "y": -53.72,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-05-01",
        //   "y": -36.24,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-06-01",
        //   "y": -19.6,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-07-01",
        //   "y": -15.68,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-08-01",
        //   "y": -19.21,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-09-01",
        //   "y": -21.9,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-10-01",
        //   "y": -23.87,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-11-01",
        //   "y": -26.5,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1979-12-01",
        //   "y": -27.32,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-01-01",
        //   "y": -26.97,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-02-01",
        //   "y": -25.74,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-03-01",
        //   "y": -24.46,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-04-01",
        //   "y": -22.36,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-05-01",
        //   "y": -8.28,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-06-01",
        //   "y": 0.5,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-07-01",
        //   "y": -3.6,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-08-01",
        //   "y": -9.52,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-09-01",
        //   "y": -12.22,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-10-01",
        //   "y": -14.53,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-11-01",
        //   "y": -17.19,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1980-12-01",
        //   "y": -18.72,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-01-01",
        //   "y": -20.43,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-02-01",
        //   "y": -21.91,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-03-01",
        //   "y": -22.25,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-04-01",
        //   "y": -22.75,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-05-01",
        //   "y": -22.24,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-06-01",
        //   "y": -19.39,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-07-01",
        //   "y": -22.21,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-08-01",
        //   "y": -26.56,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-09-01",
        //   "y": -28.04,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-10-01",
        //   "y": -28.06,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-11-01",
        //   "y": -29.32,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1981-12-01",
        //   "y": -32.53,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-01-01",
        //   "y": -36.25,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-02-01",
        //   "y": -37.25,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-03-01",
        //   "y": -36.04,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-04-01",
        //   "y": -34.41,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-05-01",
        //   "y": -25.41,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-06-01",
        //   "y": -15.08,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-07-01",
        //   "y": -12.65,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-08-01",
        //   "y": -13.36,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-09-01",
        //   "y": -12.73,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-10-01",
        //   "y": -12.35,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-11-01",
        //   "y": -13.74,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1982-12-01",
        //   "y": -15.26,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-01-01",
        //   "y": -16.68,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-02-01",
        //   "y": -17.87,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-03-01",
        //   "y": -15.38,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-04-01",
        //   "y": -14.2,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-05-01",
        //   "y": -4.92,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-06-01",
        //   "y": 7.13,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-07-01",
        //   "y": 7.45,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-08-01",
        //   "y": 4.42,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-09-01",
        //   "y": -1.14,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-10-01",
        //   "y": -5.77,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-11-01",
        //   "y": -10.18,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1983-12-01",
        //   "y": -14.76,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-01-01",
        //   "y": -19.47,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-02-01",
        //   "y": -22.98,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-03-01",
        //   "y": -25.9,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-04-01",
        //   "y": -25.83,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-05-01",
        //   "y": -12.17,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-06-01",
        //   "y": 1.28,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-07-01",
        //   "y": 2.15,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-08-01",
        //   "y": 0.36,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-09-01",
        //   "y": -4.09,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-10-01",
        //   "y": -6.71,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-11-01",
        //   "y": -10.8,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1984-12-01",
        //   "y": -15.24,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-01-01",
        //   "y": -19.5,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-02-01",
        //   "y": -23.88,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-03-01",
        //   "y": -23.53,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-04-01",
        //   "y": -15.41,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-05-01",
        //   "y": -4.42,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-06-01",
        //   "y": 0.11,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-07-01",
        //   "y": -2.88,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-08-01",
        //   "y": -9.26,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-09-01",
        //   "y": -14.34,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-10-01",
        //   "y": -13.77,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-11-01",
        //   "y": -13.53,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1985-12-01",
        //   "y": -12.81,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-01-01",
        //   "y": -14.41,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-02-01",
        //   "y": -16.43,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-03-01",
        //   "y": -19.32,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-04-01",
        //   "y": -17.94,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-05-01",
        //   "y": -14.91,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-06-01",
        //   "y": -5.01,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-07-01",
        //   "y": -0.29,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-08-01",
        //   "y": -5.28,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-09-01",
        //   "y": -10.38,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-10-01",
        //   "y": -10.78,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-11-01",
        //   "y": -11.94,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1986-12-01",
        //   "y": -15.64,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-01-01",
        //   "y": -20.93,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-02-01",
        //   "y": -22.35,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-03-01",
        //   "y": -20.58,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-04-01",
        //   "y": -15.55,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-05-01",
        //   "y": -5.43,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-06-01",
        //   "y": -1.63,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-07-01",
        //   "y": -3.91,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-08-01",
        //   "y": -8.39,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-09-01",
        //   "y": -12.05,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-10-01",
        //   "y": -12.21,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-11-01",
        //   "y": -12.57,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1987-12-01",
        //   "y": -14.7,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-01-01",
        //   "y": -16.96,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-02-01",
        //   "y": -18.25,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-03-01",
        //   "y": -18.31,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-04-01",
        //   "y": -16.39,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-05-01",
        //   "y": -11.99,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-06-01",
        //   "y": -7.1,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-07-01",
        //   "y": -9.02,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-08-01",
        //   "y": -11.92,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-09-01",
        //   "y": -14.39,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-10-01",
        //   "y": -15.81,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-11-01",
        //   "y": -17.9,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1988-12-01",
        //   "y": -20.24,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-01-01",
        //   "y": -23.41,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-02-01",
        //   "y": -25.39,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-03-01",
        //   "y": -25.4,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-04-01",
        //   "y": -24.15,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-05-01",
        //   "y": -22.16,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-06-01",
        //   "y": -22.33,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-07-01",
        //   "y": -26.41,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-08-01",
        //   "y": -30.24,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-09-01",
        //   "y": -34.8,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-10-01",
        //   "y": -36.56,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-11-01",
        //   "y": -38.66,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        // {
        //   "x": "1989-12-01",
        //   "y": -41.54,
        //   "lake": "Powell",
        //   "color": "#63A2EC"
        // },
        {
          "x": "1990-01-01",
          "y": -44.4,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-02-01",
          "y": -46.83,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-03-01",
          "y": -48.97,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-04-01",
          "y": -50.49,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-05-01",
          "y": -51.21,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-06-01",
          "y": -49.49,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-07-01",
          "y": -52.69,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-08-01",
          "y": -58.94,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-09-01",
          "y": -62.39,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-10-01",
          "y": -62.61,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-11-01",
          "y": -63.76,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1990-12-01",
          "y": -66.52,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-01-01",
          "y": -69.29,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-02-01",
          "y": -71,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-03-01",
          "y": -72.25,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-04-01",
          "y": -72.39,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-05-01",
          "y": -67.7,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-06-01",
          "y": -60.98,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-07-01",
          "y": -64.41,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-08-01",
          "y": -68.78,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-09-01",
          "y": -71.38,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-10-01",
          "y": -72.76,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-11-01",
          "y": -73.41,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1991-12-01",
          "y": -75.45,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-01-01",
          "y": -78.74,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-02-01",
          "y": -80.17,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-03-01",
          "y": -80.61,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-04-01",
          "y": -78.59,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-05-01",
          "y": -69.27,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-06-01",
          "y": -66.26,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-07-01",
          "y": -69.46,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-08-01",
          "y": -73.84,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-09-01",
          "y": -76.99,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-10-01",
          "y": -78.79,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-11-01",
          "y": -80.56,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1992-12-01",
          "y": -84.09,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-01-01",
          "y": -86.32,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-02-01",
          "y": -87.4,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-03-01",
          "y": -83.34,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-04-01",
          "y": -76.3,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-05-01",
          "y": -53.33,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-06-01",
          "y": -33.66,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-07-01",
          "y": -32.27,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-08-01",
          "y": -35.61,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-09-01",
          "y": -37.48,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-10-01",
          "y": -37.74,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-11-01",
          "y": -38.47,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1993-12-01",
          "y": -40.69,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-01-01",
          "y": -42.85,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-02-01",
          "y": -44.96,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-03-01",
          "y": -45.48,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-04-01",
          "y": -45.99,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-05-01",
          "y": -38.97,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-06-01",
          "y": -34.34,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-07-01",
          "y": -38.24,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-08-01",
          "y": -43.1,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-09-01",
          "y": -45.58,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-10-01",
          "y": -46.27,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-11-01",
          "y": -47.6,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1994-12-01",
          "y": -49.96,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-01-01",
          "y": -53.02,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-02-01",
          "y": -55.26,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-03-01",
          "y": -54.79,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-04-01",
          "y": -53.48,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-05-01",
          "y": -41.09,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-06-01",
          "y": -19.72,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-07-01",
          "y": -6.41,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-08-01",
          "y": -8.57,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-09-01",
          "y": -12.9,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-10-01",
          "y": -15.42,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-11-01",
          "y": -17.12,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1995-12-01",
          "y": -19.08,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-01-01",
          "y": -22.15,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-02-01",
          "y": -23.92,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-03-01",
          "y": -27.26,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-04-01",
          "y": -27.5,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-05-01",
          "y": -19.1,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-06-01",
          "y": -12.25,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-07-01",
          "y": -13.51,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-08-01",
          "y": -17.8,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-09-01",
          "y": -20.71,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-10-01",
          "y": -22,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-11-01",
          "y": -22.51,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1996-12-01",
          "y": -25.29,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-01-01",
          "y": -28.9,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-02-01",
          "y": -33.78,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-03-01",
          "y": -36.78,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-04-01",
          "y": -35.36,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-05-01",
          "y": -21.16,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-06-01",
          "y": -5.92,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-07-01",
          "y": -6.5,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-08-01",
          "y": -9.09,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-09-01",
          "y": -9.68,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-10-01",
          "y": -11.3,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-11-01",
          "y": -14.15,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1997-12-01",
          "y": -17.7,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-01-01",
          "y": -21.07,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-02-01",
          "y": -24.36,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-03-01",
          "y": -26.88,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-04-01",
          "y": -23.55,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-05-01",
          "y": -10.7,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-06-01",
          "y": -3.73,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-07-01",
          "y": -4.76,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-08-01",
          "y": -9.26,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-09-01",
          "y": -12.29,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-10-01",
          "y": -13.65,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-11-01",
          "y": -14.46,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1998-12-01",
          "y": -17.3,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-01-01",
          "y": -19.41,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-02-01",
          "y": -21.17,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-03-01",
          "y": -22.36,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-04-01",
          "y": -22.52,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-05-01",
          "y": -17.93,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-06-01",
          "y": -6.43,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-07-01",
          "y": -5.96,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-08-01",
          "y": -6.51,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-09-01",
          "y": -8.41,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-10-01",
          "y": -11.43,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-11-01",
          "y": -15.18,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "1999-12-01",
          "y": -18.73,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-01-01",
          "y": -20.83,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-02-01",
          "y": -22.14,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-03-01",
          "y": -23.04,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-04-01",
          "y": -24.05,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-05-01",
          "y": -21.47,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-06-01",
          "y": -16.6,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-07-01",
          "y": -17.88,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-08-01",
          "y": -19.68,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-09-01",
          "y": -22.2,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-10-01",
          "y": -23.5,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-11-01",
          "y": -26.68,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2000-12-01",
          "y": -30.11,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-01-01",
          "y": -33.73,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-02-01",
          "y": -35.99,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-03-01",
          "y": -37.18,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-04-01",
          "y": -37.51,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-05-01",
          "y": -30.3,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-06-01",
          "y": -26.98,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-07-01",
          "y": -30.54,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-08-01",
          "y": -33.78,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-09-01",
          "y": -35.16,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-10-01",
          "y": -37.65,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-11-01",
          "y": -39.86,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2001-12-01",
          "y": -43.83,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-01-01",
          "y": -47.67,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-02-01",
          "y": -50.21,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-03-01",
          "y": -52.33,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-04-01",
          "y": -54.15,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-05-01",
          "y": -55.54,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-06-01",
          "y": -59.09,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-07-01",
          "y": -65.76,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-08-01",
          "y": -72.55,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-09-01",
          "y": -73.47,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-10-01",
          "y": -75.28,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-11-01",
          "y": -76.75,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2002-12-01",
          "y": -79.9,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-01-01",
          "y": -84.72,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-02-01",
          "y": -88.98,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-03-01",
          "y": -92.87,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-04-01",
          "y": -94.9,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-05-01",
          "y": -89.74,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-06-01",
          "y": -83.8,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-07-01",
          "y": -89.37,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-08-01",
          "y": -95.79,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-09-01",
          "y": -96.27,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-10-01",
          "y": -98.07,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-11-01",
          "y": -99.52,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2003-12-01",
          "y": -102.78,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-01-01",
          "y": -108.2,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-02-01",
          "y": -113.16,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-03-01",
          "y": -117.22,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-04-01",
          "y": -117.07,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-05-01",
          "y": -112.83,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-06-01",
          "y": -113.84,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-07-01",
          "y": -120.3,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-08-01",
          "y": -127.9,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-09-01",
          "y": -129.23,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-10-01",
          "y": -129.5,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-11-01",
          "y": -132.72,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2004-12-01",
          "y": -135.58,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-01-01",
          "y": -137.93,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-02-01",
          "y": -140.77,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-03-01",
          "y": -144.1,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-04-01",
          "y": -137.19,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-05-01",
          "y": -113.47,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-06-01",
          "y": -93.72,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-07-01",
          "y": -93.13,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-08-01",
          "y": -97.17,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-09-01",
          "y": -98.03,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-10-01",
          "y": -97.23,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-11-01",
          "y": -97.64,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2005-12-01",
          "y": -101.83,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-01-01",
          "y": -105.79,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-02-01",
          "y": -110.3,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-03-01",
          "y": -111.29,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-04-01",
          "y": -107.01,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-05-01",
          "y": -94.75,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-06-01",
          "y": -89.65,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-07-01",
          "y": -93.15,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-08-01",
          "y": -97.22,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-09-01",
          "y": -98.26,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-10-01",
          "y": -92.04,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-11-01",
          "y": -93.15,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2006-12-01",
          "y": -96.61,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-01-01",
          "y": -100.49,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-02-01",
          "y": -102.09,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-03-01",
          "y": -101.19,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-04-01",
          "y": -99.65,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-05-01",
          "y": -90.39,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-06-01",
          "y": -88.5,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-07-01",
          "y": -92.65,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-08-01",
          "y": -96.42,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-09-01",
          "y": -98.13,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-10-01",
          "y": -99.38,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-11-01",
          "y": -101.37,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2007-12-01",
          "y": -105.36,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-01-01",
          "y": -109.34,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-02-01",
          "y": -109.34,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-03-01",
          "y": -110.23,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-04-01",
          "y": -105.91,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-05-01",
          "y": -89.19,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-06-01",
          "y": -68.95,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-07-01",
          "y": -67,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-08-01",
          "y": -70.45,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-09-01",
          "y": -73.1,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-10-01",
          "y": -76.18,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-11-01",
          "y": -78.1,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2008-12-01",
          "y": -82.11,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-01-01",
          "y": -85.83,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-02-01",
          "y": -87.95,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-03-01",
          "y": -89.57,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-04-01",
          "y": -88.74,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-05-01",
          "y": -70.91,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-06-01",
          "y": -59.51,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-07-01",
          "y": -58.86,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-08-01",
          "y": -62.5,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-09-01",
          "y": -64.63,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-10-01",
          "y": -66.48,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-11-01",
          "y": -68.9,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2009-12-01",
          "y": -73.78,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-01-01",
          "y": -77.86,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-02-01",
          "y": -79.84,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-03-01",
          "y": -80.59,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-04-01",
          "y": -79.5,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-05-01",
          "y": -74.04,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-06-01",
          "y": -61.18,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-07-01",
          "y": -63.48,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-08-01",
          "y": -65.45,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-09-01",
          "y": -66.34,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-10-01",
          "y": -65.92,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-11-01",
          "y": -69.69,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2010-12-01",
          "y": -73.46,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-01-01",
          "y": -79.45,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-02-01",
          "y": -85.05,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-03-01",
          "y": -89.27,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-04-01",
          "y": -88.07,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-05-01",
          "y": -76.87,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-06-01",
          "y": -51.02,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-07-01",
          "y": -39.14,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-08-01",
          "y": -44.66,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-09-01",
          "y": -46.99,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-10-01",
          "y": -49.73,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-11-01",
          "y": -54.33,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2011-12-01",
          "y": -60.25,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-01-01",
          "y": -63.09,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-02-01",
          "y": -64.72,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-03-01",
          "y": -64.67,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-04-01",
          "y": -64.24,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-05-01",
          "y": -63.17,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-06-01",
          "y": -66.1,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-07-01",
          "y": -71.55,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-08-01",
          "y": -76.38,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-09-01",
          "y": -78.44,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-10-01",
          "y": -80.54,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-11-01",
          "y": -84.9,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2012-12-01",
          "y": -90.18,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-01-01",
          "y": -95.58,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-02-01",
          "y": -98.53,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-03-01",
          "y": -101.04,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-04-01",
          "y": -103.47,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-05-01",
          "y": -100.56,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-06-01",
          "y": -99.93,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-07-01",
          "y": -105.83,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-08-01",
          "y": -110.36,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-09-01",
          "y": -108.75,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-10-01",
          "y": -109.12,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-11-01",
          "y": -112.1,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2013-12-01",
          "y": -115.57,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-01-01",
          "y": -121.31,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-02-01",
          "y": -124.45,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-03-01",
          "y": -125.24,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-04-01",
          "y": -122.44,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-05-01",
          "y": -110.62,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-06-01",
          "y": -90.81,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-07-01",
          "y": -91.95,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-08-01",
          "y": -94.18,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-09-01",
          "y": -94.47,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-10-01",
          "y": -94.43,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-11-01",
          "y": -98.13,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2014-12-01",
          "y": -102.25,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-01-01",
          "y": -106.43,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-02-01",
          "y": -107.77,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-03-01",
          "y": -108.98,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-04-01",
          "y": -109.82,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-05-01",
          "y": -102.73,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-06-01",
          "y": -86.46,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-07-01",
          "y": -87.38,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-08-01",
          "y": -90.93,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-09-01",
          "y": -93.99,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-10-01",
          "y": -93.56,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-11-01",
          "y": -94.53,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2015-12-01",
          "y": -99.2,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-01-01",
          "y": -103.42,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-02-01",
          "y": -105.59,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-03-01",
          "y": -107.82,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-04-01",
          "y": -107.88,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-05-01",
          "y": -96.13,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-06-01",
          "y": -79.99,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-07-01",
          "y": -81.78,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-08-01",
          "y": -86.45,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-09-01",
          "y": -89.07,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-10-01",
          "y": -90.52,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-11-01",
          "y": -94.19,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2016-12-01",
          "y": -99.51,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-01-01",
          "y": -104.14,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-02-01",
          "y": -105.67,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-03-01",
          "y": -104.09,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-04-01",
          "y": -95.86,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-05-01",
          "y": -80.91,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-06-01",
          "y": -65.11,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-07-01",
          "y": -65.31,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-08-01",
          "y": -69.12,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-09-01",
          "y": -71.69,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-10-01",
          "y": -72.91,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-11-01",
          "y": -74.71,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2017-12-01",
          "y": -77.15,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-01-01",
          "y": -80.86,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-02-01",
          "y": -83.98,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-03-01",
          "y": -87.77,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-04-01",
          "y": -90.61,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-05-01",
          "y": -88.46,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-06-01",
          "y": -90.02,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-07-01",
          "y": -96.2,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-08-01",
          "y": -102.88,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-09-01",
          "y": -107.72,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-10-01",
          "y": -109.54,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-11-01",
          "y": -113.5,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2018-12-01",
          "y": -118.15,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-01-01",
          "y": -123.66,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-02-01",
          "y": -128.11,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-03-01",
          "y": -130.72,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-04-01",
          "y": -128.88,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-05-01",
          "y": -115.35,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-06-01",
          "y": -88.18,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-07-01",
          "y": -78.4,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-08-01",
          "y": -81.45,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-09-01",
          "y": -84.64,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-10-01",
          "y": -87.01,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-11-01",
          "y": -88.77,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2019-12-01",
          "y": -91.26,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-01-01",
          "y": -94.52,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-02-01",
          "y": -97.28,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-03-01",
          "y": -99.29,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-04-01",
          "y": -100.68,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-05-01",
          "y": -94.95,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-06-01",
          "y": -89.38,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-07-01",
          "y": -93.75,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-08-01",
          "y": -100.28,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-09-01",
          "y": -104.02,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-10-01",
          "y": -108.28,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-11-01",
          "y": -112.28,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2020-12-01",
          "y": -117.79,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2021-01-01",
          "y": -123.55,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2021-02-01",
          "y": -128.54,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2021-03-01",
          "y": -133.29,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2021-04-01",
          "y": -137.63,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2021-05-01",
          "y": -139.43,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2021-06-01",
          "y": -139.94,
          "lake": "Powell",
          "color": "#63A2EC"
        },
        {
          "x": "2021-07-01",
          "y": -146.12,
          "lake": "Powell",
          "color": "#63A2EC"
        }
      ]
    },
    {
      "title": "Mead Levels",
      "strokeWidth": 2,
      "color": '#E7A744',
      "data": [
        {
          "x": "2021-07-01",
          "y": -164.35,
          "lake": "Mead",
          "color": "#E7A749"
        },
        {
          "x": "2021-06-01",
          "y": -163.23,
          "lake": "Mead",
          "color": "#E7A750"
        },
        {
          "x": "2021-05-01",
          "y": -158.5,
          "lake": "Mead",
          "color": "#E7A751"
        },
        {
          "x": "2021-04-01",
          "y": -152.7,
          "lake": "Mead",
          "color": "#E7A752"
        },
        {
          "x": "2021-03-01",
          "y": -147.61,
          "lake": "Mead",
          "color": "#E7A753"
        },
        {
          "x": "2021-02-01",
          "y": -144.74,
          "lake": "Mead",
          "color": "#E7A754"
        },
        {
          "x": "2021-01-01",
          "y": -146.05,
          "lake": "Mead",
          "color": "#E7A755"
        },
        {
          "x": "2020-12-01",
          "y": -148.28,
          "lake": "Mead",
          "color": "#E7A756"
        },
        {
          "x": "2020-11-01",
          "y": -150.93,
          "lake": "Mead",
          "color": "#E7A757"
        },
        {
          "x": "2020-10-01",
          "y": -150.12,
          "lake": "Mead",
          "color": "#E7A758"
        },
        {
          "x": "2020-09-01",
          "y": -148.79,
          "lake": "Mead",
          "color": "#E7A759"
        },
        {
          "x": "2020-08-01",
          "y": -147.96,
          "lake": "Mead",
          "color": "#E7A760"
        },
        {
          "x": "2020-07-01",
          "y": -147.37,
          "lake": "Mead",
          "color": "#E7A761"
        },
        {
          "x": "2020-06-01",
          "y": -144.93,
          "lake": "Mead",
          "color": "#E7A762"
        },
        {
          "x": "2020-05-01",
          "y": -140.68,
          "lake": "Mead",
          "color": "#E7A763"
        },
        {
          "x": "2020-04-01",
          "y": -135.61,
          "lake": "Mead",
          "color": "#E7A764"
        },
        {
          "x": "2020-03-01",
          "y": -133.41,
          "lake": "Mead",
          "color": "#E7A765"
        },
        {
          "x": "2020-02-01",
          "y": -135.73,
          "lake": "Mead",
          "color": "#E7A766"
        },
        {
          "x": "2020-01-01",
          "y": -137.32,
          "lake": "Mead",
          "color": "#E7A767"
        },
        {
          "x": "2019-12-01",
          "y": -141.51,
          "lake": "Mead",
          "color": "#E7A768"
        },
        {
          "x": "2019-11-01",
          "y": -148.15,
          "lake": "Mead",
          "color": "#E7A769"
        },
        {
          "x": "2019-10-01",
          "y": -149.39,
          "lake": "Mead",
          "color": "#E7A770"
        },
        {
          "x": "2019-09-01",
          "y": -149,
          "lake": "Mead",
          "color": "#E7A771"
        },
        {
          "x": "2019-08-01",
          "y": -148.55,
          "lake": "Mead",
          "color": "#E7A772"
        },
        {
          "x": "2019-07-01",
          "y": -149.18,
          "lake": "Mead",
          "color": "#E7A773"
        },
        {
          "x": "2019-06-01",
          "y": -147.29,
          "lake": "Mead",
          "color": "#E7A774"
        },
        {
          "x": "2019-05-01",
          "y": -145.52,
          "lake": "Mead",
          "color": "#E7A775"
        },
        {
          "x": "2019-04-01",
          "y": -143.05,
          "lake": "Mead",
          "color": "#E7A776"
        },
        {
          "x": "2019-03-01",
          "y": -141.76,
          "lake": "Mead",
          "color": "#E7A777"
        },
        {
          "x": "2019-02-01",
          "y": -144.03,
          "lake": "Mead",
          "color": "#E7A778"
        },
        {
          "x": "2019-01-01",
          "y": -146.25,
          "lake": "Mead",
          "color": "#E7A779"
        },
        {
          "x": "2018-12-01",
          "y": -150.54,
          "lake": "Mead",
          "color": "#E7A780"
        },
        {
          "x": "2018-11-01",
          "y": -153.68,
          "lake": "Mead",
          "color": "#E7A781"
        },
        {
          "x": "2018-10-01",
          "y": -153.48,
          "lake": "Mead",
          "color": "#E7A782"
        },
        {
          "x": "2018-09-01",
          "y": -153.71,
          "lake": "Mead",
          "color": "#E7A783"
        },
        {
          "x": "2018-08-01",
          "y": -153.12,
          "lake": "Mead",
          "color": "#E7A784"
        },
        {
          "x": "2018-07-01",
          "y": -154.57,
          "lake": "Mead",
          "color": "#E7A785"
        },
        {
          "x": "2018-06-01",
          "y": -155.19,
          "lake": "Mead",
          "color": "#E7A786"
        },
        {
          "x": "2018-05-01",
          "y": -152,
          "lake": "Mead",
          "color": "#E7A787"
        },
        {
          "x": "2018-04-01",
          "y": -147.51,
          "lake": "Mead",
          "color": "#E7A788"
        },
        {
          "x": "2018-03-01",
          "y": -143.89,
          "lake": "Mead",
          "color": "#E7A789"
        },
        {
          "x": "2018-02-01",
          "y": -143.79,
          "lake": "Mead",
          "color": "#E7A790"
        },
        {
          "x": "2018-01-01",
          "y": -144.5,
          "lake": "Mead",
          "color": "#E7A791"
        },
        {
          "x": "2017-12-01",
          "y": -149.48,
          "lake": "Mead",
          "color": "#E7A792"
        },
        {
          "x": "2017-11-01",
          "y": -151.05,
          "lake": "Mead",
          "color": "#E7A793"
        },
        {
          "x": "2017-10-01",
          "y": -149.7,
          "lake": "Mead",
          "color": "#E7A794"
        },
        {
          "x": "2017-09-01",
          "y": -149.95,
          "lake": "Mead",
          "color": "#E7A795"
        },
        {
          "x": "2017-08-01",
          "y": -150.56,
          "lake": "Mead",
          "color": "#E7A796"
        },
        {
          "x": "2017-07-01",
          "y": -152.97,
          "lake": "Mead",
          "color": "#E7A797"
        },
        {
          "x": "2017-06-01",
          "y": -152.48,
          "lake": "Mead",
          "color": "#E7A798"
        },
        {
          "x": "2017-05-01",
          "y": -150.44,
          "lake": "Mead",
          "color": "#E7A799"
        },
        {
          "x": "2017-04-01",
          "y": -147.11,
          "lake": "Mead",
          "color": "#E7A800"
        },
        {
          "x": "2017-03-01",
          "y": -143.74,
          "lake": "Mead",
          "color": "#E7A801"
        },
        {
          "x": "2017-02-01",
          "y": -142.22,
          "lake": "Mead",
          "color": "#E7A802"
        },
        {
          "x": "2017-01-01",
          "y": -145.92,
          "lake": "Mead",
          "color": "#E7A803"
        },
        {
          "x": "2016-12-01",
          "y": -151.18,
          "lake": "Mead",
          "color": "#E7A804"
        },
        {
          "x": "2016-11-01",
          "y": -155.45,
          "lake": "Mead",
          "color": "#E7A805"
        },
        {
          "x": "2016-10-01",
          "y": -155.66,
          "lake": "Mead",
          "color": "#E7A806"
        },
        {
          "x": "2016-09-01",
          "y": -156.77,
          "lake": "Mead",
          "color": "#E7A807"
        },
        {
          "x": "2016-08-01",
          "y": -156.83,
          "lake": "Mead",
          "color": "#E7A808"
        },
        {
          "x": "2016-07-01",
          "y": -159.25,
          "lake": "Mead",
          "color": "#E7A809"
        },
        {
          "x": "2016-06-01",
          "y": -160.36,
          "lake": "Mead",
          "color": "#E7A810"
        },
        {
          "x": "2016-05-01",
          "y": -158.2,
          "lake": "Mead",
          "color": "#E7A811"
        },
        {
          "x": "2016-04-01",
          "y": -155.87,
          "lake": "Mead",
          "color": "#E7A812"
        },
        {
          "x": "2016-03-01",
          "y": -151.55,
          "lake": "Mead",
          "color": "#E7A813"
        },
        {
          "x": "2016-02-01",
          "y": -147.83,
          "lake": "Mead",
          "color": "#E7A814"
        },
        {
          "x": "2016-01-01",
          "y": -148.32,
          "lake": "Mead",
          "color": "#E7A815"
        },
        {
          "x": "2015-12-01",
          "y": -151.09,
          "lake": "Mead",
          "color": "#E7A816"
        },
        {
          "x": "2015-11-01",
          "y": -153.77,
          "lake": "Mead",
          "color": "#E7A817"
        },
        {
          "x": "2015-10-01",
          "y": -153.01,
          "lake": "Mead",
          "color": "#E7A818"
        },
        {
          "x": "2015-09-01",
          "y": -153.9,
          "lake": "Mead",
          "color": "#E7A819"
        },
        {
          "x": "2015-08-01",
          "y": -153.69,
          "lake": "Mead",
          "color": "#E7A820"
        },
        {
          "x": "2015-07-01",
          "y": -153.85,
          "lake": "Mead",
          "color": "#E7A821"
        },
        {
          "x": "2015-06-01",
          "y": -156.92,
          "lake": "Mead",
          "color": "#E7A822"
        },
        {
          "x": "2015-05-01",
          "y": -155.43,
          "lake": "Mead",
          "color": "#E7A823"
        },
        {
          "x": "2015-04-01",
          "y": -152.97,
          "lake": "Mead",
          "color": "#E7A824"
        },
        {
          "x": "2015-03-01",
          "y": -147.13,
          "lake": "Mead",
          "color": "#E7A825"
        },
        {
          "x": "2015-02-01",
          "y": -143.02,
          "lake": "Mead",
          "color": "#E7A826"
        },
        {
          "x": "2015-01-01",
          "y": -143.49,
          "lake": "Mead",
          "color": "#E7A827"
        },
        {
          "x": "2014-12-01",
          "y": -144.21,
          "lake": "Mead",
          "color": "#E7A828"
        },
        {
          "x": "2014-11-01",
          "y": -148.43,
          "lake": "Mead",
          "color": "#E7A829"
        },
        {
          "x": "2014-10-01",
          "y": -149.21,
          "lake": "Mead",
          "color": "#E7A830"
        },
        {
          "x": "2014-09-01",
          "y": -150.67,
          "lake": "Mead",
          "color": "#E7A831"
        },
        {
          "x": "2014-08-01",
          "y": -150.45,
          "lake": "Mead",
          "color": "#E7A832"
        },
        {
          "x": "2014-07-01",
          "y": -151.4,
          "lake": "Mead",
          "color": "#E7A833"
        },
        {
          "x": "2014-06-01",
          "y": -149.34,
          "lake": "Mead",
          "color": "#E7A834"
        },
        {
          "x": "2014-05-01",
          "y": -144.54,
          "lake": "Mead",
          "color": "#E7A835"
        },
        {
          "x": "2014-04-01",
          "y": -137.45,
          "lake": "Mead",
          "color": "#E7A836"
        },
        {
          "x": "2014-03-01",
          "y": -130.29,
          "lake": "Mead",
          "color": "#E7A837"
        },
        {
          "x": "2014-02-01",
          "y": -124.06,
          "lake": "Mead",
          "color": "#E7A838"
        },
        {
          "x": "2014-01-01",
          "y": -123.25,
          "lake": "Mead",
          "color": "#E7A839"
        },
        {
          "x": "2013-12-01",
          "y": -125.27,
          "lake": "Mead",
          "color": "#E7A840"
        },
        {
          "x": "2013-11-01",
          "y": -125.64,
          "lake": "Mead",
          "color": "#E7A841"
        },
        {
          "x": "2013-10-01",
          "y": -127.96,
          "lake": "Mead",
          "color": "#E7A842"
        },
        {
          "x": "2013-09-01",
          "y": -125.08,
          "lake": "Mead",
          "color": "#E7A843"
        },
        {
          "x": "2013-08-01",
          "y": -125.87,
          "lake": "Mead",
          "color": "#E7A844"
        },
        {
          "x": "2013-07-01",
          "y": -126.08,
          "lake": "Mead",
          "color": "#E7A845"
        },
        {
          "x": "2013-06-01",
          "y": -126.02,
          "lake": "Mead",
          "color": "#E7A846"
        },
        {
          "x": "2013-05-01",
          "y": -123.64,
          "lake": "Mead",
          "color": "#E7A847"
        },
        {
          "x": "2013-04-01",
          "y": -119.09,
          "lake": "Mead",
          "color": "#E7A848"
        },
        {
          "x": "2013-03-01",
          "y": -113.41,
          "lake": "Mead",
          "color": "#E7A849"
        },
        {
          "x": "2013-02-01",
          "y": -109.86,
          "lake": "Mead",
          "color": "#E7A850"
        },
        {
          "x": "2013-01-01",
          "y": -109.68,
          "lake": "Mead",
          "color": "#E7A851"
        },
        {
          "x": "2012-12-01",
          "y": -111.64,
          "lake": "Mead",
          "color": "#E7A852"
        },
        {
          "x": "2012-11-01",
          "y": -114.76,
          "lake": "Mead",
          "color": "#E7A853"
        },
        {
          "x": "2012-10-01",
          "y": -115.5,
          "lake": "Mead",
          "color": "#E7A854"
        },
        {
          "x": "2012-09-01",
          "y": -116.84,
          "lake": "Mead",
          "color": "#E7A855"
        },
        {
          "x": "2012-08-01",
          "y": -115.44,
          "lake": "Mead",
          "color": "#E7A856"
        },
        {
          "x": "2012-07-01",
          "y": -116.08,
          "lake": "Mead",
          "color": "#E7A857"
        },
        {
          "x": "2012-06-01",
          "y": -116.16,
          "lake": "Mead",
          "color": "#E7A858"
        },
        {
          "x": "2012-05-01",
          "y": -112.62,
          "lake": "Mead",
          "color": "#E7A859"
        },
        {
          "x": "2012-04-01",
          "y": -108.07,
          "lake": "Mead",
          "color": "#E7A860"
        },
        {
          "x": "2012-03-01",
          "y": -102.59,
          "lake": "Mead",
          "color": "#E7A861"
        },
        {
          "x": "2012-02-01",
          "y": -98.94,
          "lake": "Mead",
          "color": "#E7A862"
        },
        {
          "x": "2012-01-01",
          "y": -97.82,
          "lake": "Mead",
          "color": "#E7A863"
        },
        {
          "x": "2011-12-01",
          "y": -99.17,
          "lake": "Mead",
          "color": "#E7A864"
        },
        {
          "x": "2011-11-01",
          "y": -106.18,
          "lake": "Mead",
          "color": "#E7A865"
        },
        {
          "x": "2011-10-01",
          "y": -111,
          "lake": "Mead",
          "color": "#E7A866"
        },
        {
          "x": "2011-09-01",
          "y": -115.96,
          "lake": "Mead",
          "color": "#E7A867"
        },
        {
          "x": "2011-08-01",
          "y": -118.55,
          "lake": "Mead",
          "color": "#E7A868"
        },
        {
          "x": "2011-07-01",
          "y": -124.93,
          "lake": "Mead",
          "color": "#E7A869"
        },
        {
          "x": "2011-06-01",
          "y": -129.62,
          "lake": "Mead",
          "color": "#E7A870"
        },
        {
          "x": "2011-05-01",
          "y": -134.1,
          "lake": "Mead",
          "color": "#E7A871"
        },
        {
          "x": "2011-04-01",
          "y": -136.24,
          "lake": "Mead",
          "color": "#E7A872"
        },
        {
          "x": "2011-03-01",
          "y": -135.61,
          "lake": "Mead",
          "color": "#E7A873"
        },
        {
          "x": "2011-02-01",
          "y": -136.22,
          "lake": "Mead",
          "color": "#E7A874"
        },
        {
          "x": "2011-01-01",
          "y": -140.27,
          "lake": "Mead",
          "color": "#E7A875"
        },
        {
          "x": "2010-12-01",
          "y": -145.7,
          "lake": "Mead",
          "color": "#E7A876"
        },
        {
          "x": "2010-11-01",
          "y": -150.06,
          "lake": "Mead",
          "color": "#E7A877"
        },
        {
          "x": "2010-10-01",
          "y": -149.64,
          "lake": "Mead",
          "color": "#E7A878"
        },
        {
          "x": "2010-09-01",
          "y": -148.19,
          "lake": "Mead",
          "color": "#E7A879"
        },
        {
          "x": "2010-08-01",
          "y": -145.09,
          "lake": "Mead",
          "color": "#E7A880"
        },
        {
          "x": "2010-07-01",
          "y": -145.03,
          "lake": "Mead",
          "color": "#E7A881"
        },
        {
          "x": "2010-06-01",
          "y": -142.7,
          "lake": "Mead",
          "color": "#E7A882"
        },
        {
          "x": "2010-05-01",
          "y": -137.7,
          "lake": "Mead",
          "color": "#E7A883"
        },
        {
          "x": "2010-04-01",
          "y": -134,
          "lake": "Mead",
          "color": "#E7A884"
        },
        {
          "x": "2010-03-01",
          "y": -131.34,
          "lake": "Mead",
          "color": "#E7A885"
        },
        {
          "x": "2010-02-01",
          "y": -128.79,
          "lake": "Mead",
          "color": "#E7A886"
        },
        {
          "x": "2010-01-01",
          "y": -131.98,
          "lake": "Mead",
          "color": "#E7A887"
        },
        {
          "x": "2009-12-01",
          "y": -135.7,
          "lake": "Mead",
          "color": "#E7A888"
        },
        {
          "x": "2009-11-01",
          "y": -138.48,
          "lake": "Mead",
          "color": "#E7A889"
        },
        {
          "x": "2009-10-01",
          "y": -138.74,
          "lake": "Mead",
          "color": "#E7A890"
        },
        {
          "x": "2009-09-01",
          "y": -138.32,
          "lake": "Mead",
          "color": "#E7A891"
        },
        {
          "x": "2009-08-01",
          "y": -138.27,
          "lake": "Mead",
          "color": "#E7A892"
        },
        {
          "x": "2009-07-01",
          "y": -137.8,
          "lake": "Mead",
          "color": "#E7A893"
        },
        {
          "x": "2009-06-01",
          "y": -136.74,
          "lake": "Mead",
          "color": "#E7A894"
        },
        {
          "x": "2009-05-01",
          "y": -135.08,
          "lake": "Mead",
          "color": "#E7A895"
        },
        {
          "x": "2009-04-01",
          "y": -130.74,
          "lake": "Mead",
          "color": "#E7A896"
        },
        {
          "x": "2009-03-01",
          "y": -124.6,
          "lake": "Mead",
          "color": "#E7A897"
        },
        {
          "x": "2009-02-01",
          "y": -120.57,
          "lake": "Mead",
          "color": "#E7A898"
        },
        {
          "x": "2009-01-01",
          "y": -120.22,
          "lake": "Mead",
          "color": "#E7A899"
        },
        {
          "x": "2008-12-01",
          "y": -121.03,
          "lake": "Mead",
          "color": "#E7A900"
        },
        {
          "x": "2008-11-01",
          "y": -124.67,
          "lake": "Mead",
          "color": "#E7A901"
        },
        {
          "x": "2008-10-01",
          "y": -124.06,
          "lake": "Mead",
          "color": "#E7A902"
        },
        {
          "x": "2008-09-01",
          "y": -126.24,
          "lake": "Mead",
          "color": "#E7A903"
        },
        {
          "x": "2008-08-01",
          "y": -126.87,
          "lake": "Mead",
          "color": "#E7A904"
        },
        {
          "x": "2008-07-01",
          "y": -127.58,
          "lake": "Mead",
          "color": "#E7A905"
        },
        {
          "x": "2008-06-01",
          "y": -127.02,
          "lake": "Mead",
          "color": "#E7A906"
        },
        {
          "x": "2008-05-01",
          "y": -124.95,
          "lake": "Mead",
          "color": "#E7A907"
        },
        {
          "x": "2008-04-01",
          "y": -121.39,
          "lake": "Mead",
          "color": "#E7A908"
        },
        {
          "x": "2008-03-01",
          "y": -116.35,
          "lake": "Mead",
          "color": "#E7A909"
        },
        {
          "x": "2008-02-01",
          "y": -115.07,
          "lake": "Mead",
          "color": "#E7A910"
        },
        {
          "x": "2008-01-01",
          "y": -115.54,
          "lake": "Mead",
          "color": "#E7A911"
        },
        {
          "x": "2007-12-01",
          "y": -117.19,
          "lake": "Mead",
          "color": "#E7A912"
        },
        {
          "x": "2007-11-01",
          "y": -120.78,
          "lake": "Mead",
          "color": "#E7A913"
        },
        {
          "x": "2007-10-01",
          "y": -121.05,
          "lake": "Mead",
          "color": "#E7A914"
        },
        {
          "x": "2007-09-01",
          "y": -120.94,
          "lake": "Mead",
          "color": "#E7A915"
        },
        {
          "x": "2007-08-01",
          "y": -120.16,
          "lake": "Mead",
          "color": "#E7A916"
        },
        {
          "x": "2007-07-01",
          "y": -120.42,
          "lake": "Mead",
          "color": "#E7A917"
        },
        {
          "x": "2007-06-01",
          "y": -118.5,
          "lake": "Mead",
          "color": "#E7A918"
        },
        {
          "x": "2007-05-01",
          "y": -116.11,
          "lake": "Mead",
          "color": "#E7A919"
        },
        {
          "x": "2007-04-01",
          "y": -111.31,
          "lake": "Mead",
          "color": "#E7A920"
        },
        {
          "x": "2007-03-01",
          "y": -106.21,
          "lake": "Mead",
          "color": "#E7A921"
        },
        {
          "x": "2007-02-01",
          "y": -102.65,
          "lake": "Mead",
          "color": "#E7A922"
        },
        {
          "x": "2007-01-01",
          "y": -102.45,
          "lake": "Mead",
          "color": "#E7A923"
        },
        {
          "x": "2006-12-01",
          "y": -103.88,
          "lake": "Mead",
          "color": "#E7A924"
        },
        {
          "x": "2006-11-01",
          "y": -105.37,
          "lake": "Mead",
          "color": "#E7A925"
        },
        {
          "x": "2006-10-01",
          "y": -105.87,
          "lake": "Mead",
          "color": "#E7A926"
        },
        {
          "x": "2006-09-01",
          "y": -106.64,
          "lake": "Mead",
          "color": "#E7A927"
        },
        {
          "x": "2006-08-01",
          "y": -105.46,
          "lake": "Mead",
          "color": "#E7A928"
        },
        {
          "x": "2006-07-01",
          "y": -105.58,
          "lake": "Mead",
          "color": "#E7A929"
        },
        {
          "x": "2006-06-01",
          "y": -103.74,
          "lake": "Mead",
          "color": "#E7A930"
        },
        {
          "x": "2006-05-01",
          "y": -100.86,
          "lake": "Mead",
          "color": "#E7A931"
        },
        {
          "x": "2006-04-01",
          "y": -96.06,
          "lake": "Mead",
          "color": "#E7A932"
        },
        {
          "x": "2006-03-01",
          "y": -92.52,
          "lake": "Mead",
          "color": "#E7A933"
        },
        {
          "x": "2006-02-01",
          "y": -90.8,
          "lake": "Mead",
          "color": "#E7A934"
        },
        {
          "x": "2006-01-01",
          "y": -92.54,
          "lake": "Mead",
          "color": "#E7A935"
        },
        {
          "x": "2005-12-01",
          "y": -94.48,
          "lake": "Mead",
          "color": "#E7A936"
        },
        {
          "x": "2005-11-01",
          "y": -96.73,
          "lake": "Mead",
          "color": "#E7A937"
        },
        {
          "x": "2005-10-01",
          "y": -94.99,
          "lake": "Mead",
          "color": "#E7A938"
        },
        {
          "x": "2005-09-01",
          "y": -93.64,
          "lake": "Mead",
          "color": "#E7A939"
        },
        {
          "x": "2005-08-01",
          "y": -92.39,
          "lake": "Mead",
          "color": "#E7A940"
        },
        {
          "x": "2005-07-01",
          "y": -92.99,
          "lake": "Mead",
          "color": "#E7A941"
        },
        {
          "x": "2005-06-01",
          "y": -91.54,
          "lake": "Mead",
          "color": "#E7A942"
        },
        {
          "x": "2005-05-01",
          "y": -90.11,
          "lake": "Mead",
          "color": "#E7A943"
        },
        {
          "x": "2005-04-01",
          "y": -87.55,
          "lake": "Mead",
          "color": "#E7A944"
        },
        {
          "x": "2005-03-01",
          "y": -84.34,
          "lake": "Mead",
          "color": "#E7A945"
        },
        {
          "x": "2005-02-01",
          "y": -88.75,
          "lake": "Mead",
          "color": "#E7A946"
        },
        {
          "x": "2005-01-01",
          "y": -94.6,
          "lake": "Mead",
          "color": "#E7A947"
        },
        {
          "x": "2004-12-01",
          "y": -101.99,
          "lake": "Mead",
          "color": "#E7A948"
        },
        {
          "x": "2004-11-01",
          "y": -101.87,
          "lake": "Mead",
          "color": "#E7A949"
        },
        {
          "x": "2004-10-01",
          "y": -104.57,
          "lake": "Mead",
          "color": "#E7A950"
        },
        {
          "x": "2004-09-01",
          "y": -106.14,
          "lake": "Mead",
          "color": "#E7A951"
        },
        {
          "x": "2004-08-01",
          "y": -105.33,
          "lake": "Mead",
          "color": "#E7A952"
        },
        {
          "x": "2004-07-01",
          "y": -106.27,
          "lake": "Mead",
          "color": "#E7A953"
        },
        {
          "x": "2004-06-01",
          "y": -105.07,
          "lake": "Mead",
          "color": "#E7A954"
        },
        {
          "x": "2004-05-01",
          "y": -102.3,
          "lake": "Mead",
          "color": "#E7A955"
        },
        {
          "x": "2004-04-01",
          "y": -97.02,
          "lake": "Mead",
          "color": "#E7A956"
        },
        {
          "x": "2004-03-01",
          "y": -93.3,
          "lake": "Mead",
          "color": "#E7A957"
        },
        {
          "x": "2004-02-01",
          "y": -91.89,
          "lake": "Mead",
          "color": "#E7A958"
        },
        {
          "x": "2004-01-01",
          "y": -91.61,
          "lake": "Mead",
          "color": "#E7A959"
        },
        {
          "x": "2003-12-01",
          "y": -92.88,
          "lake": "Mead",
          "color": "#E7A960"
        },
        {
          "x": "2003-11-01",
          "y": -92.52,
          "lake": "Mead",
          "color": "#E7A961"
        },
        {
          "x": "2003-10-01",
          "y": -90.83,
          "lake": "Mead",
          "color": "#E7A962"
        },
        {
          "x": "2003-09-01",
          "y": -89.88,
          "lake": "Mead",
          "color": "#E7A963"
        },
        {
          "x": "2003-08-01",
          "y": -88.73,
          "lake": "Mead",
          "color": "#E7A964"
        },
        {
          "x": "2003-07-01",
          "y": -90.07,
          "lake": "Mead",
          "color": "#E7A965"
        },
        {
          "x": "2003-06-01",
          "y": -88.81,
          "lake": "Mead",
          "color": "#E7A966"
        },
        {
          "x": "2003-05-01",
          "y": -87.32,
          "lake": "Mead",
          "color": "#E7A967"
        },
        {
          "x": "2003-04-01",
          "y": -83.73,
          "lake": "Mead",
          "color": "#E7A968"
        },
        {
          "x": "2003-03-01",
          "y": -78.91,
          "lake": "Mead",
          "color": "#E7A969"
        },
        {
          "x": "2003-02-01",
          "y": -77.58,
          "lake": "Mead",
          "color": "#E7A970"
        },
        {
          "x": "2003-01-01",
          "y": -78.67,
          "lake": "Mead",
          "color": "#E7A971"
        },
        {
          "x": "2002-12-01",
          "y": -79.87,
          "lake": "Mead",
          "color": "#E7A972"
        },
        {
          "x": "2002-11-01",
          "y": -78.7,
          "lake": "Mead",
          "color": "#E7A973"
        },
        {
          "x": "2002-10-01",
          "y": -77.11,
          "lake": "Mead",
          "color": "#E7A974"
        },
        {
          "x": "2002-09-01",
          "y": -76.58,
          "lake": "Mead",
          "color": "#E7A975"
        },
        {
          "x": "2002-08-01",
          "y": -75.58,
          "lake": "Mead",
          "color": "#E7A976"
        },
        {
          "x": "2002-07-01",
          "y": -74.43,
          "lake": "Mead",
          "color": "#E7A977"
        },
        {
          "x": "2002-06-01",
          "y": -71.81,
          "lake": "Mead",
          "color": "#E7A978"
        },
        {
          "x": "2002-05-01",
          "y": -69.61,
          "lake": "Mead",
          "color": "#E7A979"
        },
        {
          "x": "2002-04-01",
          "y": -64.51,
          "lake": "Mead",
          "color": "#E7A980"
        },
        {
          "x": "2002-03-01",
          "y": -59.94,
          "lake": "Mead",
          "color": "#E7A981"
        },
        {
          "x": "2002-02-01",
          "y": -55.5,
          "lake": "Mead",
          "color": "#E7A982"
        },
        {
          "x": "2002-01-01",
          "y": -54.06,
          "lake": "Mead",
          "color": "#E7A983"
        },
        {
          "x": "2001-12-01",
          "y": -54.63,
          "lake": "Mead",
          "color": "#E7A984"
        },
        {
          "x": "2001-11-01",
          "y": -54.78,
          "lake": "Mead",
          "color": "#E7A985"
        },
        {
          "x": "2001-10-01",
          "y": -53.97,
          "lake": "Mead",
          "color": "#E7A986"
        },
        {
          "x": "2001-09-01",
          "y": -54.04,
          "lake": "Mead",
          "color": "#E7A987"
        },
        {
          "x": "2001-08-01",
          "y": -52.03,
          "lake": "Mead",
          "color": "#E7A988"
        },
        {
          "x": "2001-07-01",
          "y": -51.22,
          "lake": "Mead",
          "color": "#E7A989"
        },
        {
          "x": "2001-06-01",
          "y": -48.88,
          "lake": "Mead",
          "color": "#E7A990"
        },
        {
          "x": "2001-05-01",
          "y": -44.68,
          "lake": "Mead",
          "color": "#E7A991"
        },
        {
          "x": "2001-04-01",
          "y": -41.24,
          "lake": "Mead",
          "color": "#E7A992"
        },
        {
          "x": "2001-03-01",
          "y": -37.32,
          "lake": "Mead",
          "color": "#E7A993"
        },
        {
          "x": "2001-02-01",
          "y": -35.38,
          "lake": "Mead",
          "color": "#E7A994"
        },
        {
          "x": "2001-01-01",
          "y": -34.73,
          "lake": "Mead",
          "color": "#E7A995"
        },
        {
          "x": "2000-12-01",
          "y": -35.88,
          "lake": "Mead",
          "color": "#E7A996"
        },
        {
          "x": "2000-11-01",
          "y": -35.55,
          "lake": "Mead",
          "color": "#E7A997"
        },
        {
          "x": "2000-10-01",
          "y": -35.34,
          "lake": "Mead",
          "color": "#E7A998"
        },
        {
          "x": "2000-09-01",
          "y": -35.28,
          "lake": "Mead",
          "color": "#E7A999"
        },
        {
          "x": "2000-08-01",
          "y": -35.34,
          "lake": "Mead",
          "color": "#E7A1000"
        },
        {
          "x": "2000-07-01",
          "y": -32.03,
          "lake": "Mead",
          "color": "#E7A1001"
        },
        {
          "x": "2000-06-01",
          "y": -27.91,
          "lake": "Mead",
          "color": "#E7A1002"
        },
        {
          "x": "2000-05-01",
          "y": -24.33,
          "lake": "Mead",
          "color": "#E7A1003"
        },
        {
          "x": "2000-04-01",
          "y": -23.22,
          "lake": "Mead",
          "color": "#E7A1004"
        },
        {
          "x": "2000-03-01",
          "y": -20.67,
          "lake": "Mead",
          "color": "#E7A1005"
        },
        {
          "x": "2000-02-01",
          "y": -18.21,
          "lake": "Mead",
          "color": "#E7A1006"
        },
        {
          "x": "2000-01-01",
          "y": -17.74,
          "lake": "Mead",
          "color": "#E7A1007"
        },
        {
          "x": "1999-12-01",
          "y": -18.06,
          "lake": "Mead",
          "color": "#E7A1008"
        },
        {
          "x": "1999-11-01",
          "y": -19.42,
          "lake": "Mead",
          "color": "#E7A1009"
        },
        {
          "x": "1999-10-01",
          "y": -19.89,
          "lake": "Mead",
          "color": "#E7A1010"
        },
        {
          "x": "1999-09-01",
          "y": -20.71,
          "lake": "Mead",
          "color": "#E7A1011"
        },
        {
          "x": "1999-08-01",
          "y": -23.26,
          "lake": "Mead",
          "color": "#E7A1012"
        },
        {
          "x": "1999-07-01",
          "y": -24.72,
          "lake": "Mead",
          "color": "#E7A1013"
        },
        {
          "x": "1999-06-01",
          "y": -25.6,
          "lake": "Mead",
          "color": "#E7A1014"
        },
        {
          "x": "1999-05-01",
          "y": -24.63,
          "lake": "Mead",
          "color": "#E7A1015"
        },
        {
          "x": "1999-04-01",
          "y": -23.2,
          "lake": "Mead",
          "color": "#E7A1016"
        },
        {
          "x": "1999-03-01",
          "y": -20.25,
          "lake": "Mead",
          "color": "#E7A1017"
        },
        {
          "x": "1999-02-01",
          "y": -18.7,
          "lake": "Mead",
          "color": "#E7A1018"
        },
        {
          "x": "1999-01-01",
          "y": -19.11,
          "lake": "Mead",
          "color": "#E7A1019"
        },
        {
          "x": "1998-12-01",
          "y": -19.47,
          "lake": "Mead",
          "color": "#E7A1020"
        },
        {
          "x": "1998-11-01",
          "y": -17.21,
          "lake": "Mead",
          "color": "#E7A1021"
        },
        {
          "x": "1998-10-01",
          "y": -16.24,
          "lake": "Mead",
          "color": "#E7A1022"
        },
        {
          "x": "1998-09-01",
          "y": -17.22,
          "lake": "Mead",
          "color": "#E7A1023"
        },
        {
          "x": "1998-08-01",
          "y": -17.7,
          "lake": "Mead",
          "color": "#E7A1024"
        },
        {
          "x": "1998-07-01",
          "y": -19.57,
          "lake": "Mead",
          "color": "#E7A1025"
        },
        {
          "x": "1998-06-01",
          "y": -20.17,
          "lake": "Mead",
          "color": "#E7A1026"
        },
        {
          "x": "1998-05-01",
          "y": -20.41,
          "lake": "Mead",
          "color": "#E7A1027"
        },
        {
          "x": "1998-04-01",
          "y": -19.26,
          "lake": "Mead",
          "color": "#E7A1028"
        },
        {
          "x": "1998-03-01",
          "y": -17.74,
          "lake": "Mead",
          "color": "#E7A1029"
        },
        {
          "x": "1998-02-01",
          "y": -17.95,
          "lake": "Mead",
          "color": "#E7A1030"
        },
        {
          "x": "1998-01-01",
          "y": -17.6,
          "lake": "Mead",
          "color": "#E7A1031"
        },
        {
          "x": "1997-12-01",
          "y": -17.36,
          "lake": "Mead",
          "color": "#E7A1032"
        },
        {
          "x": "1997-11-01",
          "y": -19.2,
          "lake": "Mead",
          "color": "#E7A1033"
        },
        {
          "x": "1997-10-01",
          "y": -22.36,
          "lake": "Mead",
          "color": "#E7A1034"
        },
        {
          "x": "1997-09-01",
          "y": -26.19,
          "lake": "Mead",
          "color": "#E7A1035"
        },
        {
          "x": "1997-08-01",
          "y": -27.48,
          "lake": "Mead",
          "color": "#E7A1036"
        },
        {
          "x": "1997-07-01",
          "y": -28.51,
          "lake": "Mead",
          "color": "#E7A1037"
        },
        {
          "x": "1997-06-01",
          "y": -29.68,
          "lake": "Mead",
          "color": "#E7A1038"
        },
        {
          "x": "1997-05-01",
          "y": -32.04,
          "lake": "Mead",
          "color": "#E7A1039"
        },
        {
          "x": "1997-04-01",
          "y": -31.99,
          "lake": "Mead",
          "color": "#E7A1040"
        },
        {
          "x": "1997-03-01",
          "y": -32.9,
          "lake": "Mead",
          "color": "#E7A1041"
        },
        {
          "x": "1997-02-01",
          "y": -35.49,
          "lake": "Mead",
          "color": "#E7A1042"
        },
        {
          "x": "1997-01-01",
          "y": -36.37,
          "lake": "Mead",
          "color": "#E7A1043"
        },
        {
          "x": "1996-12-01",
          "y": -37.62,
          "lake": "Mead",
          "color": "#E7A1044"
        },
        {
          "x": "1996-11-01",
          "y": -39.29,
          "lake": "Mead",
          "color": "#E7A1045"
        },
        {
          "x": "1996-10-01",
          "y": -40.44,
          "lake": "Mead",
          "color": "#E7A1046"
        },
        {
          "x": "1996-09-01",
          "y": -41.16,
          "lake": "Mead",
          "color": "#E7A1047"
        },
        {
          "x": "1996-08-01",
          "y": -41.98,
          "lake": "Mead",
          "color": "#E7A1048"
        },
        {
          "x": "1996-07-01",
          "y": -41.74,
          "lake": "Mead",
          "color": "#E7A1049"
        },
        {
          "x": "1996-06-01",
          "y": -41.57,
          "lake": "Mead",
          "color": "#E7A1050"
        },
        {
          "x": "1996-05-01",
          "y": -40.74,
          "lake": "Mead",
          "color": "#E7A1051"
        },
        {
          "x": "1996-04-01",
          "y": -39.25,
          "lake": "Mead",
          "color": "#E7A1052"
        },
        {
          "x": "1996-03-01",
          "y": -38.19,
          "lake": "Mead",
          "color": "#E7A1053"
        },
        {
          "x": "1996-02-01",
          "y": -38.19,
          "lake": "Mead",
          "color": "#E7A1054"
        },
        {
          "x": "1996-01-01",
          "y": -38.55,
          "lake": "Mead",
          "color": "#E7A1055"
        },
        {
          "x": "1995-12-01",
          "y": -41.08,
          "lake": "Mead",
          "color": "#E7A1056"
        },
        {
          "x": "1995-11-01",
          "y": -42.35,
          "lake": "Mead",
          "color": "#E7A1057"
        },
        {
          "x": "1995-10-01",
          "y": -45.88,
          "lake": "Mead",
          "color": "#E7A1058"
        },
        {
          "x": "1995-09-01",
          "y": -47.72,
          "lake": "Mead",
          "color": "#E7A1059"
        },
        {
          "x": "1995-08-01",
          "y": -50.3,
          "lake": "Mead",
          "color": "#E7A1060"
        },
        {
          "x": "1995-07-01",
          "y": -51.25,
          "lake": "Mead",
          "color": "#E7A1061"
        },
        {
          "x": "1995-06-01",
          "y": -52.97,
          "lake": "Mead",
          "color": "#E7A1062"
        },
        {
          "x": "1995-05-01",
          "y": -53.67,
          "lake": "Mead",
          "color": "#E7A1063"
        },
        {
          "x": "1995-04-01",
          "y": -52.32,
          "lake": "Mead",
          "color": "#E7A1064"
        },
        {
          "x": "1995-03-01",
          "y": -49.73,
          "lake": "Mead",
          "color": "#E7A1065"
        },
        {
          "x": "1995-02-01",
          "y": -49.42,
          "lake": "Mead",
          "color": "#E7A1066"
        },
        {
          "x": "1995-01-01",
          "y": -51.63,
          "lake": "Mead",
          "color": "#E7A1067"
        },
        {
          "x": "1994-12-01",
          "y": -55.45,
          "lake": "Mead",
          "color": "#E7A1068"
        },
        {
          "x": "1994-11-01",
          "y": -56.33,
          "lake": "Mead",
          "color": "#E7A1069"
        },
        {
          "x": "1994-10-01",
          "y": -54.2,
          "lake": "Mead",
          "color": "#E7A1070"
        },
        {
          "x": "1994-09-01",
          "y": -53.6,
          "lake": "Mead",
          "color": "#E7A1071"
        },
        {
          "x": "1994-08-01",
          "y": -53.76,
          "lake": "Mead",
          "color": "#E7A1072"
        },
        {
          "x": "1994-07-01",
          "y": -53.03,
          "lake": "Mead",
          "color": "#E7A1073"
        },
        {
          "x": "1994-06-01",
          "y": -52.58,
          "lake": "Mead",
          "color": "#E7A1074"
        },
        {
          "x": "1994-05-01",
          "y": -50.36,
          "lake": "Mead",
          "color": "#E7A1075"
        },
        {
          "x": "1994-04-01",
          "y": -47.06,
          "lake": "Mead",
          "color": "#E7A1076"
        },
        {
          "x": "1994-03-01",
          "y": -43.49,
          "lake": "Mead",
          "color": "#E7A1077"
        },
        {
          "x": "1994-02-01",
          "y": -41.03,
          "lake": "Mead",
          "color": "#E7A1078"
        },
        {
          "x": "1994-01-01",
          "y": -41.91,
          "lake": "Mead",
          "color": "#E7A1079"
        },
        {
          "x": "1993-12-01",
          "y": -43.25,
          "lake": "Mead",
          "color": "#E7A1080"
        },
        {
          "x": "1993-11-01",
          "y": -43.55,
          "lake": "Mead",
          "color": "#E7A1081"
        },
        {
          "x": "1993-10-01",
          "y": -43.95,
          "lake": "Mead",
          "color": "#E7A1082"
        },
        {
          "x": "1993-09-01",
          "y": -42.85,
          "lake": "Mead",
          "color": "#E7A1083"
        },
        {
          "x": "1993-08-01",
          "y": -43.59,
          "lake": "Mead",
          "color": "#E7A1084"
        },
        {
          "x": "1993-07-01",
          "y": -44.8,
          "lake": "Mead",
          "color": "#E7A1085"
        },
        {
          "x": "1993-06-01",
          "y": -43.73,
          "lake": "Mead",
          "color": "#E7A1086"
        },
        {
          "x": "1993-05-01",
          "y": -41.73,
          "lake": "Mead",
          "color": "#E7A1087"
        },
        {
          "x": "1993-04-01",
          "y": -38.96,
          "lake": "Mead",
          "color": "#E7A1088"
        },
        {
          "x": "1993-03-01",
          "y": -38.54,
          "lake": "Mead",
          "color": "#E7A1089"
        },
        {
          "x": "1993-02-01",
          "y": -42.12,
          "lake": "Mead",
          "color": "#E7A1090"
        },
        {
          "x": "1993-01-01",
          "y": -48.36,
          "lake": "Mead",
          "color": "#E7A1091"
        },
        {
          "x": "1992-12-01",
          "y": -55.14,
          "lake": "Mead",
          "color": "#E7A1092"
        },
        {
          "x": "1992-11-01",
          "y": -56.55,
          "lake": "Mead",
          "color": "#E7A1093"
        },
        {
          "x": "1992-10-01",
          "y": -57.32,
          "lake": "Mead",
          "color": "#E7A1094"
        },
        {
          "x": "1992-09-01",
          "y": -57.56,
          "lake": "Mead",
          "color": "#E7A1095"
        },
        {
          "x": "1992-08-01",
          "y": -58.41,
          "lake": "Mead",
          "color": "#E7A1096"
        },
        {
          "x": "1992-07-01",
          "y": -58.08,
          "lake": "Mead",
          "color": "#E7A1097"
        },
        {
          "x": "1992-06-01",
          "y": -56.93,
          "lake": "Mead",
          "color": "#E7A1098"
        },
        {
          "x": "1992-05-01",
          "y": -54.69,
          "lake": "Mead",
          "color": "#E7A1099"
        },
        {
          "x": "1992-04-01",
          "y": -52.22,
          "lake": "Mead",
          "color": "#E7A1100"
        },
        {
          "x": "1992-03-01",
          "y": -51.69,
          "lake": "Mead",
          "color": "#E7A1101"
        },
        {
          "x": "1992-02-01",
          "y": -52.58,
          "lake": "Mead",
          "color": "#E7A1102"
        },
        {
          "x": "1992-01-01",
          "y": -54.75,
          "lake": "Mead",
          "color": "#E7A1103"
        },
        {
          "x": "1991-12-01",
          "y": -58.56,
          "lake": "Mead",
          "color": "#E7A1104"
        },
        {
          "x": "1991-11-01",
          "y": -60.18,
          "lake": "Mead",
          "color": "#E7A1105"
        },
        {
          "x": "1991-10-01",
          "y": -60.27,
          "lake": "Mead",
          "color": "#E7A1106"
        },
        {
          "x": "1991-09-01",
          "y": -58.99,
          "lake": "Mead",
          "color": "#E7A1107"
        },
        {
          "x": "1991-08-01",
          "y": -59.08,
          "lake": "Mead",
          "color": "#E7A1108"
        },
        {
          "x": "1991-07-01",
          "y": -58.71,
          "lake": "Mead",
          "color": "#E7A1109"
        },
        {
          "x": "1991-06-01",
          "y": -57.33,
          "lake": "Mead",
          "color": "#E7A1110"
        },
        {
          "x": "1991-05-01",
          "y": -56.08,
          "lake": "Mead",
          "color": "#E7A1111"
        },
        {
          "x": "1991-04-01",
          "y": -54.2,
          "lake": "Mead",
          "color": "#E7A1112"
        },
        {
          "x": "1991-03-01",
          "y": -52.69,
          "lake": "Mead",
          "color": "#E7A1113"
        },
        {
          "x": "1991-02-01",
          "y": -51.94,
          "lake": "Mead",
          "color": "#E7A1114"
        },
        {
          "x": "1991-01-01",
          "y": -52.61,
          "lake": "Mead",
          "color": "#E7A1115"
        },
        {
          "x": "1990-12-01",
          "y": -54.11,
          "lake": "Mead",
          "color": "#E7A1116"
        },
        {
          "x": "1990-11-01",
          "y": -53.46,
          "lake": "Mead",
          "color": "#E7A1117"
        },
        {
          "x": "1990-10-01",
          "y": -53.1,
          "lake": "Mead",
          "color": "#E7A1118"
        },
        {
          "x": "1990-09-01",
          "y": -51.98,
          "lake": "Mead",
          "color": "#E7A1119"
        },
        {
          "x": "1990-08-01",
          "y": -53.18,
          "lake": "Mead",
          "color": "#E7A1120"
        },
        {
          "x": "1990-07-01",
          "y": -52.51,
          "lake": "Mead",
          "color": "#E7A1121"
        },
        {
          "x": "1990-06-01",
          "y": -49.94,
          "lake": "Mead",
          "color": "#E7A1122"
        },
        {
          "x": "1990-05-01",
          "y": -48.07,
          "lake": "Mead",
          "color": "#E7A1123"
        },
        {
          "x": "1990-04-01",
          "y": -45.11,
          "lake": "Mead",
          "color": "#E7A1124"
        },
        {
          "x": "1990-03-01",
          "y": -42.44,
          "lake": "Mead",
          "color": "#E7A1125"
        },
        {
          "x": "1990-02-01",
          "y": -40.25,
          "lake": "Mead",
          "color": "#E7A1126"
        },
        {
          "x": "1990-01-01",
          "y": -41,
          "lake": "Mead",
          "color": "#E7A1127"
        },
        // {
        //   "x": "1989-12-01",
        //   "y": -42.2,
        //   "lake": "Mead",
        //   "color": "#E7A1128"
        // },
        // {
        //   "x": "1989-11-01",
        //   "y": -42.98,
        //   "lake": "Mead",
        //   "color": "#E7A1129"
        // },
        // {
        //   "x": "1989-10-01",
        //   "y": -42.45,
        //   "lake": "Mead",
        //   "color": "#E7A1130"
        // },
        // {
        //   "x": "1989-09-01",
        //   "y": -41.78,
        //   "lake": "Mead",
        //   "color": "#E7A1131"
        // },
        // {
        //   "x": "1989-08-01",
        //   "y": -42.6,
        //   "lake": "Mead",
        //   "color": "#E7A1132"
        // },
        // {
        //   "x": "1989-07-01",
        //   "y": -42.28,
        //   "lake": "Mead",
        //   "color": "#E7A1133"
        // },
        // {
        //   "x": "1989-06-01",
        //   "y": -41.6,
        //   "lake": "Mead",
        //   "color": "#E7A1134"
        // },
        // {
        //   "x": "1989-05-01",
        //   "y": -39.41,
        //   "lake": "Mead",
        //   "color": "#E7A1135"
        // },
        // {
        //   "x": "1989-04-01",
        //   "y": -34.65,
        //   "lake": "Mead",
        //   "color": "#E7A1136"
        // },
        // {
        //   "x": "1989-03-01",
        //   "y": -31.72,
        //   "lake": "Mead",
        //   "color": "#E7A1137"
        // },
        // {
        //   "x": "1989-02-01",
        //   "y": -29.51,
        //   "lake": "Mead",
        //   "color": "#E7A1138"
        // },
        // {
        //   "x": "1989-01-01",
        //   "y": -30.6,
        //   "lake": "Mead",
        //   "color": "#E7A1139"
        // },
        // {
        //   "x": "1988-12-01",
        //   "y": -32.25,
        //   "lake": "Mead",
        //   "color": "#E7A1140"
        // },
        // {
        //   "x": "1988-11-01",
        //   "y": -32.54,
        //   "lake": "Mead",
        //   "color": "#E7A1141"
        // },
        // {
        //   "x": "1988-10-01",
        //   "y": -32.85,
        //   "lake": "Mead",
        //   "color": "#E7A1142"
        // },
        // {
        //   "x": "1988-09-01",
        //   "y": -32.84,
        //   "lake": "Mead",
        //   "color": "#E7A1143"
        // },
        // {
        //   "x": "1988-08-01",
        //   "y": -32.43,
        //   "lake": "Mead",
        //   "color": "#E7A1144"
        // },
        // {
        //   "x": "1988-07-01",
        //   "y": -30.57,
        //   "lake": "Mead",
        //   "color": "#E7A1145"
        // },
        // {
        //   "x": "1988-06-01",
        //   "y": -28.82,
        //   "lake": "Mead",
        //   "color": "#E7A1146"
        // },
        // {
        //   "x": "1988-05-01",
        //   "y": -25.77,
        //   "lake": "Mead",
        //   "color": "#E7A1147"
        // },
        // {
        //   "x": "1988-04-01",
        //   "y": -23.64,
        //   "lake": "Mead",
        //   "color": "#E7A1148"
        // },
        // {
        //   "x": "1988-03-01",
        //   "y": -21.26,
        //   "lake": "Mead",
        //   "color": "#E7A1149"
        // },
        // {
        //   "x": "1988-02-01",
        //   "y": -20.3,
        //   "lake": "Mead",
        //   "color": "#E7A1150"
        // },
        // {
        //   "x": "1988-01-01",
        //   "y": -20.83,
        //   "lake": "Mead",
        //   "color": "#E7A1151"
        // },
        // {
        //   "x": "1987-12-01",
        //   "y": -20.97,
        //   "lake": "Mead",
        //   "color": "#E7A1152"
        // },
        // {
        //   "x": "1987-11-01",
        //   "y": -21.95,
        //   "lake": "Mead",
        //   "color": "#E7A1153"
        // },
        // {
        //   "x": "1987-10-01",
        //   "y": -23.31,
        //   "lake": "Mead",
        //   "color": "#E7A1154"
        // },
        // {
        //   "x": "1987-09-01",
        //   "y": -22.21,
        //   "lake": "Mead",
        //   "color": "#E7A1155"
        // },
        // {
        //   "x": "1987-08-01",
        //   "y": -22.44,
        //   "lake": "Mead",
        //   "color": "#E7A1156"
        // },
        // {
        //   "x": "1987-07-01",
        //   "y": -24.2,
        //   "lake": "Mead",
        //   "color": "#E7A1157"
        // },
        // {
        //   "x": "1987-06-01",
        //   "y": -25.4,
        //   "lake": "Mead",
        //   "color": "#E7A1158"
        // },
        // {
        //   "x": "1987-05-01",
        //   "y": -26.01,
        //   "lake": "Mead",
        //   "color": "#E7A1159"
        // },
        // {
        //   "x": "1987-04-01",
        //   "y": -24.33,
        //   "lake": "Mead",
        //   "color": "#E7A1160"
        // },
        // {
        //   "x": "1987-03-01",
        //   "y": -23.43,
        //   "lake": "Mead",
        //   "color": "#E7A1161"
        // },
        // {
        //   "x": "1987-02-01",
        //   "y": -21.42,
        //   "lake": "Mead",
        //   "color": "#E7A1162"
        // },
        // {
        //   "x": "1987-01-01",
        //   "y": -21.77,
        //   "lake": "Mead",
        //   "color": "#E7A1163"
        // },
        // {
        //   "x": "1986-12-01",
        //   "y": -21.61,
        //   "lake": "Mead",
        //   "color": "#E7A1164"
        // },
        // {
        //   "x": "1986-11-01",
        //   "y": -22.23,
        //   "lake": "Mead",
        //   "color": "#E7A1165"
        // },
        // {
        //   "x": "1986-10-01",
        //   "y": -23.3,
        //   "lake": "Mead",
        //   "color": "#E7A1166"
        // },
        // {
        //   "x": "1986-09-01",
        //   "y": -23.17,
        //   "lake": "Mead",
        //   "color": "#E7A1167"
        // },
        // {
        //   "x": "1986-08-01",
        //   "y": -24.48,
        //   "lake": "Mead",
        //   "color": "#E7A1168"
        // },
        // {
        //   "x": "1986-07-01",
        //   "y": -24.86,
        //   "lake": "Mead",
        //   "color": "#E7A1169"
        // },
        // {
        //   "x": "1986-06-01",
        //   "y": -21.87,
        //   "lake": "Mead",
        //   "color": "#E7A1170"
        // },
        // {
        //   "x": "1986-05-01",
        //   "y": -23.84,
        //   "lake": "Mead",
        //   "color": "#E7A1171"
        // },
        // {
        //   "x": "1986-04-01",
        //   "y": -27.22,
        //   "lake": "Mead",
        //   "color": "#E7A1172"
        // },
        // {
        //   "x": "1986-03-01",
        //   "y": -29.55,
        //   "lake": "Mead",
        //   "color": "#E7A1173"
        // },
        // {
        //   "x": "1986-02-01",
        //   "y": -29.28,
        //   "lake": "Mead",
        //   "color": "#E7A1174"
        // },
        // {
        //   "x": "1986-01-01",
        //   "y": -30.41,
        //   "lake": "Mead",
        //   "color": "#E7A1175"
        // },
        // {
        //   "x": "1985-12-01",
        //   "y": -26.51,
        //   "lake": "Mead",
        //   "color": "#E7A1176"
        // },
        // {
        //   "x": "1985-11-01",
        //   "y": -23.24,
        //   "lake": "Mead",
        //   "color": "#E7A1177"
        // },
        // {
        //   "x": "1985-10-01",
        //   "y": -20.84,
        //   "lake": "Mead",
        //   "color": "#E7A1178"
        // },
        // {
        //   "x": "1985-09-01",
        //   "y": -18.86,
        //   "lake": "Mead",
        //   "color": "#E7A1179"
        // },
        // {
        //   "x": "1985-08-01",
        //   "y": -19.21,
        //   "lake": "Mead",
        //   "color": "#E7A1180"
        // },
        // {
        //   "x": "1985-07-01",
        //   "y": -18.05,
        //   "lake": "Mead",
        //   "color": "#E7A1181"
        // },
        // {
        //   "x": "1985-06-01",
        //   "y": -17.75,
        //   "lake": "Mead",
        //   "color": "#E7A1182"
        // },
        // {
        //   "x": "1985-05-01",
        //   "y": -22.17,
        //   "lake": "Mead",
        //   "color": "#E7A1183"
        // },
        // {
        //   "x": "1985-04-01",
        //   "y": -25.84,
        //   "lake": "Mead",
        //   "color": "#E7A1184"
        // },
        // {
        //   "x": "1985-03-01",
        //   "y": -25.57,
        //   "lake": "Mead",
        //   "color": "#E7A1185"
        // },
        // {
        //   "x": "1985-02-01",
        //   "y": -25.32,
        //   "lake": "Mead",
        //   "color": "#E7A1186"
        // },
        // {
        //   "x": "1985-01-01",
        //   "y": -25.05,
        //   "lake": "Mead",
        //   "color": "#E7A1187"
        // },
        // {
        //   "x": "1984-12-01",
        //   "y": -24.1,
        //   "lake": "Mead",
        //   "color": "#E7A1188"
        // },
        // {
        //   "x": "1984-11-01",
        //   "y": -23.71,
        //   "lake": "Mead",
        //   "color": "#E7A1189"
        // },
        // {
        //   "x": "1984-10-01",
        //   "y": -23.37,
        //   "lake": "Mead",
        //   "color": "#E7A1190"
        // },
        // {
        //   "x": "1984-09-01",
        //   "y": -21.94,
        //   "lake": "Mead",
        //   "color": "#E7A1191"
        // },
        // {
        //   "x": "1984-08-01",
        //   "y": -20.6,
        //   "lake": "Mead",
        //   "color": "#E7A1192"
        // },
        // {
        //   "x": "1984-07-01",
        //   "y": -19.08,
        //   "lake": "Mead",
        //   "color": "#E7A1193"
        // },
        // {
        //   "x": "1984-06-01",
        //   "y": -19.83,
        //   "lake": "Mead",
        //   "color": "#E7A1194"
        // },
        // {
        //   "x": "1984-05-01",
        //   "y": -22.26,
        //   "lake": "Mead",
        //   "color": "#E7A1195"
        // },
        // {
        //   "x": "1984-04-01",
        //   "y": -26.09,
        //   "lake": "Mead",
        //   "color": "#E7A1196"
        // },
        // {
        //   "x": "1984-03-01",
        //   "y": -25.95,
        //   "lake": "Mead",
        //   "color": "#E7A1197"
        // },
        // {
        //   "x": "1984-02-01",
        //   "y": -24.33,
        //   "lake": "Mead",
        //   "color": "#E7A1198"
        // },
        // {
        //   "x": "1984-01-01",
        //   "y": -22.18,
        //   "lake": "Mead",
        //   "color": "#E7A1199"
        // },
        // {
        //   "x": "1983-12-01",
        //   "y": -19.67,
        //   "lake": "Mead",
        //   "color": "#E7A1200"
        // },
        // {
        //   "x": "1983-11-01",
        //   "y": -18.62,
        //   "lake": "Mead",
        //   "color": "#E7A1201"
        // },
        // {
        //   "x": "1983-10-01",
        //   "y": -16.68,
        //   "lake": "Mead",
        //   "color": "#E7A1202"
        // },
        // {
        //   "x": "1983-09-01",
        //   "y": -13.79,
        //   "lake": "Mead",
        //   "color": "#E7A1203"
        // },
        // {
        //   "x": "1983-08-01",
        //   "y": -9.83,
        //   "lake": "Mead",
        //   "color": "#E7A1204"
        // },
        // {
        //   "x": "1983-07-01",
        //   "y": -6.56,
        //   "lake": "Mead",
        //   "color": "#E7A1205"
        // },
        // {
        //   "x": "1983-06-01",
        //   "y": -11.73,
        //   "lake": "Mead",
        //   "color": "#E7A1206"
        // },
        // {
        //   "x": "1983-05-01",
        //   "y": -19.67,
        //   "lake": "Mead",
        //   "color": "#E7A1207"
        // },
        // {
        //   "x": "1983-04-01",
        //   "y": -20.74,
        //   "lake": "Mead",
        //   "color": "#E7A1208"
        // },
        // {
        //   "x": "1983-03-01",
        //   "y": -20.41,
        //   "lake": "Mead",
        //   "color": "#E7A1209"
        // },
        // {
        //   "x": "1983-02-01",
        //   "y": -21.75,
        //   "lake": "Mead",
        //   "color": "#E7A1210"
        // },
        // {
        //   "x": "1983-01-01",
        //   "y": -25.17,
        //   "lake": "Mead",
        //   "color": "#E7A1211"
        // },
        // {
        //   "x": "1982-12-01",
        //   "y": -23.63,
        //   "lake": "Mead",
        //   "color": "#E7A1212"
        // },
        // {
        //   "x": "1982-11-01",
        //   "y": -27.24,
        //   "lake": "Mead",
        //   "color": "#E7A1213"
        // },
        // {
        //   "x": "1982-10-01",
        //   "y": -30.94,
        //   "lake": "Mead",
        //   "color": "#E7A1214"
        // },
        // {
        //   "x": "1982-09-01",
        //   "y": -33.04,
        //   "lake": "Mead",
        //   "color": "#E7A1215"
        // },
        // {
        //   "x": "1982-08-01",
        //   "y": -34.61,
        //   "lake": "Mead",
        //   "color": "#E7A1216"
        // },
        // {
        //   "x": "1982-07-01",
        //   "y": -35.52,
        //   "lake": "Mead",
        //   "color": "#E7A1217"
        // },
        // {
        //   "x": "1982-06-01",
        //   "y": -35.58,
        //   "lake": "Mead",
        //   "color": "#E7A1218"
        // },
        // {
        //   "x": "1982-05-01",
        //   "y": -35.07,
        //   "lake": "Mead",
        //   "color": "#E7A1219"
        // },
        // {
        //   "x": "1982-04-01",
        //   "y": -33.7,
        //   "lake": "Mead",
        //   "color": "#E7A1220"
        // },
        // {
        //   "x": "1982-03-01",
        //   "y": -30.98,
        //   "lake": "Mead",
        //   "color": "#E7A1221"
        // },
        // {
        //   "x": "1982-02-01",
        //   "y": -29.61,
        //   "lake": "Mead",
        //   "color": "#E7A1222"
        // },
        // {
        //   "x": "1982-01-01",
        //   "y": -30.85,
        //   "lake": "Mead",
        //   "color": "#E7A1223"
        // },
        // {
        //   "x": "1981-12-01",
        //   "y": -33.72,
        //   "lake": "Mead",
        //   "color": "#E7A1224"
        // },
        // {
        //   "x": "1981-11-01",
        //   "y": -36.58,
        //   "lake": "Mead",
        //   "color": "#E7A1225"
        // },
        // {
        //   "x": "1981-10-01",
        //   "y": -37.89,
        //   "lake": "Mead",
        //   "color": "#E7A1226"
        // },
        // {
        //   "x": "1981-09-01",
        //   "y": -39.33,
        //   "lake": "Mead",
        //   "color": "#E7A1227"
        // },
        // {
        //   "x": "1981-08-01",
        //   "y": -39.22,
        //   "lake": "Mead",
        //   "color": "#E7A1228"
        // },
        // {
        //   "x": "1981-07-01",
        //   "y": -39.12,
        //   "lake": "Mead",
        //   "color": "#E7A1229"
        // },
        // {
        //   "x": "1981-06-01",
        //   "y": -38.3,
        //   "lake": "Mead",
        //   "color": "#E7A1230"
        // },
        // {
        //   "x": "1981-05-01",
        //   "y": -35.78,
        //   "lake": "Mead",
        //   "color": "#E7A1231"
        // },
        // {
        //   "x": "1981-04-01",
        //   "y": -33.56,
        //   "lake": "Mead",
        //   "color": "#E7A1232"
        // },
        // {
        //   "x": "1981-03-01",
        //   "y": -29.85,
        //   "lake": "Mead",
        //   "color": "#E7A1233"
        // },
        // {
        //   "x": "1981-02-01",
        //   "y": -27.69,
        //   "lake": "Mead",
        //   "color": "#E7A1234"
        // },
        // {
        //   "x": "1981-01-01",
        //   "y": -28.42,
        //   "lake": "Mead",
        //   "color": "#E7A1235"
        // },
        // {
        //   "x": "1980-12-01",
        //   "y": -29.12,
        //   "lake": "Mead",
        //   "color": "#E7A1236"
        // },
        // {
        //   "x": "1980-11-01",
        //   "y": -28.32,
        //   "lake": "Mead",
        //   "color": "#E7A1237"
        // },
        // {
        //   "x": "1980-10-01",
        //   "y": -28.79,
        //   "lake": "Mead",
        //   "color": "#E7A1238"
        // },
        // {
        //   "x": "1980-09-01",
        //   "y": -27.08,
        //   "lake": "Mead",
        //   "color": "#E7A1239"
        // },
        // {
        //   "x": "1980-08-01",
        //   "y": -27.42,
        //   "lake": "Mead",
        //   "color": "#E7A1240"
        // },
        // {
        //   "x": "1980-07-01",
        //   "y": -27.35,
        //   "lake": "Mead",
        //   "color": "#E7A1241"
        // },
        // {
        //   "x": "1980-06-01",
        //   "y": -29.73,
        //   "lake": "Mead",
        //   "color": "#E7A1242"
        // },
        // {
        //   "x": "1980-05-01",
        //   "y": -32.07,
        //   "lake": "Mead",
        //   "color": "#E7A1243"
        // },
        // {
        //   "x": "1980-04-01",
        //   "y": -30.06,
        //   "lake": "Mead",
        //   "color": "#E7A1244"
        // },
        // {
        //   "x": "1980-03-01",
        //   "y": -29.13,
        //   "lake": "Mead",
        //   "color": "#E7A1245"
        // },
        // {
        //   "x": "1980-02-01",
        //   "y": -27.87,
        //   "lake": "Mead",
        //   "color": "#E7A1246"
        // },
        // {
        //   "x": "1980-01-01",
        //   "y": -33.01,
        //   "lake": "Mead",
        //   "color": "#E7A1247"
        // },
        // {
        //   "x": "1979-12-01",
        //   "y": -34.03,
        //   "lake": "Mead",
        //   "color": "#E7A1248"
        // },
        // {
        //   "x": "1979-11-01",
        //   "y": -35.36,
        //   "lake": "Mead",
        //   "color": "#E7A1249"
        // },
        // {
        //   "x": "1979-10-01",
        //   "y": -36.88,
        //   "lake": "Mead",
        //   "color": "#E7A1250"
        // },
        // {
        //   "x": "1979-09-01",
        //   "y": -36.7,
        //   "lake": "Mead",
        //   "color": "#E7A1251"
        // },
        // {
        //   "x": "1979-08-01",
        //   "y": -36.29,
        //   "lake": "Mead",
        //   "color": "#E7A1252"
        // },
        // {
        //   "x": "1979-07-01",
        //   "y": -37.14,
        //   "lake": "Mead",
        //   "color": "#E7A1253"
        // },
        // {
        //   "x": "1979-06-01",
        //   "y": -36.5,
        //   "lake": "Mead",
        //   "color": "#E7A1254"
        // },
        // {
        //   "x": "1979-05-01",
        //   "y": -34.45,
        //   "lake": "Mead",
        //   "color": "#E7A1255"
        // },
        // {
        //   "x": "1979-04-01",
        //   "y": -31.99,
        //   "lake": "Mead",
        //   "color": "#E7A1256"
        // },
        // {
        //   "x": "1979-03-01",
        //   "y": -30.96,
        //   "lake": "Mead",
        //   "color": "#E7A1257"
        // },
        // {
        //   "x": "1979-02-01",
        //   "y": -29.39,
        //   "lake": "Mead",
        //   "color": "#E7A1258"
        // },
        // {
        //   "x": "1979-01-01",
        //   "y": -33.17,
        //   "lake": "Mead",
        //   "color": "#E7A1259"
        // },
        // {
        //   "x": "1978-12-01",
        //   "y": -38.69,
        //   "lake": "Mead",
        //   "color": "#E7A1260"
        // },
        // {
        //   "x": "1978-11-01",
        //   "y": -43.27,
        //   "lake": "Mead",
        //   "color": "#E7A1261"
        // },
        // {
        //   "x": "1978-10-01",
        //   "y": -45.31,
        //   "lake": "Mead",
        //   "color": "#E7A1262"
        // },
        // {
        //   "x": "1978-09-01",
        //   "y": -46.57,
        //   "lake": "Mead",
        //   "color": "#E7A1263"
        // },
        // {
        //   "x": "1978-08-01",
        //   "y": -48.26,
        //   "lake": "Mead",
        //   "color": "#E7A1264"
        // },
        // {
        //   "x": "1978-07-01",
        //   "y": -48.69,
        //   "lake": "Mead",
        //   "color": "#E7A1265"
        // },
        // {
        //   "x": "1978-06-01",
        //   "y": -47.24,
        //   "lake": "Mead",
        //   "color": "#E7A1266"
        // },
        // {
        //   "x": "1978-05-01",
        //   "y": -47.42,
        //   "lake": "Mead",
        //   "color": "#E7A1267"
        // },
        // {
        //   "x": "1978-04-01",
        //   "y": -45.86,
        //   "lake": "Mead",
        //   "color": "#E7A1268"
        // },
        // {
        //   "x": "1978-03-01",
        //   "y": -43.56,
        //   "lake": "Mead",
        //   "color": "#E7A1269"
        // },
        // {
        //   "x": "1978-02-01",
        //   "y": -44.38,
        //   "lake": "Mead",
        //   "color": "#E7A1270"
        // },
        // {
        //   "x": "1978-01-01",
        //   "y": -45.91,
        //   "lake": "Mead",
        //   "color": "#E7A1271"
        // },
        // {
        //   "x": "1977-12-01",
        //   "y": -51.18,
        //   "lake": "Mead",
        //   "color": "#E7A1272"
        // },
        // {
        //   "x": "1977-11-01",
        //   "y": -52.71,
        //   "lake": "Mead",
        //   "color": "#E7A1273"
        // },
        // {
        //   "x": "1977-10-01",
        //   "y": -51.64,
        //   "lake": "Mead",
        //   "color": "#E7A1274"
        // },
        // {
        //   "x": "1977-09-01",
        //   "y": -51.52,
        //   "lake": "Mead",
        //   "color": "#E7A1275"
        // },
        // {
        //   "x": "1977-08-01",
        //   "y": -54.73,
        //   "lake": "Mead",
        //   "color": "#E7A1276"
        // },
        // {
        //   "x": "1977-07-01",
        //   "y": -56.66,
        //   "lake": "Mead",
        //   "color": "#E7A1277"
        // },
        // {
        //   "x": "1977-06-01",
        //   "y": -55.84,
        //   "lake": "Mead",
        //   "color": "#E7A1278"
        // },
        // {
        //   "x": "1977-05-01",
        //   "y": -52.17,
        //   "lake": "Mead",
        //   "color": "#E7A1279"
        // },
        // {
        //   "x": "1977-04-01",
        //   "y": -48.9,
        //   "lake": "Mead",
        //   "color": "#E7A1280"
        // },
        // {
        //   "x": "1977-03-01",
        //   "y": -42.99,
        //   "lake": "Mead",
        //   "color": "#E7A1281"
        // },
        // {
        //   "x": "1977-02-01",
        //   "y": -40.02,
        //   "lake": "Mead",
        //   "color": "#E7A1282"
        // },
        // {
        //   "x": "1977-01-01",
        //   "y": -38.99,
        //   "lake": "Mead",
        //   "color": "#E7A1283"
        // },
        // {
        //   "x": "1976-12-01",
        //   "y": -44.13,
        //   "lake": "Mead",
        //   "color": "#E7A1284"
        // },
        // {
        //   "x": "1976-11-01",
        //   "y": -46.06,
        //   "lake": "Mead",
        //   "color": "#E7A1285"
        // },
        // {
        //   "x": "1976-10-01",
        //   "y": -49.84,
        //   "lake": "Mead",
        //   "color": "#E7A1286"
        // },
        // {
        //   "x": "1976-09-01",
        //   "y": -52.6,
        //   "lake": "Mead",
        //   "color": "#E7A1287"
        // },
        // {
        //   "x": "1976-08-01",
        //   "y": -54.16,
        //   "lake": "Mead",
        //   "color": "#E7A1288"
        // },
        // {
        //   "x": "1976-07-01",
        //   "y": -52.15,
        //   "lake": "Mead",
        //   "color": "#E7A1289"
        // },
        // {
        //   "x": "1976-06-01",
        //   "y": -51.53,
        //   "lake": "Mead",
        //   "color": "#E7A1290"
        // },
        // {
        //   "x": "1976-05-01",
        //   "y": -51.61,
        //   "lake": "Mead",
        //   "color": "#E7A1291"
        // },
        // {
        //   "x": "1976-04-01",
        //   "y": -52.32,
        //   "lake": "Mead",
        //   "color": "#E7A1292"
        // },
        // {
        //   "x": "1976-03-01",
        //   "y": -50.75,
        //   "lake": "Mead",
        //   "color": "#E7A1293"
        // },
        // {
        //   "x": "1976-02-01",
        //   "y": -49.1,
        //   "lake": "Mead",
        //   "color": "#E7A1294"
        // },
        // {
        //   "x": "1976-01-01",
        //   "y": -51.07,
        //   "lake": "Mead",
        //   "color": "#E7A1295"
        // },
        // {
        //   "x": "1975-12-01",
        //   "y": -52.37,
        //   "lake": "Mead",
        //   "color": "#E7A1296"
        // },
        // {
        //   "x": "1975-11-01",
        //   "y": -52.36,
        //   "lake": "Mead",
        //   "color": "#E7A1297"
        // },
        // {
        //   "x": "1975-10-01",
        //   "y": -51.59,
        //   "lake": "Mead",
        //   "color": "#E7A1298"
        // },
        // {
        //   "x": "1975-09-01",
        //   "y": -51.9,
        //   "lake": "Mead",
        //   "color": "#E7A1299"
        // },
        // {
        //   "x": "1975-08-01",
        //   "y": -53.84,
        //   "lake": "Mead",
        //   "color": "#E7A1300"
        // },
        // {
        //   "x": "1975-07-01",
        //   "y": -55.03,
        //   "lake": "Mead",
        //   "color": "#E7A1301"
        // },
        // {
        //   "x": "1975-06-01",
        //   "y": -57.52,
        //   "lake": "Mead",
        //   "color": "#E7A1302"
        // },
        // {
        //   "x": "1975-05-01",
        //   "y": -58.34,
        //   "lake": "Mead",
        //   "color": "#E7A1303"
        // },
        // {
        //   "x": "1975-04-01",
        //   "y": -57.82,
        //   "lake": "Mead",
        //   "color": "#E7A1304"
        // },
        // {
        //   "x": "1975-03-01",
        //   "y": -54.87,
        //   "lake": "Mead",
        //   "color": "#E7A1305"
        // },
        // {
        //   "x": "1975-02-01",
        //   "y": -53.62,
        //   "lake": "Mead",
        //   "color": "#E7A1306"
        // },
        // {
        //   "x": "1975-01-01",
        //   "y": -53.26,
        //   "lake": "Mead",
        //   "color": "#E7A1307"
        // },
        // {
        //   "x": "1974-12-01",
        //   "y": -55.2,
        //   "lake": "Mead",
        //   "color": "#E7A1308"
        // },
        // {
        //   "x": "1974-11-01",
        //   "y": -56.33,
        //   "lake": "Mead",
        //   "color": "#E7A1309"
        // },
        // {
        //   "x": "1974-10-01",
        //   "y": -58.17,
        //   "lake": "Mead",
        //   "color": "#E7A1310"
        // },
        // {
        //   "x": "1974-09-01",
        //   "y": -58.01,
        //   "lake": "Mead",
        //   "color": "#E7A1311"
        // },
        // {
        //   "x": "1974-08-01",
        //   "y": -58.7,
        //   "lake": "Mead",
        //   "color": "#E7A1312"
        // },
        // {
        //   "x": "1974-07-01",
        //   "y": -60.13,
        //   "lake": "Mead",
        //   "color": "#E7A1313"
        // },
        // {
        //   "x": "1974-06-01",
        //   "y": -62.4,
        //   "lake": "Mead",
        //   "color": "#E7A1314"
        // },
        // {
        //   "x": "1974-05-01",
        //   "y": -62.32,
        //   "lake": "Mead",
        //   "color": "#E7A1315"
        // },
        // {
        //   "x": "1974-04-01",
        //   "y": -60.65,
        //   "lake": "Mead",
        //   "color": "#E7A1316"
        // },
        // {
        //   "x": "1974-03-01",
        //   "y": -57.05,
        //   "lake": "Mead",
        //   "color": "#E7A1317"
        // },
        // {
        //   "x": "1974-02-01",
        //   "y": -53.92,
        //   "lake": "Mead",
        //   "color": "#E7A1318"
        // },
        // {
        //   "x": "1974-01-01",
        //   "y": -51.86,
        //   "lake": "Mead",
        //   "color": "#E7A1319"
        // },
        // {
        //   "x": "1973-12-01",
        //   "y": -55.08,
        //   "lake": "Mead",
        //   "color": "#E7A1320"
        // },
        // {
        //   "x": "1973-11-01",
        //   "y": -53.3,
        //   "lake": "Mead",
        //   "color": "#E7A1321"
        // },
        // {
        //   "x": "1973-10-01",
        //   "y": -52.53,
        //   "lake": "Mead",
        //   "color": "#E7A1322"
        // },
        // {
        //   "x": "1973-09-01",
        //   "y": -51.74,
        //   "lake": "Mead",
        //   "color": "#E7A1323"
        // },
        // {
        //   "x": "1973-08-01",
        //   "y": -49.72,
        //   "lake": "Mead",
        //   "color": "#E7A1324"
        // },
        // {
        //   "x": "1973-07-01",
        //   "y": -47.11,
        //   "lake": "Mead",
        //   "color": "#E7A1325"
        // },
        // {
        //   "x": "1973-06-01",
        //   "y": -45.66,
        //   "lake": "Mead",
        //   "color": "#E7A1326"
        // },
        // {
        //   "x": "1973-05-01",
        //   "y": -46.07,
        //   "lake": "Mead",
        //   "color": "#E7A1327"
        // },
        // {
        //   "x": "1973-04-01",
        //   "y": -45.86,
        //   "lake": "Mead",
        //   "color": "#E7A1328"
        // },
        // {
        //   "x": "1973-03-01",
        //   "y": -53.22,
        //   "lake": "Mead",
        //   "color": "#E7A1329"
        // },
        // {
        //   "x": "1973-02-01",
        //   "y": -57.27,
        //   "lake": "Mead",
        //   "color": "#E7A1330"
        // },
        // {
        //   "x": "1973-01-01",
        //   "y": -59.25,
        //   "lake": "Mead",
        //   "color": "#E7A1331"
        // },
        // {
        //   "x": "1972-12-01",
        //   "y": -63.65,
        //   "lake": "Mead",
        //   "color": "#E7A1332"
        // },
        // {
        //   "x": "1972-11-01",
        //   "y": -68.18,
        //   "lake": "Mead",
        //   "color": "#E7A1333"
        // },
        // {
        //   "x": "1972-10-01",
        //   "y": -70.41,
        //   "lake": "Mead",
        //   "color": "#E7A1334"
        // },
        // {
        //   "x": "1972-09-01",
        //   "y": -73.51,
        //   "lake": "Mead",
        //   "color": "#E7A1335"
        // },
        // {
        //   "x": "1972-08-01",
        //   "y": -75.72,
        //   "lake": "Mead",
        //   "color": "#E7A1336"
        // },
        // {
        //   "x": "1972-07-01",
        //   "y": -77.16,
        //   "lake": "Mead",
        //   "color": "#E7A1337"
        // },
        // {
        //   "x": "1972-06-01",
        //   "y": -77.4,
        //   "lake": "Mead",
        //   "color": "#E7A1338"
        // },
        // {
        //   "x": "1972-05-01",
        //   "y": -77.65,
        //   "lake": "Mead",
        //   "color": "#E7A1339"
        // },
        // {
        //   "x": "1972-04-01",
        //   "y": -77.26,
        //   "lake": "Mead",
        //   "color": "#E7A1340"
        // },
        // {
        //   "x": "1972-03-01",
        //   "y": -75.88,
        //   "lake": "Mead",
        //   "color": "#E7A1341"
        // },
        // {
        //   "x": "1972-02-01",
        //   "y": -71.06,
        //   "lake": "Mead",
        //   "color": "#E7A1342"
        // },
        // {
        //   "x": "1972-01-01",
        //   "y": -69.73,
        //   "lake": "Mead",
        //   "color": "#E7A1343"
        // },
        // {
        //   "x": "1971-12-01",
        //   "y": -71.87,
        //   "lake": "Mead",
        //   "color": "#E7A1344"
        // },
        // {
        //   "x": "1971-11-01",
        //   "y": -75.16,
        //   "lake": "Mead",
        //   "color": "#E7A1345"
        // },
        // {
        //   "x": "1971-10-01",
        //   "y": -77.6,
        //   "lake": "Mead",
        //   "color": "#E7A1346"
        // },
        // {
        //   "x": "1971-09-01",
        //   "y": -78.39,
        //   "lake": "Mead",
        //   "color": "#E7A1347"
        // },
        // {
        //   "x": "1971-08-01",
        //   "y": -79.15,
        //   "lake": "Mead",
        //   "color": "#E7A1348"
        // },
        // {
        //   "x": "1971-07-01",
        //   "y": -80.78,
        //   "lake": "Mead",
        //   "color": "#E7A1349"
        // },
        // {
        //   "x": "1971-06-01",
        //   "y": -82.15,
        //   "lake": "Mead",
        //   "color": "#E7A1350"
        // },
        // {
        //   "x": "1971-05-01",
        //   "y": -82.94,
        //   "lake": "Mead",
        //   "color": "#E7A1351"
        // },
        // {
        //   "x": "1971-04-01",
        //   "y": -83.37,
        //   "lake": "Mead",
        //   "color": "#E7A1352"
        // },
        // {
        //   "x": "1971-03-01",
        //   "y": -83.71,
        //   "lake": "Mead",
        //   "color": "#E7A1353"
        // },
        // {
        //   "x": "1971-02-01",
        //   "y": -81.6,
        //   "lake": "Mead",
        //   "color": "#E7A1354"
        // },
        // {
        //   "x": "1971-01-01",
        //   "y": -79.13,
        //   "lake": "Mead",
        //   "color": "#E7A1355"
        // },
        // {
        //   "x": "1970-12-01",
        //   "y": -79.05,
        //   "lake": "Mead",
        //   "color": "#E7A1356"
        // },
        // {
        //   "x": "1970-11-01",
        //   "y": -80.25,
        //   "lake": "Mead",
        //   "color": "#E7A1357"
        // },
        // {
        //   "x": "1970-10-01",
        //   "y": -80.23,
        //   "lake": "Mead",
        //   "color": "#E7A1358"
        // },
        // {
        //   "x": "1970-09-01",
        //   "y": -79.42,
        //   "lake": "Mead",
        //   "color": "#E7A1359"
        // },
        // {
        //   "x": "1970-08-01",
        //   "y": -80.67,
        //   "lake": "Mead",
        //   "color": "#E7A1360"
        // },
        // {
        //   "x": "1970-07-01",
        //   "y": -81.47,
        //   "lake": "Mead",
        //   "color": "#E7A1361"
        // },
        // {
        //   "x": "1970-06-01",
        //   "y": -81.27,
        //   "lake": "Mead",
        //   "color": "#E7A1362"
        // },
        // {
        //   "x": "1970-05-01",
        //   "y": -81.13,
        //   "lake": "Mead",
        //   "color": "#E7A1363"
        // },
        // {
        //   "x": "1970-04-01",
        //   "y": -81.2,
        //   "lake": "Mead",
        //   "color": "#E7A1364"
        // },
        // {
        //   "x": "1970-03-01",
        //   "y": -80.94,
        //   "lake": "Mead",
        //   "color": "#E7A1365"
        // },
        // {
        //   "x": "1970-02-01",
        //   "y": -78.68,
        //   "lake": "Mead",
        //   "color": "#E7A1366"
        // },
        // {
        //   "x": "1970-01-01",
        //   "y": -78.35,
        //   "lake": "Mead",
        //   "color": "#E7A1367"
        // },
        // {
        //   "x": "1969-12-01",
        //   "y": -79.5,
        //   "lake": "Mead",
        //   "color": "#E7A1368"
        // },
        // {
        //   "x": "1969-11-01",
        //   "y": -82.29,
        //   "lake": "Mead",
        //   "color": "#E7A1369"
        // },
        // {
        //   "x": "1969-10-01",
        //   "y": -84.78,
        //   "lake": "Mead",
        //   "color": "#E7A1370"
        // },
        // {
        //   "x": "1969-09-01",
        //   "y": -85.14,
        //   "lake": "Mead",
        //   "color": "#E7A1371"
        // },
        // {
        //   "x": "1969-08-01",
        //   "y": -86.69,
        //   "lake": "Mead",
        //   "color": "#E7A1372"
        // },
        // {
        //   "x": "1969-07-01",
        //   "y": -88.68,
        //   "lake": "Mead",
        //   "color": "#E7A1373"
        // },
        // {
        //   "x": "1969-06-01",
        //   "y": -90.21,
        //   "lake": "Mead",
        //   "color": "#E7A1374"
        // },
        // {
        //   "x": "1969-05-01",
        //   "y": -90.74,
        //   "lake": "Mead",
        //   "color": "#E7A1375"
        // },
        // {
        //   "x": "1969-04-01",
        //   "y": -91.21,
        //   "lake": "Mead",
        //   "color": "#E7A1376"
        // },
        // {
        //   "x": "1969-03-01",
        //   "y": -92.66,
        //   "lake": "Mead",
        //   "color": "#E7A1377"
        // },
        // {
        //   "x": "1969-02-01",
        //   "y": -91.33,
        //   "lake": "Mead",
        //   "color": "#E7A1378"
        // },
        // {
        //   "x": "1969-01-01",
        //   "y": -91.54,
        //   "lake": "Mead",
        //   "color": "#E7A1379"
        // },
        // {
        //   "x": "1968-12-01",
        //   "y": -92.35,
        //   "lake": "Mead",
        //   "color": "#E7A1380"
        // },
        // {
        //   "x": "1968-11-01",
        //   "y": -92.95,
        //   "lake": "Mead",
        //   "color": "#E7A1381"
        // },
        // {
        //   "x": "1968-10-01",
        //   "y": -94.54,
        //   "lake": "Mead",
        //   "color": "#E7A1382"
        // },
        // {
        //   "x": "1968-09-01",
        //   "y": -95.56,
        //   "lake": "Mead",
        //   "color": "#E7A1383"
        // },
        // {
        //   "x": "1968-08-01",
        //   "y": -95.11,
        //   "lake": "Mead",
        //   "color": "#E7A1384"
        // },
        // {
        //   "x": "1968-07-01",
        //   "y": -95.24,
        //   "lake": "Mead",
        //   "color": "#E7A1385"
        // },
        // {
        //   "x": "1968-06-01",
        //   "y": -95.77,
        //   "lake": "Mead",
        //   "color": "#E7A1386"
        // },
        // {
        //   "x": "1968-05-01",
        //   "y": -96.82,
        //   "lake": "Mead",
        //   "color": "#E7A1387"
        // },
        // {
        //   "x": "1968-04-01",
        //   "y": -97.85,
        //   "lake": "Mead",
        //   "color": "#E7A1388"
        // },
        // {
        //   "x": "1968-03-01",
        //   "y": -99.21,
        //   "lake": "Mead",
        //   "color": "#E7A1389"
        // },
        // {
        //   "x": "1968-02-01",
        //   "y": -99.46,
        //   "lake": "Mead",
        //   "color": "#E7A1390"
        // },
        // {
        //   "x": "1968-01-01",
        //   "y": -99.93,
        //   "lake": "Mead",
        //   "color": "#E7A1391"
        // },
        // {
        //   "x": "1967-12-01",
        //   "y": -102.16,
        //   "lake": "Mead",
        //   "color": "#E7A1392"
        // },
        // {
        //   "x": "1967-11-01",
        //   "y": -104.29,
        //   "lake": "Mead",
        //   "color": "#E7A1393"
        // },
        // {
        //   "x": "1967-10-01",
        //   "y": -103.33,
        //   "lake": "Mead",
        //   "color": "#E7A1394"
        // },
        // {
        //   "x": "1967-09-01",
        //   "y": -101.8,
        //   "lake": "Mead",
        //   "color": "#E7A1395"
        // },
        // {
        //   "x": "1967-08-01",
        //   "y": -103.56,
        //   "lake": "Mead",
        //   "color": "#E7A1396"
        // },
        // {
        //   "x": "1967-07-01",
        //   "y": -103.19,
        //   "lake": "Mead",
        //   "color": "#E7A1397"
        // },
        // {
        //   "x": "1967-06-01",
        //   "y": -101.6,
        //   "lake": "Mead",
        //   "color": "#E7A1398"
        // },
        // {
        //   "x": "1967-05-01",
        //   "y": -100.51,
        //   "lake": "Mead",
        //   "color": "#E7A1399"
        // },
        // {
        //   "x": "1967-04-01",
        //   "y": -100.28,
        //   "lake": "Mead",
        //   "color": "#E7A1400"
        // },
        // {
        //   "x": "1967-03-01",
        //   "y": -100.35,
        //   "lake": "Mead",
        //   "color": "#E7A1401"
        // },
        // {
        //   "x": "1967-02-01",
        //   "y": -98.69,
        //   "lake": "Mead",
        //   "color": "#E7A1402"
        // },
        // {
        //   "x": "1967-01-01",
        //   "y": -98.58,
        //   "lake": "Mead",
        //   "color": "#E7A1403"
        // },
        // {
        //   "x": "1966-12-01",
        //   "y": -99.95,
        //   "lake": "Mead",
        //   "color": "#E7A1404"
        // },
        // {
        //   "x": "1966-11-01",
        //   "y": -102.28,
        //   "lake": "Mead",
        //   "color": "#E7A1405"
        // },
        // {
        //   "x": "1966-10-01",
        //   "y": -103.58,
        //   "lake": "Mead",
        //   "color": "#E7A1406"
        // },
        // {
        //   "x": "1966-09-01",
        //   "y": -104.41,
        //   "lake": "Mead",
        //   "color": "#E7A1407"
        // },
        // {
        //   "x": "1966-08-01",
        //   "y": -103.79,
        //   "lake": "Mead",
        //   "color": "#E7A1408"
        // },
        // {
        //   "x": "1966-07-01",
        //   "y": -101.68,
        //   "lake": "Mead",
        //   "color": "#E7A1409"
        // },
        // {
        //   "x": "1966-06-01",
        //   "y": -99.3,
        //   "lake": "Mead",
        //   "color": "#E7A1410"
        // },
        // {
        //   "x": "1966-05-01",
        //   "y": -98.93,
        //   "lake": "Mead",
        //   "color": "#E7A1411"
        // },
        // {
        //   "x": "1966-04-01",
        //   "y": -99.85,
        //   "lake": "Mead",
        //   "color": "#E7A1412"
        // },
        // {
        //   "x": "1966-03-01",
        //   "y": -99.76,
        //   "lake": "Mead",
        //   "color": "#E7A1413"
        // },
        // {
        //   "x": "1966-02-01",
        //   "y": -98.95,
        //   "lake": "Mead",
        //   "color": "#E7A1414"
        // },
        // {
        //   "x": "1966-01-01",
        //   "y": -99.76,
        //   "lake": "Mead",
        //   "color": "#E7A1415"
        // },
        // {
        //   "x": "1965-12-01",
        //   "y": -102.26,
        //   "lake": "Mead",
        //   "color": "#E7A1416"
        // },
        // {
        //   "x": "1965-11-01",
        //   "y": -104.07,
        //   "lake": "Mead",
        //   "color": "#E7A1417"
        // },
        // {
        //   "x": "1965-10-01",
        //   "y": -106.1,
        //   "lake": "Mead",
        //   "color": "#E7A1418"
        // },
        // {
        //   "x": "1965-09-01",
        //   "y": -107.21,
        //   "lake": "Mead",
        //   "color": "#E7A1419"
        // },
        // {
        //   "x": "1965-08-01",
        //   "y": -107.68,
        //   "lake": "Mead",
        //   "color": "#E7A1420"
        // },
        // {
        //   "x": "1965-07-01",
        //   "y": -107.67,
        //   "lake": "Mead",
        //   "color": "#E7A1421"
        // },
        // {
        //   "x": "1965-06-01",
        //   "y": -106.31,
        //   "lake": "Mead",
        //   "color": "#E7A1422"
        // },
        // {
        //   "x": "1965-05-01",
        //   "y": -121.92,
        //   "lake": "Mead",
        //   "color": "#E7A1423"
        // },
        // {
        //   "x": "1965-04-01",
        //   "y": -137.43,
        //   "lake": "Mead",
        //   "color": "#E7A1424"
        // },
        // {
        //   "x": "1965-03-01",
        //   "y": -143.69,
        //   "lake": "Mead",
        //   "color": "#E7A1425"
        // },
        // {
        //   "x": "1965-02-01",
        //   "y": -141.37,
        //   "lake": "Mead",
        //   "color": "#E7A1426"
        // },
        // {
        //   "x": "1965-01-01",
        //   "y": -142.17,
        //   "lake": "Mead",
        //   "color": "#E7A1427"
        // },
        // {
        //   "x": "1964-12-01",
        //   "y": -143.86,
        //   "lake": "Mead",
        //   "color": "#E7A1428"
        // },
        // {
        //   "x": "1964-11-01",
        //   "y": -142.98,
        //   "lake": "Mead",
        //   "color": "#E7A1429"
        // },
        // {
        //   "x": "1964-10-01",
        //   "y": -141.88,
        //   "lake": "Mead",
        //   "color": "#E7A1430"
        // },
        // {
        //   "x": "1964-09-01",
        //   "y": -138.51,
        //   "lake": "Mead",
        //   "color": "#E7A1431"
        // },
        // {
        //   "x": "1964-08-01",
        //   "y": -133.42,
        //   "lake": "Mead",
        //   "color": "#E7A1432"
        // },
        // {
        //   "x": "1964-07-01",
        //   "y": -128.16,
        //   "lake": "Mead",
        //   "color": "#E7A1433"
        // },
        // {
        //   "x": "1964-06-01",
        //   "y": -119.53,
        //   "lake": "Mead",
        //   "color": "#E7A1434"
        // },
        // {
        //   "x": "1964-05-01",
        //   "y": -112.68,
        //   "lake": "Mead",
        //   "color": "#E7A1435"
        // },
        // {
        //   "x": "1964-04-01",
        //   "y": -108.59,
        //   "lake": "Mead",
        //   "color": "#E7A1436"
        // },
        // {
        //   "x": "1964-03-01",
        //   "y": -108.15,
        //   "lake": "Mead",
        //   "color": "#E7A1437"
        // },
        // {
        //   "x": "1964-02-01",
        //   "y": -103.6,
        //   "lake": "Mead",
        //   "color": "#E7A1438"
        // },
        // {
        //   "x": "1964-01-01",
        //   "y": -100.26,
        //   "lake": "Mead",
        //   "color": "#E7A1439"
        // },
        // {
        //   "x": "1963-12-01",
        //   "y": -95.07,
        //   "lake": "Mead",
        //   "color": "#E7A1440"
        // },
        // {
        //   "x": "1963-11-01",
        //   "y": -90.5,
        //   "lake": "Mead",
        //   "color": "#E7A1441"
        // },
        // {
        //   "x": "1963-10-01",
        //   "y": -87.01,
        //   "lake": "Mead",
        //   "color": "#E7A1442"
        // },
        // {
        //   "x": "1963-09-01",
        //   "y": -82.96,
        //   "lake": "Mead",
        //   "color": "#E7A1443"
        // },
        // {
        //   "x": "1963-08-01",
        //   "y": -78.12,
        //   "lake": "Mead",
        //   "color": "#E7A1444"
        // },
        // {
        //   "x": "1963-07-01",
        //   "y": -71.73,
        //   "lake": "Mead",
        //   "color": "#E7A1445"
        // },
        // {
        //   "x": "1963-06-01",
        //   "y": -64.84,
        //   "lake": "Mead",
        //   "color": "#E7A1446"
        // },
        // {
        //   "x": "1963-05-01",
        //   "y": -59.57,
        //   "lake": "Mead",
        //   "color": "#E7A1447"
        // },
        // {
        //   "x": "1963-04-01",
        //   "y": -52.94,
        //   "lake": "Mead",
        //   "color": "#E7A1448"
        // },
        // {
        //   "x": "1963-03-01",
        //   "y": -46.93,
        //   "lake": "Mead",
        //   "color": "#E7A1449"
        // },
        // {
        //   "x": "1963-02-01",
        //   "y": -42.33,
        //   "lake": "Mead",
        //   "color": "#E7A1450"
        // },
        // {
        //   "x": "1963-01-01",
        //   "y": -41.02,
        //   "lake": "Mead",
        //   "color": "#E7A1451"
        // },
        // {
        //   "x": "1962-12-01",
        //   "y": -38.88,
        //   "lake": "Mead",
        //   "color": "#E7A1452"
        // },
        // {
        //   "x": "1962-11-01",
        //   "y": -36.95,
        //   "lake": "Mead",
        //   "color": "#E7A1453"
        // },
        // {
        //   "x": "1962-10-01",
        //   "y": -35.35,
        //   "lake": "Mead",
        //   "color": "#E7A1454"
        // },
        // {
        //   "x": "1962-09-01",
        //   "y": -34.36,
        //   "lake": "Mead",
        //   "color": "#E7A1455"
        // },
        // {
        //   "x": "1962-08-01",
        //   "y": -31.13,
        //   "lake": "Mead",
        //   "color": "#E7A1456"
        // },
        // {
        //   "x": "1962-07-01",
        //   "y": -28.02,
        //   "lake": "Mead",
        //   "color": "#E7A1457"
        // },
        // {
        //   "x": "1962-06-01",
        //   "y": -33.83,
        //   "lake": "Mead",
        //   "color": "#E7A1458"
        // },
        // {
        //   "x": "1962-05-01",
        //   "y": -46.44,
        //   "lake": "Mead",
        //   "color": "#E7A1459"
        // },
        // {
        //   "x": "1962-04-01",
        //   "y": -66.25,
        //   "lake": "Mead",
        //   "color": "#E7A1460"
        // },
        // {
        //   "x": "1962-03-01",
        //   "y": -77.31,
        //   "lake": "Mead",
        //   "color": "#E7A1461"
        // },
        // {
        //   "x": "1962-02-01",
        //   "y": -75.49,
        //   "lake": "Mead",
        //   "color": "#E7A1462"
        // },
        // {
        //   "x": "1962-01-01",
        //   "y": -78.44,
        //   "lake": "Mead",
        //   "color": "#E7A1463"
        // },
        // {
        //   "x": "1961-12-01",
        //   "y": -77.36,
        //   "lake": "Mead",
        //   "color": "#E7A1464"
        // },
        // {
        //   "x": "1961-11-01",
        //   "y": -76.78,
        //   "lake": "Mead",
        //   "color": "#E7A1465"
        // },
        // {
        //   "x": "1961-10-01",
        //   "y": -76.85,
        //   "lake": "Mead",
        //   "color": "#E7A1466"
        // },
        // {
        //   "x": "1961-09-01",
        //   "y": -78.26,
        //   "lake": "Mead",
        //   "color": "#E7A1467"
        // },
        // {
        //   "x": "1961-08-01",
        //   "y": -77.95,
        //   "lake": "Mead",
        //   "color": "#E7A1468"
        // },
        // {
        //   "x": "1961-07-01",
        //   "y": -74.38,
        //   "lake": "Mead",
        //   "color": "#E7A1469"
        // },
        // {
        //   "x": "1961-06-01",
        //   "y": -70.81,
        //   "lake": "Mead",
        //   "color": "#E7A1470"
        // },
        // {
        //   "x": "1961-05-01",
        //   "y": -77.55,
        //   "lake": "Mead",
        //   "color": "#E7A1471"
        // },
        // {
        //   "x": "1961-04-01",
        //   "y": -78.44,
        //   "lake": "Mead",
        //   "color": "#E7A1472"
        // },
        // {
        //   "x": "1961-03-01",
        //   "y": -75.81,
        //   "lake": "Mead",
        //   "color": "#E7A1473"
        // },
        // {
        //   "x": "1961-02-01",
        //   "y": -71.25,
        //   "lake": "Mead",
        //   "color": "#E7A1474"
        // },
        // {
        //   "x": "1961-01-01",
        //   "y": -69.33,
        //   "lake": "Mead",
        //   "color": "#E7A1475"
        // },
        // {
        //   "x": "1960-12-01",
        //   "y": -66.88,
        //   "lake": "Mead",
        //   "color": "#E7A1476"
        // },
        // {
        //   "x": "1960-11-01",
        //   "y": -64.54,
        //   "lake": "Mead",
        //   "color": "#E7A1477"
        // },
        // {
        //   "x": "1960-10-01",
        //   "y": -63.53,
        //   "lake": "Mead",
        //   "color": "#E7A1478"
        // },
        // {
        //   "x": "1960-09-01",
        //   "y": -61.64,
        //   "lake": "Mead",
        //   "color": "#E7A1479"
        // },
        // {
        //   "x": "1960-08-01",
        //   "y": -56.75,
        //   "lake": "Mead",
        //   "color": "#E7A1480"
        // },
        // {
        //   "x": "1960-07-01",
        //   "y": -50.68,
        //   "lake": "Mead",
        //   "color": "#E7A1481"
        // },
        // {
        //   "x": "1960-06-01",
        //   "y": -48.29,
        //   "lake": "Mead",
        //   "color": "#E7A1482"
        // },
        // {
        //   "x": "1960-05-01",
        //   "y": -56.91,
        //   "lake": "Mead",
        //   "color": "#E7A1483"
        // },
        // {
        //   "x": "1960-04-01",
        //   "y": -62.06,
        //   "lake": "Mead",
        //   "color": "#E7A1484"
        // },
        // {
        //   "x": "1960-03-01",
        //   "y": -67.74,
        //   "lake": "Mead",
        //   "color": "#E7A1485"
        // },
        // {
        //   "x": "1960-02-01",
        //   "y": -68.22,
        //   "lake": "Mead",
        //   "color": "#E7A1486"
        // },
        // {
        //   "x": "1960-01-01",
        //   "y": -66.91,
        //   "lake": "Mead",
        //   "color": "#E7A1487"
        // },
        // {
        //   "x": "1959-12-01",
        //   "y": -64.72,
        //   "lake": "Mead",
        //   "color": "#E7A1488"
        // },
        // {
        //   "x": "1959-11-01",
        //   "y": -63.15,
        //   "lake": "Mead",
        //   "color": "#E7A1489"
        // },
        // {
        //   "x": "1959-10-01",
        //   "y": -62.65,
        //   "lake": "Mead",
        //   "color": "#E7A1490"
        // },
        // {
        //   "x": "1959-09-01",
        //   "y": -60.87,
        //   "lake": "Mead",
        //   "color": "#E7A1491"
        // },
        // {
        //   "x": "1959-08-01",
        //   "y": -56.35,
        //   "lake": "Mead",
        //   "color": "#E7A1492"
        // },
        // {
        //   "x": "1959-07-01",
        //   "y": -52.73,
        //   "lake": "Mead",
        //   "color": "#E7A1493"
        // },
        // {
        //   "x": "1959-06-01",
        //   "y": -52.16,
        //   "lake": "Mead",
        //   "color": "#E7A1494"
        // },
        // {
        //   "x": "1959-05-01",
        //   "y": -59.41,
        //   "lake": "Mead",
        //   "color": "#E7A1495"
        // },
        // {
        //   "x": "1959-04-01",
        //   "y": -59.17,
        //   "lake": "Mead",
        //   "color": "#E7A1496"
        // },
        // {
        //   "x": "1959-03-01",
        //   "y": -55.4,
        //   "lake": "Mead",
        //   "color": "#E7A1497"
        // },
        // {
        //   "x": "1959-02-01",
        //   "y": -49.97,
        //   "lake": "Mead",
        //   "color": "#E7A1498"
        // },
        // {
        //   "x": "1959-01-01",
        //   "y": -49.69,
        //   "lake": "Mead",
        //   "color": "#E7A1499"
        // },
        // {
        //   "x": "1958-12-01",
        //   "y": -46.15,
        //   "lake": "Mead",
        //   "color": "#E7A1500"
        // },
        // {
        //   "x": "1958-11-01",
        //   "y": -42.41,
        //   "lake": "Mead",
        //   "color": "#E7A1501"
        // },
        // {
        //   "x": "1958-10-01",
        //   "y": -39.55,
        //   "lake": "Mead",
        //   "color": "#E7A1502"
        // },
        // {
        //   "x": "1958-09-01",
        //   "y": -36.43,
        //   "lake": "Mead",
        //   "color": "#E7A1503"
        // },
        // {
        //   "x": "1958-08-01",
        //   "y": -33.03,
        //   "lake": "Mead",
        //   "color": "#E7A1504"
        // },
        // {
        //   "x": "1958-07-01",
        //   "y": -28.61,
        //   "lake": "Mead",
        //   "color": "#E7A1505"
        // },
        // {
        //   "x": "1958-06-01",
        //   "y": -26.57,
        //   "lake": "Mead",
        //   "color": "#E7A1506"
        // },
        // {
        //   "x": "1958-05-01",
        //   "y": -46.48,
        //   "lake": "Mead",
        //   "color": "#E7A1507"
        // },
        // {
        //   "x": "1958-04-01",
        //   "y": -67.39,
        //   "lake": "Mead",
        //   "color": "#E7A1508"
        // },
        // {
        //   "x": "1958-03-01",
        //   "y": -68.36,
        //   "lake": "Mead",
        //   "color": "#E7A1509"
        // },
        // {
        //   "x": "1958-02-01",
        //   "y": -63.44,
        //   "lake": "Mead",
        //   "color": "#E7A1510"
        // },
        // {
        //   "x": "1958-01-01",
        //   "y": -61.05,
        //   "lake": "Mead",
        //   "color": "#E7A1511"
        // },
        // {
        //   "x": "1957-12-01",
        //   "y": -54.61,
        //   "lake": "Mead",
        //   "color": "#E7A1512"
        // },
        // {
        //   "x": "1957-11-01",
        //   "y": -50.39,
        //   "lake": "Mead",
        //   "color": "#E7A1513"
        // },
        // {
        //   "x": "1957-10-01",
        //   "y": -49.5,
        //   "lake": "Mead",
        //   "color": "#E7A1514"
        // },
        // {
        //   "x": "1957-09-01",
        //   "y": -49.48,
        //   "lake": "Mead",
        //   "color": "#E7A1515"
        // },
        // {
        //   "x": "1957-08-01",
        //   "y": -49.66,
        //   "lake": "Mead",
        //   "color": "#E7A1516"
        // },
        // {
        //   "x": "1957-07-01",
        //   "y": -55.92,
        //   "lake": "Mead",
        //   "color": "#E7A1517"
        // },
        // {
        //   "x": "1957-06-01",
        //   "y": -81.19,
        //   "lake": "Mead",
        //   "color": "#E7A1518"
        // },
        // {
        //   "x": "1957-05-01",
        //   "y": -122.69,
        //   "lake": "Mead",
        //   "color": "#E7A1519"
        // },
        // {
        //   "x": "1957-04-01",
        //   "y": -141,
        //   "lake": "Mead",
        //   "color": "#E7A1520"
        // },
        // {
        //   "x": "1957-03-01",
        //   "y": -139.76,
        //   "lake": "Mead",
        //   "color": "#E7A1521"
        // },
        // {
        //   "x": "1957-02-01",
        //   "y": -137.68,
        //   "lake": "Mead",
        //   "color": "#E7A1522"
        // },
        // {
        //   "x": "1957-01-01",
        //   "y": -136.95,
        //   "lake": "Mead",
        //   "color": "#E7A1523"
        // },
        // {
        //   "x": "1956-12-01",
        //   "y": -134.98,
        //   "lake": "Mead",
        //   "color": "#E7A1524"
        // },
        // {
        //   "x": "1956-11-01",
        //   "y": -132.86,
        //   "lake": "Mead",
        //   "color": "#E7A1525"
        // },
        // {
        //   "x": "1956-10-01",
        //   "y": -129.95,
        //   "lake": "Mead",
        //   "color": "#E7A1526"
        // },
        // {
        //   "x": "1956-09-01",
        //   "y": -126.24,
        //   "lake": "Mead",
        //   "color": "#E7A1527"
        // },
        // {
        //   "x": "1956-08-01",
        //   "y": -121.34,
        //   "lake": "Mead",
        //   "color": "#E7A1528"
        // },
        // {
        //   "x": "1956-07-01",
        //   "y": -117.42,
        //   "lake": "Mead",
        //   "color": "#E7A1529"
        // },
        // {
        //   "x": "1956-06-01",
        //   "y": -115.38,
        //   "lake": "Mead",
        //   "color": "#E7A1530"
        // },
        // {
        //   "x": "1956-05-01",
        //   "y": -134,
        //   "lake": "Mead",
        //   "color": "#E7A1531"
        // },
        // {
        //   "x": "1956-04-01",
        //   "y": -148.19,
        //   "lake": "Mead",
        //   "color": "#E7A1532"
        // },
        // {
        //   "x": "1956-03-01",
        //   "y": -148.43,
        //   "lake": "Mead",
        //   "color": "#E7A1533"
        // },
        // {
        //   "x": "1956-02-01",
        //   "y": -144.94,
        //   "lake": "Mead",
        //   "color": "#E7A1534"
        // },
        // {
        //   "x": "1956-01-01",
        //   "y": -142.8,
        //   "lake": "Mead",
        //   "color": "#E7A1535"
        // },
        // {
        //   "x": "1955-12-01",
        //   "y": -141.01,
        //   "lake": "Mead",
        //   "color": "#E7A1536"
        // },
        // {
        //   "x": "1955-11-01",
        //   "y": -139.37,
        //   "lake": "Mead",
        //   "color": "#E7A1537"
        // },
        // {
        //   "x": "1955-10-01",
        //   "y": -136.89,
        //   "lake": "Mead",
        //   "color": "#E7A1538"
        // },
        // {
        //   "x": "1955-09-01",
        //   "y": -133.5,
        //   "lake": "Mead",
        //   "color": "#E7A1539"
        // },
        // {
        //   "x": "1955-08-01",
        //   "y": -129.22,
        //   "lake": "Mead",
        //   "color": "#E7A1540"
        // },
        // {
        //   "x": "1955-07-01",
        //   "y": -128.35,
        //   "lake": "Mead",
        //   "color": "#E7A1541"
        // },
        // {
        //   "x": "1955-06-01",
        //   "y": -126.09,
        //   "lake": "Mead",
        //   "color": "#E7A1542"
        // },
        // {
        //   "x": "1955-05-01",
        //   "y": -136.1,
        //   "lake": "Mead",
        //   "color": "#E7A1543"
        // },
        // {
        //   "x": "1955-04-01",
        //   "y": -142.28,
        //   "lake": "Mead",
        //   "color": "#E7A1544"
        // },
        // {
        //   "x": "1955-03-01",
        //   "y": -139.15,
        //   "lake": "Mead",
        //   "color": "#E7A1545"
        // },
        // {
        //   "x": "1955-02-01",
        //   "y": -135.86,
        //   "lake": "Mead",
        //   "color": "#E7A1546"
        // },
        // {
        //   "x": "1955-01-01",
        //   "y": -131.23,
        //   "lake": "Mead",
        //   "color": "#E7A1547"
        // },
        // {
        //   "x": "1954-12-01",
        //   "y": -126.6,
        //   "lake": "Mead",
        //   "color": "#E7A1548"
        // },
        // {
        //   "x": "1954-11-01",
        //   "y": -122.26,
        //   "lake": "Mead",
        //   "color": "#E7A1549"
        // },
        // {
        //   "x": "1954-10-01",
        //   "y": -119.1,
        //   "lake": "Mead",
        //   "color": "#E7A1550"
        // },
        // {
        //   "x": "1954-09-01",
        //   "y": -116.61,
        //   "lake": "Mead",
        //   "color": "#E7A1551"
        // },
        // {
        //   "x": "1954-08-01",
        //   "y": -111.18,
        //   "lake": "Mead",
        //   "color": "#E7A1552"
        // },
        // {
        //   "x": "1954-07-01",
        //   "y": -105.75,
        //   "lake": "Mead",
        //   "color": "#E7A1553"
        // },
        // {
        //   "x": "1954-06-01",
        //   "y": -102.12,
        //   "lake": "Mead",
        //   "color": "#E7A1554"
        // },
        // {
        //   "x": "1954-05-01",
        //   "y": -101.24,
        //   "lake": "Mead",
        //   "color": "#E7A1555"
        // },
        // {
        //   "x": "1954-04-01",
        //   "y": -101.74,
        //   "lake": "Mead",
        //   "color": "#E7A1556"
        // },
        // {
        //   "x": "1954-03-01",
        //   "y": -97.92,
        //   "lake": "Mead",
        //   "color": "#E7A1557"
        // },
        // {
        //   "x": "1954-02-01",
        //   "y": -93.78,
        //   "lake": "Mead",
        //   "color": "#E7A1558"
        // },
        // {
        //   "x": "1954-01-01",
        //   "y": -90.56,
        //   "lake": "Mead",
        //   "color": "#E7A1559"
        // },
        // {
        //   "x": "1953-12-01",
        //   "y": -86.22,
        //   "lake": "Mead",
        //   "color": "#E7A1560"
        // },
        // {
        //   "x": "1953-11-01",
        //   "y": -81.94,
        //   "lake": "Mead",
        //   "color": "#E7A1561"
        // },
        // {
        //   "x": "1953-10-01",
        //   "y": -79.01,
        //   "lake": "Mead",
        //   "color": "#E7A1562"
        // },
        // {
        //   "x": "1953-09-01",
        //   "y": -74.33,
        //   "lake": "Mead",
        //   "color": "#E7A1563"
        // },
        // {
        //   "x": "1953-08-01",
        //   "y": -68.62,
        //   "lake": "Mead",
        //   "color": "#E7A1564"
        // },
        // {
        //   "x": "1953-07-01",
        //   "y": -66.13,
        //   "lake": "Mead",
        //   "color": "#E7A1565"
        // },
        // {
        //   "x": "1953-06-01",
        //   "y": -66.89,
        //   "lake": "Mead",
        //   "color": "#E7A1566"
        // },
        // {
        //   "x": "1953-05-01",
        //   "y": -84.67,
        //   "lake": "Mead",
        //   "color": "#E7A1567"
        // },
        // {
        //   "x": "1953-04-01",
        //   "y": -83.6,
        //   "lake": "Mead",
        //   "color": "#E7A1568"
        // },
        // {
        //   "x": "1953-03-01",
        //   "y": -79.58,
        //   "lake": "Mead",
        //   "color": "#E7A1569"
        // },
        // {
        //   "x": "1953-02-01",
        //   "y": -74.88,
        //   "lake": "Mead",
        //   "color": "#E7A1570"
        // },
        // {
        //   "x": "1953-01-01",
        //   "y": -69.44,
        //   "lake": "Mead",
        //   "color": "#E7A1571"
        // },
        // {
        //   "x": "1952-12-01",
        //   "y": -62.9,
        //   "lake": "Mead",
        //   "color": "#E7A1572"
        // },
        // {
        //   "x": "1952-11-01",
        //   "y": -55.61,
        //   "lake": "Mead",
        //   "color": "#E7A1573"
        // },
        // {
        //   "x": "1952-10-01",
        //   "y": -48.87,
        //   "lake": "Mead",
        //   "color": "#E7A1574"
        // },
        // {
        //   "x": "1952-09-01",
        //   "y": -42,
        //   "lake": "Mead",
        //   "color": "#E7A1575"
        // },
        // {
        //   "x": "1952-08-01",
        //   "y": -36.26,
        //   "lake": "Mead",
        //   "color": "#E7A1576"
        // },
        // {
        //   "x": "1952-07-01",
        //   "y": -32.12,
        //   "lake": "Mead",
        //   "color": "#E7A1577"
        // },
        // {
        //   "x": "1952-06-01",
        //   "y": -33.94,
        //   "lake": "Mead",
        //   "color": "#E7A1578"
        // },
        // {
        //   "x": "1952-05-01",
        //   "y": -60.47,
        //   "lake": "Mead",
        //   "color": "#E7A1579"
        // },
        // {
        //   "x": "1952-04-01",
        //   "y": -90.04,
        //   "lake": "Mead",
        //   "color": "#E7A1580"
        // },
        // {
        //   "x": "1952-03-01",
        //   "y": -98.01,
        //   "lake": "Mead",
        //   "color": "#E7A1581"
        // },
        // {
        //   "x": "1952-02-01",
        //   "y": -89,
        //   "lake": "Mead",
        //   "color": "#E7A1582"
        // },
        // {
        //   "x": "1952-01-01",
        //   "y": -82.94,
        //   "lake": "Mead",
        //   "color": "#E7A1583"
        // },
        // {
        //   "x": "1951-12-01",
        //   "y": -78.88,
        //   "lake": "Mead",
        //   "color": "#E7A1584"
        // },
        // {
        //   "x": "1951-11-01",
        //   "y": -74.75,
        //   "lake": "Mead",
        //   "color": "#E7A1585"
        // },
        // {
        //   "x": "1951-10-01",
        //   "y": -71.58,
        //   "lake": "Mead",
        //   "color": "#E7A1586"
        // },
        // {
        //   "x": "1951-09-01",
        //   "y": -68.25,
        //   "lake": "Mead",
        //   "color": "#E7A1587"
        // },
        // {
        //   "x": "1951-08-01",
        //   "y": -65,
        //   "lake": "Mead",
        //   "color": "#E7A1588"
        // },
        // {
        //   "x": "1951-07-01",
        //   "y": -63.81,
        //   "lake": "Mead",
        //   "color": "#E7A1589"
        // },
        // {
        //   "x": "1951-06-01",
        //   "y": -68.47,
        //   "lake": "Mead",
        //   "color": "#E7A1590"
        // },
        // {
        //   "x": "1951-05-01",
        //   "y": -85.84,
        //   "lake": "Mead",
        //   "color": "#E7A1591"
        // },
        // {
        //   "x": "1951-04-01",
        //   "y": -90.66,
        //   "lake": "Mead",
        //   "color": "#E7A1592"
        // },
        // {
        //   "x": "1951-03-01",
        //   "y": -87.93,
        //   "lake": "Mead",
        //   "color": "#E7A1593"
        // },
        // {
        //   "x": "1951-02-01",
        //   "y": -63.98,
        //   "lake": "Mead",
        //   "color": "#E7A1594"
        // },
        // {
        //   "x": "1951-01-01",
        //   "y": -80.53,
        //   "lake": "Mead",
        //   "color": "#E7A1595"
        // },
        // {
        //   "x": "1950-12-01",
        //   "y": -75.38,
        //   "lake": "Mead",
        //   "color": "#E7A1596"
        // },
        // {
        //   "x": "1950-11-01",
        //   "y": -71.81,
        //   "lake": "Mead",
        //   "color": "#E7A1597"
        // },
        // {
        //   "x": "1950-10-01",
        //   "y": -67.57,
        //   "lake": "Mead",
        //   "color": "#E7A1598"
        // },
        // {
        //   "x": "1950-09-01",
        //   "y": -63.23,
        //   "lake": "Mead",
        //   "color": "#E7A1599"
        // },
        // {
        //   "x": "1950-08-01",
        //   "y": -58.64,
        //   "lake": "Mead",
        //   "color": "#E7A1600"
        // },
        // {
        //   "x": "1950-07-01",
        //   "y": -54.85,
        //   "lake": "Mead",
        //   "color": "#E7A1601"
        // },
        // {
        //   "x": "1950-06-01",
        //   "y": -58.05,
        //   "lake": "Mead",
        //   "color": "#E7A1602"
        // },
        // {
        //   "x": "1950-05-01",
        //   "y": -73.43,
        //   "lake": "Mead",
        //   "color": "#E7A1603"
        // },
        // {
        //   "x": "1950-04-01",
        //   "y": -79.87,
        //   "lake": "Mead",
        //   "color": "#E7A1604"
        // },
        // {
        //   "x": "1950-03-01",
        //   "y": -80.25,
        //   "lake": "Mead",
        //   "color": "#E7A1605"
        // },
        // {
        //   "x": "1950-02-01",
        //   "y": -75.3,
        //   "lake": "Mead",
        //   "color": "#E7A1606"
        // },
        // {
        //   "x": "1950-01-01",
        //   "y": -68.6,
        //   "lake": "Mead",
        //   "color": "#E7A1607"
        // },
        // {
        //   "x": "1949-12-01",
        //   "y": -61.94,
        //   "lake": "Mead",
        //   "color": "#E7A1608"
        // },
        // {
        //   "x": "1949-11-01",
        //   "y": -55.07,
        //   "lake": "Mead",
        //   "color": "#E7A1609"
        // },
        // {
        //   "x": "1949-10-01",
        //   "y": -50.81,
        //   "lake": "Mead",
        //   "color": "#E7A1610"
        // },
        // {
        //   "x": "1949-09-01",
        //   "y": -45.14,
        //   "lake": "Mead",
        //   "color": "#E7A1611"
        // },
        // {
        //   "x": "1949-08-01",
        //   "y": -38.83,
        //   "lake": "Mead",
        //   "color": "#E7A1612"
        // },
        // {
        //   "x": "1949-07-01",
        //   "y": -35.39,
        //   "lake": "Mead",
        //   "color": "#E7A1613"
        // },
        // {
        //   "x": "1949-06-01",
        //   "y": -42.93,
        //   "lake": "Mead",
        //   "color": "#E7A1614"
        // },
        // {
        //   "x": "1949-05-01",
        //   "y": -67.49,
        //   "lake": "Mead",
        //   "color": "#E7A1615"
        // },
        // {
        //   "x": "1949-04-01",
        //   "y": -83.72,
        //   "lake": "Mead",
        //   "color": "#E7A1616"
        // },
        // {
        //   "x": "1949-03-01",
        //   "y": -84.83,
        //   "lake": "Mead",
        //   "color": "#E7A1617"
        // },
        // {
        //   "x": "1949-02-01",
        //   "y": -81.01,
        //   "lake": "Mead",
        //   "color": "#E7A1618"
        // },
        // {
        //   "x": "1949-01-01",
        //   "y": -73.96,
        //   "lake": "Mead",
        //   "color": "#E7A1619"
        // },
        // {
        //   "x": "1948-12-01",
        //   "y": -67.15,
        //   "lake": "Mead",
        //   "color": "#E7A1620"
        // },
        // {
        //   "x": "1948-11-01",
        //   "y": -61.09,
        //   "lake": "Mead",
        //   "color": "#E7A1621"
        // },
        // {
        //   "x": "1948-10-01",
        //   "y": -56.11,
        //   "lake": "Mead",
        //   "color": "#E7A1622"
        // },
        // {
        //   "x": "1948-09-01",
        //   "y": -51.18,
        //   "lake": "Mead",
        //   "color": "#E7A1623"
        // },
        // {
        //   "x": "1948-08-01",
        //   "y": -45.05,
        //   "lake": "Mead",
        //   "color": "#E7A1624"
        // },
        // {
        //   "x": "1948-07-01",
        //   "y": -41.68,
        //   "lake": "Mead",
        //   "color": "#E7A1625"
        // },
        // {
        //   "x": "1948-06-01",
        //   "y": -39.85,
        //   "lake": "Mead",
        //   "color": "#E7A1626"
        // },
        // {
        //   "x": "1948-05-01",
        //   "y": -56.05,
        //   "lake": "Mead",
        //   "color": "#E7A1627"
        // },
        // {
        //   "x": "1948-04-01",
        //   "y": -73.23,
        //   "lake": "Mead",
        //   "color": "#E7A1628"
        // },
        // {
        //   "x": "1948-03-01",
        //   "y": -77.49,
        //   "lake": "Mead",
        //   "color": "#E7A1629"
        // },
        // {
        //   "x": "1948-02-01",
        //   "y": -73.2,
        //   "lake": "Mead",
        //   "color": "#E7A1630"
        // },
        // {
        //   "x": "1948-01-01",
        //   "y": -67.48,
        //   "lake": "Mead",
        //   "color": "#E7A1631"
        // },
        // {
        //   "x": "1947-12-01",
        //   "y": -61.42,
        //   "lake": "Mead",
        //   "color": "#E7A1632"
        // },
        // {
        //   "x": "1947-11-01",
        //   "y": -56.76,
        //   "lake": "Mead",
        //   "color": "#E7A1633"
        // },
        // {
        //   "x": "1947-10-01",
        //   "y": -54.12,
        //   "lake": "Mead",
        //   "color": "#E7A1634"
        // },
        // {
        //   "x": "1947-09-01",
        //   "y": -53.98,
        //   "lake": "Mead",
        //   "color": "#E7A1635"
        // },
        // {
        //   "x": "1947-08-01",
        //   "y": -51.87,
        //   "lake": "Mead",
        //   "color": "#E7A1636"
        // },
        // {
        //   "x": "1947-07-01",
        //   "y": -54.82,
        //   "lake": "Mead",
        //   "color": "#E7A1637"
        // },
        // {
        //   "x": "1947-06-01",
        //   "y": -62.36,
        //   "lake": "Mead",
        //   "color": "#E7A1638"
        // },
        // {
        //   "x": "1947-05-01",
        //   "y": -80.07,
        //   "lake": "Mead",
        //   "color": "#E7A1639"
        // },
        // {
        //   "x": "1947-04-01",
        //   "y": -97.51,
        //   "lake": "Mead",
        //   "color": "#E7A1640"
        // },
        // {
        //   "x": "1947-03-01",
        //   "y": -96.62,
        //   "lake": "Mead",
        //   "color": "#E7A1641"
        // },
        // {
        //   "x": "1947-02-01",
        //   "y": -98.9,
        //   "lake": "Mead",
        //   "color": "#E7A1642"
        // },
        // {
        //   "x": "1947-01-01",
        //   "y": -89.4,
        //   "lake": "Mead",
        //   "color": "#E7A1643"
        // },
        // {
        //   "x": "1946-12-01",
        //   "y": -83.45,
        //   "lake": "Mead",
        //   "color": "#E7A1644"
        // },
        // {
        //   "x": "1946-11-01",
        //   "y": -80.35,
        //   "lake": "Mead",
        //   "color": "#E7A1645"
        // },
        // {
        //   "x": "1946-10-01",
        //   "y": -78.1,
        //   "lake": "Mead",
        //   "color": "#E7A1646"
        // },
        // {
        //   "x": "1946-09-01",
        //   "y": -74.35,
        //   "lake": "Mead",
        //   "color": "#E7A1647"
        // },
        // {
        //   "x": "1946-08-01",
        //   "y": -70.75,
        //   "lake": "Mead",
        //   "color": "#E7A1648"
        // },
        // {
        //   "x": "1946-07-01",
        //   "y": -68.7,
        //   "lake": "Mead",
        //   "color": "#E7A1649"
        // },
        // {
        //   "x": "1946-06-01",
        //   "y": -68.05,
        //   "lake": "Mead",
        //   "color": "#E7A1650"
        // },
        // {
        //   "x": "1946-05-01",
        //   "y": -76.85,
        //   "lake": "Mead",
        //   "color": "#E7A1651"
        // },
        // {
        //   "x": "1946-04-01",
        //   "y": -83.8,
        //   "lake": "Mead",
        //   "color": "#E7A1652"
        // },
        // {
        //   "x": "1946-03-01",
        //   "y": -84.55,
        //   "lake": "Mead",
        //   "color": "#E7A1653"
        // },
        // {
        //   "x": "1946-02-01",
        //   "y": -80.35,
        //   "lake": "Mead",
        //   "color": "#E7A1654"
        // },
        // {
        //   "x": "1946-01-01",
        //   "y": -74.45,
        //   "lake": "Mead",
        //   "color": "#E7A1655"
        // },
        // {
        //   "x": "1945-12-01",
        //   "y": -68.65,
        //   "lake": "Mead",
        //   "color": "#E7A1656"
        // },
        // {
        //   "x": "1945-11-01",
        //   "y": -63.05,
        //   "lake": "Mead",
        //   "color": "#E7A1657"
        // },
        // {
        //   "x": "1945-10-01",
        //   "y": -58.4,
        //   "lake": "Mead",
        //   "color": "#E7A1658"
        // },
        // {
        //   "x": "1945-09-01",
        //   "y": -54.05,
        //   "lake": "Mead",
        //   "color": "#E7A1659"
        // },
        // {
        //   "x": "1945-08-01",
        //   "y": -54.05,
        //   "lake": "Mead",
        //   "color": "#E7A1660"
        // },
        // {
        //   "x": "1945-07-01",
        //   "y": -51.3,
        //   "lake": "Mead",
        //   "color": "#E7A1661"
        // },
        // {
        //   "x": "1945-06-01",
        //   "y": -57.45,
        //   "lake": "Mead",
        //   "color": "#E7A1662"
        // },
        // {
        //   "x": "1945-05-01",
        //   "y": -70.7,
        //   "lake": "Mead",
        //   "color": "#E7A1663"
        // },
        // {
        //   "x": "1945-04-01",
        //   "y": -84.6,
        //   "lake": "Mead",
        //   "color": "#E7A1664"
        // },
        // {
        //   "x": "1945-03-01",
        //   "y": -82.4,
        //   "lake": "Mead",
        //   "color": "#E7A1665"
        // },
        // {
        //   "x": "1945-02-01",
        //   "y": -76.25,
        //   "lake": "Mead",
        //   "color": "#E7A1666"
        // },
        // {
        //   "x": "1945-01-01",
        //   "y": -70.75,
        //   "lake": "Mead",
        //   "color": "#E7A1667"
        // },
        // {
        //   "x": "1944-12-01",
        //   "y": -64,
        //   "lake": "Mead",
        //   "color": "#E7A1668"
        // },
        // {
        //   "x": "1944-11-01",
        //   "y": -57.45,
        //   "lake": "Mead",
        //   "color": "#E7A1669"
        // },
        // {
        //   "x": "1944-10-01",
        //   "y": -51.4,
        //   "lake": "Mead",
        //   "color": "#E7A1670"
        // },
        // {
        //   "x": "1944-09-01",
        //   "y": -44.9,
        //   "lake": "Mead",
        //   "color": "#E7A1671"
        // },
        // {
        //   "x": "1944-08-01",
        //   "y": -38.1,
        //   "lake": "Mead",
        //   "color": "#E7A1672"
        // },
        // {
        //   "x": "1944-07-01",
        //   "y": -32.45,
        //   "lake": "Mead",
        //   "color": "#E7A1673"
        // },
        // {
        //   "x": "1944-06-01",
        //   "y": -37.15,
        //   "lake": "Mead",
        //   "color": "#E7A1674"
        // },
        // {
        //   "x": "1944-05-01",
        //   "y": -58.8,
        //   "lake": "Mead",
        //   "color": "#E7A1675"
        // },
        // {
        //   "x": "1944-04-01",
        //   "y": -73.9,
        //   "lake": "Mead",
        //   "color": "#E7A1676"
        // },
        // {
        //   "x": "1944-03-01",
        //   "y": -73.6,
        //   "lake": "Mead",
        //   "color": "#E7A1677"
        // },
        // {
        //   "x": "1944-02-01",
        //   "y": -67.8,
        //   "lake": "Mead",
        //   "color": "#E7A1678"
        // },
        // {
        //   "x": "1944-01-01",
        //   "y": -61.1,
        //   "lake": "Mead",
        //   "color": "#E7A1679"
        // },
        // {
        //   "x": "1943-12-01",
        //   "y": -53.65,
        //   "lake": "Mead",
        //   "color": "#E7A1680"
        // },
        // {
        //   "x": "1943-11-01",
        //   "y": -47.5,
        //   "lake": "Mead",
        //   "color": "#E7A1681"
        // },
        // {
        //   "x": "1943-10-01",
        //   "y": -42.1,
        //   "lake": "Mead",
        //   "color": "#E7A1682"
        // },
        // {
        //   "x": "1943-09-01",
        //   "y": -36.3,
        //   "lake": "Mead",
        //   "color": "#E7A1683"
        // },
        // {
        //   "x": "1943-08-01",
        //   "y": -32.35,
        //   "lake": "Mead",
        //   "color": "#E7A1684"
        // },
        // {
        //   "x": "1943-07-01",
        //   "y": -30.45,
        //   "lake": "Mead",
        //   "color": "#E7A1685"
        // },
        // {
        //   "x": "1943-06-01",
        //   "y": -32.6,
        //   "lake": "Mead",
        //   "color": "#E7A1686"
        // },
        // {
        //   "x": "1943-05-01",
        //   "y": -43.25,
        //   "lake": "Mead",
        //   "color": "#E7A1687"
        // },
        // {
        //   "x": "1943-04-01",
        //   "y": -51.4,
        //   "lake": "Mead",
        //   "color": "#E7A1688"
        // },
        // {
        //   "x": "1943-03-01",
        //   "y": -55,
        //   "lake": "Mead",
        //   "color": "#E7A1689"
        // },
        // {
        //   "x": "1943-02-01",
        //   "y": -52.4,
        //   "lake": "Mead",
        //   "color": "#E7A1690"
        // },
        // {
        //   "x": "1943-01-01",
        //   "y": -49.55,
        //   "lake": "Mead",
        //   "color": "#E7A1691"
        // },
        // {
        //   "x": "1942-12-01",
        //   "y": -43.6,
        //   "lake": "Mead",
        //   "color": "#E7A1692"
        // },
        // {
        //   "x": "1942-11-01",
        //   "y": -37.95,
        //   "lake": "Mead",
        //   "color": "#E7A1693"
        // },
        // {
        //   "x": "1942-10-01",
        //   "y": -32.75,
        //   "lake": "Mead",
        //   "color": "#E7A1694"
        // },
        // {
        //   "x": "1942-09-01",
        //   "y": -27,
        //   "lake": "Mead",
        //   "color": "#E7A1695"
        // },
        // {
        //   "x": "1942-08-01",
        //   "y": -21.6,
        //   "lake": "Mead",
        //   "color": "#E7A1696"
        // },
        // {
        //   "x": "1942-07-01",
        //   "y": -18.8,
        //   "lake": "Mead",
        //   "color": "#E7A1697"
        // },
        // {
        //   "x": "1942-06-01",
        //   "y": -18.85,
        //   "lake": "Mead",
        //   "color": "#E7A1698"
        // },
        // {
        //   "x": "1942-05-01",
        //   "y": -36.5,
        //   "lake": "Mead",
        //   "color": "#E7A1699"
        // },
        // {
        //   "x": "1942-04-01",
        //   "y": -49.1,
        //   "lake": "Mead",
        //   "color": "#E7A1700"
        // },
        // {
        //   "x": "1942-03-01",
        //   "y": -60.75,
        //   "lake": "Mead",
        //   "color": "#E7A1701"
        // },
        // {
        //   "x": "1942-02-01",
        //   "y": -55.25,
        //   "lake": "Mead",
        //   "color": "#E7A1702"
        // },
        // {
        //   "x": "1942-01-01",
        //   "y": -47.2,
        //   "lake": "Mead",
        //   "color": "#E7A1703"
        // },
        // {
        //   "x": "1941-12-01",
        //   "y": -36.2,
        //   "lake": "Mead",
        //   "color": "#E7A1704"
        // },
        // {
        //   "x": "1941-11-01",
        //   "y": -26.4,
        //   "lake": "Mead",
        //   "color": "#E7A1705"
        // },
        // {
        //   "x": "1941-10-01",
        //   "y": -20.75,
        //   "lake": "Mead",
        //   "color": "#E7A1706"
        // },
        // {
        //   "x": "1941-09-01",
        //   "y": -22.15,
        //   "lake": "Mead",
        //   "color": "#E7A1707"
        // },
        // {
        //   "x": "1941-08-01",
        //   "y": -15.5,
        //   "lake": "Mead",
        //   "color": "#E7A1708"
        // },
        // {
        //   "x": "1941-07-01",
        //   "y": -11.6,
        //   "lake": "Mead",
        //   "color": "#E7A1709"
        // },
        // {
        //   "x": "1941-06-01",
        //   "y": -16.45,
        //   "lake": "Mead",
        //   "color": "#E7A1710"
        // },
        // {
        //   "x": "1941-05-01",
        //   "y": -30.75,
        //   "lake": "Mead",
        //   "color": "#E7A1711"
        // },
        // {
        //   "x": "1941-04-01",
        //   "y": -56.15,
        //   "lake": "Mead",
        //   "color": "#E7A1712"
        // },
        // {
        //   "x": "1941-03-01",
        //   "y": -61.65,
        //   "lake": "Mead",
        //   "color": "#E7A1713"
        // },
        // {
        //   "x": "1941-02-01",
        //   "y": -64.45,
        //   "lake": "Mead",
        //   "color": "#E7A1714"
        // },
        // {
        //   "x": "1941-01-01",
        //   "y": -64.9,
        //   "lake": "Mead",
        //   "color": "#E7A1715"
        // },
        // {
        //   "x": "1940-12-01",
        //   "y": -63.95,
        //   "lake": "Mead",
        //   "color": "#E7A1716"
        // },
        // {
        //   "x": "1940-11-01",
        //   "y": -61.2,
        //   "lake": "Mead",
        //   "color": "#E7A1717"
        // },
        // {
        //   "x": "1940-10-01",
        //   "y": -58.25,
        //   "lake": "Mead",
        //   "color": "#E7A1718"
        // },
        // {
        //   "x": "1940-09-01",
        //   "y": -57.55,
        //   "lake": "Mead",
        //   "color": "#E7A1719"
        // },
        // {
        //   "x": "1940-08-01",
        //   "y": -55.9,
        //   "lake": "Mead",
        //   "color": "#E7A1720"
        // },
        // {
        //   "x": "1940-07-01",
        //   "y": -52.2,
        //   "lake": "Mead",
        //   "color": "#E7A1721"
        // },
        // {
        //   "x": "1940-06-01",
        //   "y": -49.85,
        //   "lake": "Mead",
        //   "color": "#E7A1722"
        // },
        // {
        //   "x": "1940-05-01",
        //   "y": -56.65,
        //   "lake": "Mead",
        //   "color": "#E7A1723"
        // },
        // {
        //   "x": "1940-04-01",
        //   "y": -67.15,
        //   "lake": "Mead",
        //   "color": "#E7A1724"
        // },
        // {
        //   "x": "1940-03-01",
        //   "y": -67.35,
        //   "lake": "Mead",
        //   "color": "#E7A1725"
        // },
        // {
        //   "x": "1940-02-01",
        //   "y": -66,
        //   "lake": "Mead",
        //   "color": "#E7A1726"
        // },
        // {
        //   "x": "1940-01-01",
        //   "y": -65.1,
        //   "lake": "Mead",
        //   "color": "#E7A1727"
        // },
        // {
        //   "x": "1939-12-01",
        //   "y": -62.05,
        //   "lake": "Mead",
        //   "color": "#E7A1728"
        // },
        // {
        //   "x": "1939-11-01",
        //   "y": -58.95,
        //   "lake": "Mead",
        //   "color": "#E7A1729"
        // },
        // {
        //   "x": "1939-10-01",
        //   "y": -55.9,
        //   "lake": "Mead",
        //   "color": "#E7A1730"
        // },
        // {
        //   "x": "1939-09-01",
        //   "y": -53.05,
        //   "lake": "Mead",
        //   "color": "#E7A1731"
        // },
        // {
        //   "x": "1939-08-01",
        //   "y": -54.65,
        //   "lake": "Mead",
        //   "color": "#E7A1732"
        // },
        // {
        //   "x": "1939-07-01",
        //   "y": -50.7,
        //   "lake": "Mead",
        //   "color": "#E7A1733"
        // },
        // {
        //   "x": "1939-06-01",
        //   "y": -48.55,
        //   "lake": "Mead",
        //   "color": "#E7A1734"
        // },
        // {
        //   "x": "1939-05-01",
        //   "y": -55.8,
        //   "lake": "Mead",
        //   "color": "#E7A1735"
        // },
        // {
        //   "x": "1939-04-01",
        //   "y": -69.1,
        //   "lake": "Mead",
        //   "color": "#E7A1736"
        // },
        // {
        //   "x": "1939-03-01",
        //   "y": -73.8,
        //   "lake": "Mead",
        //   "color": "#E7A1737"
        // },
        // {
        //   "x": "1939-02-01",
        //   "y": -74.6,
        //   "lake": "Mead",
        //   "color": "#E7A1738"
        // },
        // {
        //   "x": "1939-01-01",
        //   "y": -66.6,
        //   "lake": "Mead",
        //   "color": "#E7A1739"
        // },
        // {
        //   "x": "1938-12-01",
        //   "y": -63.35,
        //   "lake": "Mead",
        //   "color": "#E7A1740"
        // },
        // {
        //   "x": "1938-11-01",
        //   "y": -63.3,
        //   "lake": "Mead",
        //   "color": "#E7A1741"
        // },
        // {
        //   "x": "1938-10-01",
        //   "y": -60.55,
        //   "lake": "Mead",
        //   "color": "#E7A1742"
        // },
        // {
        //   "x": "1938-09-01",
        //   "y": -58.2,
        //   "lake": "Mead",
        //   "color": "#E7A1743"
        // },
        // {
        //   "x": "1938-08-01",
        //   "y": -60.05,
        //   "lake": "Mead",
        //   "color": "#E7A1744"
        // },
        // {
        //   "x": "1938-07-01",
        //   "y": -58.5,
        //   "lake": "Mead",
        //   "color": "#E7A1745"
        // },
        // {
        //   "x": "1938-06-01",
        //   "y": -66.4,
        //   "lake": "Mead",
        //   "color": "#E7A1746"
        // },
        // {
        //   "x": "1938-05-01",
        //   "y": -97.6,
        //   "lake": "Mead",
        //   "color": "#E7A1747"
        // },
        // {
        //   "x": "1938-04-01",
        //   "y": -122.8,
        //   "lake": "Mead",
        //   "color": "#E7A1748"
        // },
        // {
        //   "x": "1938-03-01",
        //   "y": -131.8,
        //   "lake": "Mead",
        //   "color": "#E7A1749"
        // },
        // {
        //   "x": "1938-02-01",
        //   "y": -137.15,
        //   "lake": "Mead",
        //   "color": "#E7A1750"
        // },
        // {
        //   "x": "1938-01-01",
        //   "y": -137,
        //   "lake": "Mead",
        //   "color": "#E7A1751"
        // },
        // {
        //   "x": "1937-12-01",
        //   "y": -136.25,
        //   "lake": "Mead",
        //   "color": "#E7A1752"
        // },
        // {
        //   "x": "1937-11-01",
        //   "y": -136,
        //   "lake": "Mead",
        //   "color": "#E7A1753"
        // },
        // {
        //   "x": "1937-10-01",
        //   "y": -135.3,
        //   "lake": "Mead",
        //   "color": "#E7A1754"
        // },
        // {
        //   "x": "1937-09-01",
        //   "y": -134.4,
        //   "lake": "Mead",
        //   "color": "#E7A1755"
        // },
        // {
        //   "x": "1937-08-01",
        //   "y": -132.4,
        //   "lake": "Mead",
        //   "color": "#E7A1756"
        // },
        // {
        //   "x": "1937-07-01",
        //   "y": -129.2,
        //   "lake": "Mead",
        //   "color": "#E7A1757"
        // },
        // {
        //   "x": "1937-06-01",
        //   "y": -135.4,
        //   "lake": "Mead",
        //   "color": "#E7A1758"
        // },
        // {
        //   "x": "1937-05-01",
        //   "y": -153.3,
        //   "lake": "Mead",
        //   "color": "#E7A1759"
        // },
        // {
        //   "x": "1937-04-01",
        //   "y": -187.4,
        //   "lake": "Mead",
        //   "color": "#E7A1760"
        // },
        // {
        //   "x": "1937-03-01",
        //   "y": -201,
        //   "lake": "Mead",
        //   "color": "#E7A1761"
        // },
        // {
        //   "x": "1937-02-01",
        //   "y": -205.8,
        //   "lake": "Mead",
        //   "color": "#E7A1762"
        // },
        // {
        //   "x": "1937-01-01",
        //   "y": -209.8,
        //   "lake": "Mead",
        //   "color": "#E7A1763"
        // },
        // {
        //   "x": "1936-12-01",
        //   "y": -208.5,
        //   "lake": "Mead",
        //   "color": "#E7A1764"
        // },
        // {
        //   "x": "1936-11-01",
        //   "y": -208.4,
        //   "lake": "Mead",
        //   "color": "#E7A1765"
        // },
        // {
        //   "x": "1936-10-01",
        //   "y": -209.4,
        //   "lake": "Mead",
        //   "color": "#E7A1766"
        // },
        // {
        //   "x": "1936-09-01",
        //   "y": -207.4,
        //   "lake": "Mead",
        //   "color": "#E7A1767"
        // },
        // {
        //   "x": "1936-08-01",
        //   "y": -207.6,
        //   "lake": "Mead",
        //   "color": "#E7A1768"
        // },
        // {
        //   "x": "1936-07-01",
        //   "y": -211.6,
        //   "lake": "Mead",
        //   "color": "#E7A1769"
        // },
        // {
        //   "x": "1936-06-01",
        //   "y": -216.5,
        //   "lake": "Mead",
        //   "color": "#E7A1770"
        // },
        // {
        //   "x": "1936-05-01",
        //   "y": -249.6,
        //   "lake": "Mead",
        //   "color": "#E7A1771"
        // },
        // {
        //   "x": "1936-04-01",
        //   "y": -309.8,
        //   "lake": "Mead",
        //   "color": "#E7A1772"
        // },
        // {
        //   "x": "1936-03-01",
        //   "y": -325.1,
        //   "lake": "Mead",
        //   "color": "#E7A1773"
        // },
        // {
        //   "x": "1936-02-01",
        //   "y": -323.6,
        //   "lake": "Mead",
        //   "color": "#E7A1774"
        // },
        // {
        //   "x": "1936-01-01",
        //   "y": -324.1,
        //   "lake": "Mead",
        //   "color": "#E7A1775"
        // },
        // {
        //   "x": "1935-12-01",
        //   "y": -323.6,
        //   "lake": "Mead",
        //   "color": "#E7A1776"
        // },
        // {
        //   "x": "1935-11-01",
        //   "y": -323.7,
        //   "lake": "Mead",
        //   "color": "#E7A1777"
        // },
        // {
        //   "x": "1935-10-01",
        //   "y": -317.1,
        //   "lake": "Mead",
        //   "color": "#E7A1778"
        // },
        // {
        //   "x": "1935-09-01",
        //   "y": -311.2,
        //   "lake": "Mead",
        //   "color": "#E7A1779"
        // },
        // {
        //   "x": "1935-08-01",
        //   "y": -306.1,
        //   "lake": "Mead",
        //   "color": "#E7A1780"
        // },
        // {
        //   "x": "1935-07-01",
        //   "y": -303.6,
        //   "lake": "Mead",
        //   "color": "#E7A1781"
        // },
        // {
        //   "x": "1935-06-01",
        //   "y": -322.9,
        //   "lake": "Mead",
        //   "color": "#E7A1782"
        // },
        // {
        //   "x": "1935-05-01",
        //   "y": -425.4,
        //   "lake": "Mead",
        //   "color": "#E7A1783"
        // },
        // {
        //   "x": "1935-04-01",
        //   "y": -479.6,
        //   "lake": "Mead",
        //   "color": "#E7A1784"
        // },
        // {
        //   "x": "1935-03-01",
        //   "y": -530.3,
        //   "lake": "Mead",
        //   "color": "#E7A1785"
        // },
        // {
        //   "x": "1935-02-01",
        //   "y": -523.3,
        //   "lake": "Mead",
        //   "color": "#E7A1786"
        // },
        // {
        //   "x": "1935-01-01",
        //   "y": -1232,
        //   "lake": "Mead",
        //   "color": "#E7A1787"
        // }
      ]
    }
  ]

function ReservoirLevels({ data }) {
    return <div style={{ display: 'block', height: '350px', maxWidth: '640px' }}>
        <ResponsiveLine
            data={powellMeadLevels}
            margin={{ top: 50, right: 10, bottom: 20, left: 55 }}
            xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false, precision: 'month',}}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min: -250, max: 0, stacked: false, reverse: false }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickValues:4,
              format: '%Y',
              tickRotation: 0
          }}
            axisLeft={{
                orient: 'left',                
                tickRotation: 0,
                tickValues:[0,-50,-100,-150,-200,-250],
                legend: 'Feet Below Full',
                legendPosition: 'middle',
                legendOffset: '-50'
            }}
            colors={{datum: 'color' }}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="Degrees Farenheit"
            pointLabelYOffset={-12}
            useMesh={true}
            animate={false}
            layers={[
                "axes", "lines", "points", "legends", "markers"
              ]}
            theme={{
                axis: {
                    fontSize: "14px",
                    tickColor: "#89969F",
                    legend: {
                      text: {
                          fill: "rgba(255, 255, 255, 0.87)",
                          fontFamily: "Roboto Slab"
                      }
                    },
                    ticks: {
                        line: {
                            stroke: "#89969F"
                        },
                        text: {
                            fill: "#ffffff"
                        }
                    },
                    domain: {
                        line: {
                          stroke: '#89969F',
                          strokeWidth: 1
                        }
                      },
                },
                grid: {
                    line: {
                        stroke: "#89969F"
                    }
                },
                labels: {
                    text: {
                        fill: "#89969F"
                    }
                },
                tooltip: {
                    container: {
                        background: '#222222',
                    },
                },
                crosshair: {
                    line: {
                        stroke: 'white',
                        strokeWidth: 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: '6 0'
                    }
                }
            }}
            markers={[
                {
                    axis: 'y',
                    value: -182,
                    lineStyle: { stroke: '#E7A744', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-right',
                    legend: 'Mead Min Power Level',
                    textStyle: {
                        fill: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }
                },
                // {
                //     axis: 'y',
                //     value: 104.7,
                //     lineStyle: { stroke: '#E7A744', strokeWidth: 2, strokeDasharray: '3, 3' },
                //     legendPosition: 'bottom-left',
                //     legend: '2010-2019 Avg High: 104.7',
                //     textStyle: {
                //         fill: 'white',
                //         fontSize: '12px',
                //     },
                //     textStyle: {
                //         fill: 'white',
                //         fontSize: '12px',
                //     },
                // },
                {
                    axis: 'y',
                    value: -210,
                    lineStyle: { stroke: '#63A2EC', strokeWidth: 2, strokeDasharray: '3, 3' },
                    legendPosition: 'bottom-right',
                    legend: 'Powell Min Power Level',
                    textStyle: {
                        fill: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        webkitTextStrokeColor: 'red',
                        webkitTextStrokeWidth: 2
                    }
                },
                // {
                //     axis: 'y',
                //     value: 102,
                //     lineStyle: { stroke: '#63A2EC', strokeWidth: 2, strokeDasharray: '3, 3' },
                //     legendPosition: 'bottom-right',
                //     legend: '1950-1959 Avg High: 102',
                //     textStyle: {
                //         fill: 'white',
                //         fontSize: '12px',
                //     },
                //     textStyle: {
                //         fill: 'white',
                //         fontSize: '12px',
                //     },
                // }
            ]}
            tooltip={({ point }) => (
                <div
                    style={{
                        color: 'white',
                        background: '#242424',
                        padding: '8px',
                        borderRadius: '4px'
                    }}>
                    <span
                        style={{
                            display: 'block',
                            marginBottom: '4px'
                        }}>
                        <span
                            style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                marginRight: '4px',
                                background: (point.data.linecolor)
                            }}>
                        </span>
                        {point.data.decade} Average
                    </span>
                    <span
                        style={{
                            display: 'block',
                            fontSize: '14px'
                        }}>
                        July {point.data.day}: {point.data.y} (°F)
                    </span>
                </div>)}
        />
    </div>
}

export default ReservoirLevels;