import SEO from "../../../../src/components/seo";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Gigaton28k from "../../../../src/images/28k-gigatons-inset.png";
import Pedersen from "../../../../src/images/pedersen-glacier.png";
import GigatonOpenGraphImage from "../../../../src/images/social-gigaton.png";
import * as React from 'react';
export default {
  SEO,
  graphql,
  Img,
  Gigaton28k,
  Pedersen,
  GigatonOpenGraphImage,
  React
};