import SEO from "../../../../src/components/seo";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import ExtinctionSwarmViz from "../../../../src/components/chart-extinction";
import ExtinctionOpenGraphImage from "../../../../src/images/social-extinction.jpg";
import * as React from 'react';
export default {
  SEO,
  graphql,
  Img,
  ExtinctionSwarmViz,
  ExtinctionOpenGraphImage,
  React
};